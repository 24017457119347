import { Config } from '../../util/config';


// Function Convertin colIndex to Perticular Cell..
const getColumnLetter = (colIndex) => {
    let letter = '';
    while (colIndex > 0) {
      const remainder = colIndex % 26;
      letter = String.fromCharCode((remainder === 0 ? 26 : remainder) + 64) + letter;
      colIndex = Math.floor((colIndex - 1) / 26);
    }
    return letter;
}


// Reference Setter between file / link / text...
const ReferenceSetterFunc = (currentRentData, type, index, colIndex) => {
    let refVal = currentRentData[type][index][`refrence_${Math.ceil(colIndex/3)}`];
    if(typeof(refVal) === 'object' && refVal['filename'] !== undefined){
        return {
            text: `File - ${refVal['originalname']}`,
            hyperlink: `${Config.ImageUrl}${refVal['filename']}`,
        }
    }else if(typeof(refVal) === 'string'){
        let urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        if(urlRegex.test(refVal)){
            return {
                text: `Link - ${refVal}`,
                hyperlink: refVal,
            }
        }
        return refVal;
    }
    return '';
    
}


const DepreciationFileFunc = (currentRentData, type, index, jIndex, prop ) => {
    let myFile = currentRentData[type][index][jIndex][prop];

    if(typeof(myFile) === 'object' && myFile['filename'] !== undefined) {
        return {
            text: `File - ${myFile['originalname']}`,
            hyperlink: `${Config.ImageUrl}${myFile['filename']}`,
        }
    }
    return '';
}



// Styles..
const styles = {
    borderStyles: {
        border: {
            top: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' }}
        }
    },
    textBold: {
        font: { bold: true },
        border: {
            top: { style: 'thin', color: { argb: '000000' } },    
            bottom: { style: 'thin', color: { argb: '000000' } },
        }
    },
    borderAll: { 
        font: { bold: true },
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
}



export const RentPage = (workbook, data, clientData, clientYear) => {
    let rentSheet = workbook.getWorksheet('21');


    rentSheet.getCell('B4').value = clientData.name;
    rentSheet.getCell('B6').value = clientYear;
    rentSheet.getCell('E5').value = clientData.staff;
    rentSheet.getCell('E6').value = clientData.manager;
    rentSheet.getCell('E7').value = clientData.partner;

    const currentRentData = (data.rent.length!==0)? data.rent[0] : null;
    if(currentRentData === undefined || currentRentData === null){
        return;
    }


    rentSheet.getCell('B7').value = currentRentData['preparedDate'];

    let objLength = Object.keys(currentRentData['propertyData'][0]).length - 2;
    let rowStartingIndex = 9;

    
    // Table Heading Section..
    let totalProrperties = Math.ceil(objLength/3);
    let lastProperty = totalProrperties;
    for(let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
        let currentProperty = totalProrperties - (lastProperty - 1);

        let propertyLetter = getColumnLetter(colIndex);
        let referenceLetter = getColumnLetter(colIndex+1);

        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value =  `Property ${currentProperty}`;
        rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).value =  `Reference`;
            
        lastProperty -= 1;
        colIndex += 3;
    }
    rowStartingIndex += 3;

    
    // Property Section..
    for(let i=0; i<currentRentData['propertyData'].length; i++){
        for (let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
            let propertyLetter = getColumnLetter(colIndex);
            let referenceLetter = getColumnLetter(colIndex+1);
            let commentLetter = getColumnLetter(colIndex+2);

            if(i===1){
                rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = currentRentData['propertyData'][i][`property_${Math.ceil(colIndex/3)}`];
            }
            else if(i===2){
                rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['propertyData'][i][`property_${Math.ceil(colIndex/3)}`]);
            }else if(i===3){
                rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['propertyData'][i][`property_${Math.ceil(colIndex/3)}`])/100;
                rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt = '0.00%';
            }else{
                rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = currentRentData['propertyData'][i][`property_${Math.ceil(colIndex/3)}`];
            }

            rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).value = ReferenceSetterFunc(currentRentData, 'propertyData', i, colIndex);
            rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).value = currentRentData['propertyData'][i][`comment_${Math.ceil(colIndex/3)}`];

            colIndex += 3;
        }

        rowStartingIndex += 1;
    }

    rowStartingIndex += 2;


    
    // Income Data Section..
    for(let i=0; i<currentRentData['incomeData'].length; i++) {
        for (let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
            let propertyLetter = getColumnLetter(colIndex);
            let referenceLetter = getColumnLetter(colIndex+1);
            let commentLetter = getColumnLetter(colIndex+2);

            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = currentRentData['incomeData'][i][`property_${Math.ceil(colIndex/3)}`]!=='-'?  Number(currentRentData['incomeData'][i][`property_${Math.ceil(colIndex/3)}`]) : 0;
            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).value = ReferenceSetterFunc(currentRentData, 'incomeData', i, colIndex);
            rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).value = currentRentData['incomeData'][i][`comment_${Math.ceil(colIndex/3)}`];

            colIndex += 3;
        }

        rowStartingIndex += 1;
    }

   
    //Gross Rent..
    for(let colIndex=2; colIndex <= 702 && colIndex<=objLength+1; ){
        let propertyLetter = getColumnLetter(colIndex);

        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['grossRentData'][`property_${Math.ceil(colIndex/3)}`]);
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';

        colIndex += 3;
    }
    rowStartingIndex += 3;


    
    // Expenses Section..
    let nameLetter = getColumnLetter(1);
    for(let i=0; i<currentRentData['expensesData'].length; i++) {
        rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = currentRentData['expensesData'][i]['name'];

        for (let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
            let propertyLetter = getColumnLetter(colIndex);
            let referenceLetter = getColumnLetter(colIndex+1);
            let commentLetter = getColumnLetter(colIndex+2);

            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = currentRentData['expensesData'][i][`property_${Math.ceil(colIndex/3)}`]!=='-'? Number(currentRentData['expensesData'][i][`property_${Math.ceil(colIndex/3)}`]) : 0;
            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).value = ReferenceSetterFunc(currentRentData, 'expensesData', i, colIndex);
            rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).value = currentRentData['expensesData'][i][`comment_${Math.ceil(colIndex/3)}`];

            colIndex += 3;
        }
        
        rowStartingIndex += 1;
    }
    rowStartingIndex += 1;


    
    // Total Expenses..
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = 'Total Expenses';
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).style = styles.textBold;
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).alignment  = {horizontal: 'right'};
    rentSheet.getRow(rowStartingIndex).height = 15;

    
    for(let colIndex=2; colIndex <= 702 && colIndex<=objLength+1; ){
        let propertyLetter = getColumnLetter(colIndex);
        let referenceLetter = getColumnLetter(colIndex+1);
        let commentLetter = getColumnLetter(colIndex+2);

        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['totalExpensesData'][`property_${Math.ceil(colIndex/3)}`]);
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).style = styles.borderStyles;
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
        rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).style = styles.borderStyles;
        rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).style = styles.borderStyles;

        colIndex += 3;
    }
    rowStartingIndex += 1;


    
    // NetRent..
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = 'Net Rent';
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).style = styles.textBold;
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).alignment  = {horizontal: 'right'};
    rentSheet.getRow(rowStartingIndex).height = 15;

    for(let colIndex=2; colIndex <= 702 && colIndex<=objLength+1; ){
        let propertyLetter = getColumnLetter(colIndex);
        let referenceLetter = getColumnLetter(colIndex+1);
        let commentLetter = getColumnLetter(colIndex+2);

        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['netRentData'][`property_${Math.ceil(colIndex/3)}`]);
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).style = styles.borderStyles;
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
        rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).style = styles.borderStyles;
        rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).style = styles.borderStyles;

        colIndex += 3;
    }
    rowStartingIndex += 3;



    // Setting Total..
    rentSheet.getCell(`D${rowStartingIndex}`).value = 'Total';
    rentSheet.getCell(`D${rowStartingIndex}`).style = styles.borderAll;
    rentSheet.getCell(`E${rowStartingIndex}`).value = Number(currentRentData['total']);
    rentSheet.getCell(`E${rowStartingIndex}`).style = styles.borderAll;
    rentSheet.getCell(`E${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
    rowStartingIndex += 3;
    


    // Reconcoliation Rental StatementSection..
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = 'Reconciliation to rental statement';
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).style = {font: { bold: true },};
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).alignment  = {horizontal: 'left'};
    rentSheet.getRow(rowStartingIndex).height = 15;
    rowStartingIndex += 1;
    
    for(let i=0; i<currentRentData['reconciliationRentData'].length; i++) {
        rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = currentRentData['reconciliationRentData'][i]['name'];

        for(let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
            let propertyLetter = getColumnLetter(colIndex);
            let referenceLetter = getColumnLetter(colIndex+1);
            let commentLetter = getColumnLetter(colIndex+2);

            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['reconciliationRentData'][i][`property_${Math.ceil(colIndex/3)}`]);
            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).value = ReferenceSetterFunc(currentRentData, 'reconciliationRentData', i, colIndex);
            rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).value = currentRentData['reconciliationRentData'][i][`comment_${Math.ceil(colIndex/3)}`];

            colIndex += 3;
        }
        
        rowStartingIndex += 1;
    }
    rowStartingIndex += 3;



    // NRIP Section..
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = 'Net Rental Income Per Statement';
    for(let i=0; i<currentRentData['NRIPerStatement'].length; i++) {
        rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = currentRentData['NRIPerStatement'][i]['name'];

        for (let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
            let propertyLetter = getColumnLetter(colIndex);
            let referenceLetter = getColumnLetter(colIndex+1);
            let commentLetter = getColumnLetter(colIndex+2);

            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['NRIPerStatement'][i][`property_${Math.ceil(colIndex/3)}`]);
            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).value = ReferenceSetterFunc(currentRentData, 'NRIPerStatement', i, colIndex);
            rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).value = currentRentData['NRIPerStatement'][i][`comment_${Math.ceil(colIndex/3)}`];

            colIndex += 3;
        }
        
        rowStartingIndex += 1;
    }


    // Difference Section..
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = 'Difference';
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).style = styles.textBold;
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).alignment  = {horizontal: 'left'};
    rentSheet.getRow(rowStartingIndex).height = 15;
    for(let colIndex=2; colIndex <= 702 && colIndex<=objLength+1; ){
        let propertyLetter = getColumnLetter(colIndex);
        let referenceLetter = getColumnLetter(colIndex+1);
        let commentLetter = getColumnLetter(colIndex+2);

        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['difference'][`property_${Math.ceil(colIndex/3)}`]);
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).style = styles.borderStyles;
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
        rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).style = styles.borderStyles;
        rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).style = styles.borderStyles;

        colIndex += 3;
    }

    rowStartingIndex += 3;



    // Repairs & Maintanance Section..
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = 'Repairs & Maintenance';
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).style = {font: { bold: true }};
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).alignment  = {horizontal: 'left'};
    rentSheet.getRow(rowStartingIndex).height = 15;
    
    for (let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
        let propertyLetter = getColumnLetter(colIndex); 
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = currentRentData['propertyData'][0][`property_${Math.ceil(colIndex/3)}`];
        colIndex += 3;
    }
    rowStartingIndex += 1;
    
    for(let i=0; i<currentRentData['repairMaintenanceData'].length; i++) {
        rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = currentRentData['repairMaintenanceData'][i]['name'];

        for (let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
            let propertyLetter = getColumnLetter(colIndex);
            let referenceLetter = getColumnLetter(colIndex+1);
            let commentLetter = getColumnLetter(colIndex+2);

            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['repairMaintenanceData'][i][`property_${Math.ceil(colIndex/3)}`]);
            rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).value = ReferenceSetterFunc(currentRentData, 'repairMaintenanceData', i, colIndex);
            rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).value = currentRentData['repairMaintenanceData'][i][`comment_${Math.ceil(colIndex/3)}`];

            colIndex += 3;
        }
        
        rowStartingIndex += 1;
    }

    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).style = styles.textBold;
    for(let colIndex=2; colIndex <= 702 && colIndex<=objLength+1; ){
        let propertyLetter = getColumnLetter(colIndex);
        let referenceLetter = getColumnLetter(colIndex+1);
        let commentLetter = getColumnLetter(colIndex+2);

        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = Number(currentRentData['totalRepairMaintanace'][`property_${Math.ceil(colIndex/3)}`]);
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).style = styles.borderStyles;
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
        rentSheet.getCell(`${referenceLetter}${rowStartingIndex}`).style = styles.borderStyles;
        rentSheet.getCell(`${commentLetter}${rowStartingIndex}`).style = styles.borderStyles;

        colIndex += 3;
    }    
    rowStartingIndex += 3;



    // Depreciation Property Section..
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).value = 'Depreciation';
    rentSheet.getCell(`${nameLetter}${rowStartingIndex}`).style = {font: { bold: true }};

    for (let colIndex = 2; colIndex <= 702 && colIndex<=objLength+1; ) {
        let propertyLetter = getColumnLetter(colIndex); 
        let myProperty = currentRentData['propertyData'][0][`property_${Math.ceil(colIndex/3)}`]
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).value = `PROPERTY ${Math.ceil(colIndex/3)} - ${myProperty}`;
        rentSheet.mergeCells(rowStartingIndex, colIndex, rowStartingIndex, colIndex+2);
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).style = {font: { bold: true },};
        rentSheet.getCell(`${propertyLetter}${rowStartingIndex}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        colIndex += 3;
    }
    rowStartingIndex += 1;

    let horizontalLetter = 2;
    for(let i=0; i<currentRentData['depreciatePropsData'].length; i++) {

        let tempStartingIndex = rowStartingIndex;
        for(let j=0; j<currentRentData['depreciatePropsData'][i].length; j++){
            let depNameLetter = getColumnLetter(horizontalLetter);
            
            rentSheet.getCell(`${depNameLetter}${tempStartingIndex}`).value = currentRentData['depreciatePropsData'][i][j][`name`];
            rentSheet.getCell(`${depNameLetter}${tempStartingIndex}`).style = {font: { bold: true },};
            rentSheet.getCell(`${depNameLetter}${tempStartingIndex+1}`).value = DepreciationFileFunc(currentRentData, 'depreciatePropsData', i, j, 'attachment');
            rentSheet.getCell(`${depNameLetter}${tempStartingIndex+1}`).style = {font: { bold: true },};

            for(let k=0; k<currentRentData['depreciatePropsData'][i][j]['wdv_props'].length; k++) {
                let propNameLetter = getColumnLetter(horizontalLetter+1);
                let valueLetter = getColumnLetter(horizontalLetter+2);
                
                rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).value = currentRentData['depreciatePropsData'][i][j]['wdv_props'][k]['propName'];
                rentSheet.getCell(`${valueLetter}${tempStartingIndex}`).value = Number(currentRentData['depreciatePropsData'][i][j]['wdv_props'][k]['value']);
                if(k===5) {
                    rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).style = styles.borderStyles;
                    rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).alignment = { vertical: 'middle', horizontal: 'right', wrapText: true };
                    rentSheet.getCell(`${valueLetter}${tempStartingIndex}`).style = styles.borderStyles;
                }

                tempStartingIndex++;
            }
            
            if(currentRentData['depreciatePropsData'][i][j]['owdv_props']!== undefined && currentRentData['depreciatePropsData'][i][j]['owdv_props']!== null) {
                let propNameLetter = getColumnLetter(horizontalLetter+1);
                let valueLetter = getColumnLetter(horizontalLetter+2);
                rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).value = currentRentData['depreciatePropsData'][i][j]['owdv_year'];
                rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).style = styles.borderAll;
                rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                tempStartingIndex += 1;
                
                for(let k=0; k<currentRentData['depreciatePropsData'][i][j]['owdv_props'].length; k++) {

                    rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).value = currentRentData['depreciatePropsData'][i][j]['owdv_props'][k]['propName'];
                    rentSheet.getCell(`${valueLetter}${tempStartingIndex}`).value = Number(currentRentData['depreciatePropsData'][i][j]['owdv_props'][k]['value']);
                    if(k===3) {
                        rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).style = styles.borderStyles;
                        rentSheet.getCell(`${propNameLetter}${tempStartingIndex}`).alignment = { vertical: 'middle', horizontal: 'right', wrapText: true };
                        rentSheet.getCell(`${valueLetter}${tempStartingIndex}`).style = styles.borderStyles;
                    }
    
                    tempStartingIndex++;
                }
            }
            tempStartingIndex += 1;

        }

        horizontalLetter += 3;
    }
    

}
