
export const IndexPage = (indexSheet, clientData, clientYear) => {
    // Index Page HyperLink..
    indexSheet.getCell('A7').value = { text: 'Titan Partners', hyperlink: 'http://www.titanpartners.com.au/', tooltip: 'Visit Titan Partners'};
    indexSheet.getCell('C25').value = { text: 'Review Notes', hyperlink: '#\'A\'!A1' };
    indexSheet.getCell('C26').value = { text: 'Interview Notes', hyperlink: '#\'B\'!A1' };
    indexSheet.getCell('C27').value = { text: 'Current Year Quereies', hyperlink: '#\'C\'!A1' };
    indexSheet.getCell('C28').value = { text: 'Issues to be carried forward', hyperlink: '#\'D\'!A1' };
    indexSheet.getCell('C29').value = { text: 'Issues brought forward', hyperlink: '#\'E\'!A1' };
    indexSheet.getCell('C30').value = { text: 'ATO Reports', hyperlink: '#\'ATO Reports\'!A1' };
    indexSheet.getCell('C31').value = { text: 'Client Source Documents', hyperlink: '#\'Client Source Docs\'!A1' };
    indexSheet.getCell('C34').value = { text: 'Reconciliation to ITR check', hyperlink: '#\'F\'!A1' };
    indexSheet.getCell('C37').value = { text: 'Salary or Wages', hyperlink: '#\'1\'!A1' };
    indexSheet.getCell('C38').value = { text: 'Allowances, Earnings, etc', hyperlink: '#\'2\'!A1' };
    indexSheet.getCell('C39').value = { text: 'Emploter Lump Sum Payments', hyperlink: '#\'3\'!A1' };
    indexSheet.getCell('C40').value = { text: 'ETPs', hyperlink: '#\'4\'!A1' };
    indexSheet.getCell('C41').value = { text: 'Government Allowances', hyperlink: '#\'5\'!A1' };
    indexSheet.getCell('C42').value = { text: 'Government Pensions', hyperlink: '#\'6\'!A1' };
    indexSheet.getCell('C43').value = { text: 'Other Pensions / Annuities', hyperlink: '#\'7\'!A1' };
    indexSheet.getCell('C44').value = { text: 'Superannuation Lump Sum Payments', hyperlink: '#\'8\'!A1' };
    indexSheet.getCell('C45').value = { text: 'Attributed PSI', hyperlink: '#\'9\'!A1' };
    indexSheet.getCell('C46').value = { text: 'Gross Interest', hyperlink: '#\'10\'!A1' };
    indexSheet.getCell('C47').value = { text: 'Dividends', hyperlink: '#\'11\'!A1' };
    indexSheet.getCell('C48').value = { text: 'Employee Share Schemes', hyperlink: '#\'12\'!A1' };
    indexSheet.getCell('C49').value = { text: 'Partnerships and Trusts', hyperlink: '#\'13\'!A1' };
    indexSheet.getCell('C50').value = { text: 'PSI', hyperlink: '#\'14\'!A1' };
    indexSheet.getCell('C51').value = { text: 'Business Income', hyperlink: '#\'15\'!A1' };
    indexSheet.getCell('C52').value = { text: 'Capital Gains', hyperlink: '#\'18\'!A1' };
    indexSheet.getCell('C53').value = { text: 'Foreign Source Income', hyperlink: '#\'20\'!A1' };
    indexSheet.getCell('H25').value = { text: 'Motor Vehicles', hyperlink: '#\'D1\'!A1' };
    indexSheet.getCell('H26').value = { text: 'Travel', hyperlink: '#\'D2\'!A1' };
    indexSheet.getCell('H27').value = { text: 'Uniforms, Laundry etc', hyperlink: '#\'D3\'!A1' };
    indexSheet.getCell('H28').value = { text: 'Self Education', hyperlink: '#\'D4\'!A1' };
    indexSheet.getCell('H29').value = { text: 'Other Work Related', hyperlink: '#\'D5\'!A1' };
    indexSheet.getCell('H30').value = { text: 'Project Pool', hyperlink: '#\'D6\'!A1' };
    indexSheet.getCell('H31').value = { text: 'Interest', hyperlink: '#\'D7\'!A1' };
    indexSheet.getCell('H32').value = { text: 'Dividend', hyperlink: '#\'D8\'!A1' };
    indexSheet.getCell('H33').value = { text: 'Gifts or Donations', hyperlink: '#\'D9\'!A1' };
    indexSheet.getCell('H34').value = { text: 'Tax Affairs', hyperlink: '#\'D10\'!A1' };
    indexSheet.getCell('H35').value = { text: 'Personal Superannuation Contributions', hyperlink: '#\'D12\'!A1' };
    indexSheet.getCell('H36').value = { text: 'Other Deductions', hyperlink: '#\'D15\'!A1' };
    indexSheet.getCell('H41').value = { text: 'Private Health & Medicare', hyperlink: '#\'M\'!A1' };
    indexSheet.getCell('H44').value = { text: 'Spouse & Family Benefits', hyperlink: '#\'S\'!A1' };
    indexSheet.getCell('H47').value = { text: 'Tax Offsets', hyperlink: '#\'T\'!A1' };
    indexSheet.getCell('H50').value = { text: 'Adjustments', hyperlink: '#\'Z\'!A1' };


    // Modifying Index Page;
    indexSheet.getCell('C15').value = clientData.name;
    indexSheet.getCell('C21').value = clientYear;
    indexSheet.getCell('H15').value = clientData.partner;
    indexSheet.getCell('H17').value = clientData.manager;
    indexSheet.getCell('H19').value = clientData.staff;
    indexSheet.getCell('H21').value = clientYear;
    // indexSheet.getColumn(8).width = 15;
};
