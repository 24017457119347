import React, {useState, useContext} from 'react';
import './AttachFile.css';
// import { Icon } from '@iconify/react';
import DeleteIcon from '@mui/icons-material/Delete';

import { toast } from "react-toastify";
// import { Modal  } from 'rsuite';
// import 'rsuite/dist/rsuite.min.css';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {UploadImageAPI, DeleteImageAPI} from '../util/http';
import { AuthContext } from '../store/auth-context';
import { Config } from '../util/config';


const AttachFile = ({selectedFiles, setSelectedFiles, setDocsChange, setLoading}) => {
    const authCtx = useContext(AuthContext);
    // const [openModal, setOpenModal] = useState(false);
    // const [openFile, setOpenFile] = useState([]);


    const UploadFile = async(image, userToken) => {
        setLoading(true);
        const data = new FormData();
        data.append('file', image);        

        
        const uploadRes = await UploadImageAPI(data, userToken.token);
        if(uploadRes.success===true){
            toast.success(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
            
            setSelectedFiles(prevFile => [...prevFile, uploadRes.file]);
            setDocsChange(true);
        }else{
            toast.error(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        
        setLoading(false);
    }

    
    // File Picker Code..
    const onSelectFile = (event) => {
        const files = event.target.files;
        const selectedFilesArray = Array.from(files);
        
        const userToken = JSON.parse(authCtx.token)
        selectedFilesArray.forEach((image) => {
            UploadFile(image, userToken);
        });
    }


    const deleteFile = async(fileName) => {
        setLoading(true);
        const userToken = JSON.parse(authCtx.token)
        const deleteRes = await DeleteImageAPI(fileName, userToken.token);

        if(deleteRes.success===true){
            toast.success(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setSelectedFiles(selectedFiles.filter((item) => item.filename !== fileName));
            setDocsChange(true);
        }else{
            toast.error(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    } 

    const openFileInNewTab = (file) => {
        window.open(`${Config.ImageUrl}${file.filename}`, '_blank');
    };


    return (
        <>  
            <div>
                <div className="file-picker-container" style={selectedFiles.length===0? {display: 'none'} : {}}>
                    <input 
                        type="file"
                        name="file-picker"
                        id="file-picker"
                        onChange={onSelectFile}
                        onClick={(event)=> { 
                            event.target.value = null
                        }}
                        multiple
                        className='file-input'
                    />

                    <div className="file-preview-container">
                        {selectedFiles.map((item, index) => {
                            return(
                                <div key={index} className='file-file' onClick={(event) => {event.stopPropagation(); openFileInNewTab(item)}} >
                                    {(item.mimetype.startsWith('image/'))?
                                        <img src={`${Config.ImageUrl}${item.filename}`} height="200" alt="attached File" className='uploadable-img' />
                                        :
                                        (item.mimetype==='application/pdf')?
                                        <img src={require('../assets/icons/pdf-icon.png')} height="100%" alt="attached File" className='uploadable-file' />
                                        :
                                        (item.mimetype==='application/vnd.ms-excel' || item.mimetype==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.mimetype==='text/csv')?
                                        <img src={require('../assets/icons/spreadsheet-icon.png')} height="100%" alt="attached File" className='uploadable-file' />
                                        :
                                        <img src={require('../assets/icons/unknown-file-icon.png')} height="100%" alt="attached File" className='uploadable-file' />
                                    }
                                    <button 
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            deleteFile(item.filename);
                                        }}
                                        className="file-delete"
                                    >
                                        <DeleteIcon style={{fontSize: '30px'}} />
                                    </button>

                                    <p className='file-name-size' style={{top: '5px'}} >{item.filename}</p>
                                    {(item.size>=1048576)?
                                        <p className='file-name-size' style={{bottom: '5px'}}>{(item.size/1048576).toFixed(2)} MB</p>
                                        :
                                        <p className='file-name-size' style={{bottom: '5px'}}>{(item.size/1024).toFixed(2)} KB</p>
                                    }
                                </div>
                            );
                        })}
                    </div>

                </div>

                
                {/* <Modal size="md" open={openModal} onClose={() => {setOpenModal(false)}}>
                
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={openFile}
                        config={{
                            header: {
                                disableHeader: false,
                                disableFileName: false,
                                retainURLParams: false
                            }
                        }}
                        style={{ height: 600 }}
                    />
                </Modal> */}

            </div>
            
        </>
    );
}

export default AttachFile;