export const RentPropertyData = [
    {id: "1", name: "property Address", property_1: "", refrence_1: "", comment_1: ""},
    {id: "2", name: "Date Property first rented", property_1: "", refrence_1: "", comment_1: ""},
    {id: "3", name: "Weeks rented", property_1: "", refrence_1: "", comment_1: ""},
    {id: "4", name: "Percent Ownership", property_1: "", refrence_1: "", comment_1: ""},
]

export const RentIncomeData = [
    {id: "1", name: "Gross Income", property_1: "", refrence_1: "", comment_1: ""},
    {id: "2", name: "Other rental Income", property_1: "", refrence_1: "", comment_1: ""},
]

export const RentExpensesData = [
    {id: "1", name: "Advertising for tenants", property_1: "", refrence_1: "", comment_1: ""},
    {id: "2", name: "Body corporate fees", property_1: "", refrence_1: "", comment_1: ""},
    {id: "3", name: "Borrowing expenses", property_1: "", refrence_1: "", comment_1: ""},
    {id: "4", name: "Cleaning", property_1: "", refrence_1: "", comment_1: ""},
    {id: "5", name: "Council Rates", property_1: "", refrence_1: "", comment_1: ""},
    {id: "6", name: "Capital allowances (depreciation)", property_1: "", refrence_1: "", comment_1: ""},
    {id: "7", name: "Gardening/lawn mowing", property_1: "", refrence_1: "", comment_1: ""},
    {id: "8", name: "Insurance", property_1: "", refrence_1: "", comment_1: ""},
    {id: "9", name: "Interest on loans - WDC", property_1: "", refrence_1: "", comment_1: ""},
    {id: "10", name: "Interest on loans - NAB", property_1: "", refrence_1: "", comment_1: ""},
    {id: "11", name: "Loan 1", property_1: "", refrence_1: "", comment_1: ""},
    {id: "12", name: "Loan 2", property_1: "", refrence_1: "", comment_1: ""},
    {id: "13", name: "Land Tax", property_1: "", refrence_1: "", comment_1: ""},
    {id: "14", name: "Strata Payments", property_1: "", refrence_1: "", comment_1: ""},
    {id: "15", name: "Legal fees", property_1: "", refrence_1: "", comment_1: ""},
    {id: "16", name: "Pest control", property_1: "", refrence_1: "", comment_1: ""},
    {id: "17", name: "Property agent fees/commission", property_1: "", refrence_1: "", comment_1: ""},
    {id: "18", name: "Repairs and maintenance", property_1: "", refrence_1: "", comment_1: ""},
    {id: "19", name: "Capital works - special build w/off", property_1: "", refrence_1: "", comment_1: ""},
    {id: "20", name: "Stationery, telephone and postage", property_1: "", refrence_1: "", comment_1: ""},
    {id: "21", name: "Income & Expenditure", property_1: "", refrence_1: "", comment_1: ""},
    {id: "23", name: "Locks, Keys & Card Keys", property_1: "", refrence_1: "", comment_1: ""},
    {id: "24", name: "Letting fees", property_1: "", refrence_1: "", comment_1: ""},
    {id: "25", name: "Water", property_1: "", refrence_1: "", comment_1: ""},
    {id: "26", name: "Sundry rental expenses", property_1: "", refrence_1: "", comment_1: ""},
    {id: "27", name: "Management Fees", property_1: "", refrence_1: "", comment_1: ""},
    {id: "28", name: "Postage and Sundries", property_1: "", refrence_1: "", comment_1: ""},
    {id: "29", name: "Plus GST on Items marked", property_1: "", refrence_1: "", comment_1: ""},
]

export const RentReconciliationData = [
    {id: "1", name: "Add: expense not included in rental statement", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "2", name: "Capital allowance (depreciation)", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "3", name: "Capital works-special build w/off", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "4", name: "Interest on loans", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "5", name: "Insurance", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "6", name: "General Repairs & Maintenance - Leak Detection", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "7", name: "Revenue Exp from Settlement statement", property_1: "", refrence_1: "",  comment_1: ""},
]

export const RentRepairMaintenanceData = [
    {id: "1", name: "Electrical Maintenance", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "2", name: "General Repairs & Maintenance - Leak Detection", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "3", name: "Air Conditioning", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "4", name: "Carpet Cleaning", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "5", name: "Plumbing", property_1: "", refrence_1: "",  comment_1: ""},
    {id: "6", name: "Smoke Alarm (Annual Check)", property_1: "", refrence_1: "",  comment_1: ""},
]

export const RentDepreciationPropsData = [
    [
        {
            name: "",
            attachment: "",
            // owdv_year: `${clientYear-1} - ${clientYear}`,
            wdv_props: [
                {id: "1", propName: "Cost", value: ''},
                {id: "2", propName: "Effective Life", value: ''},
                {id: "3", propName: "No. of days", value: ''},
                {id: "4", propName: "Depreciation Rate", value: ''},
                {id: "5", propName: "Depreciation", value: ''},
                {id: "6", propName: "WDV", value: ''},
            ],
            // owdv_props: [
            //     {id: "1", propName: "OWDV", value: ""},
            //     {id: "2", propName: "Depreciation Rate", value: ""},
            //     {id: "3", propName: "Depreciation", value: ""},
            //     {id: "4", propName: "WDV", value: ""},
            // ]
        }
        
    ],
]


export const RentNRIPData = [
    {id: "1", name: "Net Rental Income per statement", property_1: "", refrence_1: "", comment_1: ""},
]