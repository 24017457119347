import React, {useState, useEffect, useContext} from 'react';
import { Box, Button } from "@mui/material";
import ReactLoading from "react-loading";
import { AuthContext } from "../../../../store/auth-context";
import { CreateFormulaAPI, GetFormulasAPI, UpdateFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

const TaxEstimateFormula = () => {
    const authCtx = useContext(AuthContext);
    const currentYear = new Date().getFullYear();
    const [formulas, setFormulas] = useState({
        taxableIncome: 'totalIncome - totalDeductions',
        taxPayable: 'baseTax + (taxableIncome - taxRateIncome) * (marginalRate/100)',
        medicareLevy: 'taxableIncome * 0.02',
        medicareLevySurcharge: 'taxableIncome * 0.01',
        estimatedTax: 'taxPayable - totalLessOffset + totalPlus - totalLessCredit',
        difference: 'estimatedTax - perITR',
    })
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);


    useEffect(() => {
        const GetFormulaFunc = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);

            setLoading(true);

            const getRes = await GetFormulasAPI("taxEstimateRecon", currentYear, userToken.token);

            if(getRes.success === true){
                if(getRes.data === null || getRes.data === undefined){
                    setDataAlready(false);
                }else {
                    setDataAlready(true);
                    setFormulas(getRes.data.formulas);
                }
            }

            setLoading(false)
        }

        GetFormulaFunc();
    }, [isDataAlready])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormulas((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            year: currentYear,
            type: "taxEstimateRecon",
            formulas: formulas,
        };

        const submitRes = isDataAlready?
        await UpdateFormulasAPI(sendData, "taxEstimateRecon", currentYear, userToken.token)
        :
        await CreateFormulaAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
          
        setLoading(false);
    };

    
    return (
        <>
            <div className="table-container" style={{flexDirection: 'column'}}>
                <table className="data-table" style={{tableLayout: "fixed"}}>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>totalIncome</th>
                        <th className="th-name" style={{textAlign: 'left'}}>$ 500</th>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>totalDeductions</th>
                        <th className="th-name" style={{textAlign: 'left'}}>$ 400</th>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>taxableIncome</th>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="taxableIncome"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.taxableIncome}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>taxPayable</th>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="taxPayable"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.taxPayable}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>totalLessOffset</th>
                        <th className="th-name" style={{textAlign: 'left'}}>$ 50</th>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>medicareLevy</th>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="medicareLevy"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.medicareLevy}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>medicareLevySurcharge</th>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="medicareLevySurcharge"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.medicareLevySurcharge}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>totalPlus</th>
                        <th className="th-name" style={{textAlign: 'left'}}>$ 50</th>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>totalLessCredit</th>
                        <th className="th-name" style={{textAlign: 'left'}}>$ 50</th>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>estimatedTax</th>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="estimatedTax"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.estimatedTax}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>perITR</th>
                        <th className="th-name" style={{textAlign: 'left'}}>$ 50</th>
                    </tr>
                    <tr className="table-heading">
                        <th className="th-name" style={{textAlign: 'left'}}>difference</th>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="difference"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.difference}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>

                </table>
            </div>

            <Box mt="20px" ml="10px">
                <Button 
                    type="submit" 
                    variant="contained" 
                    style={{color: '#fff', backgroundColor: '#009ec1', marginRight: '20px'}} 
                    onClick={() => {SubmitHandler()}}
                >
                    Submit
                </Button>
            </Box>

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>
    )
}

export default TaxEstimateFormula;