import React from 'react';

const ITRTable = ({incomeData, deductionData, clientYear, taxableIncome, taxPayable, lessOffsetData, setLessOffsetData, plusData, isPrivateHealthIns, setPrivateHealthIns, lessCreditData, setLessCreditData, estimatedTax, perItr, setPerItr, difference, isComparing}) => {

    const handleLessOffsetInput = (e, index, name) => {
        const updatedArray = [...lessOffsetData];
        updatedArray[index][name] = e.target.value;
        setLessOffsetData(updatedArray);
    }

    const handleLessCreditInput = (e, index, name) => {
        const updatedArray = [...lessCreditData];
        updatedArray[index][name] = e.target.value;
        setLessCreditData(updatedArray);
    }

    return (
        <div className="table-container" style={{flexDirection: 'column'}}>
            <table className="itr-table">
                <tr className="table-heading">
                    <th className="th-name" colSpan="2">Income</th>
                    <th  className="th-name" style={{width: '200px'}} >{clientYear} ($)</th>
                    {(isComparing)&&
                        <th  className="th-name" style={{width: '200px'}} >{clientYear -1} ($)</th>
                    }
                </tr>
          
            {(incomeData.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="serial-no"  style={{width: '50px'}}>{item.id}</td>
                            <td className="income-field">{item.name}</td>
                            <td className="amount-field" style={{background: '#fff'}}>
                                <p style={{padding: '5px', fontSize: '14px'}}>$ {item.currentCost}</p>
                            </td>
                            {(isComparing)&&
                                <td className="amount-field"  style={{background: '#fff'}}>
                                    <p style={{padding: '5px', fontSize: '14px'}}>$ {item.previousCost}</p>
                                </td>
                            }
                        </tr>
                    );
                }))}

            </table>

            <table className="itr-table">
                <tr className="table-heading"  >
                    <th className="th-name" colSpan="2" >Deduction</th>
                    <th className="th-name" style={{width: '200px'}} >{clientYear} ($)</th>
                    {(isComparing)&&
                        <th className="th-name" style={{width: '200px'}} >{clientYear -1} ($)</th>
                    }
                </tr>

                {(deductionData.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="serial-no" style={{width: '50px'}} >{item.id}</td>
                            <td className="income-field">{item.name}</td>
                            <td className="amount-field" style={{background: '#fff'}}>
                                <p style={{padding: '5px', fontSize: '14px'}}>$ {item.currentCost}</p>
                            </td>
                            {(isComparing)&&
                                <td className="amount-field" style={{background: '#fff'}}>
                                    <p style={{padding: '5px', fontSize: '14px'}}>$ {item.previousCost}</p>
                                </td>
                            }
                        </tr>
                    );
                }))}
            </table>

            <table className="itr-table">
                <tr>
                    <th className="th-name" colSpan="2" style={{textAlign: 'left'}}>Taxable Income</th>
                    <th className="th-name" style={{width: '200px', textAlign: 'left'}}>$ {taxableIncome.currentCost}</th>
                    {(isComparing)&&
                        <th className="th-name" style={{width: '200px', textAlign: 'left'}}>$ {taxableIncome.previousCost}</th>
                    }
                </tr>
                <tr>
                    <th className="th-name" colSpan="2" style={{textAlign: 'left'}}>Tax payable on taxable income</th>
                    <th className="th-name" style={{width: '200px', textAlign: 'left'}}>$ {taxPayable.currentCost}</th>
                    {(isComparing)&&
                        <th className="th-name" style={{width: '200px', textAlign: 'left'}}>$ {taxPayable.previousCost}</th>
                    }
                </tr>
            </table>
        
            <table className="itr-table">
                <tr>
                    <th className="th-name" colSpan="2" >Less Offsets</th>
                    <th className="th-name" style={{width: '200px'}} >{clientYear} ($)</th>
                    {(isComparing)&&
                        <th className="th-name" style={{width: '200px'}} >{clientYear -1} ($)</th>
                    }
                </tr>
                
                {(lessOffsetData.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="income-field" colSpan="2">{item.name}</td>
                            <td className="data-fill-field income-field">
                                {item.isDefunct?
                                    <p style={{fontSize: "14px", padding: "5px", color: '#000'}}>Defunct</p>
                                    :
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder="..."
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.currentCost}
                                            onChange={(e) => {
                                            if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                handleLessOffsetInput(e, index, 'currentCost');
                                            }
                                            }}
                                        />
                                    </div>
                                }
                            </td>
                            {(isComparing)&&
                                <td className="data-fill-field income-field">
                                    {item.isDefunct?
                                        <p style={{fontSize: "14px", padding: "5px", color: '#000'}}>Defunct</p>
                                        :        
                                        <p style={{fontSize: "14px", padding: "5px", color: '#000'}}>$ {item.previousCost}</p>
                                    }
                                </td>
                            }
                        </tr>
                    );
                }))}
            </table>

            <table className="itr-table">
                <tr>
                    <th className="th-name" colSpan="2" >PLUS</th>
                    <th className="th-name" style={{width: '200px'}}>{clientYear} ($)</th>
                    {(isComparing)&&
                        <th className="th-name" style={{width: '200px'}}>{clientYear -1} ($)</th>
                    }
                </tr>
                <tr>
                    <td className="income-field" colSpan="2">{plusData[0].name}</td>
                    <td className="amount-field" style={{background: '#fff'}}>
                        <p style={{padding: '5px', fontSize: '14px'}}>$ {plusData[0].currentCost}</p>
                    </td>
                    {(isComparing)&&
                        <td className="amount-field" style={{background: '#fff'}}>
                            <p style={{padding: '5px', fontSize: '14px'}}>$ {plusData[0].previousCost}</p>                  
                        </td>
                    }
                </tr>

                <tr>
                    <td className="income-field"  colSpan="2">
                        {plusData[1].name}
                        <select style={{marginLeft: '15px', padding: '5px', borderRadius: '5px', maxWidth:'60px'}} 
                            name='inp_phi' 
                            value={isPrivateHealthIns}
                            onChange={() => {setPrivateHealthIns(!isPrivateHealthIns)}}
                        >
                            <option disabled>Private Health Insurance Yes or No</option>
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>                                            
                        </select>
                    </td>
                    <td className="amount-field" style={{background: '#fff'}}>
                        <p style={{padding: '5px', fontSize: '14px'}}>$ {plusData[1].currentCost}</p>
                    </td>
                    {(isComparing)&&
                        <td className="amount-field" style={{background: '#fff'}}>    
                            <p style={{padding: '5px', fontSize: '14px'}}>$ {plusData[1].previousCost}</p>    
                        </td>
                    }
                </tr>

                <tr>
                    <td className="income-field" colSpan="2">{plusData[2].name} (ceases to apply from 1-July-2017)</td>
                    <td className="amount-field" style={{background: '#fff'}}>
                        <p style={{padding: '5px', fontSize: '14px'}}>$ {plusData[2].currentCost}</p>
                    </td>
                    {(isComparing)&&
                        <td className="amount-field" style={{background: '#fff'}}>
                            <p style={{padding: '5px', fontSize: '14px'}}>$ {plusData[2].previousCost}</p>
                        </td>
                    }
                </tr>
            </table>

            <table className="itr-table">
                <tr>
                    <th className="th-name" colSpan="2" >Less Credits</th>
                    <th className="th-name" style={{width: '200px'}}>{clientYear} ($)</th>
                    {(isComparing)&&
                        <th className="th-name" style={{width: '200px'}}>{clientYear -1} ($)</th>
                    }
                </tr>

                {(lessCreditData.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="income-field" colSpan="2">{item.name}</td>
                            <td className="data-fill-field income-field">
                                <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                    <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                    <input
                                        type="text"
                                        placeholder="..."
                                        style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                        value={item.currentCost}
                                        onChange={(e) => {
                                            if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                            handleLessCreditInput(e, index, 'currentCost');
                                            }
                                        }}
                                    />
                                </div>
                            </td>
                            {(isComparing)&&
                                <td className="amount-field" style={{background: '#fff'}}>
                                    <p style={{fontSize: "14px", padding: "5px", color: '#000'}}>$ {item.previousCost}</p>
                                </td>
                            }
                            
                        </tr>
                    );
                }))}
            </table>

            <table className="itr-table">
                <tr>
                    <th className="th-name" colSpan="2" style={{textAlign: 'left'}}>Estimated Tax Payable/(Refundable)</th>
                    <th className="th-name"  style={{width: '200px', textAlign: 'left'}}>
                        $ {estimatedTax.currentCost}
                        <span style={estimatedTax.currentCost<0? {marginLeft: '10px', color: '#fff', padding: '5px', borderRadius: '5px' ,background: '#49d19d'} : {marginLeft: '10px', color: '#fff', padding: '5px', borderRadius: '5px',  background: '#bf1d40'}}>
                            {estimatedTax.currentCost<0? 'Refund' : 'Payable'}
                        </span>
                    </th>
                    {(isComparing)&&
                        <th className="th-name"  style={{width: '200px', textAlign: 'left'}}>
                            $ {estimatedTax.previousCost}
                            <span style={120000<0? {marginLeft: '10px', color: '#fff', padding: '5px', borderRadius: '5px' ,background: '#49d19d'} : {marginLeft: '10px', color: '#fff', padding: '5px', borderRadius: '5px',  background: '#bf1d40'}}>
                                {120000<0? 'Refund' : 'Payable'}
                            </span>
                        </th>
                    }
                </tr>
                <tr>
                    <th className="th-name" colSpan="2" style={{textAlign: 'left'}}>Per ITR</th>
                    <td className="data-fill-field income-field">
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                            <input
                                type="text"
                                placeholder="..."
                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                value={perItr.currentCost}
                                onChange={(e) => {
                                    if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                        const updatedObject = { ...perItr };
                                        updatedObject['currentCost'] = e.target.value;
                                        setPerItr(updatedObject);
                                    }
                                }}
                            />
                        </div>
                    </td>
                    {(isComparing)&&
                        <td className="amount-field" style={{background: '#fff'}}>
                            <p style={{padding: '5px', fontSize: '14px'}}>$ {perItr.previousCost}</p>
                        </td>
                    }
                </tr>
                <tr>
                    <th className="th-name" colSpan="2" style={{textAlign: 'left'}}>Difference</th>
                    <th className="th-name" style={{textAlign: 'left'}}>$ {difference.currentCost}</th>
                    {(isComparing)&&
                        <th className="th-name" style={{textAlign: 'left'}}>$ {difference.previousCost}</th>
                    }
                </tr>
            </table>

        </div>
    )
}

export default ITRTable;