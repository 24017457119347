import React, {useState, useEffect, useContext} from 'react';
import { Box, Button } from "@mui/material";
import ReactLoading from "react-loading";
import { AuthContext } from "../../../../store/auth-context";
import { CreateFormulaAPI, GetFormulasAPI, UpdateFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

const PartnershipTrustFormula = () => {
    const authCtx = useContext(AuthContext);
    const currentYear = new Date().getFullYear();
    const [formulas, setFormulas] = useState({
        adjustedCost: 'cost + taxDeferred',
    });
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);

    
    useEffect(() => {
        const GetFormulaFunc = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);

            setLoading(true);

            const getRes = await GetFormulasAPI("partnershipTrust", currentYear, userToken.token);

            if(getRes.success === true){
                if(getRes.data === null || getRes.data === undefined){
                    setDataAlready(false);
                }else {
                    setDataAlready(true);
                    setFormulas(getRes.data.formulas);
                }
            }

            setLoading(false)
        }

        GetFormulaFunc();
    }, [isDataAlready])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormulas((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            year: currentYear,
            type: "partnershipTrust",
            formulas: formulas,
        };

        const submitRes = isDataAlready?
        await UpdateFormulasAPI(sendData, "partnershipTrust", currentYear, userToken.token)
        :
        await CreateFormulaAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
          
        setLoading(false);
    }


    return (
        <>
            <div className="table-container" style={{flexDirection: 'column'}}>
                <table className="data-table">
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{width: '50%'}} ></th>
                        <th className="th-name"  style={{width: '15%'}}>Ref</th>
                        <th className="th-name"  >taxWithheld</th>
                        <th className="th-name"  style={{width: '15%'}}>gross</th>
                    </tr>
                    <tr>
                        <td className="data-fill-field" >
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>Partnerships and Trusts</div>
                        </td>
                        <td  className="data-fill-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>Ref</div>
                        </td>
                        <td  className="data-fill-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>Tax Withheld</div>
                        </td>
                        <td  className="data-fill-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>Gross</div>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" colSpan="2" >Total</th>
                        <th className="th-name" >$ 200</th>
                        <th className="th-name" >$ 500.00</th>
                    </tr>
                </table>

                <table className="calculation-table" >
                    <tr>
                        <td className="th-name" colSpan="2" style={{textAlign: 'center', padding: '5px'}}>Tax Deferred</td>
                    </tr>
                    <tr>
                        <td className="th-name" style={{padding: '5px'}}>FY{currentYear-2}</td>
                        <td className="calculation-table-cell data-fill-field income-field" >$ 250.00</td>
                    </tr>
                    <tr>
                        <td className="th-name" style={{padding: '5px'}}>FY{currentYear-1}</td>
                        <td className="calculation-table-cell  data-fill-field income-field">$ 350.00</td>
                    </tr>
                    <tr>
                        <td className="th-name" style={{padding: '5px'}}>FY{currentYear}</td>
                        <td className="calculation-table-cell  data-fill-field income-field">$ 500.00</td>
                    </tr>
                    <tr >
                        <td className="th-name" style={{padding: '5px'}}>Total</td>
                        <td className="calculation-table-cell  data-fill-field income-field">$ 1100.00</td>
                    </tr>
                </table>

                <table className="calculation-table" >
                    <tr>
                        <td className="th-name" style={{padding: '5px'}}>cost</td>
                        <td className="calculation-table-cell data-fill-field income-field" >$ 600.00</td>
                    </tr>
                    <tr>
                        <td className="th-name" style={{padding: '5px'}}>taxDeferred</td>
                        <td className="calculation-table-cell  data-fill-field income-field">$ 1100.00</td>
                    </tr>
                    <tr style={{border: '3px solid #000'}}>
                        <td className="th-name" style={{padding: '5px'}}>Adjusted Cost</td>
                        <td className="calculation-table-cell  data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="adjustedCost"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.adjustedCost}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                </table>

            </div>

            <Box mt="20px" ml="10px">
                <Button 
                    type="submit" 
                    variant="contained" 
                    style={{color: '#fff', backgroundColor: '#009ec1', marginRight: '20px'}} 
                    onClick={() => {SubmitHandler()}}
                >
                    Submit
                </Button>
            </Box>

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>

        
    )
}

export default PartnershipTrustFormula;