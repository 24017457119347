import React from 'react'
import EditableTaxRateHeader from '../../../../../components/EditableTaxRateHeader';

const ResidentTable = ({editable, inputs, handleFormInput, dataArray, handleInputChange}) => {
    return(
        <table className="data-table" style={{marginTop: '5px', textAlign: 'center'}}>
            <tr className="data-table-heading" >
                <th className="th-name" style={{width: '33.34%'}}>

                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading1'}
                        inputLink={'heading1Link'}
                        inputs={inputs}
                    />
                    
                </th>
                <th className="th-name" style={{width: '33.33%'}}>

                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading2'}
                        inputLink={'heading2Link'}
                        inputs={inputs}
                    />

                </th>
                <th className="th-name" style={{width: '33.33%'}}>
                    
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading3'}
                        inputLink={'heading3Link'}
                        inputs={inputs}
                    />
                </th>
            </tr>

            
            {dataArray.map((item, index) => {
                return(
                    <tr>
                        <td className="data-fill-field" >
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                <input 
                                    type="text" 
                                    placeholder="" 
                                    value={item.taxableIncome}
                                    style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                                    onChange={(e) => {
                                        if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                            handleInputChange(e, index, 'taxableIncome');
                                        }
                                    }} 
                                />
                            </div>
                        </td>
                        
                        <td  className="data-fill-field" style={{position: 'relative'}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                <input 
                                    type="text" 
                                    placeholder=""
                                    value={item.baseTax}
                                    style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                                    onChange={(e) => {
                                        if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                            handleInputChange(e, index, 'baseTax');
                                        }
                                    }} 
                                />
                            </div>
                        </td>

                        <td  className="data-fill-field" style={{position: 'relative'}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input 
                                    type="text" 
                                    placeholder=""
                                    value={item.marginalRate}
                                    style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                                    onChange={(e) => {
                                        if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                            handleInputChange(e, index, 'marginalRate');
                                        }
                                    }} 
                                />
                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                            </div>
                        </td>

                    </tr>
                );
            })}
            
        </table>
    );
}


export default ResidentTable;