import React from 'react';

const EditRates = ({notes, links, editable, inputs, handleFormInput, handleArrayInputs}) => {
    return (
        <>
            {editable?
            
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <input 
                        name="title"
                        placeholder='Title'
                        style={{padding: '10px', border: '1px solid #000', outline: 'auto', borderRadius: '5px', margin: '5px'}}
                        value={inputs.title}
                        onChange={(e) => handleFormInput(e)}
                    />
                    {notes.map((item, index) =>{
                        return(
                            <input 
                                key={index}
                                name="note"
                                placeholder='Note'
                                style={{padding: '10px', border: '1px solid #000', outline: 'auto', borderRadius: '5px', margin: '5px'}}
                                value={item.note}
                                onChange={(e) => handleArrayInputs(index, 'note', 'note' , e)}
                            />
                        );
                    })}
                    
                    {links.map((item, index) => {
                        return(
                            <div style={{display: 'flex'}} key={index}>
                                <input 
                                    name="link1text"
                                    placeholder='Link Text'
                                    style={{padding: '10px', border: '1px solid #000', outline: 'auto', borderRadius: '5px', margin: '5px'}}
                                    value={item.linkTitle}
                                    onChange={(e) => handleArrayInputs(index, 'linkTitle', 'link' , e)}
                                />
                                <input 
                                    name="link1"
                                    placeholder='Link'
                                    style={{padding: '10px', border: '1px solid #000', outline: 'auto', borderRadius: '5px', margin: '5px'}}
                                    value={item.link}
                                    onChange={(e) => handleArrayInputs(index, 'link', 'link' , e)}
                                />
                            </div>
                        );
                    })}
                </div>
        
                :
                <div>
                    <div >
                        {links&& links.map((item, index) => {
                            return(
                                <span key={index} style={{color: "blue", marginRight: 20}}>
                                    <a href={item.link} target="_blank">{item.linkTitle}</a>
                                </span>
                            );
                        })}
                    </div>
                    <div>
                        {notes&& notes.map((item, index) => {
                            return(
                                <p style={{margin: 0}}>{item.note}</p>
                            );
                        })}
                    </div>
                </div>
            }
        </>
    );
}

export default EditRates;