import React from 'react';
import ResidentTable from './DataTables/ResidentTable';
import LowIncomeTable from './DataTables/LowIncomeTable';
import LowMiddleIncomeTable from './DataTables/LowMiddleIncomeTable';
import MotorVehicleTable from './DataTables/MotorVehicleTable';
import RestTable from './DataTables/RestTable';

const TaxRateTable = (props) => {
    return (
        <>
            {(props.taxRateType==="residentTaxRate")?
                <ResidentTable 
                    editable={props.editable}
                    inputs={props.inputs}
                    handleFormInput={props.handleFormInput}
                    dataArray={props.dataArray}
                    handleInputChange={props.handleInputChange}
                />
                :
                (props.taxRateType==="motorVehicleRate")?
                <MotorVehicleTable 
                    editable={props.editable}
                    inputs={props.inputs}
                    handleFormInput={props.handleFormInput}
                    dataArray={props.dataArray}
                    handleInputChange={props.handleInputChange}
                />
                :
                (props.taxRateType==="lowIncomeTaxRate")?
                <LowIncomeTable 
                    editable={props.editable}
                    inputs={props.inputs}
                    handleFormInput={props.handleFormInput}
                    dataArray={props.dataArray}
                    handleInputChange={props.handleInputChange}
                />
                :
                (props.taxRateType==="lowMidleIncomeTaxRate")?
                <LowMiddleIncomeTable 
                    editable={props.editable}
                    inputs={props.inputs}
                    handleFormInput={props.handleFormInput}
                    dataArray={props.dataArray}
                    handleInputChange={props.handleInputChange}
                />
                :
                <RestTable 
                    editable={props.editable}
                    inputs={props.inputs}
                    handleFormInput={props.handleFormInput}
                    dataArray={props.dataArray}
                    handleInputChange={props.handleInputChange}
                />
            }
        </>
    )
}

export default TaxRateTable;