import React, {useState, useEffect, useContext} from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import SendIcon from '@mui/icons-material/Send';
import ReactLoading from "react-loading";

import { evaluate } from 'mathjs';

import {SimpleClientTables} from '../../ClientTables/ClientTables';
import {PartnershipTrustTable} from './DataTables/IncomeDataTables';

import { CreatePartnershipTrustAPI, GetPartnershipTrusAPI, UpdatePartnershipTrustAPI, GetFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../../../../store/auth-context";
import { toast } from "react-toastify";

import LinkAttachFile from '../../../../components/LinkAttachFile';
import AttachFile from '../../../../components/AttachFile';
import FormulaErrorSnackbar from '../../../../components/FormulaErrorSnackbar';


const PartnershipTrusts = ({currentModal, currentPage, clientData, clientUpdateYear, setDocsChange}) => {
    const authCtx = useContext(AuthContext);
    const [dataArray, setDataArray] = useState([{
        name: '',
        ref: '',
        taxWithheld: '',
        gross: '',
    }])
    const [clientTableDetails, setClientTableDetails] = useState({
        preparedDate: '',
        scheduleRef: currentPage,
        staffMember: 'No',
        managerReview: 'No',
        partnerReview: 'No',
    })
    
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);

    const [fyYear, setFyYear] = useState({
        lastYearCost: 0,
        secondLastYearCost: 0,
    });
    const [taxDeferredTotal, setTaxDefferedTotal] = useState(0);

    // Using at LinkAttachFile.js
    const [linkAttachable, setLinkAttachable] = useState(false);
    const [links, setLinks] = useState([]);
    const [errorOpen, setErrorOpen] = useState(false);
    
    // Using at AttachFile.js
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [totalwithheld, setTotawithheld] = useState(0);
    const [totalGross, setTotalGross] = useState(0);
    const [costInput, setCostInput] = useState('');
    const [adjustedCost, setAdjustedCost] = useState('0.00');
    const clientYear = clientUpdateYear.getFullYear();
    const [formulas, setFormulas] = useState({
        adjustedCost: 'cost + taxDeferred',
    });

    useEffect(() => {
        const GetPartnershipTrustData = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);
            setLoading(true);

            const getDataRes = await GetPartnershipTrusAPI(clientData._id, clientYear, userToken.token);
            if(getDataRes.success===true){
                if(getDataRes.data === null || getDataRes.data === undefined){
                    setDataAlready(false);
                }else{
                    setDataAlready(true);
                    setDataArray(getDataRes.data.data);
                    setTotalGross(getDataRes.data.total);
                    setCostInput(getDataRes.data.costObject.cost);
                    setFyYear({
                        lastYearCost: (getDataRes.lastYear===null || getDataRes.lastYear===undefined)? 0 : parseFloat(getDataRes.lastYear.total).toFixed(2),
                        secondLastYearCost: (getDataRes.secondLastYear===null || getDataRes.secondLastYear===undefined)? 0 : parseFloat(getDataRes.secondLastYear.total).toFixed(2)
                    });
                    setClientTableDetails({preparedDate: getDataRes.data.preparedDate, scheduleRef: getDataRes.data.scheduleRef, staffMember: getDataRes.data.staffMember, managerReview: getDataRes.data.managerReview, partnerReview: getDataRes.data.partnerReview});
                    setSelectedFiles(getDataRes.data.attachment);
                    setLinks(getDataRes.data.link)
                }
            }

            const getFormulas = await GetFormulasAPI('partnershipTrust', clientYear, userToken.token);
            if(getFormulas.success === true){
                if(getFormulas.data !== null && getFormulas.data !== undefined){
                    setFormulas(getFormulas.data.formulas)
                }
            }

            setLoading(false);
        }

        GetPartnershipTrustData()

    }, [isDataAlready]);

    useEffect(() => {
        const calculateTotal = () => {
            let totalWith = 0;
            let totalGr = 0;
            for (const row of dataArray) {
                const Withheld = parseFloat(row.taxWithheld);
                const grossVal = parseFloat(row.gross);
                if (!isNaN(Withheld)) {
                    totalWith += Withheld;
                }
                if(!isNaN(grossVal)){
                    totalGr += grossVal;
                }
            }
            setTotawithheld(totalWith);
            setTotalGross(totalGr);


            let lastYearCost = parseFloat(fyYear.lastYearCost);
            let secondLastYearCost = parseFloat(fyYear.secondLastYearCost);
            let taxDefTotal = Number(lastYearCost + secondLastYearCost + totalGr).toFixed(2);
            setTaxDefferedTotal(taxDefTotal);
            
            let scope = {
                cost: costInput,
                taxDeferred: taxDefTotal,
                gross: totalGr,
                taxWithheld: totalWith,
            }
            
            try {
                let adjCost = evaluate(formulas.adjustedCost, scope).toFixed(2);
                setAdjustedCost(adjCost);
            } catch (error) {
                setErrorOpen(true);
            }

        };
        
        calculateTotal();

    }, [dataArray, costInput]);


    const handleChange = (rowIndex, cellKey, value) => {
        const updatedTableData = dataArray.map((row, index) => {
            if (index === rowIndex) {
                return { ...row, [cellKey]: value };
            }
            return row;
        });
        setDataArray(updatedTableData);
    };


    const AddCellFunc = () => {
        let newElement = {name: '', ref: '', taxWithheld: '', gross: ''};
        setDataArray(oldArray => [...oldArray, newElement]);
    };

    const RemoveCellFunc = () => {
        let newData = [...dataArray];
        newData.pop();
        setDataArray(newData);
    }

    
    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            clientId: clientData._id,
            year: clientYear,
            leadSchedule : currentModal,
            preparedDate: clientTableDetails?.preparedDate,
            scheduleRef: clientTableDetails?.scheduleRef,
            staffMember: clientTableDetails?.staffMember,
            managerReview: clientTableDetails?.managerReview,
            partnerReview: clientTableDetails?.partnerReview,
            data: dataArray,
            total: totalGross,
            totalTaxWithheld: totalwithheld,
            costObject: {cost: costInput},
            lastYearCost: fyYear.lastYearCost,
            secondLastYearCost: fyYear.secondLastYearCost,
            taxDeferredTotal: taxDeferredTotal,
            adjustedCost: adjustedCost,
            attachment: selectedFiles,
            link: links,
        };

        const submitRes = isDataAlready?
        await UpdatePartnershipTrustAPI(sendData, clientData._id, clientYear, userToken.token)
        :
        await CreatePartnershipTrustAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
            setDocsChange(false);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        setLoading(false);
    };


    return (
        <>
            <SimpleClientTables 
                currentModal={currentModal} 
                clientData={clientData} 
                area={'Lead Schedule: '} 
                clientUpdateYear={clientUpdateYear} 
                clientTableDetails={clientTableDetails}
                setClientTableDetails={setClientTableDetails}
            />

            <PartnershipTrustTable 
                dataArray={dataArray}
                handleChange={handleChange} 
                totalwithheld={totalwithheld}
                totalGross={totalGross}
                clientUpdateYear={clientUpdateYear}
                fyYear={fyYear}
                taxDeferredTotal={taxDeferredTotal}
                costInput={costInput}
                setCostInput={setCostInput}
                adjustedCost={adjustedCost}
            />

            <AttachFile 
                selectedFiles={selectedFiles} 
                setSelectedFiles={setSelectedFiles} 
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />

            <LinkAttachFile 
                links={links} 
                setLinks={setLinks} 
                linkAttachable={linkAttachable} 
                setLinkAttachable={setLinkAttachable} 
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            /> 

            <div style={{display: 'flex', marginLeft: '5px', paddingBottom: '80px'}} >
                <button className="submit-button" onClick={() => {AddCellFunc()}} ><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Cell</button>
                {dataArray.length>1&&
                    <button className="submit-button" onClick={() => {RemoveCellFunc()}} ><DeleteForeverIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Remove Cell</button>
                }
                <label htmlFor="file-picker" className="submit-button" onClick={() => {}} ><AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Attach</label>
                <button  className="submit-button" onClick={() =>{setLinkAttachable(true)}} ><InsertLinkIcon  style={{fontSize: '14px', verticalAlign: 'middle' }} /> Link</button>
                <button className="submit-button" onClick={() => {SubmitHandler()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
            </div>

            <FormulaErrorSnackbar
                open={errorOpen}
                setOpen={(val) => setErrorOpen(val)}
                currentTable="Partnership & Trust Formulas"
            />

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>
    );
}

export default PartnershipTrusts;