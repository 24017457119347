import React, {useState, useEffect, useContext} from 'react';
import './LoginPage.css';
import '../../assets/fonts/iconic/css/material-design-iconic-font.min.css';
import ReactLoading from "react-loading";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import {LoginAPI} from '../../util/http';
import { AuthContext } from "../../store/auth-context";

const LoginPage = () => {
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        username: {
            value: '',
            isValid: true,
        },
        password: {
            value: '',
            isValid: true,
        },

    })

    useEffect(() => {
        setLoading(true);

        const TokenChecker = async() => {
            const userToken = await secureLocalStorage.getItem("token");
            setLoading(false);

            if(userToken!==null && userToken!==undefined){
                const role = await secureLocalStorage.getItem("role");
                const user = JSON.parse(userToken);

                // console.log("User Id", user.doc._id);
                // console.log("Role", role);

                if(role==="admin"){
                    navigate("/partner-list");
                }else if(role==="partner"){
                    navigate(`/manager-list/${user.doc._id}`);
                }else if(role==="manager"){
                    navigate(`/staff-list/${user.doc._id}`);
                }else if(role==="staff"){
                    navigate(`/client-list/${user.doc._id}`);
                }else if(role==="user"){
                    navigate(`/client-profile/${user.doc._id}`);
                }
            }
        }
        TokenChecker();


    }, [navigate])



    const handleInputChange = (event) =>{
        setInputs(currentInputs => {
          return {
            ...currentInputs,
            [event.target.id]: {value: event.target.value, isValid: true},
          };
        });      
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);


        const usernameIsValid = inputs.username.value.trim().length>0;
        const passwordIsValid = inputs.password.value.trim().length>=5;
        if(!usernameIsValid || !passwordIsValid){
            setLoading(false);

            setInputs(curInputs => {
                return {
                    username: {
                        value: curInputs.username.value,
                        isValid: usernameIsValid,
                    },
                    password: {
                        value: curInputs.password.value,
                        isValid: passwordIsValid,
                    }
                }
            });

            toast.error(`Please enter correct username and password`, {
                position: toast.POSITION.TOP_CENTER,
            });

            return;
        }


        let data = {
            username: inputs.username.value,
            password: inputs.password.value,
        }

        const LoginRes = await LoginAPI(data);
        
        if(LoginRes.success === true){
            authCtx.authenticate(JSON.stringify(LoginRes.data));
            authCtx.roleSetter(LoginRes.data.doc.role);
            
            toast.success(`${LoginRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });


            setTimeout(() => {
                setLoading(false);
                if(LoginRes.data.doc.role==="admin"){
                    navigate('/partner-list');
                }else if(LoginRes.data.doc.role==="partner"){
                    navigate(`/manager-list/${LoginRes.data.doc._id}`);
                }else if(LoginRes.data.doc.role==="manager"){
                    navigate(`/staff-list/${LoginRes.data.doc._id}`);
                }else if(LoginRes.data.doc.role==="staff"){
                    navigate(`/client-list/${LoginRes.data.doc._id}`);
                }else if(LoginRes.data.doc.role==="user"){
                    navigate(`/client-profile/${LoginRes.data.doc._id}`);
                }
            }, 2000);

        }else{
            setLoading(false);
            toast.error(`${LoginRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });

        }

    }

    return (
        <>
            <div className="limiter">
                <div className="container-login100" style={{position: 'relative'}}>
                    <div className="wrap-login100">
                        <form className="login100-form validate-form"  onSubmit={handleSubmit}>
                            <span className="login100-form-logo">
                                <img className="company-logo" src={require("../../assets/logo.png")} alt="Titan Partners" />
                            </span>

                            <span className="login100-form-title">Log in</span>

                            <div className="wrap-input100 validate-input" style={!inputs.username.isValid? {borderBottom: '1px solid red'} : null}  data-validate = "Enter username">
                                <input 
                                    className="input100"
                                    type="text" 
                                    name="username" 
                                    placeholder="Username" 
                                    id="username"
                                    value={inputs.username.value}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                                <span className="focus-input100" data-placeholder="&#xf207;"></span>
                            </div>

                            <div className="wrap-input100 validate-input" style={!inputs.password.isValid? {borderBottom: '1px solid red'} : null} data-validate="Enter password">
                                <input 
                                    className="input100" 
                                    type="password" 
                                    name="pass" 
                                    placeholder="Password" 
                                    id="password"
                                    value={inputs.password.value}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                                <span className="focus-input100" data-placeholder="&#xf191;"></span>
                            </div>


                            <div className="container-login100-form-btn">
                                <button type="submit" className="login100-form-btn" >Login</button>
                            </div>

                        </form>
                    </div>
                    
                    {(loading)&&
                        <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
                            <ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
                        </div>
                    }

                </div>
                
            </div>
        
        </>
    );
}


export default LoginPage;