import React, {useState} from 'react';
import { Drawer } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { toast } from "react-toastify";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './DataTable.css';

import ReviewNotesModal from './Issue & Review/ReviewNotesModal';
import InterviewNotesModal from './Issue & Review/InterviewNotesModal';


const IssueReviewModal = ({modalOpen, setModalOpen, currentModal, currentPage, clientData, clientUpdateYear}) => {
    const [isdocsChange, setDocsChange] = useState(false);

    const CloseModalFunc = () => {
        if(isdocsChange){
            toast.warn(`Submit data before closing to avoid confict.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }else{
            setModalOpen(false);
        }
    }

    return (
        <>
            <Drawer size="md" placement='right' open={modalOpen} >

                <h3 className="headingTitle" >{currentModal}</h3>
                <IconButton 
                    aria-label="close" size="small"  style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                    onClick={() => {CloseModalFunc()}}
                >
                    <CloseIcon style={{fontSize: '30px', color: '#000'}}  />
                </IconButton>

                <div style={{backgroundColor: '#fff', paddingBottom: '30px', overflow: 'auto', height: '100%'}}>
                    {(currentModal==="Review Notes" || currentModal==="Current year Queries")?
                        <ReviewNotesModal currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                        :
                        <InterviewNotesModal currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                    }
                </div>
            </Drawer>

        </>
    )
}

export default IssueReviewModal;