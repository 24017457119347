import React, {useContext} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Typography} from '@mui/material';
import './UICSS.css';
import CloseIcon from '@mui/icons-material/Close';
import { AuthContext } from '../store/auth-context';
import {useNavigate} from 'react-router-dom';
import { DeleteManagerByIdAPI, DeleteUserByIdAPI, DeletePartnerByIdAPI, DeleteStaffByIdAPI } from '../util/http';
import { toast } from "react-toastify";
import secureLocalStorage from 'react-secure-storage';


const ConfirmDeleteDialog = ({openDeleteDialog, setOpenDeleteDialog, userData, message}) => {
    const authCtx = useContext(AuthContext);
    const role = secureLocalStorage.getItem("role");
    const navigate = useNavigate();

    const DeleteClientFunc = async() => {
        const userToken = JSON.parse(authCtx.token);

        const deleteRes = (message==="Delete Partner")?
        await DeletePartnerByIdAPI(userData._id, userToken.token)
        :
        (message==="Delete Manager")?
        await DeleteManagerByIdAPI(userData._id, userToken.token)
        :
        (message==="Delete Staff")?
        await DeleteStaffByIdAPI(userData._id, userToken.token)
        :
        await DeleteUserByIdAPI(userData._id, userToken.token);

        if(deleteRes.success===true){
            toast.success(`${deleteRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }else{
            toast.error(`${deleteRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        
        authCtx.updateTrigger();

        setOpenDeleteDialog(false);
    };

    return (
        <Dialog open={openDeleteDialog} >
            <DialogTitle style={{paddingBottom: '10px',paddingLeft: '10px', fontSize: '24px', fontWeight: 'bold'}}>Delete {userData?.name}'s Profile?</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={() => {setOpenDeleteDialog(false)}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent style={{padding: 0}}>
                <Typography style={{padding: '10px', fontSize: '16px', fontWeight: '500'}}>Are you sure you want to delete {userData?.name}'s Profile?</Typography>
            </DialogContent>
            <DialogActions>
                <Button  variant="contained"  onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                <Button  variant="contained" style={{backgroundColor: '#F75E7F'}} onClick={() => DeleteClientFunc()} >Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDeleteDialog;