import axios from "axios";

const Base_URL = "http://137.184.199.153:4006/manager/";


export async function CreatePartnerAPI(data, token) {
  const response = await axios.post(Base_URL + "createPartner/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function GetPartnersAPI(token) {
  const response = await axios.get(Base_URL + "getPartner/", {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function UpdatePartnerByIdAPI(data, id, token) {
  const response = await axios.put(Base_URL + `updatePartnerById/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function DeletePartnerByIdAPI(id, token) {
  const response = await axios.delete(Base_URL + `deletePartnerById/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}


export async function CreateManagerAPI(data, token) {
  const response = await axios.post(Base_URL + 'createManager/', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function GetManagersAPI(token) {
  const response = await axios.get(Base_URL + 'getManager/', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function GetManagersByIdAPI(id, token) {
  const response = await axios.get(Base_URL + `getManager/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function UpdateManagerByIdAPI(data, id, token) {
  const response = await axios.put(Base_URL + 'updateManagerById/' + id, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function DeleteManagerByIdAPI(id, token) {
  const response = await axios.delete(Base_URL + 'deleteManagerById/' + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function CreateStaffAPI(data, token) {
  const response = await axios.post(Base_URL + "createStaff/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function GetStaffByIdAPI(id, token) {
  const response = await axios.get(Base_URL + `getStaff/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function UpdateStaffByIdAPI(data, id, token) {
  const response = await axios.put(Base_URL + `updateStaffById/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function DeleteStaffByIdAPI(id, token) {
  const response = await axios.delete(Base_URL + `deleteStaffById/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}



export async function CreateUserAPI(data, token) {
  const response = await axios.post(Base_URL + "createUser/", data,  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function GetUsersAPI(token) {
  const response = await axios.get(Base_URL + "getUsers/", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetUserListById(id, token) {
  const response = await axios.get(Base_URL + `getUsers/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetPerticulerUserAPI(id, token) {
  const response = await axios.get(Base_URL + "getUserById/" + id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function UpdateUserByIdAPI(data, id, token) {
  const response = await axios.put(Base_URL + "updateUserById/" + id, data,  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function DeleteUserByIdAPI(id, token){
  const response = await axios.delete(Base_URL + "deleteUserById/" + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function CreateIssueReviewDataAPI(api, data, token){
  const response = await axios.post(Base_URL + `${api}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.data;
}

export async function GetIssueReviewDataAPI(api, id, year, type, token){
  const response = await axios.get(Base_URL + `${api}/${id}/${year}/${type}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.data;
}

export async function UpdateIssueReviewDataAPI(api, id, year, type, data , token){
  const response = await axios.put(Base_URL + `${api}/${id}/${year}/${type}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.data;
}


export async function CreateDeductionAPI(data, token){
  const response = await axios.post(Base_URL + "createDeduction/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetDeductionByIdAPI(id, year, type, token){
  const response = await axios.get(Base_URL + `getDeductionById/${id}/${year}/${type}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function updateDeductionByIdAPI(data, id, year, type, token){
  const response = await axios.put(Base_URL + `updateDeductionById/${id}/${year}/${type}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}


export async function CreateOtherSmallDataAPI(api, data, token){
  const response = await axios.post(Base_URL + api, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetOtherSmallDataAPI(api, id, year, token){
  const response = await axios.get(Base_URL + api + `${id}/${year}/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function UpdateOtherSmallDataAPI(api, id, year, data , token){
  const response = await axios.put(Base_URL + api + `${id}/${year}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function CreateIncomeAPI(data, token){
  const response = await axios.post(Base_URL + "createIncome/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetIncomeByIdAPI(id, year, type, token){
  const response = await axios.get(Base_URL + `getIncomeById/${id}/${year}/${type}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function UpdateIncomeByIdAPI(data, id, year, type, token){
  const response = await axios.put(Base_URL + `updateIncomeById/${id}/${year}/${type}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}


export async function CreateCapitalGainAPI(data, token){
  const response = await axios.post(Base_URL + "createCapital/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetCapitalGainByIdAPI(id, year, token){
  const response = await axios.get(Base_URL + `getCapitalById/${id}/${year}`, {
    headers: {
      Authorization: `Beaere ${token}`
    }
  });

  return response.data;
}

export async function UpdateCapitalGainByIdAPI(data, id, year, token){
  const response = await axios.put(Base_URL + `updateCapitalById/${id}/${year}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}


export async function CreateDividendsAPI(data, token){
  const response = await axios.post(Base_URL + "createDividends/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetDividendsByIdAPI(id, year, token){
  const response = await axios.get(Base_URL + `getDividendsById/${id}/${year}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function UpdateDividendByIdAPI(data, id, year, token){
  const response = await axios.put(Base_URL + `updateDividendsById/${id}/${year}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}


export async function CreatePartnershipTrustAPI(data, token){
  const response = await axios.post(Base_URL + "createPartnership/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetPartnershipTrusAPI(id, year, token){
  const response = await axios.get(Base_URL + `getPartnershipById/${id}/${year}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function UpdatePartnershipTrustAPI(data, id, year, token){
  const response = await axios.put(Base_URL + `updatePartnershipById/${id}/${year}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function CreateRentAPI(data, token){
  const response = await axios.post(Base_URL + "createRent/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetRentByIdAPI(id, year, token){
  const response = await axios.get(Base_URL + `getRentById/${id}/${year}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function updateRentByIdAPI(data, id, year, token){
  const response = await axios.put(Base_URL + `updateRentById/${id}/${year}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}


export async function CreateTaxEstimateAPI(data, token) {
  const response = await axios.post(Base_URL + "createTaxEstimates", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetTaxEstimateAPI(id, year, token) {
  const response = await axios.get(Base_URL + `getTaxEstimatesById/${id}/${year}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function UpdateTaxEstimateAPI(data, id, year, token){
  const response = await axios.put(Base_URL + `updateTaxEstimatesById/${id}/${year}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function CreateInsuranceAPI(data, token){
  const response = await axios.post(Base_URL + 'createInsurance', data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetInsuranceByIdAPI(id, year, token){
  const response = await axios.get(Base_URL + `getInsuranceById/${id}/${year}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function UpdateInsuranceByIdAPI(data, id, year, token){
  const response = await axios.put(Base_URL + `updateInsuranceById/${id}/${year}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function GetALLDataByYearAPI(id, year, token){
  const response = await axios.get(Base_URL + `getAllDataByYear/${id}/${year}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}






export async function LoginAPI(data, token) {
  const response = await axios.post(Base_URL + "login/", data)

  return response.data;
}

export async function UpdatePasswordByIdAPI(data, id, token) {
  const response = await axios.put(Base_URL + `updatePasswordById/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function UploadImageAPI(data, token){
  const response = await axios.post(Base_URL + "uploadImage/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    },
  })

  return response.data;
}

export async function UpdateImageAPI(data, token){
  const response = await axios.put(Base_URL + `updateImage/${data.name}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data;
}

export async function DeleteImageAPI(fileName, token){
  const response = await axios.delete(Base_URL + `deleteImage/${fileName}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })

  return response.data;
}

export async function CreateTaxRateAPI(data, token){
  const response = await axios.post(Base_URL + "createTaxRates/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function GetAllTaxRateAPI(token){
  const response = await axios.get(Base_URL + "getAllTaxRates/", {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}


export async function GetTaxRateByTypeAPI(year, type, token){
  const response = await axios.get(Base_URL + `getTaxRatesByType/${year}/${type}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function UpdateTaxRateAPI(data, id, token){
  const response = await axios.put(Base_URL + `updateTaxRatesById/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}



export async function CreateFormulaAPI(data, token){
  const response = await axios.post(Base_URL + "createFormula", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function GetFormulasAPI(type, year, token){
  const response = await axios.get(Base_URL + `getFormulaByType/${type}/${year}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}

export async function UpdateFormulasAPI(data, type, year, token) {
  const response = await axios.put(Base_URL + `updateFormulaByType/${type}/${year}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  return response.data;
}