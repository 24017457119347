import { Config } from '../../util/config';


const styles = {
    borderAll: {
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
}



export const OtherPages = (workbook, data, clientData, clientYear) => {
    let otherList = ['M', 'S', 'Z', 'T'];
    for(let i=0; i<4; i++){
        let otherSheet = workbook.getWorksheet(otherList[i]);

        const currentOtherData = ((data.medicare.length!==0) && data.medicare[0]['scheduleRef'] === otherList[i])?
        data.medicare
        :
        ((data.spouse.length!==0) && data.spouse[0]['scheduleRef'] === otherList[i])?
        data.spouse
        :
        ((data.adjustment.length!==0) && data.adjustment[0]['scheduleRef'] === otherList[i])?
        data.adjustment
        :
        ((data.taxOffset.length!==0) && data.taxOffset[0]['scheduleRef'] === otherList[i])?
        data.taxOffset
        :
        null;

        if(currentOtherData === undefined || currentOtherData === null){
            continue;
        }

        let startingIndex = 0;
        for( ; startingIndex<currentOtherData[0]['data'].length; startingIndex++){
            if((10+startingIndex)>35){
                otherSheet.insertRow(10+startingIndex, [], 'i');
            }
            
            otherSheet.mergeCells(10+startingIndex, 1, 10+startingIndex, 5);
            otherSheet.getCell(`A${10+startingIndex}`).value = currentOtherData[0]['data'][startingIndex]['name'];
            otherSheet.getCell(`F${10+startingIndex}`).value = currentOtherData[0]['data'][startingIndex]['ref'];
            otherSheet.getCell(`H${10+startingIndex}`).value = currentOtherData[0]['data'][startingIndex]['total']!=='-'? Number(currentOtherData[0]['data'][startingIndex]['total']) : 0;
            
            otherSheet.getCell(`F${10+startingIndex}`).style = styles.borderAll;
            otherSheet.getCell(`H${10+startingIndex}`).style = styles.borderAll;
            otherSheet.getCell(`H${10+startingIndex}`).numFmt = '$#,##0.00';

            // Adjusting Cell Height of Name section in Table..
            otherSheet.getRow(10+startingIndex).height = currentOtherData[0]['data'][startingIndex]['name'].length>50?
            (currentOtherData[0]['data'][startingIndex]['name'].length/50)*15
            : 
            15;
        }
        if((10+startingIndex)>35){
            otherSheet.mergeCells(startingIndex+14, 1, startingIndex+14, 5);
            otherSheet.getCell(`H${startingIndex+14}`).value = Number(currentOtherData[0]['total']);
            otherSheet.getCell(`H${startingIndex+14}`).numFmt = '$#,##0.00';
        }else{
            otherSheet.mergeCells(40, 1, 40, 5);
            otherSheet.getCell(`H40`).value = Number(currentOtherData[0]['total']);
            otherSheet.getCell(`H40`).numFmt = '$#,##0.00';
        }

        


        // Assigning Files..
        if(currentOtherData[0]['attachment'].length>0){
            otherSheet.getCell(`J9`).value = 'FILES:';
            otherSheet.getCell('J9').font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
        }
        let fileIndex = 10;
        for(let j=0; j<currentOtherData[0]['attachment'].length; j++){
            otherSheet.getCell(`J${10+j}`).value = { 
                text: currentOtherData[0]['attachment'][j]['originalname'],
                hyperlink: `${Config.ImageUrl}${currentOtherData[0]['attachment'][j]['filename']}`, 
            };
            otherSheet.getCell(`J${10+j}`).font = {color: { argb: '3366cc' }}
            fileIndex += j;
        }
        fileIndex += 5;


        // Assigning Links..
        if(currentOtherData[0]['link'].length>0){
            otherSheet.getCell(`J${fileIndex}`).value = 'LINKS:';
            otherSheet.getCell(`J${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
        }
        let linkFilesLength = 0;
        for(let j=0; j<currentOtherData[0]['link'].length; j++) {
            otherSheet.getCell(`J${fileIndex+1+(j+linkFilesLength)}`).value = {
                text: currentOtherData[0]['link'][j]['link'],
                hyperlink: currentOtherData[0]['link'][j]['link'],
            }
            otherSheet.getCell(`J${fileIndex+1+(j+linkFilesLength)}`).font = {color: { argb: '3366cc' }}
            
            for(let k=0; k<currentOtherData[0]['link'][j]['attachments'].length; k++) {
                otherSheet.getCell(`K${fileIndex+2+(j+linkFilesLength)+k}`).value = { 
                    text: currentOtherData[0]['link'][j]['attachments'][k]['originalname'],
                    hyperlink: `${Config.ImageUrl}${currentOtherData[0]['link'][j]['attachments'][k]['filename']}`, 
                };
                otherSheet.getCell(`K${fileIndex+2+(j+linkFilesLength)+k}`).font = {color: { argb: '3366cc' }}
            }
            linkFilesLength += currentOtherData[0]['link'][j]['attachments'].length;
            linkFilesLength += 1;
        }
    }
}
