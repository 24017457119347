import React, {useEffect, useState, useContext} from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { evaluate } from 'mathjs';
import { AuthContext } from "../../../../../store/auth-context";
import { toast } from "react-toastify";
import {UploadImageAPI, DeleteImageAPI} from '../../../../../util/http';
import { Config } from '../../../../../util/config';



const ExpensesTable = ({expensesData, setExpensesData, totalExpenseData, setTotalExpenseData, netRentData, setNetRentData, grossRentData, propertyData, totalRent, setTotalRent, addPropertyColumn, deletePropertyColumn, SubmitHandler, formulas, setDocsChange, setLoading, setErrorOpen}) => {
    const authCtx = useContext(AuthContext);
    const [currentRow, seCurrentRow] = useState("");
    const [currenCell, setCurrentCell] = useState("");
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    useEffect(() => {
        const calculateTotal = (cellKey) => {
            if(cellKey.slice(0,8)!=="property"){
                return;
            }
            const sum = expensesData.reduce((acc, obj) => acc + parseFloat(obj[cellKey] || 0), 0);
            if(!isNaN(sum)){
                setTotalExpenseData((prevData) => ({
                    ...prevData, [cellKey]: sum
                }))
            }
        };

        for(const key of Object.keys(expensesData[0]) ){
            calculateTotal(key);
        }
    }, [expensesData]);

    
    useEffect(() => {
        const calculateNetRent = () => {    
            for(const key of Object.keys(totalExpenseData)){
                let scope = {
                    grossRent: grossRentData[key],
                    totalExpenses: totalExpenseData[key],
                    percentOwnership: propertyData[3][key],
                }
                
                try {
                    let netRent =  evaluate(formulas.netRent, scope).toFixed(2);
                    setNetRentData((prevData) => ({
                        ...prevData, [key]: netRent
                    }))
                } catch (error) {
                    setErrorOpen(true);
                }

            }
        }

        calculateNetRent();

    }, [totalExpenseData, grossRentData, propertyData, expensesData])

    useEffect(() => {
        const calculateTotalRent = () => {
            let total = 0;
            for(const key of Object.keys(netRentData)){
                let net_rent = parseFloat(netRentData[key]);
                if(!isNaN(net_rent)){
                    total += net_rent;
                }
            }
            setTotalRent(total);
        }
        
        calculateTotalRent();
    }, [netRentData])

    const addRow = () => {
        const appendableData = {...expensesData[0]};
        for(const key in appendableData){
            if(appendableData.hasOwnProperty(key)){
                appendableData[key] = "";
            }
        }

        appendableData.id = `${expensesData.length+1}`;
        setExpensesData((prevData) => [...prevData, appendableData]);
    }

    const handleTextChange = (rowIndex, cellKey, value) => {
        const updatedTableData = expensesData.map((row, index) => {
            if(index===rowIndex){
                return {...row, [cellKey]: value};
            }
            return row;
        });
        setExpensesData(updatedTableData);
    };


    const UploadFile = async(image, userToken) => {
        setLoading(true);
        const data = new FormData();
        data.append('file', image);        
        
        const uploadRes = await UploadImageAPI(data, userToken.token);
        if(uploadRes.success===true){
            toast.success(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
            const updatedTableData = expensesData.map((row, index) => {
                if (index === currentRow) {
                    return { ...row, [currenCell]: uploadRes.file };
                }
                return row;
            });
            setExpensesData(updatedTableData);
            setDocsChange(true);

        }else{
            toast.error(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }


    // File Picker Code..
    const handleFileChange = (eventfile) => {
        const selectedFile = eventfile;
        const userToken = JSON.parse(authCtx.token);
        UploadFile(selectedFile[0], userToken);
    }

    // Delete Selcted File..
    const DeleteFileChange = async(rowIndex, cellKey, fileName) => {
        setLoading(true);
        const userToken = JSON.parse(authCtx.token)
        const deleteRes = await DeleteImageAPI(fileName, userToken.token);

        if(deleteRes.success===true){
            toast.success(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
            const updatedTableData = expensesData.map((row, index) => {
                if (index === rowIndex) {
                    return { ...row, [cellKey]: "" };
                }
                return row;
            });
            setExpensesData(updatedTableData);
            setDocsChange(true);

        }else{
            toast.error(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }
    
    const openFileInNewTab = (file) => {
        window.open(`${Config.ImageUrl}${file.filename}`, '_blank');
    };


    return (
        <div className="table-container" style={{flexDirection: 'column',  overflowX: "auto"}}>

            <table className="data-table" style={{tableLayout: "fixed"}}>
                <thead>
                    <tr className="data-table-heading">
                        {Object.keys(expensesData[0]).map((key, colIndex) => {
                            return(
                                <React.Fragment key={colIndex}>
                                    {(key!=="id")&&
                                        <th className="th-name" style={key==="name"? {minWidth: "320px"}:{minWidth: "250px"}}>
                                            {key==="name"? "Expenses" : key.split("_").join(" ").toUpperCase()}
                                        </th>
                                    }
                                </React.Fragment>
                            );
                        })}
                    </tr>
                </thead>

                <tbody>  
                    {expensesData.map((item, rowIndex) => {
                        return(
                            <tr key={rowIndex} className="data-table-heading" >
                                {Object.keys(item).map((key, colIndex) => {
                                    return(
                                        (key!=="id")&&
                                        <td key={colIndex} className="data-fill-field income-field" >
                                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                                {(key.slice(0, 8) === "property")&&    
                                                    <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                                }
                                                {(key.includes("property") || key.includes("comment") || key.includes("name"))?
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                        value={item[key]}
                                                        onChange={(e) => {
                                                            if(key.slice(0, 8) === "property"){
                                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                                    handleTextChange(rowIndex, key, e.target.value);
                                                                }
                                                            }else{
                                                                handleTextChange(rowIndex, key, e.target.value);
                                                            }
                                                        }}
                                                    />
                                                    : 
                                                    <div style={{flex: 1,display: "flex", alignItems: 'center'}}>
                                                        {(typeof(item[key])==="string") ?
                                                            <input 
                                                                type="text"
                                                                placeholder={""}
                                                                style={urlRegex.test(item[key])? {flex: 1, border: 'none', color: '#009ec1', outline: 'none', fontSize: '14px', padding: '5px'} : {flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                                value={item[key]}
                                                                onChange={(e) => { handleTextChange(rowIndex, key, e.target.value); }}
                                                            />
                                                            :
                                                            <div style={{flex: 1, display: 'flex', cursor: 'pointer' }} onClick={() => { openFileInNewTab(item[key])}}>
                                                                <span style={{padding: '5px', border: '1px solid red', color: 'red', flex: 1}}>
                                                                    {item[key].filename.slice(0,10) + (item[key].filename.length>10? `...${item[key].filename.slice(-4)}` : '')}
                                                                    {" "}<CloseIcon style={{fontSize: '16px', verticalAlign: 'middle', cursor: 'pointer' }} onClick={(e) => {e.stopPropagation();  DeleteFileChange(rowIndex, key, item[key].filename)}} />
                                                                </span>
                                                            </div>
                                                        }
                                                        <div style={{display: 'flex'}}>
                                                            <label 
                                                                htmlFor="expenses-file-picker" 
                                                                className="submit-button" 
                                                                style={{margin: 0, borderRadius: 0}}
                                                                onClick={() => {seCurrentRow(rowIndex); setCurrentCell(key)}}
                                                            >
                                                                <AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} />
                                                            </label>
                                                            <input 
                                                                type="file"
                                                                name="expenses-file-picker"
                                                                id="expenses-file-picker"
                                                                onChange={(e) => {
                                                                    handleFileChange(e.target.files);
                                                                }}
                                                                onClick={(event)=> { 
                                                                    event.target.value = null
                                                                }}
                                                                className='file-input'
                                                                style={{display: 'none'}}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                
                <tfoot>
                    <tr className="data-table-heading">
                        {Object.keys(totalExpenseData).map((key, colIndex) => {
                            return(
                                <React.Fragment key={colIndex}>
                                    {(key==="property_1")&&
                                        <th className="th-name" >Total Expenses</th>
                                    }
                                    <th className="th-name" >$ {Number(totalExpenseData[key]).toFixed(2)}</th>
                                    <th className="th-name" ></th>
                                    <th className="th-name" ></th>
                                </React.Fragment>
                            );        
                        })}
                    </tr>
                    <tr className="data-table-heading">
                        {Object.keys(netRentData).map((key, colIndex) => {
                            return(
                                <React.Fragment key={colIndex}>
                                    {(key==="property_1")&&
                                        <th className="th-name" >Net Rent</th>
                                    }
                                    <th className="th-name" >$ {Number(netRentData[key]).toFixed(2)}</th>
                                    <th className="th-name" ></th>
                                    <th className="th-name" ></th>
                                </React.Fragment>
                            );        
                        })}
                    </tr>
                </tfoot>

            </table>


            <div style={{display: 'flex', justifyContent: 'space-between'}} >
                <div style={{flex: 3, display: 'flex'}}>
                    <button className="submit-button" onClick={() => {addRow()}}><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Row</button>
                    <button className="submit-button" onClick={() => {addPropertyColumn()}}><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Property & Reference</button>
                    {(Object.keys(expensesData[0]).length>5)&&
                        <button className="submit-button" onClick={() => {deletePropertyColumn()}}><DeleteForeverIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Delete Property & Reference</button>
                    }
                    <button className="submit-button" onClick={() => {SubmitHandler()}}><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
                </div>

                <table style={{flex: 1, marginTop: '10px'}} >
                    <tr style={{padding:"5px"}}>
                        <td className="th-name">Total</td>
                        <td className="calculation-table-cell">$ {Number(totalRent).toFixed(2)}</td>
                    </tr>
                </table>

            </div>


        </div>
    );
}

export default ExpensesTable