import { Config } from '../../util/config';


const styles = {
    borderAll: {
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
}

export const DeductionPages = (workbook, data, clientData, clientYear) => {
    let deductionList = ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10', 'D12', 'D15'];
    
    for(let i=0; i<deductionList.length; i++){
        let deductionSheet = workbook.getWorksheet(deductionList[i]);

        deductionSheet.getCell('C4').value = clientData.name;
        deductionSheet.getCell('C6').value = clientYear;
        deductionSheet.getCell('H5').value = clientData.staff;
        deductionSheet.getCell('H6').value = clientData.manager;
        deductionSheet.getCell('H7').value = clientData.partner;


        const currentDeductionData = data.deduction.find(item =>  (item.scheduleRef === deductionList[i]))
        if(currentDeductionData === undefined || currentDeductionData === null){
            continue;
        }

        deductionSheet.getCell('C7').value = currentDeductionData['preparedDate'];

        let startingIndex = 0;
        for( ; startingIndex<currentDeductionData['data'].length; startingIndex++){
            if((10+startingIndex)>35){
                deductionSheet.insertRow(10+startingIndex, [], 'i');
            }

            deductionSheet.mergeCells(10+startingIndex, 1, 10+startingIndex, 5);
            deductionSheet.getCell(`A${10+startingIndex}`).value = currentDeductionData['data'][startingIndex]['name'];
            deductionSheet.getCell(`F${10+startingIndex}`).value = currentDeductionData['data'][startingIndex]['ref'];
            if(deductionList[i] !== 'D5'){
                deductionSheet.getCell(`H${10+startingIndex}`).value = currentDeductionData['data'][startingIndex]['total']!=='-'? Number(currentDeductionData['data'][startingIndex]['total']) : 0;
            }else{
                deductionSheet.getCell(`G${10+startingIndex}`).value = currentDeductionData['data'][startingIndex]['fixedRateMethod']!=='-'? Number(currentDeductionData['data'][startingIndex]['fixedRateMethod']) : 0;
                deductionSheet.getCell(`H${10+startingIndex}`).value = currentDeductionData['data'][startingIndex]['shortcutMethod']!=='-'? Number(currentDeductionData['data'][startingIndex]['shortcutMethod']) : 0;
                deductionSheet.getCell(`G${10+startingIndex}`).style = styles.borderAll;
                deductionSheet.getCell(`G${10+startingIndex}`).numFmt = '$#,##0.00';
            }
            deductionSheet.getCell(`F${10+startingIndex}`).style = styles.borderAll;
            deductionSheet.getCell(`H${10+startingIndex}`).style = styles.borderAll;
            deductionSheet.getCell(`H${10+startingIndex}`).numFmt = '$#,##0.00';

            
            // Adjusting Cell Height of Name section in Table..
            deductionSheet.getRow(10+startingIndex).height = currentDeductionData['data'][startingIndex]['name'].length>50?
            (currentDeductionData['data'][startingIndex]['name'].length/50)*15
            : 
            15;
        }

        if((10+startingIndex)>35) {
            deductionSheet.mergeCells(startingIndex+14, 1, startingIndex+14, 5);
            if(deductionList[i] !== 'D5'){
                deductionSheet.getCell(`H${startingIndex+14}`).value = Number(currentDeductionData['total']);
                deductionSheet.getCell(`H${startingIndex+14}`).numFmt = '$#,##0.00';
            }else{
                deductionSheet.getCell(`G${startingIndex+14}`).value = Number(currentDeductionData['total']);
                deductionSheet.getCell(`G${startingIndex+14}`).numFmt = '$#,##0.00';
                deductionSheet.getCell(`H${startingIndex+14}`).value = Number(currentDeductionData['clientShare']);
                deductionSheet.getCell(`H${startingIndex+14}`).numFmt = '$#,##0.00';
            }
        }else{
            deductionSheet.mergeCells(40, 1, 40, 5);
            if(deductionList[i] !== 'D5'){
                deductionSheet.getCell(`H40`).value = Number(currentDeductionData['total']);
                deductionSheet.getCell(`H40`).numFmt = '$#,##0.00';
            }else{
                deductionSheet.getCell(`G40`).value = Number(currentDeductionData['total']);
                deductionSheet.getCell(`G40`).numFmt = '$#,##0.00';
                deductionSheet.getCell(`H40`).value = Number(currentDeductionData['clientShare']);
                deductionSheet.getCell(`H40`).numFmt = '$#,##0.00';
            }
        }



        // Assigning Files..
        if(currentDeductionData['attachment'].length>0){
            deductionSheet.getCell(`J9`).value = 'FILES:';
            deductionSheet.getCell('J9').font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
        }
        let fileIndex = 10;
        for(let j=0; j<currentDeductionData['attachment'].length; j++){
            deductionSheet.getCell(`J${10+j}`).value = { 
                text: currentDeductionData['attachment'][j]['originalname'],
                hyperlink: `${Config.ImageUrl}${currentDeductionData['attachment'][j]['filename']}`, 
            };
            deductionSheet.getCell(`J${10+j}`).font = {color: { argb: '3366cc' }}
            fileIndex += j;
        }
        fileIndex += 5;

        // Assigning Links..
        if(currentDeductionData['link'].length>0){
            deductionSheet.getCell(`J${fileIndex}`).value = 'LINKS:';
            deductionSheet.getCell(`J${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
        }
        let linkFilesLength = 0;
        for(let j=0; j<currentDeductionData['link'].length; j++) {
            deductionSheet.getCell(`J${fileIndex+1+(j+linkFilesLength)}`).value = {
                text: currentDeductionData['link'][j]['link'],
                hyperlink: currentDeductionData['link'][j]['link'],
            }
            deductionSheet.getCell(`J${fileIndex+1+(j+linkFilesLength)}`).font = {color: { argb: '3366cc' }}
            
            for(let k=0; k<currentDeductionData['link'][j]['attachments'].length; k++) {
                deductionSheet.getCell(`K${fileIndex+2+(j+linkFilesLength)+k}`).value = { 
                    text: currentDeductionData['link'][j]['attachments'][k]['originalname'],
                    hyperlink: `${Config.ImageUrl}${currentDeductionData['link'][j]['attachments'][k]['filename']}`, 
                };
                deductionSheet.getCell(`K${fileIndex+2+(j+linkFilesLength)+k}`).font = {color: { argb: '3366cc' }}
            }
            linkFilesLength += currentDeductionData['link'][j]['attachments'].length;
            linkFilesLength += 1;
        }

    }
}