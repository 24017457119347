import React from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Config } from '../../../../util/config';

const InsuranceTable = ({insuranceArray, handleInputChange, handleInputFileChange, setCurrentTable, DeleteFileChange, addcellFunc, removeCellFunc, totals, finalTotal}) => {

    const openFileInNewTab = (file) => {
        window.open(`${Config.ImageUrl}${file.filename}`, '_blank');
    };

    return (
        <div className="table-container" style={{flexDirection: 'column'}}>
                
            {insuranceArray.map((item, index) => {
                return(
                    <table className="data-table" style={{tableLayout: "fixed", flex: 1}} key={index}>
                        <tr className="data-table-heading">
                            <th className="th-name" colSpan="2" >Insurance {index + 1}</th>
                        </tr>
                        
                        
                        <tr className="data-table-heading" >
                            <td className="data-fill-field income-field" style={{minWidth: '320px'}}>
                                <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                        value={item.name}
                                        onChange={(e) => {
                                            handleInputChange(index, 0, 'date', e, 'name');
                                        }}
                                    />
                                </div>
                            </td>
                            
                            <td className="data-fill-field income-field">
                                <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                    <input
                                        type="file"
                                        placeholder=""
                                        id={`insurance_file_${index}`}
                                        name={`insurance_file_${index}`}
                                        style={{display: "none"}}
                                        onChange={(e) => {
                                            handleInputFileChange(e.target.files);
                                        }}
                                        onClick={(event)=> { 
                                            event.target.value = null
                                        }}
                                        className='file-input'
                                    />
                                    <label 
                                        htmlFor={`insurance_file_${index}`} 
                                        className="submit-button" 
                                        style={{margin: 0, borderRadius: 0}}
                                        onClick={() => {setCurrentTable(index)}}
                                    >
                                        <AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} />
                                    </label>
                                    {((typeof(item.file))==="object" && Object.keys(item.file).length>0)&&
                                        <div style={{flex: 1, display: 'flex', cursor: 'pointer' }} onClick={() => { openFileInNewTab(item.file) }}>
                                            <span style={{padding: '5px', border: '1px solid red', color: 'red', flex: 1}}>
                                                {item.file.filename.slice(0,10) + (item.file.filename.length>10? `...${item.file.filename.slice(-4)}` : '')}
                                                {" "}<CloseIcon style={{fontSize: '16px', verticalAlign: 'middle', cursor: 'pointer' }} onClick={(e) => {e.stopPropagation(); DeleteFileChange(item.file.filename, index) }} />
                                            </span>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>


                        
                        {item.data.map((innerItem, innerIndex) => {
                            return(
                                <tr className="data-table-heading" key={innerIndex + index} >
                                    <td className="data-fill-field income-field" style={{minWidth: '320px'}}>
                                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                            <input
                                                type="date"
                                                placeholder=""
                                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                value={innerItem.date}
                                                onChange={(e) => {
                                                    handleInputChange(index, innerIndex, 'date', e, 'data');
                                                }}
                                            />
                                        </div>
                                    </td>
                                    
                                    <td className="data-fill-field income-field">
                                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                            <input
                                                type="text"
                                                placeholder="..."
                                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                value={innerItem.amount}
                                                onChange={(e) => {
                                                    if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                        handleInputChange(index, innerIndex, 'amount', e, 'data');
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}

                        <tr className="data-table-heading">
                            <th className="th-name" >Total</th>
                            {(totals[`total${index+1}`]!==undefined)?
                                <th className="th-name" >$ {Number(totals[`total${index+1}`]).toFixed(2)}</th>
                                :
                                <th className="th-name" >$ 0.00</th>
                            }
                        </tr>
                        <tr>
                            <div style={{flex: 3, display: 'flex'}}>
                                <button className="submit-button" style={{margin: '5px'}} onClick={() => {addcellFunc(index)}}><AddIcon style={{fontSize: '14px', verticalAlign: 'middle', margin: '5px' }} /> Add Cell</button>
                                {(item.data.length>1)&&
                                    <button className="submit-button" style={{margin: '5px'}} onClick={() => {removeCellFunc(index)}}><AddIcon style={{fontSize: '14px', verticalAlign: 'middle', margin: '5px' }} /> Remove Cell</button>
                                }
                            </div>
                        </tr>

                    </table>
                );
            })}

            <table className="calculation-table" style={{ width: 300}}>
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>TOTAL</td>
                    <td className="calculation-table-cell data-fill-field income-field" style={{textAlign: 'left', paddingLeft: 5}} >$ {Number(finalTotal).toFixed(2)}</td>
                </tr>
            </table>

        </div>
    )
}

export default InsuranceTable