import React, {useState, useEffect, useContext} from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import SendIcon from '@mui/icons-material/Send';
import ReactLoading from "react-loading";

import QueryClientTables, {PreparedReviewedTable} from '../../ClientTables/ClientTables';
import IssueReviewDataTables from './DataTables/IssueReviewDataTables';

import { CreateIssueReviewDataAPI, GetIssueReviewDataAPI, UpdateIssueReviewDataAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../../../../store/auth-context";
import { toast } from "react-toastify";

import LinkAttachFile from '../../../../components/LinkAttachFile';
import AttachFile from '../../../../components/AttachFile';


const ReviewNotesModal = ({currentModal, currentPage, clientData, clientUpdateYear, setDocsChange}) => {
  const authCtx = useContext(AuthContext);
  const [queryTable, setQueryTable] = useState([{ query: "", response: "" }]);
  const [reviewPrepare, setReviewPrepare] = useState({
    preparedBy: '',
    preparedDate: '',
    reviewedBy: '',
    reviewedDate: '',
  });

  const [loading, setLoading] = useState(false);
  const [isDataAlready, setDataAlready] = useState(false);

  // Using at LinkAttachFile.js
  const [linkAttachable, setLinkAttachable] = useState(false);
  const [links, setLinks] = useState([]);
  
  // Using at AttachFile.js
  const [selectedFiles, setSelectedFiles] = useState([]);

  const apis =  {get: 'getReviewNotesById', create: 'createReviewNotes', update: 'updateReviewNotesById'};
  const modalType = currentModal.replace(/[\s,]+/g, '_');
  const clientYear = clientUpdateYear.getFullYear();


  useEffect(() => {
    const GettingReviewNotesData = async() => {
      const authToken = await secureLocalStorage.getItem("token");
      const userToken = JSON.parse(authToken);
      setLoading(true);

      const getRes = await GetIssueReviewDataAPI(apis.get, clientData._id, clientYear, modalType, userToken.token);
      if(getRes.success === true){
        if(getRes.data === null || getRes.data === undefined){
          setDataAlready(false);
        }else {
          setDataAlready(true);
          setQueryTable(getRes.data.data);
          setReviewPrepare({preparedBy: getRes.data.preparedBy, preparedDate: getRes.data.preparedDate, reviewedBy: getRes.data.reviewBy, reviewedDate: getRes.data.reviewDate})
          setSelectedFiles(getRes.data.attachment);
          setLinks(getRes.data.link);
        }
      }
      
      setLoading(false);
    }

    GettingReviewNotesData();

  }, [isDataAlready]);

  
  const handleChange = (rowIndex, cellKey, value) => {
    const updatedTableData = queryTable.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [cellKey]: value };
      }
      return row;
    });
    setQueryTable(updatedTableData);

  };

  const AddCellFunc = () => {
    let newElement = {query: "", response: ""};
    setQueryTable(oldArray => [...oldArray, newElement]);
  }

  const RemoveCellFunc = () => {
    let newData = [...queryTable];
    newData.pop();
    setQueryTable(newData);
  }


  const HandleSubmit = async() => {
    const userToken = JSON.parse(authCtx.token);
    setLoading(true);

    let sendData = {
      clientId: clientData._id,
      year: clientYear,
      type: modalType,
      area: currentModal,
      preparedBy: reviewPrepare.preparedBy,
      preparedDate: reviewPrepare.preparedDate,
      reviewBy: reviewPrepare.reviewedBy,
      reviewDate: reviewPrepare.reviewedDate,
      data: queryTable,
      attachment: selectedFiles,
      link: links,
    };

    const submitRes = isDataAlready? 
    await UpdateIssueReviewDataAPI(apis.update, clientData._id, clientYear, modalType, sendData, userToken.token) 
    :
    await CreateIssueReviewDataAPI(apis.create, sendData, userToken.token);

    if(submitRes.success === true){
      toast.success(`${submitRes.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
      setDataAlready(true);
      setDocsChange(false);
    }else{
      toast.error(`${submitRes.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    
    setLoading(false);
  };


  return (
    <>
      <QueryClientTables currentModal={currentModal} clientData={clientData} clientUpdateYear={clientUpdateYear} />

      <PreparedReviewedTable reviewPrepare={reviewPrepare} setReviewPrepare={setReviewPrepare} />


      <IssueReviewDataTables 
        queryTable={queryTable} 
        handleChange={handleChange}
      />


      <AttachFile 
        selectedFiles={selectedFiles} 
        setSelectedFiles={setSelectedFiles}
        setDocsChange={setDocsChange}
        setLoading={setLoading}
      />

      <LinkAttachFile 
        links={links} 
        setLinks={setLinks} 
        linkAttachable={linkAttachable} 
        setLinkAttachable={setLinkAttachable} 
        setDocsChange={setDocsChange}
        setLoading={setLoading}
      />

      <div style={{display: 'flex', marginLeft: '5px', paddingBottom: '80px'}} >
        <button className="submit-button" onClick={() => {AddCellFunc()}} ><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Cell</button>
        {queryTable.length>1&&
          <button className="submit-button" onClick={() => {RemoveCellFunc()}} ><DeleteForeverIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Remove Cell</button>
        }
        <label htmlFor="file-picker" className="submit-button" onClick={() => {}} ><AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Attach</label>
        <button  className="submit-button" onClick={() =>{setLinkAttachable(true)}} ><InsertLinkIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Link</button>
        <button className="submit-button" onClick={() => {HandleSubmit()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
      </div>
      
      {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}

    </>
  )
}

export default ReviewNotesModal;