import React from 'react';
import EditableTaxRateHeader from '../../../../../components/EditableTaxRateHeader';

const MotorVehicleTable = ({editable, inputs, handleFormInput, dataArray, handleInputChange}) => {
    return(
        <table className="data-table" style={{marginTop: '5px', textAlign: 'center'}}>
            <tr>
                <th className="th-name" style={{width: '55%'}}>
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading1'}
                        inputLink={'heading1Link'}
                        inputs={inputs}
                    />
                </th>
                <td className="data-fill-field" >
                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                        <input 
                            type="text" 
                            placeholder=""
                            name="year"
                            value={dataArray[0].year}
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                            onChange={(e) => { editable&& handleInputChange(e, 0, "year") }} 
                        />
                    </div>
                </td>

                <td className="data-fill-field" >
                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                        <input 
                            type="text" 
                            placeholder=""
                            name="price"
                            value={dataArray[0].price}
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                            onChange={(e) => { 
                                if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                    handleInputChange(e, 0, "price");
                                }
                            }} 
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th className="th-name">
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading2'}
                        inputLink={'heading2Link'}
                        inputs={inputs}
                    />
                </th>
                <td className="data-fill-field" >
                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                        <input 
                            type="text" 
                            placeholder=""
                            name="year"
                            value={dataArray[1].year}
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                            onChange={(e) => { editable&& handleInputChange(e, 1, 'year') }} 
                        />
                    </div>
                </td>
                <td className="data-fill-field" >
                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                        <input 
                            type="text" 
                            placeholder=""
                            name="price"
                            value={dataArray[1].price}
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                            onChange={(e) => { 
                                if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                    handleInputChange(e, 1, 'price');
                                }
                            }} 
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th className="th-name">
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading3'}
                        inputLink={'heading3Link'}
                        inputs={inputs}
                    />
                </th>
                <td className="data-fill-field" >
                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                        <input 
                            type="text" 
                            placeholder=""
                            name="year"
                            value={dataArray[2].year}
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                            onChange={(e) => { editable&& handleInputChange(e, 2, 'year') }} 
                        />
                    </div>
                </td>
                <td className="data-fill-field" >
                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                        <input 
                            type="text" 
                            placeholder=""
                            name="price"
                            value={dataArray[2].price}
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}  
                            onChange={(e) => { 
                                if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                    handleInputChange(e, 2, 'price');
                                }
                            }} 
                        />
                    </div>
                </td>
            </tr>
        </table>
    );
}

export default MotorVehicleTable;