import React from 'react'

const TaxWithheldGrossTable = ({dataArray, handleChange, totalwithheld, totalGross}) => {
    
    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset to auto height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
    };

    return (
        <div className="table-container" style={{flexDirection: 'column'}}>
            <table className="data-table">
                <tr className="data-table-heading" >
                    <th className="th-name" style={{width: '50%'}} ></th>
                    <th className="th-name" style={{width: '15%'}} >Ref</th>
                    <th className="th-name"  >Tax Withheld</th>
                    <th className="th-name" style={{width: '15%'}} >Gross</th>
                </tr>

                {dataArray.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="data-fill-field" >
                                <textarea 
                                    type="text" 
                                    placeholder="" 
                                    rows="1"
                                    wrap="hard"
                                    style={{resize: 'vertical'}}
                                    className="textArea textArea-complete-witdth"
                                    value={item.name}
                                    onChange={(e) => { 
                                        handleChange(index, "name", e.target.value);
                                        adjustTextareaHeight(e.target);
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="text" 
                                    placeholder=""
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.ref}
                                    onChange={(e) => { 
                                        handleChange(index, "ref", e.target.value);
                                        adjustTextareaHeight(e.target);
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.taxWithheld}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "taxWithheld", e.target.value);
                                            adjustTextareaHeight(e.target);
                                        }
                                    }} 
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.gross}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "gross", e.target.value);
                                            adjustTextareaHeight(e.target);
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })}

                <tr className="data-table-heading" >
                    <th className="th-name" colSpan="2" >Total</th>
                    <th className="th-name" >$ {Number(totalwithheld).toFixed(2)}</th>
                    <th className="th-name" >$ {Number(totalGross).toFixed(2)}</th>
                </tr>

            </table>

        </div>
    );
}


export const SimpleCGTable = ({dataArray, handleChange, totalTotal, totalNet}) => {
    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset to auto height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
    };

    return (
        <div className="table-container" style={{flexDirection: 'column'}}>
            <table className="data-table">
                <tr className="data-table-heading" >
                    <th className="th-name" style={{width: '50%'}} ></th>
                    <th className="th-name" style={{width: '15%'}} >Ref</th>
                    <th className="th-name"  >Total</th>
                    <th className="th-name" style={{width: '15%'}} >Net</th>
                </tr>

                {dataArray.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="data-fill-field" >
                                <textarea 
                                    type="text" 
                                    placeholder="" 
                                    rows="1"
                                    wrap="hard"
                                    style={{resize: 'vertical'}}
                                    className="textArea textArea-complete-witdth"
                                    value={item.name}
                                    onChange={(e) => { 
                                        handleChange(index, "name", e.target.value);
                                        adjustTextareaHeight(e.target);
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="text" 
                                    placeholder=""
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.ref}
                                    onChange={(e) => { 
                                        handleChange(index, "ref", e.target.value);
                                        adjustTextareaHeight(e.target);
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.taxWithheld}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "taxWithheld", e.target.value);
                                            adjustTextareaHeight(e.target);
                                        }
                                    }} 
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.gross}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "gross", e.target.value);
                                            adjustTextareaHeight(e.target);
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })}

                <tr className="data-table-heading" >
                    <th className="th-name" colSpan="2" >Total</th>
                    <th className="th-name" >$ {Number(totalTotal).toFixed(2)}</th>
                    <th className="th-name" >$ {Number(totalNet).toFixed(2)}</th>
                </tr>

            </table>

        </div>
    );
}


const ClientShareTable = ({dataArray, handleChange, totalAmount, clientAmount}) => {

    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset to auto height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
    };

    return(
        <div className="table-container" style={{flexDirection: 'column'}}>
            <table className="data-table">
                <tr className="data-table-heading" >
                    <th className="th-name" style={{width: '50%'}} ></th>
                    <th className="th-name" style={{width: '15%'}} >Ref</th>
                    <th className="th-name" style={{width: '15%'}} >Total</th>
                    <th className="th-name"  >Client Share</th>
                </tr>

                {dataArray.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="data-fill-field" >
                                <textarea 
                                    type="text" 
                                    placeholder="" 
                                    rows="1"
                                    wrap="hard"
                                    style={{resize: 'vertical'}}
                                    className="textArea textArea-complete-witdth"
                                    value={item.name}
                                    onChange={(e) => { 
                                        handleChange(index, "name", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="text" 
                                    placeholder=""
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.ref}
                                    onChange={(e) => { 
                                        handleChange(index, "ref", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.total}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "total", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }} 
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.clientShare}
                                    onChange={(e) => { 
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "clientShare", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }} 
                                />
                            </td>
                        </tr>
                    );
                })}

                <tr className="data-table-heading" >
                    <th className="th-name" colSpan="2" >Total</th>
                    <th className="th-name" >$ {Number(totalAmount).toFixed(2)}</th>
                    <th className="th-name" >$ {Number(clientAmount).toFixed(2)}</th>
                </tr>

            </table>
        

        </div>
    );
}


const BusinessIncomeTable = ({dataArray, handleChange, totalAmount}) => {
    
    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset to auto height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
    };

    return(
        <div className="table-container" style={{flexDirection: 'column'}}>
            <table className="data-table">
                <tr className="data-table-heading" >
                    <th className="th-name" style={{width: '50%'}} ></th>
                    <th className="th-name" style={{width: '15%'}} >Ref</th>
                    <th className="th-name">Total</th>
                </tr>

                {dataArray.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="data-fill-field" >
                                <textarea 
                                    type="text" 
                                    placeholder="" 
                                    rows="1"
                                    wrap="hard"
                                    style={{resize: 'vertical'}}
                                    className="textArea textArea-complete-witdth"
                                    value={item.name}
                                    onChange={(e) => { 
                                        handleChange(index, "name", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="text" 
                                    placeholder=""
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.ref}
                                    onChange={(e) => { 
                                        handleChange(index, "ref", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.total}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "total", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }} 
                                />
                            </td>
                        </tr>
                    );
                })}

                <tr className="data-table-heading" >
                    <th className="th-name" colSpan="2" >Total</th>
                    <th className="th-name" >$ {Number(totalAmount).toFixed(2)}</th>
                </tr>

            </table>
        </div>
    );
}


const DividendTable = ({dividendArray, handleInputChange, totals, clientData, partnerName}) => {
    return(
        <div className="table-container" style={{justifyContent: "unset", flexDirection: 'column'}}>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row',  overflowX: "auto", justifyContent: "unset", alignItems: 'baseline'}} >

                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading">
                        <th className="th-name" colSpan="7" ></th>
                    </tr>
                    
                    <tr className="data-table-heading" style={{backgroundColor: '#ffff99'}} >
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Company</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Shares Held</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>$ Div per Share</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Franking %</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Total Dividend</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Total Franking Cr</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>ownership</th>
                    </tr>

                    {dividendArray[0].map((item, index) => {
                        return(
                            <tr className="data-table-heading" key={index} >
                                
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.company}
                                            onChange={(e) => {
                                                handleInputChange(0, index, 'company', e);
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.sharesHeld}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)) {
                                                    handleInputChange(0, index, 'sharesHeld', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.divPerShare}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(0, index, 'divPerShare', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.Franking}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(0, index, 'Franking', e);
                                                }
                                            }}
                                        />
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.totalDividend}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(0, index, 'totalDividend', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.totalFrankingCr}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(0, index, 'totalFrankingCr', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.ownership}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(0, index, 'ownership', e);
                                                }
                                            }}
                                        />
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}

                    <tr className="data-table-heading" style={{backgroundColor: '#ffff99'}}>
                        <th className="th-name" style={{color: '#000'}} colSpan="4">Total</th>
                        <th className="th-name" style={{color: '#000'}} >$ {totals.totalDividend.toFixed(2)}</th>
                        <th className="th-name" style={{color: '#000'}} >$ {totals.totalFrankingCr.toFixed(2)}</th>
                    </tr>

                </table>

                
                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading" style={{backgroundColor: '#99ccff'}} >
                        <th className="th-name" style={{color: '#000'}} colSpan="3" >{clientData.name}</th>
                    </tr>
                    
                    <tr className="data-table-heading" style={{backgroundColor: '#99ccff'}} >
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Franked Div</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>UnFranked Div</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Franking Cr</th>
                    </tr>

                    {dividendArray[1].map((item, index) => {
                        return(
                            <tr className="data-table-heading" key={index}>
                                
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.owner_frankedDiv}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(1, index, 'owner_frankedDiv', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.owner_unFrankedDiv}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(1, index, 'owner_unFrankedDiv', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.owner_frankingCr}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(1, index, 'owner_frankingCr', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                
                            </tr>
                        );
                    })}


                    <tr className="data-table-heading"  style={{backgroundColor: '#99ccff'}} >
                        <th className="th-name" style={{color: '#000'}} >$ {totals.owner_frankedDiv.toFixed(2)}</th>
                        <th className="th-name" style={{color: '#000'}} >$ {totals.owner_unFrankedDiv.toFixed(2)}</th>
                        <th className="th-name" style={{color: '#000'}} >$ {totals.owner_frankingCr.toFixed(2)}</th>
                    </tr>

                </table>

                
                {/* Partner Table.. */}
                {(dividendArray[2] !==undefined)&&
                    <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                        <tr className="data-table-heading" style={{backgroundColor: '#ff99cc'}}>
                            <th className="th-name" style={{color: '#000'}} colSpan="3" >{partnerName}</th>
                        </tr>
                        
                        <tr className="data-table-heading" style={{backgroundColor: '#ff99cc'}}>
                            <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Franked Div</th>
                            <th className="th-name" style={{minWidth: "250px", color: '#000'}}>UnFranked Div</th>
                            <th className="th-name" style={{minWidth: "250px", color: '#000'}}>Franking Cr</th>
                        </tr>

                        {dividendArray[2].map((item, index) => {
                            return(
                                <tr className="data-table-heading" key={index}>  
                                    <td className="data-fill-field income-field">
                                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                            <input
                                                type="text"
                                                placeholder=""
                                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                value={item.partner_frankedDiv}
                                                onChange={(e) => {
                                                    if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                        // handleInputChange(2, index, 'partner_frankedDiv', e);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className="data-fill-field income-field">
                                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                            <input
                                                type="text"
                                                placeholder=""
                                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                value={item.partner_unFrankedDiv}
                                                onChange={(e) => {
                                                    if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                        // handleInputChange(2, index, 'partner_unFrankedDiv', e);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className="data-fill-field income-field">
                                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                            <input
                                                type="text"
                                                placeholder=""
                                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                value={item.partner_frankingCr}
                                                onChange={(e) => {
                                                    if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                        // handleInputChange(2, index, 'partner_frankingCr', e);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>  
                                </tr>
                            );
                        })}

                        <tr className="data-table-heading" style={{backgroundColor: '#ff99cc'}}>
                            <th className="th-name" style={{color: '#000'}}>$ {totals.partner_frankedDiv.toFixed(2)}</th>
                            <th className="th-name" style={{color: '#000'}}>$ {totals.partner_unFrankedDiv.toFixed(2)}</th>
                            <th className="th-name" style={{color: '#000'}}>$ {totals.partner_frankingCr.toFixed(2)}</th>
                        </tr>

                    </table>
                }


            </div>
            
        </div>
    );
}



const PartnershipTrustTable = ({dataArray, handleChange, totalwithheld, totalGross, clientUpdateYear, fyYear, taxDeferredTotal, costInput, setCostInput, adjustedCost}) => {
    
    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset to auto height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
    };

    return (
        <div className="table-container" style={{flexDirection: 'column'}}>
            <table className="data-table">
                <tr className="data-table-heading" >
                    <th className="th-name" style={{width: '50%'}} ></th>
                    <th className="th-name"  style={{width: '15%'}}>Ref</th>
                    <th className="th-name"  >Tax Withheld</th>
                    <th className="th-name"  style={{width: '15%'}}>Gross</th>
                </tr>

                {dataArray.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="data-fill-field" key={index} >
                                <textarea 
                                    type="text" 
                                    placeholder="" 
                                    rows="1"
                                    wrap="hard"
                                    style={{resize: 'vertical'}}
                                    className="textArea textArea-complete-witdth"
                                    value={item.name}
                                    onChange={(e) => { 
                                        handleChange(index, "name", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="text" 
                                    placeholder=""
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.ref}
                                    onChange={(e) => { 
                                        handleChange(index, "ref", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.taxWithheld}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "taxWithheld", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }} 
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.gross}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "gross", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })}

                <tr className="data-table-heading" >
                    <th className="th-name" colSpan="2" >Total</th>
                    <th className="th-name" >$ {Number(totalwithheld).toFixed(2)}</th>
                    <th className="th-name" >$ {Number(totalGross).toFixed(2)}</th>
                </tr>

            </table>
            

            <table className="calculation-table" >
                <tr>
                    <td className="th-name" colSpan="2" style={{textAlign: 'center', padding: '5px'}}>Tax Deferred</td>
                </tr>
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>FY{clientUpdateYear.getFullYear()-2}</td>
                    <td className="calculation-table-cell data-fill-field income-field" >
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                            <p>{Number(fyYear.secondLastYearCost).toFixed(2)}</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>FY{clientUpdateYear.getFullYear()-1}</td>
                    <td className="calculation-table-cell  data-fill-field income-field">
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                            <p>{Number(fyYear.lastYearCost).toFixed(2)}</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>FY{clientUpdateYear.getFullYear()}</td>
                    <td className="calculation-table-cell  data-fill-field income-field">
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                            <p>{Number(totalGross).toFixed(2)}</p>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td className="th-name" style={{padding: '5px'}}>Total</td>
                    <td className="calculation-table-cell  data-fill-field income-field">
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                            <p>{Number(taxDeferredTotal).toFixed(2)}</p>
                        </div>
                    </td>
                </tr>
            </table>

            <table className="calculation-table" >
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>Cost</td>
                    <td className="calculation-table-cell data-fill-field income-field" >
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                            <input
                                type="text"
                                placeholder="Enter Cost.."
                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                value={costInput}
                                onChange={(e) => {
                                    if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                        setCostInput(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>Tax deferred</td>
                    <td className="calculation-table-cell  data-fill-field income-field">
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                            <p>{Number(taxDeferredTotal).toFixed(2)}</p>
                        </div>
                    </td>
                </tr>
                <tr style={{border: '3px solid #000'}}>
                    <td className="th-name" style={{padding: '5px'}}>Adjusted Cost</td>
                    <td className="calculation-table-cell  data-fill-field income-field">
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                            <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                            <p>{Number(adjustedCost).toFixed(2)}</p>
                        </div>
                    </td>
                </tr>
            </table>

        </div>
    );
}



const CapitalGainsTable = ({capitalGainsData, handleInputChange, totals, discountedCG}) => {
    return (
        <div className="table-container" style={{justifyContent: "unset", flexDirection: 'column'}}>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row',  overflowX: "auto", justifyContent: "unset", alignItems: 'baseline'}} >

                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading"  style={{backgroundColor: '#339966'}} >
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Company</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>D.O.P</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Qty #</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>$ Unit</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Brkage</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Cost</th>
                    </tr>
                    
                    {capitalGainsData[0].map((item, index) => {
                        return (
                            <tr className="data-table-heading" key={index} >
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.company}
                                            onChange={(e) => {
                                                handleInputChange(0, index, 'company', e);
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="date"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '0 5px'}}
                                            value={item.dop}
                                            onChange={(e) => {
                                                handleInputChange(0, index, 'dop', e);
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.purchaseQty}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(0, index, 'purchaseQty', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.purchaseUnit}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(0, index, 'purchaseUnit', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.purchaseBrkage}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(0, index, 'purchaseBrkage', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.cost}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(0, index, 'cost', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}

                    <tr className="data-table-heading"  style={{backgroundColor: '#339966'}} >
                        <th className="th-name" style={{color: '#fff'}} colSpan="5">Total</th>
                        <th className="th-name" style={{color: '#fff'}} >$ {totals.cost.toFixed(2)}</th>
                    </tr>

                </table>

                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading" style={{backgroundColor: '#ff9900'}} >
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>D.O.S</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Qty #</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>$ Unit</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Brkage</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Proceeds</th>
                    </tr>

                    {capitalGainsData[1].map((item, index) => {
                        return(
                            <tr className="data-table-heading" key={index}>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="date"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '0 5px'}}
                                            value={item.dos}
                                            onChange={(e) => {
                                                handleInputChange(1, index, 'dos', e);
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.saleQty}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(1, index, 'saleQty', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.saleUnit}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(1, index, 'saleUnit', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.saleBrkage}
                                            onChange={(e) => {
                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleInputChange(1, index, 'saleBrkage', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.proceeds}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(1, index, 'proceeds', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}

                    <tr className="data-table-heading" style={{backgroundColor: '#ff9900'}} >
                        <th className="th-name" style={{color: '#fff'}} colSpan="4"></th>
                        <th className="th-name" style={{color: '#fff'}} >$ {totals.proceeds.toFixed(2)}</th>
                    </tr>


                </table>


                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading" style={{backgroundColor: '#0000ff'}} >
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Discount CG</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Other CG</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>Capital Loss</th>
                    </tr>

                    {capitalGainsData[2].map((item, index) => {
                        return(
                            <tr className="data-table-heading" key={index}>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.discountCG}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(2, index, 'discountCG', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.otherCG}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(2, index, 'otherCG', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td className="data-fill-field income-field">
                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                            value={item.capitalLoss}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    // handleInputChange(2, index, 'capitalLoss', e);
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}

                    <tr className="data-table-heading" style={{backgroundColor: '#0000ff'}} >
                        <th className="th-name" style={{color: '#fff'}} >$ {totals.discountCG.toFixed(2)}</th>
                        <th className="th-name" style={{color: '#fff'}} >$ {totals.otherCG.toFixed(2)}</th>
                        <th className="th-name" style={{color: '#fff'}} >$ {totals.capitalLoss.toFixed(2)}</th>
                    </tr>

                </table>

            </div>


            <table className="calculation-table" >
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>Discountable CG</td>
                    <td className="calculation-table-cell data-fill-field income-field" style={{ textAlign: 'left', paddingLeft: 5 }}>$ {totals.discountCG.toFixed(2)}</td>
                </tr>
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>Less: Capital loss</td>
                    <td className="calculation-table-cell  data-fill-field income-field" style={{ textAlign: 'left', paddingLeft: 5 }}>$ {totals.capitalLoss.toFixed(2)}</td>
                </tr>
                <tr>
                    <td className="th-name" style={{padding: '5px'}}>Total CG</td>
                    <td className="calculation-table-cell  data-fill-field income-field" style={{ textAlign: 'left', paddingLeft: 5 }}>$ {(totals.discountCG.toFixed(2) - totals.capitalLoss.toFixed(2)).toFixed(2)}</td>
                </tr>
                <tr style={{border: '3px solid #000'}}>
                    <td className="th-name" style={{padding: '5px'}}>Discounted CG</td>
                    <td className="calculation-table-cell  data-fill-field income-field" style={{ textAlign: 'left', paddingLeft: 5 }}>$ {Number(discountedCG).toFixed(2)}</td>
                </tr>
            </table>
        </div>
    );
}



export default TaxWithheldGrossTable;
export {ClientShareTable};
export {BusinessIncomeTable};
export {DividendTable};
export {PartnershipTrustTable};
export {CapitalGainsTable};
