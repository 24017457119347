import React from 'react';
import './Check.css';
import { incomeData, deductionData, otherData } from './ChecksData';
import parse from 'html-react-parser';

const CheckBox = ({dataList, heading}) => {
    return(
        <div className='check-list-Container'>
            <div className='check-box-heading-container'>
                <h3 className='check-box-heading'>{heading}</h3>
            </div>

            {dataList.map((item, index) => {
                return(
                    <div className='check-lists-box' key={index}>
                        <div className='mrg-btm-10'>
                            {(!item.isTitleAnchor)?
                                <h4 className='innerListHeading' >{item.title}</h4>
                                :
                                <a className='innerListAnchor' href={item.anchor} target='_blank'>{item.title}</a>
                            }
                            <div className='inner-list-detail-container'>
                                {item.desc.map((descItem, descIndex) =>{
                                    return(<>{parse(descItem.data)}</>)
                                })}

                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

const Checks = () => {
    return (
        <div className='checks-container'>
            <div className='boxes-container'>
                
                <CheckBox dataList={incomeData} heading="Income" />
                <CheckBox dataList={deductionData} heading="Deductions" />
                <CheckBox dataList={otherData} heading="Other" />
                
            </div>
        </div>
    )
}

export default Checks;