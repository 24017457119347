import React, {useState} from 'react';
import ExportToExcel from './ExportToExcel';

const TableBox = ({ModalOpenerFunc}) => {
    const [visibleModalList, setVisibleModalList] = useState("1");

    return (
        <div className="table-box">
            <div className="card">
                <div className="card-body">
                    <div className="servicelists">
                        <div className="">
                            <div className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="horizontal">
                                <button className={`nav-link ${visibleModalList==='1'&& 'active'}`} id="v-pills-tabone-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabone" type="button" role="tab" aria-controls="v-pills-tabone" aria-selected={visibleModalList==='1'? 'true' : 'false'} onClick={() => {setVisibleModalList("1")}} >ISSUES & REVIEW</button>
                                <button className={`nav-link ${visibleModalList==='2'&& 'active'}`} id="v-pills-tabtwo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabtwo" type="button" role="tab" aria-controls="v-pills-tabtwo" aria-selected={visibleModalList==='2'? 'true' : 'false'} onClick={() => {setVisibleModalList("2")}} >DEDUCTIONS</button>
                                <button className={`nav-link ${visibleModalList==='3'&& 'active'}`} id="v-pills-tabthree-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabthree" type="button" role="tab" aria-controls="v-pills-tabthree" aria-selected={visibleModalList==='3'? 'true' : 'false'} onClick={() => {setVisibleModalList("3")}} >TAX ESTIMATES</button>
                                <button className={`nav-link ${visibleModalList==='4'&& 'active'}`} id="v-pills-tabfour-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabfour" type="button" role="tab" aria-controls="v-pills-tabfour" aria-selected={visibleModalList==='4'? 'true' : 'false'} onClick={() => {setVisibleModalList("4")}} >INCOME</button>
                                <button className={`nav-link ${visibleModalList==='5'&& 'active'}`} id="v-pills-tabfive-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabfive" type="button" role="tab" aria-controls="v-pills-tabfive" aria-selected={visibleModalList==='5'? 'true' : 'false'} onClick={() => {setVisibleModalList("5")}} >MEDICARE & PRIVATE HEALTH</button>
                                <button className={`nav-link ${visibleModalList==='6'&& 'active'}`} id="v-pills-tabsix-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabsix" type="button" role="tab" aria-controls="v-pills-tabsix" aria-selected={visibleModalList==='6'? 'true' : 'false'} onClick={() => {setVisibleModalList("6")}} >SPOUSE & FAMILY BENEFITS</button>
                                <button className={`nav-link ${visibleModalList==='7'&& 'active'}`} id="v-pills-tabsaven-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabsaven" type="button" role="tab" aria-controls="v-pills-tabsaven" aria-selected={visibleModalList==='7'? 'true' : 'false'} onClick={() => {setVisibleModalList("7")}} >TAX OFFSETS</button>
                                <button className={`nav-link ${visibleModalList==='8'&& 'active'}`} id="v-pills-tabeight-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabeight" type="button" role="tab" aria-controls="v-pills-tabeight" aria-selected={visibleModalList==='8'? 'true' : 'false'} onClick={() => {setVisibleModalList("8")}} >ADJUSTMENTS	</button>
                                <button className={`nav-link ${visibleModalList==='10'&& 'active'}`} id="v-pills-tabten-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabten" type="button" role="tab" aria-controls="v-pills-tabten" aria-selected={visibleModalList==='10'? 'true' : 'false'} onClick={() => {setVisibleModalList("10")}} >INSURANCE</button>
                                <button className={`nav-link ${visibleModalList==='11'&& 'active'}`} id="v-pills-tabeleven-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabeleven" type="button" role="tab" aria-controls="v-pills-tabeleven" aria-selected={visibleModalList==='11'? 'true' : 'false'} onClick={() => {setVisibleModalList("11")}} >CHECKS</button>
                                <button className={`nav-link ${visibleModalList==='9'&& 'active'}`} id="v-pills-tabnine-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tabnine" type="button" role="tab" aria-controls="v-pills-tabnine" aria-selected={visibleModalList==='9'? 'true' : 'false'} onClick={() => {setVisibleModalList("9")}} >TAX RATES</button>
                            </div>

                            <div className="tab-content" id="v-pills-tabContent">
                                <div className={`tab-pane fade ${visibleModalList==='1'&& 'show active'}`} id="v-pills-tabone" role="tabpanel" aria-labelledby="v-pills-tabone-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Review Notes", "A")}} ><a href="#">Review Notes</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Interview notes", "B")}} ><a href="#">Interview notes</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Current year Queries", "C")}} ><a href="#">Current year Queries</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Issues to be carried forward", "D")}}><a href="#">Issues to be carried forward</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Issues brought forward", "E")}}><a href="#">Issues brought forward</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("ATO Reports", "")}} ><a href="#">ATO Reports</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Client Source Documents", "")}}><a href="#">Client Source Documents</a></div>
                                </div>
                                
                                <div className={`tab-pane fade ${visibleModalList==='2'&& 'show active'}`} id="v-pills-tabtwo" role="tabpanel" aria-labelledby="v-pills-tabtwo-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Motor Vehicle", "D1")}} ><a href="#">Motor Vehicle</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Travel", "D2")}} ><a href="#">Travel</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Uniforms, Laundry etc", "D3")}} ><a href="#">Uniforms, Laundry etc</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Self Education", "D4")}} ><a href="#">Self Education</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Other Work Related", "D5")}} ><a href="#">Other Work Related</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Project Pool", "D6")}} ><a href="#">Project Pool</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Interest", "D7")}} ><a href="#">Interest</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Dividend", "D8")}} ><a href="#">Dividend</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Gifts or Donations", "D9")}} ><a href="#">Gifts or Donations</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Tax Affairs", "D10")}} ><a href="#">Tax Affairs</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Personal Superannuation Contributions", "D12")}} ><a href="#">Personal Superannuation Contributions</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Other Deductions", "D15")}} ><a href="#">Other Deductions</a></div>
                                    {/* <div className="chip"><a href="#" onClick={() => {}} >Other</a></div> */}
                                </div>
                    
                                <div className={`tab-pane fade ${visibleModalList==='3'&& 'show active'}`} id="v-pills-tabthree" role="tabpanel" aria-labelledby="v-pills-tabthree-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Reconciliation to ITR check", "F")}} ><a href="#">Reconciliation to ITR check</a></div>		
                                </div>
                                <div className={`tab-pane fade ${visibleModalList==='4'&& 'show active'}`} id="v-pills-tabfour" role="tabpanel" aria-labelledby="v-pills-tabfour-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Salary or Wages", "1")}} ><a href="#">Salary or Wages</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Allowances, Earnings, etc", "2")}} ><a href="#">Allowances, Earnings, etc</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Employer Lump Sum Payments", "3")}} ><a href="#">Employer Lump Sum Payments</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("ETPs", "4")}} ><a href="#">ETPs</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Government Allowances", "5")}} ><a href="#">Government Allowances</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Government Pensions", "6")}} ><a href="#">Government Pensions</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Other Pensions / Annuities", "7")}} ><a href="#">Other Pensions / Annuities</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Superannuation Lump Sum Payments", "8")}} ><a href="#">Superannuation Lump Sum Payments</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Attributed PSI", "9")}} ><a href="#">Attributed PSI</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Gross Interest", "10")}} ><a href="#">Gross Interest</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Dividends", "11")}} ><a href="#">Dividends</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Employee Share Schemes", "12")}} ><a href="#">Employee Share Schemes</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Partnerships and Trusts", "13")}} ><a href="#">Partnerships and Trusts</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("PSI", "14")}} ><a href="#">PSI</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Business Income", "15")}} ><a href="#">Business Income</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Capital Gains", "18")}} ><a href="#">Capital Gains</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Capital Gains", "18.1")}} ><a href="#">Capital Gains</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Foreign Source Income", "20")}} ><a href="#">Foreign Source Income</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Rent", "21")}} ><a href="#">Rent</a></div>
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Other Income", "24")}} ><a href="#">Other Income</a></div>
                                </div>
                
                                <div className={`tab-pane fade ${visibleModalList==='5'&& 'show active'}`} id="v-pills-tabfive" role="tabpanel" aria-labelledby="v-pills-tabfive-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Private Health & Medicare", "M")}}><a href="#">Private Health & Medicare</a></div>
                                </div>
                                <div className={`tab-pane fade ${visibleModalList==='6'&& 'show active'}`} id="v-pills-tabsix" role="tabpanel" aria-labelledby="v-pills-tabsix-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Spouse & Family Benefits", "S")}} ><a href="#">Spouse & Family Benefits</a></div>
                                </div>
                                <div className={`tab-pane fade ${visibleModalList==='7'&& 'show active'}`} id="v-pills-tabsaven" role="tabpanel" aria-labelledby="v-pills-tabsaven-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Tax Offsets", "T")}}><a href="#">Tax Offsets</a></div>
                                </div>
                                <div className={`tab-pane fade ${visibleModalList==='8'&& 'show active'}`} id="v-pills-tabeight" role="tabpanel" aria-labelledby="v-pills-tabeight-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Adjustments", "Z")}}><a href="#">Adjustments</a></div>
                                </div>
                                <div className={`tab-pane fade ${visibleModalList==='9'&& 'show active'}`} id="v-pills-tabnine" role="tabpanel" aria-labelledby="v-pills-tabnine-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Tax Rates", "")}}><a href="#">Tax Rates</a></div>
                                </div>
                                <div className={`tab-pane fade ${visibleModalList==='10'&& 'show active'}`} id="v-pills-tabten" role="tabpanel" aria-labelledby="v-pills-tabten-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Insurance", "")}}><a href="#">Insurance</a></div>
                                </div>
                                <div className={`tab-pane fade ${visibleModalList==='11'&& 'show active'}`} id="v-pills-taeleven" role="tabpanel" aria-labelledby="v-pills-tabeleven-tab">
                                    <div className="chip" onClick={() => {ModalOpenerFunc("Checks", "")}}><a href="#">Checks</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TableBox