import React, {useContext} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Typography} from '@mui/material';
// import './UICSS.css';
import CloseIcon from '@mui/icons-material/Close';
import { AuthContext } from '../store/auth-context';
import {useNavigate} from 'react-router-dom';

const ConfirmLogoutDialog = ({openLogoutDialog, setOpenLogoutDialog}) => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();

    const LogoutFunc = () => {
        authCtx.logout();
        setOpenLogoutDialog(false);

        navigate('/');
    };

    return (
        <Dialog open={openLogoutDialog} >
            <DialogTitle style={{paddingBottom: '10px',paddingLeft: '10px', fontSize: '24px', fontWeight: 'bold'}}>Logout Titan Partners?</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={() => {setOpenLogoutDialog(false)}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent style={{padding: 0}}>
                <Typography style={{padding: '10px', fontSize: '16px', fontWeight: '500'}}>Are you sure you want to logout?</Typography>
            </DialogContent>
            <DialogActions>
                <Button  variant="contained"  onClick={() => setOpenLogoutDialog(false)}>Cancel</Button>
                <Button  variant="contained" style={{backgroundColor: '#009ec1'}} onClick={() => LogoutFunc()} >Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmLogoutDialog;