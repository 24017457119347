import React, { useState, useEffect, useContext } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CancelIcon from '@mui/icons-material/Cancel';
import ITRTable from './DataTables/ITRTable';
import {SimpleClientTables} from '../../ClientTables/ClientTables';
import { IncomeITRData, DeductionITRData, LessOffsetITRData, PlusITRData, LessCreditITRData, TaxITRRates } from './ReconITRData';
import { CreateTaxEstimateAPI, GetTaxEstimateAPI, UpdateTaxEstimateAPI, GetTaxRateByTypeAPI, GetFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../../../../store/auth-context";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { evaluate } from 'mathjs';
import FormulaErrorSnackbar from '../../../../components/FormulaErrorSnackbar';



const ReconcilationITR = ({currentModal, currentPage, clientData, clientUpdateYear}) => {
  const authCtx = useContext(AuthContext);
  const [incomeData, setIncomeData] = useState(IncomeITRData);
  const [deductionData, setDeductionData] = useState(DeductionITRData);

  const [taxableIncome, setTaxableIncome] = useState({currentCost: '', previousCost: ''});
  const [taxPayable, setTaxPayable] = useState({currentCost: '', previousCost: ''});

  const [isPrivateHealthIns, setPrivateHealthIns] = useState(false);

  const [lessOffsetData, setLessOffsetData] = useState(LessOffsetITRData);
  const [plusData, setPlusData] = useState(PlusITRData);
  const [lessCreditData, setLessCreditData] = useState(LessCreditITRData);

  const [totalIncome, setTotalIncome] = useState({currentCost: '', previousCost: ''});
  const [totalDeduction, setTotalDeduciton] = useState({currentCost: '', previousCost: ''})
  const [totalOffset, setTotalOffset] = useState({currentCost: '', previousCost: ''});
  const [totalPlusVal, setTotalPlusVal] = useState({currentCost: '', previousCost: ''});
  const [totalLessCreditVal, setTotalLessCreditVal] = useState({currentCost: '', previousCost: ''});
  const [errorOpen, setErrorOpen] = useState(false);

  const [estimatedTax, setEstimatedTax] = useState({currentCost: '', previousCost: ''});
  const [perItr, setPerItr] = useState({currentCost: "", previousCost: ""});
  const [difference, setDifference] = useState({currentCost: "", previousCost: ""});

  const [taxRates, setTaxRates] = useState(TaxITRRates);

  const [formulas, setFormulas] = useState({
    taxableIncome: 'totalIncome - totalDeductions',
    taxPayable: 'baseTax + (taxableIncome - taxRateIncome) * (marginalRate/100)',
    medicareLevy: 'taxableIncome * 0.02',
    medicareLevySurcharge: 'taxableIncome * 0.01',
    estimatedTax: 'taxPayable - totalLessOffset + totalPlus - totalLessCredit',
    difference: 'estimatedTax - perITR',
  });

  const [clientTableDetails, setClientTableDetails] = useState({
    preparedDate: '',
    scheduleRef: currentPage,
    staffMember: 'No',
    managerReview: 'No',
    partnerReview: 'No',
  })

  const [loading, setLoading] = useState(false);
  const [isComparing, setComparing] = useState(false);
  const [isDataAlready, setDataAlready] = useState(false);
  const clientYear = clientUpdateYear.getFullYear();


  // Assigning Data..
  const fillaDataFunc = (data) => {
    const updateIncomeData = incomeData.map((firstObj) => {
      const matchingSecondObj = data.income.find((secondObj) => secondObj.id === firstObj.id);
      if (matchingSecondObj){
        return { ...firstObj, currentCost: Number(matchingSecondObj.currentCost).toFixed(2) };
      }
      return firstObj;
    });
    setIncomeData(updateIncomeData);

    const updateDeductionData = deductionData.map((firstObj) => {
      const matchingSecondObj = data.deduction.find((secondObj) => secondObj.id === firstObj.id);
      if(matchingSecondObj){
        return { ...firstObj, currentCost: Number(matchingSecondObj.currentCost).toFixed(2) }
      }
      return firstObj;
    });
    setDeductionData(updateDeductionData);

    const updateLessOffset = lessOffsetData.map((firstObj) => {
      const matchingSecondObj = data.lessOffsets.find((secondObj) => secondObj.name === firstObj.name);
      if(matchingSecondObj){
        return { ...firstObj, currentCost: matchingSecondObj.currentCost }
      }
      return firstObj;
    })
    setLessOffsetData(updateLessOffset);
    
    const updateLessCredits = lessCreditData.map((firstObj) => {
      const matchingSecondObj = data.lessCredits.find((secondObj) => secondObj.name === firstObj.name);
      if(matchingSecondObj){
        return { ...firstObj, currentCost: matchingSecondObj.currentCost }
      }
      return firstObj;
    })
    setLessCreditData(updateLessCredits);

    if(data.perItr !== null && data.perItr !== undefined){
      setPerItr({...perItr, ['currentCost']: data.perItr})
    }

    if(data.isPrivateHealth !== null && data.isPrivateHealth !== undefined){
      setPrivateHealthIns(data.isPrivateHealth);
    }
  }

  // Fetching Data..
  useEffect(() => {
    const GetTaxEstimateFunc = async() => {
      const authToken = await secureLocalStorage.getItem("token");
      const userToken = JSON.parse(authToken);

      setLoading(true);

      const getITR = await GetTaxEstimateAPI(clientData._id, clientYear, userToken.token);
      if(getITR.success === true){
        if(getITR.data === null || getITR.data === undefined){
          setDataAlready(false);
        }else {
          setDataAlready(true);
          fillaDataFunc(getITR.data);
          setClientTableDetails({preparedDate: getITR.data.preparedDate, scheduleRef: currentPage, staffMember: getITR.data.staffMember, managerReview: getITR.data.managerReview, partnerReview: getITR.data.partnerReview})
        }
      }

      const getTaxRate = await GetTaxRateByTypeAPI(clientYear, 'residentTaxRate', userToken.token);
      if(getTaxRate.success === true) {
        if(getTaxRate.doc !== null && getTaxRate.doc !== undefined){
          setTaxRates(getTaxRate.doc.data);
        }
      }

      const getFormulas = await GetFormulasAPI('taxEstimateRecon', clientYear, userToken.token);
      if(getFormulas.success === true){
        if(getFormulas.data !== null && getFormulas.data !== undefined){
          setFormulas(getFormulas.data.formulas)
        }
      }

      setLoading(false);
    }

    GetTaxEstimateFunc();


  }, [isDataAlready])


  // Calculation / Formulas Applied Here..
  useEffect(() => {
    const Calculate = () => {
      let currentIncome = 0;
      let currentDeduct = 0;

      let scope = {
        totalIncome: currentIncome,
        totalDeductions: currentDeduct,
        taxableIncome: 0,
        baseTax: 0,
        taxRateIncome: 0,
        marginalRate: 0,
        medicareLevy: 0,
        medicareLevySurcharge: 0,
        taxPayable: 0,
        totalLessOffset: 0,
        totalPlus: 0,
        totalLessCredit: 0,
        estimatedTax: 0,
        perITR: 0,
        difference: 0,
      }

      incomeData.forEach(item => {
        let currentCost = parseFloat(item.currentCost);
        currentIncome = !isNaN(currentCost)? currentIncome + currentCost : currentIncome;
      });
      setTotalIncome({...totalIncome, ['currentCost']: currentIncome})

      
      deductionData.forEach(item => {
        let currentCost = parseFloat(item.currentCost);
        currentDeduct = !isNaN(currentCost)? currentDeduct + currentCost : currentDeduct;
      })
      setTotalDeduciton({...totalDeduction, ['currentCost']: currentDeduct})
      scope.totalIncome = currentIncome;
      scope.totalDeductions = currentDeduct;
      
      try {
        let currentTaxableIncome = evaluate(formulas.taxableIncome, scope).toFixed(2)
        setTaxableIncome({...taxableIncome, ['currentCost']: currentTaxableIncome})
        scope.taxableIncome = Number(currentTaxableIncome).toFixed(2);
        
        let baseTax = 0;
        let taxRateIncome = 0;
        let marginalRate = 0;
  
        // For Tax Payable Section..
        let currentTaxPayable = 0;
  
        for(let i=taxRates.length-1; i>=0; i--){
          if(Number(currentTaxableIncome) >= taxRates[i]['taxableIncome']){
            baseTax = parseFloat(taxRates[i]['baseTax']);
            taxRateIncome = parseFloat(taxRates[i]['taxableIncome']);
            marginalRate = parseFloat(taxRates[i]['marginalRate']);
            break;
          }
        }
  
        scope.baseTax = baseTax;
        scope.taxRateIncome = taxRateIncome;
        scope.marginalRate = marginalRate;
  
  
        currentTaxPayable = evaluate(formulas.taxPayable, scope).toFixed(2);
        currentTaxPayable = currentTaxPayable>0? currentTaxPayable : Number('0').toFixed(2);
        setTaxPayable({...taxPayable, ['currentCost']: currentTaxPayable});
        scope.taxPayable = currentTaxPayable;
  
        
        // Estimated Tax Data Section..
        let currentTotalLessOffsets = 0;
        lessOffsetData.forEach(item => {
          let currentCost = parseFloat(item.currentCost);
          currentTotalLessOffsets = !isNaN(currentCost)? currentTotalLessOffsets + currentCost : currentTotalLessOffsets;
        });
        setTotalOffset({...totalOffset, ['currentCost']: Number(currentTotalLessOffsets).toFixed(2)});
        scope.totalLessOffset = currentTotalLessOffsets;
  
  
        // For Plus Data Section..
        let updatePlusData = plusData;
        updatePlusData[0].currentCost = evaluate(formulas.medicareLevy, scope).toFixed(2);
  
        updatePlusData[1].currentCost = (isPrivateHealthIns)? evaluate(formulas.medicareLevySurcharge, scope).toFixed(2) : Number('0').toFixed(2);
        setPlusData(updatePlusData);
        scope.medicareLevy = updatePlusData[0].currentCost;
        scope.medicareLevySurcharge = updatePlusData[1].currentCost;
  
        
        let currentTotalPlus = 0;
        plusData.forEach(item => {
          let currentCost = parseFloat(item.currentCost);
          currentTotalPlus = !isNaN(currentCost)? currentTotalPlus + currentCost : currentTotalPlus;
        });
        setTotalPlusVal({...totalPlusVal, ['currentCost']: Number(currentTotalPlus).toFixed(2)})
        scope.totalPlus = currentTotalPlus;
  
        
        let currentTotalLessCredits = 0;
        lessCreditData.forEach(item => {
          let currentCost = parseFloat(item.currentCost);
          
          currentTotalLessCredits = !isNaN(currentCost)? currentTotalLessCredits + currentCost : currentTotalLessCredits;
        });
        setTotalLessCreditVal({...totalLessCreditVal, ['currentCost']: Number(currentTotalLessCredits).toFixed(2)})
        scope.totalLessCredit = currentTotalLessCredits;
  
        
        let currentEstimatedTax = evaluate(formulas.estimatedTax, scope).toFixed(2);
        setEstimatedTax({...estimatedTax, ['currentCost']: currentEstimatedTax})
  
        scope.estimatedTax = currentEstimatedTax;
        scope.perITR = Number(perItr.currentCost).toFixed(2);
  
        let diff = evaluate(formulas.difference, scope).toFixed(2);
        setDifference({...difference, ['currentCost']: diff})
        scope.difference = diff;
        
      } catch (error) {
        console.log("My Error: ", error);
        setErrorOpen(true);
      }

    }

    Calculate();
  }, [incomeData, deductionData, isPrivateHealthIns, lessOffsetData, lessCreditData, perItr, taxRates, formulas])



  // Assigning Previous Year Data...
  const fillPrevDataFunc = (data) => {
    const updateIncomeData = incomeData.map((firstObj) => {
      const matchingSecondObj = data.income.find((secondObj) => secondObj.id === firstObj.id);
      if (matchingSecondObj){
        return { ...firstObj, previousCost: Number(matchingSecondObj.currentCost).toFixed(2) };
      }
      return firstObj;
    });
    setIncomeData(updateIncomeData);

    const updateDeductionData = deductionData.map((firstObj) => {
      const matchingSecondObj = data.deduction.find((secondObj) => secondObj.id === firstObj.id);
      if(matchingSecondObj){
        return { ...firstObj, previousCost: Number(matchingSecondObj.currentCost).toFixed(2) }
      }
      return firstObj;
    });
    setDeductionData(updateDeductionData);

    const updateLessOffset = lessOffsetData.map((firstObj) => {
      const matchingSecondObj = data.lessOffsets.find((secondObj) => secondObj.name === firstObj.name);
      if(matchingSecondObj){
        return { ...firstObj, previousCost: matchingSecondObj.currentCost }
      }
      return firstObj;
    })
    setLessOffsetData(updateLessOffset);

    const updateplus = plusData.map((firstObj) => {
      const matchingSecondObj = data.plus.find((secondObj) => secondObj.name === firstObj.name);
      if(matchingSecondObj){
        return { ...firstObj, previousCost: matchingSecondObj.currentCost }
      }
      return firstObj;
    })
    setPlusData(updateplus);

    const updateLessCredits = lessCreditData.map((firstObj) => {
      const matchingSecondObj = data.lessCredits.find((secondObj) => secondObj.name === firstObj.name);
      if(matchingSecondObj){
        return { ...firstObj, previousCost: matchingSecondObj.currentCost }
      }
      return firstObj;
    })
    setLessCreditData(updateLessCredits);

    if(data.taxableIncome !== null && data.taxableIncome !== undefined){
      setTaxableIncome({...taxableIncome, ['previousCost']: data.taxableIncome })
    }
    if(data.taxPayable !== null && data.taxPayable !== undefined){
      setTaxPayable({...taxPayable, ['previousCost']: data.taxPayable })
    }
    if(data.estimatedTax !== null && data.estimatedTax !== undefined){
      setEstimatedTax({...estimatedTax, ['previousCost']: data.estimatedTax})
    }
    if(data.perItr !== null && data.perItr !== undefined){
      setPerItr({...perItr, ['previousCost']: data.perItr})
    }
    if(data.difference !== null && data.difference !== undefined){
      setDifference({...difference, ['previousCost']: data.difference})
    }

  }

  // Fetching Previous Year Data for Comparison...
  const ComparePrevHandler = async() => {
    const userToken = JSON.parse(authCtx.token);
    setLoading(true);

    const getRes = await GetTaxEstimateAPI(clientData._id, clientYear-1, userToken.token);
    if(getRes.success === true){
      if(getRes.data !== null && getRes.data !== undefined){
        fillPrevDataFunc(getRes.data);
        setComparing(true);
      }else{
        toast.error(`There is no Data for Comparison`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }else{
      toast.error(`There is some error while feching data`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    setLoading(false);
  }


  // Submiting Data..
  const SubmitHandler = async() => {
    const userToken = JSON.parse(authCtx.token);
    setLoading(true);

    let currentLessOffsetData = lessOffsetData.map((item) => {
      const { previousCost, ...rest } = item;
      return rest;
    })

    let currentPlusData = plusData.map((item) => {
      const { previousCost,  ...rest } = item;
      return rest;
    })

    let currentLessCreditData = lessCreditData.map((item) => {
      const { previousCost, ...rest } = item;
      return rest;
    })

    let sendData = {
      clientId: clientData._id,
      year: clientYear,
      area: 'Reconciliation to ITR check',
      preparedDate: clientTableDetails?.preparedDate,
      scheduleRef: clientTableDetails?.scheduleRef,
      staffMember: clientTableDetails?.staffMember,
      managerReview: clientTableDetails?.managerReview,
      partnerReview: clientTableDetails?.partnerReview,
      lessOffsets: currentLessOffsetData,
      plus: currentPlusData,
      lessCredits: currentLessCreditData,
      lessOffsetTotal: totalOffset.currentCost,
      plusTotal: totalPlusVal.currentCost,
      lessCreditTotal: totalLessCreditVal.currentCost,
      taxableIncome: taxableIncome.currentCost,
      taxPayable: taxPayable.currentCost,
      isPrivateHealth: isPrivateHealthIns,
      estimatedTax: estimatedTax.currentCost,
      perItr: perItr.currentCost,
      difference: difference.currentCost,
    }

    const submitRes = isDataAlready?
    await UpdateTaxEstimateAPI(sendData, clientData._id, clientYear, userToken.token)
    :
    await CreateTaxEstimateAPI(sendData, userToken.token);

    if(submitRes.success === true){
      toast.success(`${submitRes.message}`, {
          position: toast.POSITION.TOP_CENTER,
      });
      setDataAlready(true);
    }else{
      toast.success(`${submitRes.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    setLoading(false);
  }

  return (
    <>
          
      <SimpleClientTables 
        currentModal={currentModal} 
        clientData={clientData} 
        area={'Area: '} 
        clientUpdateYear={clientUpdateYear} 
        clientTableDetails={clientTableDetails}
        setClientTableDetails={setClientTableDetails}
      />

      <ITRTable
        incomeData={incomeData}
        deductionData={deductionData}
        clientYear={clientYear}
        taxableIncome={taxableIncome}
        taxPayable={taxPayable}
        lessOffsetData={lessOffsetData}
        setLessOffsetData={(val) => setLessOffsetData(val)}
        plusData={plusData}
        isPrivateHealthIns={isPrivateHealthIns}
        setPrivateHealthIns={(val) => setPrivateHealthIns(val)}
        lessCreditData={lessCreditData}
        setLessCreditData={(val) => setLessCreditData(val)}
        estimatedTax={estimatedTax}
        perItr={perItr}
        setPerItr={(val) => setPerItr(val)}
        difference={difference}
        isComparing={isComparing}

      />

      <div style={{display: 'flex', marginLeft: '5px', paddingBottom: '80px'}} >
        {!isComparing?
          <button className="submit-button" onClick={() => {ComparePrevHandler()}} ><ReceiptLongIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Compare {clientYear-1}</button>
          :
          <button className="submit-button" onClick={() => {setComparing(false)}} ><CancelIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Cancel Compare</button>
        }
        <button className="submit-button" onClick={() => {SubmitHandler()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
      </div>


      <FormulaErrorSnackbar
        open={errorOpen}
        setOpen={(val) => setErrorOpen(val)}
        currentTable="Tax Estimate Formulas"
      />

      
      {(loading)&&
				<div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
       
    </>
  )
}

export default ReconcilationITR;