// Function Convertin colIndex to Perticular Cell..
const getColumnLetter = (colIndex) => {
    let letter = '';
    while (colIndex > 0) {
      const remainder = colIndex % 26;
      letter = String.fromCharCode((remainder === 0 ? 26 : remainder) + 64) + letter;
      colIndex = Math.floor((colIndex - 1) / 26);
    }
    return letter;
}

const LinkorTextFunc = (myText, myLink) => {
    let urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if(urlRegex.test(myLink)){
        return {
            text: myText,
            hyperlink: myLink,
        }
    }
    return myText;
}

const fontAssignerFunc = (myLink) => {
    let urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if(urlRegex.test(myLink)){
        return {
            name: 'Arial Black',
            family: 1,
            color: { argb: 'ff0b0080' },
            size: 9,
            bold: false 
        };
    }
    return {
        name: 'Arial Black',
        color: { argb: 'ff000000' },
        family: 1,
        size: 9,
        bold: false,
    }
}



export const TaxRatePage = (workbook, data) => {
    let taxRateSheet = workbook.getWorksheet('Tax Rates');
    taxRateSheet.getCell('A1').font = {color: { argb: 'ff000000' }, bold: false, size: 10}

    // Resident Tax Rate Table..
    const currentTaxData = (data.taxRates.length!==0)? data.taxRates : null;
    if(currentTaxData === undefined || currentTaxData === null){
        return;
    }

    let residentTaxData = currentTaxData.find((item) => item['inputs'].fixedName === 'Resident Tax Rates');
    if(residentTaxData!==undefined || residentTaxData!==null){
        taxRateSheet.getCell(`C3`).value = residentTaxData['inputs'].working==="Working"?
        `${residentTaxData['inputs'].title}`
        :
        `${residentTaxData['inputs'].title} **Defunct**`;

        
        taxRateSheet.getCell(`C4`).value = LinkorTextFunc(residentTaxData['inputs'].tableHeading1, residentTaxData['inputs'].heading1Link)
        taxRateSheet.getCell(`C4`).font = fontAssignerFunc(residentTaxData['inputs'].heading1Link)
        taxRateSheet.getCell(`D4`).value = LinkorTextFunc(residentTaxData['inputs'].tableHeading2, residentTaxData['inputs'].heading2Link)
        taxRateSheet.getCell(`D4`).font = fontAssignerFunc(residentTaxData['inputs'].heading2Link)
        taxRateSheet.getCell(`E4`).value = LinkorTextFunc(residentTaxData['inputs'].tableHeading3, residentTaxData['inputs'].heading3Link)
        taxRateSheet.getCell(`E4`).font = fontAssignerFunc(residentTaxData['inputs'].heading3Link)
        

        let rowStartingIndex = 5;
        for(let i=0; i<residentTaxData['data'].length; i++){
            taxRateSheet.getCell(`C${rowStartingIndex}`).value = Number(residentTaxData['data'][i]['taxableIncome']);
            taxRateSheet.getCell(`C${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`D${rowStartingIndex}`).value = Number(residentTaxData['data'][i]['baseTax']);
            taxRateSheet.getCell(`D${rowStartingIndex}`).numFmt = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`E${rowStartingIndex}`).value = Number(residentTaxData['data'][i]['marginalRate'])/100;
            taxRateSheet.getCell(`E${rowStartingIndex}`).numFmt = '0.00%';

            rowStartingIndex++;
        }


        rowStartingIndex = 6;
        let linkIndex = 7;
        for(let i=0; i<residentTaxData['link'].length; i++) {
            let linkLetter = getColumnLetter(linkIndex);

            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).value = LinkorTextFunc(residentTaxData['link'][i].linkTitle, residentTaxData['link'][i].link);
            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).font = fontAssignerFunc(residentTaxData['link'][i].link);

            linkIndex +=3;
        }
        rowStartingIndex++
        
        for(let i=0; i<residentTaxData['notes'].length; i++) {
            taxRateSheet.getCell(`G${rowStartingIndex}`).value = residentTaxData['notes'][i].note;

            rowStartingIndex++
        }
    }



    // Motor Vehicle Tax Rate Table..
    let motorVehicleData = currentTaxData.find((item) => item['inputs'].fixedName === 'Motor Vehicle Rates');
    if(motorVehicleData!==undefined || motorVehicleData!==null){
        taxRateSheet.getCell(`C12`).value = motorVehicleData['inputs'].working==="Working"?
        `${motorVehicleData['inputs'].title}`
        :
        `${motorVehicleData['inputs'].title} **Defunct**`;


        taxRateSheet.getCell(`C14`).value = LinkorTextFunc(motorVehicleData['inputs'].tableHeading1, motorVehicleData['inputs'].heading1Link)
        taxRateSheet.getCell(`C14`).font = fontAssignerFunc(motorVehicleData['inputs'].heading1Link)
        taxRateSheet.getCell(`C15`).value = LinkorTextFunc(motorVehicleData['inputs'].tableHeading2, motorVehicleData['inputs'].heading2Link)
        taxRateSheet.getCell(`C15`).font = fontAssignerFunc(motorVehicleData['inputs'].heading2Link)
        taxRateSheet.getCell(`C17`).value = LinkorTextFunc(motorVehicleData['inputs'].tableHeading3, motorVehicleData['inputs'].heading3Link)
        taxRateSheet.getCell(`C17`).font = fontAssignerFunc(motorVehicleData['inputs'].heading3Link)


        let rowStartingIndex = 14;
        for(let i=0; i<motorVehicleData['data'].length; i++){
            if(i==2){
                rowStartingIndex = 17;
            }

            taxRateSheet.getCell(`G${rowStartingIndex}`).value = motorVehicleData['data'][i]['year'];
            taxRateSheet.getCell(`H${rowStartingIndex}`).value = Number(motorVehicleData['data'][i]['price']);
            taxRateSheet.getCell(`H${rowStartingIndex}`).numFmt = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';

            rowStartingIndex++;
        }


        rowStartingIndex = 14;
        let linkIndex = 11;
        for(let i=0; i<motorVehicleData['link'].length; i++) {
            let linkLetter = getColumnLetter(linkIndex);

            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).value = LinkorTextFunc(motorVehicleData['link'][i].linkTitle, motorVehicleData['link'][i].link);
            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).font = fontAssignerFunc(motorVehicleData['link'][i].link);

            linkIndex +=3;
        }
        rowStartingIndex++
        
        for(let i=0; i<motorVehicleData['notes'].length; i++) {
            taxRateSheet.getCell(`K${rowStartingIndex}`).value = motorVehicleData['notes'][i].note;

            rowStartingIndex++
        }

    }



    // Low Income Tax Offset..
    let lowIncomeData = currentTaxData.find((item) => item['inputs'].fixedName === 'Low Income Rates');
    if(lowIncomeData!==undefined || lowIncomeData!==null) {
        taxRateSheet.getCell(`M22`).value = lowIncomeData['inputs'].working==="Working"?
        `${lowIncomeData['inputs'].title}`
        :
        `${lowIncomeData['inputs'].title} **Defunct**`;

        taxRateSheet.getCell(`M23`).value = LinkorTextFunc(lowIncomeData['inputs'].tableHeading1, lowIncomeData['inputs'].heading1Link)
        taxRateSheet.getCell(`M23`).font = fontAssignerFunc(lowIncomeData['inputs'].heading1Link);
        taxRateSheet.getCell(`N23`).value = LinkorTextFunc(lowIncomeData['inputs'].tableHeading2, lowIncomeData['inputs'].heading2Link)
        taxRateSheet.getCell(`N23`).font = fontAssignerFunc(lowIncomeData['inputs'].heading2Link);
        taxRateSheet.getCell(`P23`).value = LinkorTextFunc(lowIncomeData['inputs'].tableHeading3, lowIncomeData['inputs'].heading3Link)
        taxRateSheet.getCell(`P23`).font = fontAssignerFunc(lowIncomeData['inputs'].heading3Link);
        taxRateSheet.getCell(`Q23`).value = LinkorTextFunc(lowIncomeData['inputs'].tableHeading4, lowIncomeData['inputs'].heading4Link)
        taxRateSheet.getCell(`Q23`).font = fontAssignerFunc(lowIncomeData['inputs'].heading4Link);


        let rowStartingIndex = 24;
        for(let i=0; i<lowIncomeData['data'].length; i++){
            taxRateSheet.getCell(`M${rowStartingIndex}`).value = lowIncomeData['data'][i]['taxableIncomeName'];
            taxRateSheet.getCell(`N${rowStartingIndex}`).value = Number(lowIncomeData['data'][i]['taxableIncome_1']);
            taxRateSheet.getCell(`N${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`P${rowStartingIndex}`).value = Number(lowIncomeData['data'][i]['taxableIncome_2']);
            taxRateSheet.getCell(`P${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`Q${rowStartingIndex}`).value = lowIncomeData['data'][i]['marginalRate'];

            rowStartingIndex++;
        }


        rowStartingIndex = 24;
        let linkIndex = 19;
        for(let i=0; i<lowIncomeData['link'].length; i++) {
            let linkLetter = getColumnLetter(linkIndex);

            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).value = LinkorTextFunc(lowIncomeData['link'][i].linkTitle, lowIncomeData['link'][i].link);
            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).font = fontAssignerFunc(lowIncomeData['link'][i].link);

            linkIndex +=3;
        }
        rowStartingIndex++
        
        for(let i=0; i<lowIncomeData['notes'].length; i++) {
            taxRateSheet.getCell(`S${rowStartingIndex}`).value = lowIncomeData['notes'][i].note;

            rowStartingIndex++
        }
    }


    // Low and Middle Income Tax Offset..
    let lowMiddleData = currentTaxData.find((item) => item['inputs'].fixedName === 'Low & Middle Income Rates');
    if(lowMiddleData!==undefined || lowMiddleData!==null) {
        taxRateSheet.getCell(`C30`).value = lowMiddleData['inputs'].working==="Working"?
        `${lowMiddleData['inputs'].title}`
        :
        `${lowMiddleData['inputs'].title} **Defunct**`;


        taxRateSheet.getCell(`C31`).value = LinkorTextFunc(lowMiddleData['inputs'].tableHeading1, lowMiddleData['inputs'].heading1Link)
        taxRateSheet.getCell(`C31`).font = fontAssignerFunc(lowMiddleData['inputs'].heading1Link);
        taxRateSheet.getCell(`D31`).value = LinkorTextFunc(lowMiddleData['inputs'].tableHeading2, lowMiddleData['inputs'].heading2Link)
        taxRateSheet.getCell(`D31`).font = fontAssignerFunc(lowMiddleData['inputs'].heading2Link);
        taxRateSheet.getCell(`F31`).value = LinkorTextFunc(lowMiddleData['inputs'].tableHeading3, lowMiddleData['inputs'].heading3Link)
        taxRateSheet.getCell(`F31`).font = fontAssignerFunc(lowMiddleData['inputs'].heading3Link);
        taxRateSheet.getCell(`G31`).value = LinkorTextFunc(lowMiddleData['inputs'].tableHeading4, lowMiddleData['inputs'].heading4Link)
        taxRateSheet.getCell(`G31`).font = fontAssignerFunc(lowMiddleData['inputs'].heading4Link);


        let rowStartingIndex = 32;
        for(let i=0; i<lowMiddleData['data'].length; i++){
            taxRateSheet.getCell(`C${rowStartingIndex}`).value = lowMiddleData['data'][i]['taxableIncomeName'];
            taxRateSheet.getCell(`D${rowStartingIndex}`).value = Number(lowMiddleData['data'][i]['taxableIncome_1']);
            taxRateSheet.getCell(`D${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`F${rowStartingIndex}`).value = Number(lowMiddleData['data'][i]['taxableIncome_2']);
            taxRateSheet.getCell(`F${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`G${rowStartingIndex}`).value = lowMiddleData['data'][i]['marginalRate'];

            rowStartingIndex++;
        }


        rowStartingIndex = 32;
        let linkIndex = 10;
        for(let i=0; i<lowMiddleData['link'].length; i++) {
            let linkLetter = getColumnLetter(linkIndex);

            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).value = LinkorTextFunc(lowMiddleData['link'][i].linkTitle, lowMiddleData['link'][i].link);
            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).font = fontAssignerFunc(lowMiddleData['link'][i].link);

            linkIndex +=3;
        }
        rowStartingIndex++
        
        for(let i=0; i<lowMiddleData['notes'].length; i++) {
            taxRateSheet.getCell(`J${rowStartingIndex}`).value = lowMiddleData['notes'][i].note;

            rowStartingIndex++
        }
    }



    // Low and Middle Income Tax Offset..
    let nonResidentData = currentTaxData.find((item) => item['inputs'].fixedName === 'Non-Resident Rates');
    if(nonResidentData!==undefined || nonResidentData!==null) {
        taxRateSheet.getCell(`C39`).value = nonResidentData['inputs'].working==="Working"?
        `${nonResidentData['inputs'].title}`
        :
        `${nonResidentData['inputs'].title} **Defunct**`;


        taxRateSheet.getCell(`C40`).value = LinkorTextFunc(nonResidentData['inputs'].tableHeading1, nonResidentData['inputs'].heading1Link)
        taxRateSheet.getCell(`C40`).font = fontAssignerFunc(nonResidentData['inputs'].heading1Link);
        taxRateSheet.getCell(`D40`).value = LinkorTextFunc(nonResidentData['inputs'].tableHeading2, nonResidentData['inputs'].heading2Link)
        taxRateSheet.getCell(`D40`).font = fontAssignerFunc(nonResidentData['inputs'].heading2Link);
        taxRateSheet.getCell(`E40`).value = LinkorTextFunc(nonResidentData['inputs'].tableHeading3, nonResidentData['inputs'].heading3Link)
        taxRateSheet.getCell(`E40`).font = fontAssignerFunc(nonResidentData['inputs'].heading3Link);
        taxRateSheet.getCell(`F40`).value = LinkorTextFunc(nonResidentData['inputs'].tableHeading4, nonResidentData['inputs'].heading4Link)
        taxRateSheet.getCell(`F40`).font = fontAssignerFunc(nonResidentData['inputs'].heading4Link);
        taxRateSheet.getCell(`G40`).value = LinkorTextFunc(nonResidentData['inputs'].tableHeading5, nonResidentData['inputs'].heading5Link)
        taxRateSheet.getCell(`G40`).font = fontAssignerFunc(nonResidentData['inputs'].heading5Link);


        let rowStartingIndex = 41;
        for(let i=0; i<nonResidentData['data'].length; i++){
            taxRateSheet.getCell(`C${rowStartingIndex}`).value = nonResidentData['data'][i]['taxableIncomeName'];
            taxRateSheet.getCell(`D${rowStartingIndex}`).value = Number(nonResidentData['data'][i]['taxableIncome_1']);
            taxRateSheet.getCell(`D${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`E${rowStartingIndex}`).value = Number(nonResidentData['data'][i]['taxableIncome_2']);
            taxRateSheet.getCell(`E${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`F${rowStartingIndex}`).value = Number(nonResidentData['data'][i]['baseTax']);
            taxRateSheet.getCell(`F${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`G${rowStartingIndex}`).value = Number(nonResidentData['data'][i]['marginalRate'])/100;
            taxRateSheet.getCell(`G${rowStartingIndex}`).numFmt = '0.00%';

            rowStartingIndex++;
        }


        rowStartingIndex = 41;
        let linkIndex = 10;
        for(let i=0; i<nonResidentData['link'].length; i++) {
            let linkLetter = getColumnLetter(linkIndex);

            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).value = LinkorTextFunc(nonResidentData['link'][i].linkTitle, nonResidentData['link'][i].link);
            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).font = fontAssignerFunc(nonResidentData['link'][i].link);

            linkIndex +=3;
        }
        rowStartingIndex++
        
        for(let i=0; i<nonResidentData['notes'].length; i++) {
            taxRateSheet.getCell(`J${rowStartingIndex}`).value = nonResidentData['notes'][i].note;

            rowStartingIndex++
        }
    }



    // Low and Middle Income Tax Offset..
    let workingHolidayData = currentTaxData.find((item) => item['inputs'].fixedName === 'Working Holiday Rates');
    if(workingHolidayData!==undefined || workingHolidayData!==null) {
        taxRateSheet.getCell(`C48`).value = workingHolidayData['inputs'].working==="Working"?
        `${workingHolidayData['inputs'].title}`
        :
        `${workingHolidayData['inputs'].title} **Defunct**`;

        taxRateSheet.getCell(`C49`).value = LinkorTextFunc(workingHolidayData['inputs'].tableHeading1, workingHolidayData['inputs'].heading1Link)
        taxRateSheet.getCell(`C49`).font = fontAssignerFunc(workingHolidayData['inputs'].heading1Link);
        taxRateSheet.getCell(`D49`).value = LinkorTextFunc(workingHolidayData['inputs'].tableHeading2, workingHolidayData['inputs'].heading2Link)
        taxRateSheet.getCell(`D49`).font = fontAssignerFunc(workingHolidayData['inputs'].heading2Link);
        taxRateSheet.getCell(`E49`).value = LinkorTextFunc(workingHolidayData['inputs'].tableHeading3, workingHolidayData['inputs'].heading3Link)
        taxRateSheet.getCell(`E49`).font = fontAssignerFunc(workingHolidayData['inputs'].heading3Link);
        taxRateSheet.getCell(`F49`).value = LinkorTextFunc(workingHolidayData['inputs'].tableHeading4, workingHolidayData['inputs'].heading4Link)
        taxRateSheet.getCell(`F49`).font = fontAssignerFunc(workingHolidayData['inputs'].heading4Link);
        taxRateSheet.getCell(`G49`).value = LinkorTextFunc(workingHolidayData['inputs'].tableHeading5, workingHolidayData['inputs'].heading5Link)
        taxRateSheet.getCell(`G49`).font = fontAssignerFunc(workingHolidayData['inputs'].heading5Link);


        let rowStartingIndex = 50;
        for(let i=0; i<nonResidentData['data'].length; i++){
            taxRateSheet.getCell(`C${rowStartingIndex}`).value = workingHolidayData['data'][i]['taxableIncomeName'];
            taxRateSheet.getCell(`D${rowStartingIndex}`).value = Number(workingHolidayData['data'][i]['taxableIncome_1']);
            taxRateSheet.getCell(`D${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`E${rowStartingIndex}`).value = Number(workingHolidayData['data'][i]['taxableIncome_2']);
            taxRateSheet.getCell(`E${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`F${rowStartingIndex}`).value = Number(workingHolidayData['data'][i]['baseTax']);
            taxRateSheet.getCell(`F${rowStartingIndex}`).numFmt  = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
            taxRateSheet.getCell(`G${rowStartingIndex}`).value = Number(workingHolidayData['data'][i]['marginalRate'])/100;
            taxRateSheet.getCell(`G${rowStartingIndex}`).numFmt = '0.00%';

            rowStartingIndex++;
        }


        rowStartingIndex = 50;
        let linkIndex = 10;
        for(let i=0; i<workingHolidayData['link'].length; i++) {
            let linkLetter = getColumnLetter(linkIndex);

            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).value = LinkorTextFunc(workingHolidayData['link'][i].linkTitle, workingHolidayData['link'][i].link);
            taxRateSheet.getCell(`${linkLetter}${rowStartingIndex}`).font = fontAssignerFunc(workingHolidayData['link'][i].link);

            linkIndex +=3;
        }
        rowStartingIndex++
        
        for(let i=0; i<workingHolidayData['notes'].length; i++) {
            taxRateSheet.getCell(`J${rowStartingIndex}`).value = workingHolidayData['notes'][i].note;

            rowStartingIndex++
        }
    }
}


