import React, {useContext, useState} from 'react';
import { Box, Button, TextField, IconButton, Typography } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { Modal  } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import ReactLoading from "react-loading";
import CloseIcon from '@mui/icons-material/Close';
import { UpdatePasswordByIdAPI } from '../util/http';
import { AuthContext } from '../store/auth-context';
import secureLocalStorage from 'react-secure-storage';

const PasswordUpdateModal = ({openPasswordModal, setOpenPasswordModal, clientData, updateMessage}) => {
    const authCtx = useContext(AuthContext);
    const role = secureLocalStorage.getItem("role");
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [loading, setLoading] = useState(false);

    const initialValues = {
        name: clientData?.name,
        email: clientData?.email,
        password: '',
        confirmPassword: '',
    };

    const checkoutSchema = yup.object().shape({
        name:yup.string().required("Required"),
        email:yup.string().email("Invalid email!").required("Required"),
        password:yup.string().min(5, 'Password must be at least 5 characters long').required("Required"),
        confirmPassword:yup.string().oneOf([yup.ref('password'), null], 'Must match with Password').required("Required"),
    });

    const handleFormSubmit = async(values) => {
        if( (values.password.length<5)  || (values.password !==values.confirmPassword)){
            return;
        }

        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            password: values.password,
        };

        const submitRes = await UpdatePasswordByIdAPI(sendData, clientData._id, userToken.token);
        if(submitRes.success===true){
            toast.success(`${submitRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }else{
            toast.error(`${submitRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
        authCtx.updateTrigger();
        setOpenPasswordModal(false);
    }


    return (
        <Modal size="lg" open={openPasswordModal} >
            <Box  mb="10px">
                <Typography variant="h1" color={'#009ec1'} textAlign="center" >{updateMessage}</Typography>
            </Box>

            <IconButton 
                aria-label="close" 
                size="small" 
                style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                onClick={() => {setOpenPasswordModal(false)}}
            >
                <CloseIcon fontSize="inherit" />
            </IconButton>

            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        
                        <Box
                            display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Name"
                                InputProps={{
                                    readOnly: true,
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                InputProps={{
                                    readOnly: true,
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Confirm Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.confirmPassword}
                                name="confirmPassword"
                                error={!!touched.confirmPassword && !!errors.confirmPassword }
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>
                        
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" variant="contained" style={{color: '#fff', backgroundColor: '#009ec1'}} >Update Password</Button>
                        </Box>


                    </form>
                )}
            </Formik>
            
            {/* Loading Screen */}
			{(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}

        </Modal>
    );
}

export default PasswordUpdateModal;