export const IncomeITRData = [
    {id: "1", name: "Salary or Wages", currentCost: "", previousCost: ""},
    {id: "2", name: "Allowances, Earnigns, etc", currentCost: "", previousCost: ""},
    {id: "3", name: "Employer Lump Sum Payments", currentCost: "", previousCost: ""},
    {id: "4", name: "ETPs", currentCost: "", previousCost: ""},
    {id: "5", name: "Government Allowances", currentCost: "", previousCost: ""},
    {id: "6", name: "Government Pensions", currentCost: "", previousCost: ""},
    {id: "7", name: "Other Pensions / Annuities", currentCost: "", previousCost: ""},
    {id: "8", name: "Superannuation Lump Sum Payments", currentCost: "", previousCost: ""},
    {id: "9", name: "Attributed Personal Services income", currentCost: "", previousCost: ""},
    {id: "10", name: "Gross Interest", currentCost: "", previousCost: ""},
    {id: "11", name: "Dividends", currentCost: "", previousCost: ""},
    {id: "12", name: "Employee Share Schemes", currentCost: "", previousCost: ""},
    {id: "13", name: "Partnerships and Trusts", currentCost: "", previousCost: ""},
    {id: "14", name: "PSI", currentCost: "", previousCost: ""},
    {id: "15", name: "Business Income", currentCost: "", previousCost: ""},
    {id: "18", name: "Capital Gains", currentCost: "", previousCost: ""},
    {id: "20", name: "Foreign Source Income", currentCost: "", previousCost: ""},
    {id: "21", name: "Rent", currentCost: "", previousCost: ""},
    {id: "24", name: "Other Income", currentCost: "", previousCost: ""},
    // {id: "25", name: "Other", currentCost: "", previousCost: ""},
]

export const DeductionITRData = [
    {id: "D1", name: "Motor Vehicle", currentCost: "", previousCost: ""},
    {id: "D2", name: "Travel", currentCost: "", previousCost: ""},
    {id: "D3", name: "Uniforms, Laundry, etc", currentCost: "", previousCost: ""},
    {id: "D4", name: "Self Education", currentCost: "", previousCost: ""},
    {id: "D5", name: "Other Work Related", currentCost: "", previousCost: ""},
    {id: "D6", name: "Project Pool", currentCost: "", previousCost: ""},
    {id: "D7", name: "Interest Deduction", currentCost: "", previousCost: ""},
    {id: "D8", name: "Dividend Deduction", currentCost: "", previousCost: ""},
    {id: "D9", name: "Gifts or Donations", currentCost: "", previousCost: ""},
    {id: "D10", name: "Tax Affairs", currentCost: "", previousCost: ""},
    {id: "D12", name: "Personal Superannuation Contributions", currentCost: "", previousCost: ""},
    {id: "D15", name: "Other Deductions", currentCost: "", previousCost: ""},
    // {id: "D16", name: "Other", currentCost: "", previousCost: ""},
]

export const LessOffsetITRData = [
    {id: "1", name: "Offsets (T1 to T15 except T2, T3, T7, T13, T14)",isDefunct: false, currentCost: "", previousCost: ""},
    {id: "2", name: "Seniors / Pension / Beneficiary Offset",isDefunct: false, currentCost: "", previousCost: ""},
    {id: "3", name: "Mature Aged Workers Tax Offset",isDefunct: false, currentCost: "", previousCost: ""},
    {id: "4", name: "Low Income Offset",isDefunct: false, currentCost: "", previousCost: ""},
    {id: "5", name: "Low and middle Income Offset",isDefunct: true, currentCost: "", previousCost: ""},
    {id: "6", name: "Lump Sum",isDefunct: false, currentCost: "", previousCost: ""},
    {id: "7", name: "Life Assurance Bonus",isDefunct: false, currentCost: "", previousCost: ""},
    {id: "8", name: "Other Offsets",isDefunct: false, currentCost: "", previousCost: ""},
]

export const PlusITRData = [
    {id: "1", name: "Medicare Levy", currentCost: "", previousCost: ""},
    {id: "2", name: "Medicare Levy Surcharge", currentCost: "", previousCost: ""},
    {id: "3", name: "Budget Deficit Levy (2015 - 2017)", currentCost: "0.00", previousCost: "0.00"},
]

export const LessCreditITRData = [
    {id: "1", name: "Tax withheld - salary & wage type income", currentCost: "", previousCost: ""},
    {id: "2", name: "Arrears tax withheld", currentCost: "", previousCost: ""},
    {id: "3", name: "Foreign Tax Credits", currentCost: "", previousCost: ""},
    {id: "4", name: "TFN Amounts (credits)", currentCost: "", previousCost: ""},
    {id: "5", name: "Franking Tax Offset (refundalble)", currentCost: "", previousCost: ""},
    {id: "6", name: "Other Refundable Credits", currentCost: "", previousCost: ""},
    {id: "7", name: "Other Amounts withheld - ABN, Vol, Labour, Foreign", currentCost: "", previousCost: ""},
    {id: "8", name: "Baby Bonus Offset (refundable)", currentCost: "", previousCost: ""},
    {id: "9", name: "PAYG Income Tax Instalments", currentCost: "", previousCost: ""},
]

export const TaxITRRates = [
    {taxableIncome: '0.00', baseTax: '0.00',marginalRate: '0.0'},
    {taxableIncome: '18200.00', baseTax: '0.00',marginalRate: '19.0'},
    {taxableIncome: '45000.00', baseTax: '5092.00',marginalRate: '32.5'}, 
    {taxableIncome: '120000.00', baseTax: '29467.00',marginalRate: '37.0'},
    {taxableIncome: '180000.00', baseTax: '51667.00',marginalRate: '45.0'}
]