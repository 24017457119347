import React, {useState, useEffect, useContext} from 'react';

import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { evaluate } from 'mathjs';

import {SimpleClientTables} from '../../ClientTables/ClientTables';
import {DividendTable} from './DataTables/IncomeDataTables';

import { CreateDividendsAPI, GetDividendsByIdAPI, UpdateDividendByIdAPI, GetFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../../../../store/auth-context";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

import LinkAttachFile from '../../../../components/LinkAttachFile';
import AttachFile from '../../../../components/AttachFile';
import FormulaErrorSnackbar from '../../../../components/FormulaErrorSnackbar';


const Dividends = ({currentModal, currentPage, clientData, clientUpdateYear, setDocsChange}) => {
    const authCtx = useContext(AuthContext);
    const [dividendArray, setDividendArray] = useState([
        [
            {company: '', sharesHeld: '', divPerShare: '', Franking: '100', totalDividend: '', totalFrankingCr: '', ownership: '100'},
        ],
        [
            {owner_frankedDiv: '', owner_unFrankedDiv: '', owner_frankingCr: ''},
        ],
        // [
        //     {partner_frankedDiv: '', partner_unFrankedDiv: '', partner_frankingCr: ''},
        // ],
    ]);
    const [totals, setTotals] = useState(
        {totalDividend: 0, totalFrankingCr: 0, owner_frankedDiv: 0, owner_unFrankedDiv: 0, owner_frankingCr: 0, partner_frankedDiv: 0, partner_unFrankedDiv: 0, partner_frankingCr: 0},
    );
    const [ownerTotal, setOwnerTotal] = useState(0);
    const [clientTableDetails, setClientTableDetails] = useState({
        preparedDate: '',
        scheduleRef: currentPage,
        staffMember: 'No',
        managerReview: 'No',
        partnerReview: 'No',
    })
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);
    
    const [linkAttachable, setLinkAttachable] = useState(false);
    const [links, setLinks] = useState([]);
    const [errorOpen, setErrorOpen] = useState(false);
    
    // Using at AttachFile.js
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [partnerName, setPartneraName] = useState("Partner");
    const [isInputPartnerName, setInputPartnerName] = useState(false);
    const clientYear = clientUpdateYear.getFullYear();
    const [formulas, setFormulas] = useState({
        totalDividend: 'sharesHeld*divPerShare', 
        totalFrankingCr: 'sharesHeld*divPerShare*(franking/100)*0.3/0.7', 
        owner_frankedDiv: 'totalDividend*(franking/100)*(ownership/100)', 
        owner_unFrankedDiv: 'totalDividend*(ownership/100)*(1-franking/100)', 
        owner_frankingCr: 'owner_frankedDiv*(0.3/0.7)', 
        partner_frankedDiv: 'totalDividend*(franking/100)*(1-ownership/100)', 
        partner_unFrankedDiv: 'totalDividend*(1-franking/100)*(1-ownership/100)', 
        partner_frankingCr: 'partner_frankedDiv*(0.3/0.7)'
    });

    
    useEffect(() => {
        const GetDividendsData = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);
            setLoading(true);

            const getDataRes = await GetDividendsByIdAPI(clientData._id, clientYear, userToken.token);
            if(getDataRes.success===true){
                if(getDataRes.data === null || getDataRes.data === undefined){
                    setDataAlready(false);
                }else{
                    setDataAlready(true);
                    setDividendArray(getDataRes.data.data);
                    setPartneraName((getDataRes.data.partnerName!==undefined || getDataRes.data.partnerName!==null) ?  getDataRes.data.partnerName : 'Partner')
                    setClientTableDetails({preparedDate: getDataRes.data.preparedDate, scheduleRef: getDataRes.data.scheduleRef, staffMember: getDataRes.data.staffMember, managerReview: getDataRes.data.managerReview, partnerReview: getDataRes.data.partnerReview});
                    setSelectedFiles(getDataRes.data.attachment);
                    setLinks(getDataRes.data.link);
                }
            }

            const getFormulas = await GetFormulasAPI('dividends', clientYear, userToken.token);
            if(getFormulas.success === true){
                if(getFormulas.data !== null && getFormulas.data !== undefined){
                    setFormulas(getFormulas.data.formulas)
                }
            }

            setLoading(false);
        }

        GetDividendsData();
    }, [isDataAlready])


    useEffect(() => {
        let total__dividend = 0;
        let total__franking__cr = 0;
        let total__owner__frankedDiv = 0;
        let total__owner__unFrankedDiv = 0;
        let total__owner__frankingCr = 0;
        let total__partner__frankedDiv = 0;
        let total__partner__unFrankedDiv = 0;
        let total__partner__frankingCr = 0;

        for(let i=0; i<dividendArray[0].length; i++){
            let dividend = parseFloat(dividendArray[0][i]['totalDividend']);
            let frankingcr = parseFloat(dividendArray[0][i]['totalFrankingCr']);
            let ownerFrankedDiv = parseFloat(dividendArray[1][i]['owner_frankedDiv']);
            let ownerUnFrankedDiv = parseFloat(dividendArray[1][i]['owner_unFrankedDiv']);
            let ownerFrankingCr = parseFloat(dividendArray[1][i]['owner_frankingCr']);
            let partnerFrankedDiv = dividendArray[2]!==undefined? parseFloat(dividendArray[2][i]['partner_frankedDiv']) : 0;
            let partnerUnFrankedDiv = dividendArray[2]!==undefined? parseFloat(dividendArray[2][i]['partner_unFrankedDiv']) : 0;
            let partnerFrankingCr = dividendArray[2]!==undefined? parseFloat(dividendArray[2][i]['partner_frankingCr']) : 0;


            total__dividend = !isNaN(dividend)? total__dividend + dividend : total__dividend;
            total__franking__cr = !isNaN(frankingcr)? total__franking__cr + frankingcr : total__franking__cr;
            total__owner__frankedDiv = !isNaN(ownerFrankedDiv)? total__owner__frankedDiv + ownerFrankedDiv : total__owner__frankedDiv;
            total__owner__unFrankedDiv = !isNaN(ownerUnFrankedDiv)? total__owner__unFrankedDiv + ownerUnFrankedDiv : total__owner__unFrankedDiv;
            total__owner__frankingCr = !isNaN(ownerFrankingCr)? total__owner__frankingCr + ownerFrankingCr : total__owner__frankingCr;
            total__partner__frankedDiv = !isNaN(partnerFrankedDiv)? total__partner__frankedDiv + partnerFrankedDiv : total__partner__frankedDiv;
            total__partner__unFrankedDiv = !isNaN(partnerUnFrankedDiv)? total__partner__unFrankedDiv + partnerUnFrankedDiv : total__partner__unFrankedDiv;
            total__partner__frankingCr = !isNaN(partnerFrankingCr)? total__partner__frankingCr + partnerFrankingCr : total__partner__frankingCr;

        }

        setOwnerTotal(Number(total__owner__frankedDiv + total__owner__unFrankedDiv + total__owner__frankingCr).toFixed(2))

        setTotals(prevObj => ({
            ...prevObj, totalDividend: total__dividend, totalFrankingCr: total__franking__cr, owner_frankedDiv: total__owner__frankedDiv, owner_unFrankedDiv: total__owner__unFrankedDiv, owner_frankingCr: total__owner__frankingCr, partner_frankedDiv: total__partner__frankedDiv, partner_unFrankedDiv: total__partner__unFrankedDiv, partner_frankingCr: total__partner__frankingCr
        }))

    }, [dividendArray]);
    

    const EvaluateFunc = () => {
        const updatedData = [...dividendArray];

        for(let i=0; i<updatedData[0].length; i++){
            let scope = {
                sharesHeld: updatedData[0][i]['sharesHeld'],
                divPerShare: updatedData[0][i]['divPerShare'],
                franking: updatedData[0][i]['Franking'],
                totalDividend: updatedData[0][i]['totalDividend'],
                ownership: updatedData[0][i]['ownership'],
                owner_frankedDiv: updatedData[1][i]['owner_frankedDiv'],
                owner_unFrankedDiv: updatedData[1][i]['owner_unFrankedDiv'],
                owner_frankingCr: updatedData[1][i]['owner_frankingCr'],
                partner_frankedDiv: updatedData[2]!==undefined? updatedData[2][i]['partner_frankedDiv'] : 0,
                partner_unFrankedDiv: updatedData[2]!==undefined? updatedData[2][i]['partner_unFrankedDiv'] : 0,
                partner_frankingCr: updatedData[2]!==undefined? updatedData[2][i]['partner_frankingCr'] : 0,
            }


            try {
                updatedData[0][i]['totalDividend'] = evaluate(formulas.totalDividend, scope).toFixed(2);
                scope.totalDividend = updatedData[0][i]['totalDividend'];
                updatedData[0][i]['totalFrankingCr'] = evaluate(formulas.totalFrankingCr, scope).toFixed(2);
                scope.totalFrankingCr = updatedData[0][i]['totalFrankingCr'];
                updatedData[1][i]['owner_frankedDiv'] = evaluate(formulas.owner_frankedDiv, scope).toFixed(2);
                scope.owner_frankedDiv = updatedData[1][i]['owner_frankedDiv'];
                updatedData[1][i]['owner_unFrankedDiv'] = evaluate(formulas.owner_unFrankedDiv, scope).toFixed(2);
                scope.owner_unFrankedDiv = updatedData[1][i]['owner_unFrankedDiv'];
                updatedData[1][i]['owner_frankingCr'] = evaluate(formulas.owner_frankingCr, scope).toFixed(2);
                scope.owner_frankingCr = updatedData[1][i]['owner_frankingCr'];
                if(updatedData[2] !== undefined){
                    updatedData[2][i]['partner_frankedDiv'] = evaluate(formulas.partner_frankedDiv, scope).toFixed(2);
                    scope.partner_frankedDiv = updatedData[2][i]['partner_frankedDiv'];
                    updatedData[2][i]['partner_unFrankedDiv'] = evaluate(formulas.partner_unFrankedDiv, scope).toFixed(2);
                    scope.partner_unFrankedDiv = updatedData[2][i]['partner_unFrankedDiv'];
                    updatedData[2][i]['partner_frankingCr'] = evaluate(formulas.partner_frankingCr, scope).toFixed(2);
                    scope.partner_frankingCr = updatedData[2][i]['partner_frankingCr'];
                }
            } catch (error) {
                setErrorOpen(true);
            }            
        }

        setDividendArray(updatedData);
    }


    const handleInputChange = (outerIndex, innerIndex, objectKey, e) => {
        const newValue = e.target.value;

        const newData = [...dividendArray];
        newData[outerIndex][innerIndex][objectKey] = newValue;
        setDividendArray(newData);

        EvaluateFunc();
    }



    const AddCellFunc = () => {

        const array_1_append = {company: '', sharesHeld: '', divPerShare: '', Franking: '100', totalDividend: '', totalFrankingCr: '', ownership: '100'};
        const array_2_append = {owner_frankedDiv: '', owner_unFrankedDiv: '', owner_frankingCr: ''};
        const array_3_append = {partner_frankedDiv: '', partner_unFrankedDiv: '', partner_frankingCr: ''};
        let newData = [...dividendArray];
        newData[0].push(array_1_append);
        newData[1].push(array_2_append);
        if(dividendArray[2]!==undefined){
            newData[2].push(array_3_append);
        }
        setDividendArray(newData);
    };


    const AddPartnerFunc = () => {
        if(partnerName.length===0){
            return;
        }

        let newData = [...dividendArray];
        let partner_Array = [];

        for(let i=0; i<newData[0].length; i++){
            partner_Array.push({partner_frankedDiv: '', partner_unFrankedDiv: '', partner_frankingCr: ''});
        }

        newData.push(partner_Array);

        for(let i=0; i<newData[2].length; i++){
            let scope = {
                totalDividend: newData[0][i]['totalDividend'],
                franking: newData[0][i]['Franking'],
                ownership: newData[0][i]['ownership'],
                partner_frankedDiv: newData[2][i]['partner_frankedDiv'],
                partner_unFrankedDiv: newData[2][i]['partner_unFrankedDiv'],
                partner_frankingCr: newData[2][i]['partner_frankingCr'],
            }
            
            try{
                newData[2][i]['partner_frankedDiv'] = evaluate(formulas.partner_frankedDiv, scope).toFixed(2);
                scope.partner_frankedDiv = newData[2][i]['partner_frankedDiv'];
                newData[2][i]['partner_unFrankedDiv'] = evaluate(formulas.partner_unFrankedDiv, scope).toFixed(2);
                scope.partner_unFrankedDiv = newData[2][i]['partner_unFrankedDiv'];
                newData[2][i]['partner_frankingCr'] = evaluate(formulas.partner_frankingCr, scope).toFixed(2);
                scope.partner_frankingCr = newData[2][i]['partner_frankingCr'];
            }catch (error){
                setErrorOpen(true);
            }
        }

        setDividendArray(newData);        
        setInputPartnerName(false);
    }


    const RemoveCellFunc = () => {
        let newData = [...dividendArray];
        newData[0].pop();
        newData[1].pop();
        if(newData.length===3){
            newData[2].pop();
        }
        setDividendArray(newData);
    }


    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            clientId: clientData._id,
            year: clientYear,
            leadSchedule : currentModal,
            preparedDate: clientTableDetails?.preparedDate,
            scheduleRef: clientTableDetails?.scheduleRef,
            staffMember: clientTableDetails?.staffMember,
            managerReview: clientTableDetails?.managerReview,
            partnerReview: clientTableDetails?.partnerReview,
            data: dividendArray,
            total: totals,
            ownerTotal: ownerTotal,
            partnerName: partnerName,
            attachment: selectedFiles,
            link: links,
        };

        const submitRes = isDataAlready?
        await UpdateDividendByIdAPI(sendData, clientData._id, clientYear, userToken.token)
        :
        await CreateDividendsAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
            setDocsChange(false);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        
        setLoading(false);
    }


    return (
        <>
            <SimpleClientTables 
                currentModal={currentModal} 
                clientData={clientData} 
                area={'Lead Schedule: '} 
                clientUpdateYear={clientUpdateYear} 
                clientTableDetails={clientTableDetails}
                setClientTableDetails={setClientTableDetails}
            />

            <DividendTable 
                dividendArray={dividendArray}
                handleInputChange={handleInputChange}
                totals={totals}
                clientData={clientData}
                partnerName={partnerName}
            />

            <AttachFile 
                selectedFiles={selectedFiles} 
                setSelectedFiles={setSelectedFiles}
                setDocsChange={setDocsChange} 
                setLoading={setLoading}
            />

            <LinkAttachFile 
                links={links} 
                setLinks={setLinks} 
                linkAttachable={linkAttachable} 
                setLinkAttachable={setLinkAttachable} 
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />

            {(isInputPartnerName) &&
                <div className="input-button-contariner-parent">
                    <div className="link-option-container">
                        <span>Name</span>
                        <input type="text" name="name-add" id="name-add" placeholder="Enter Partner Name.." className='input-link'
                            value={partnerName} onChange={(e) => {setPartneraName(e.target.value);}}
                            style={(partnerName.length===0) ? {border: '1px solid #e01f3c'}:{border: '1px solid #000'}}
                        />
                    </div>
                    <IconButton size="small" className="link-submit" onClick={() => {AddPartnerFunc()}}>
                        <CheckIcon style={{fontSize: '30px', color: '#fff'}}  />
                    </IconButton>
                    <IconButton size="small" className="link-submit" onClick={() => {setInputPartnerName(!isInputPartnerName)}}>
                        <CloseIcon style={{fontSize: '30px', color: '#fff'}}  />
                    </IconButton>
                </div>
            }
            
            <div style={{display: 'flex', marginLeft: '5px', paddingBottom: '80px'}} >
                <button className="submit-button" onClick={() => {AddCellFunc()}} ><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Cell</button>
                {dividendArray[0].length>1&&
                    <button className="submit-button" onClick={() => {RemoveCellFunc()}} ><DeleteForeverIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Remove Cell</button>
                }
                {dividendArray.length<3&&
                    <button className="submit-button" onClick={() => {setInputPartnerName(true); }} ><HandshakeIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Partner</button>
                }
                <label htmlFor="file-picker" className="submit-button" onClick={() => {}} ><AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Attach</label>
                <button  className="submit-button" onClick={() =>{setLinkAttachable(true)}} ><InsertLinkIcon  style={{fontSize: '14px', verticalAlign: 'middle' }} /> Link</button>
                <button className="submit-button" onClick={() => {SubmitHandler()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
            </div>

            <FormulaErrorSnackbar
                open={errorOpen}
                setOpen={(val) => setErrorOpen(val)}
                currentTable="Dividend Formulas"
            />

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}

        </>
    );
}

export default Dividends