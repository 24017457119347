import React, {useState, useEffect, useContext} from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import SendIcon from '@mui/icons-material/Send';
import ReactLoading from "react-loading";

import {SimpleClientTables} from '../../ClientTables/ClientTables';
import {OtherWorkRelatedTable} from './DataTables/DeductionDataTables';

import { CreateDeductionAPI, GetDeductionByIdAPI, updateDeductionByIdAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../../../../store/auth-context";
import { toast } from "react-toastify";

import LinkAttachFile from '../../../../components/LinkAttachFile';
import AttachFile from '../../../../components/AttachFile';


const OtherWorkRelated = ({currentModal, currentPage, clientData, clientUpdateYear, setDocsChange}) => {
    const authCtx = useContext(AuthContext);
    const [dataArray, setDataArray] = useState([{
        name: '',
        ref: '',
        fixedRateMethod: '',
        shortcutMethod: ''
    }])
    const [clientTableDetails, setClientTableDetails] = useState({
        preparedDate: '',
        scheduleRef: currentPage,
        staffMember: 'No',
        managerReview: 'No',
        partnerReview: 'No',
    })
    
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);


    // Using at LinkAttachFile.js
    const [linkAttachable, setLinkAttachable] = useState(false);
    const [links, setLinks] = useState([]);
    
    // Using at AttachFile.js
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [totalFixedRate, setTotalFixedRate] = useState(0);
    const [totalShortcut, setTotalShortcut] = useState(0);
    const modalType = currentModal.replace(/[\s,]+/g, '_');
    const clientYear = clientUpdateYear.getFullYear();


    useEffect(() => {
        const GetttingDeductionsData = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);

            setLoading(true);

            const getRes = await GetDeductionByIdAPI(clientData._id, clientYear, modalType , userToken.token);
            if(getRes.success===true){
                if(getRes.data === null || getRes.data === undefined){
                    setDataAlready(false);
                }else{
                    setDataAlready(true);
                    setDataArray(getRes.data.data);
                    setTotalFixedRate(getRes.data.total);
                    setTotalShortcut(getRes.data.clientShare);
                    setClientTableDetails({preparedDate: getRes.data.preparedDate, scheduleRef: getRes.data.scheduleRef, staffMember: getRes.data.staffMember, managerReview: getRes.data.managerReview, partnerReview: getRes.data.partnerReview});
                    setSelectedFiles(getRes.data.attachment);
                    setLinks(getRes.data.link);
                }
            }

            setLoading(false);
        }

        GetttingDeductionsData();
    }, [isDataAlready])


    useEffect(() => {
        const calculateTotal = () => {
            let totalFixed = 0;
            let totalShort = 0;
            for (const row of dataArray) {
                const fixedRateVal = parseFloat(row.fixedRateMethod);
                const shortcutval = parseFloat(row.shortcutMethod);
                if (!isNaN(fixedRateVal)) {
                    totalFixed += fixedRateVal;
                }
                if(!isNaN(shortcutval)){
                    totalShort += shortcutval;
                }
            }
            setTotalFixedRate(totalFixed);
            setTotalShortcut(totalShort);

        };
        calculateTotal();

    }, [dataArray])


    const handleChange = (rowIndex, cellKey, value) => {
        const updatedTableData = dataArray.map((row, index) => {
            if (index === rowIndex) {
                return { ...row, [cellKey]: value };
            }
            return row;
        });
        setDataArray(updatedTableData);
    };


    const AddCellFunc = () => {
        let newElement = {name: '', ref: '', fixedRateMethod: '', shortcutMethod: ''};
        setDataArray(oldArray => [...oldArray, newElement]);
    };
    
    const RemoveCellFunc = () => {
        let newData = [...dataArray];
        newData.pop();
        setDataArray(newData);
    }
    

    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);

        setLoading(true);

        let sendData = {
            clientId: clientData._id,
            year: clientYear,
            type: modalType,
            leadSchedule : currentModal,
            preparedDate: clientTableDetails?.preparedDate,
            scheduleRef: clientTableDetails?.scheduleRef,
            staffMember: clientTableDetails?.staffMember,
            managerReview: clientTableDetails?.managerReview,
            partnerReview: clientTableDetails?.partnerReview,
            total: totalFixedRate,
            clientShare: totalShortcut,
            data: dataArray,
            attachment: selectedFiles,
            link: links,
        };

        const submitRes = isDataAlready?
        await updateDeductionByIdAPI(sendData, clientData._id, clientYear, modalType, userToken.token)
        :
        await CreateDeductionAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
            setDocsChange(false);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    };


    return (
        <>
            <SimpleClientTables 
                currentModal={currentModal} 
                clientData={clientData} 
                area={'Lead Schedule: '} 
                clientUpdateYear={clientUpdateYear}
                clientTableDetails={clientTableDetails}
                setClientTableDetails={setClientTableDetails}
            />

            <OtherWorkRelatedTable 
                dataArray={dataArray}
                handleChange={handleChange}
                totalFixedRate={totalFixedRate}
                totalShortcut={totalShortcut}
            />


            <AttachFile 
                selectedFiles={selectedFiles} 
                setSelectedFiles={setSelectedFiles}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />

            <LinkAttachFile 
                links={links} 
                setLinks={setLinks} 
                linkAttachable={linkAttachable} 
                setLinkAttachable={setLinkAttachable}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />

            <div style={{display: 'flex', marginLeft: '5px', paddingBottom: '80px'}} >
                <button className="submit-button" onClick={() => {AddCellFunc()}} ><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Cell</button>
                {dataArray.length>1&&
                    <button className="submit-button" onClick={() => {RemoveCellFunc()}} ><DeleteForeverIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Remove Cell</button>
                }
                <label htmlFor="file-picker" className="submit-button" onClick={() => {}} ><AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Attach</label>
                <button  className="submit-button" onClick={() =>{setLinkAttachable(true)}} ><InsertLinkIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Link</button>
                <button className="submit-button" onClick={() => {SubmitHandler()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
            </div>

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>
    );
}

export default OtherWorkRelated