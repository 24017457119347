import React, {useContext} from 'react';
import { Button, Typography, IconButton } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {GetALLDataByYearAPI} from '../util/http';
import { AuthContext } from "../store/auth-context";
import { toast } from "react-toastify";
import { IndexPage } from './ExportSection/IndexPage';
import { DeductionPages } from './ExportSection/DeductionPages';
import { IncomePages, IncomePartnershipPage } from './ExportSection/IncomePages';
import { OtherPages } from './ExportSection/OtherPages';
import { DividendsPage } from './ExportSection/DividendsPage';
import { CapitalGainsPage } from './ExportSection/CapitalGainsPage'
import { RentPage } from './ExportSection/RentPage';
import { ITRPage } from './ExportSection/ITRPage';
import { TaxRatePage } from './ExportSection/TaxRatePage';
import { IssueReviewPage } from './ExportSection/IssueReviewPage';
import { InsurancePage } from './ExportSection/InsurancePage';
const ExcelJS = require('exceljs');


const ExportToExcel = ({setLoading, clientUpdateYear, clientData}) => {
    const authCtx = useContext(AuthContext);
    const clientYear = clientUpdateYear.getFullYear();

    const ExcelFormationFunc = async(data) => {
        const response = await fetch('/excel/book2.xlsx');
        const blob = await response.blob();

        // Create a new workbook and read the existing file
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(blob);

        const indexSheet = workbook.getWorksheet('INDEX');


        // Index Sheet..
        IndexPage(indexSheet, clientData, clientYear);

        
 

        //All Deduction Sheets..
        DeductionPages(workbook, data, clientData, clientYear);

        //All Issues and Review Notes Sheets..
        IssueReviewPage(workbook, data, clientData, clientYear);

        // Insurance Sheet..
        InsurancePage(workbook, data, clientData, clientYear);


        // Simple Income Sheets..
        IncomePages(workbook, data, clientData, clientYear);
        IncomePartnershipPage(workbook, data, clientData, clientYear);

        // Dividends Sheet..
        DividendsPage(workbook, data, clientData, clientYear);

        // Capital Gains Sheet..
        CapitalGainsPage(workbook, data, clientData, clientYear);

        // Rent Sheet..
        RentPage(workbook, data, clientData, clientYear);
        
        
        // Other Small Sheets..
        OtherPages(workbook, data, clientData, clientYear);


        // Tax Estimate Sheets..
        ITRPage(workbook, data, clientData, clientYear);

        // Tax Rate..
        TaxRatePage(workbook, data, clientData, clientYear);
        


         // Set up the response headers
        const headerOptions = {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-Disposition': 'attachment; filename=colored_text.xlsx'
        };

        // Create a Blob for the modified file
        const modifiedBlob = await workbook.xlsx.writeBuffer();

        // Create a download link and trigger a click event
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([modifiedBlob], { type: headerOptions['Content-Type'] }));
        downloadLink.setAttribute('download', `${clientData.name} - Tax File(${clientYear}).xlsx`);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up
        document.body.removeChild(downloadLink);
    }

    const GetWholeDataFunc = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        const getData = await GetALLDataByYearAPI(clientData._id, clientYear, userToken.token);
        console.log("Get Data: ", getData);
        if(getData.success===true){
            ExcelFormationFunc(getData.doc);
            toast.success('Your File is about to start downloading', {
                position: toast.POSITION.TOP_CENTER,
            })
        }else{
            toast.error(`${getData.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }


    return (
        <Button variant="contained" style={{backgroundColor: '#009ec1'}} onClick={() => {GetWholeDataFunc()}}>
            <Typography variant="h6" color={'#fff'} fontSize={"16px"}>Export {clientYear}</Typography>
            <CloudDownloadIcon style={{color: '#fff', padding: '0 0 0 5px'}} />
        </Button>
    )
}

export default ExportToExcel;