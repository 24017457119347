import React, {useEffect, useContext, useState} from "react";
import { Box, useTheme, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";


import { useNavigate, useParams } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";

import { GetUsersAPI, GetManagersAPI, GetUserListById } from "../../util/http";
import { AuthContext } from "../../store/auth-context";
import ClientUpdateModal from "../../components/ClientUpdateModal";
import LaunchIcon from '@mui/icons-material/Launch';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Topbar from '../global/Topbar';



const ClientList = () => {
    const {staffId} = useParams();
    const navigate = useNavigate();
    const role = secureLocalStorage.getItem("role");
    const authCtx = useContext(AuthContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openClientModal, setOpenClientModal] = useState(false);
    const [userData, setUserData] = useState({});

    const [clientsList, setClientsList] = useState([]);
    // const [serverLoading, setServerLoading] = useState(false);
    // const [totalClients, setTotalClients] = useState(clientsList.length);
    
    // const [currentPage, setCurrentPage] = useState(1);
    // const [pageSize, setPageSize] = useState(10);

    
    useEffect(() => {
        const TokenChecker = async() => {
            const userToken = await secureLocalStorage.getItem("token");
            const validRole = (role==="admin" || role==="partner" || role==="manager"  || role==="staff");

            if(userToken===null || userToken===undefined || !validRole){
                navigate("/");
            }
        }
        TokenChecker();
        
    }, [navigate])


    useEffect(() => {
        const ClientListUpdater = async() => {
            const authToken = await secureLocalStorage.getItem("token")
            if(authToken === null || authToken === undefined){
                return;
            }
            const userToken = JSON.parse(authToken);
            const getRes = await GetUserListById(staffId,  userToken.token);
            if(getRes.success===true){
                setClientsList(getRes.doc);
            }
        }

        ClientListUpdater();
    }, [authCtx.token, authCtx.clientListTrigger, role])


    const UpdateManagerFunc = (data) => {
        setUserData(data);
        setOpenClientModal(true);
    }


    const DeletePerticularClient = (data) => {
        setUserData(data);
        setOpenDeleteDialog(true);
        
    } 



    const columns = [
        { field: "name", headerName: "Name", flex: 1, headerAlign: 'center', },
        { field: "position", headerName: "Position", flex: 1, headerAlign: 'center', },
        { field: "office", headerName: "Office",  flex: 1, headerAlign: 'center', },
        { field: "age", headerName: "Age",  flex: 1,  cellClassName: "align-column--cell", headerAlign: 'center', },
        { 
            field: "salary", 
            headerName: "Salary", 
            flex: 1,  
            headerAlign: 'center', 
            renderCell: ({ row }) => {
                return (<>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(parseFloat(row.salary))}</>)
            }
        },
        { 
            field: "action", 
            headerName: "Action", 
            flex: 1, 
            headerAlign: 'center', 
            renderCell: ({ row }) => {
                return (
                    <div style={{margin: '0 auto'}}>
                        <Button
                            style={{backgroundColor: colors.importantAccent[300], color: '#fff', padding: '5px 10px', marginRight: '5px'}}
                            onClick={() => { navigate(`/client-profile/${row._id}`)}}
                        >
                            <LaunchIcon />
                            {(role!=="admin")&&<Typography color={'#fff'} sx={{ ml: "5px" }}>Open</Typography>}
                        </Button>
                        
                        {(role==="admin")&&
                            <Button
                                style={{backgroundColor: '#f75e7f', color: '#fff', padding: '5px 10px', marginLeft: '5px'}}
                                onClick={() => { DeletePerticularClient(row) }}
                            >
                                <DeleteForeverIcon />
                            </Button>
                        }

                    </div>
                );
            },
        }
    ];

    
    


    // useEffect(() => {
    //     const FetchClientList = async () => {
    //         setServerLoading(true);
    //         const DataRes = await Something(pageState.page, pageState.pageSize);
    //         
    //         if(DataRes.success===true){
    //             setClientList(DataRes.data);
    //             setTotalClients(jo bhi info);
    //             
    //         }
    //          
    //          setServerLoading(false);
    //     }
    // }, [pageState.page, pageState.pageSize, authCtx.clientListTrigger])



    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{
                "& .MuiSvgIcon-root": {
                    color: '#000'
                },
                "& .MuiTablePagination-root": {
                    color: '#000'
                },
            }}>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    return (
        <>
            {(role==="admin")&&
                <Box>
                    <Topbar
                        name="ClientList" 
                        message="Create Client"
                        id={staffId}
                    />
                </Box>
            }
            
            <Box>
                <Box p="20px" backgroundColor={colors.importantAccent[100]} >

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Header title="Client List" subtitle="All Clients are Listed Here" />
                    </Box>

                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        height="80vh"
                        sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300],
                        },
                        "& .align-column--cell": {
                            textAlign: "start",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.importantAccent[300],
                            fontSize: '14px',
                            borderBottom: "none",
                            color: '#fff'
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                            fontSize: '14px',
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.importantAccent[300],
                            fontSize: '14px',
                            color: '#fff'
                        },
                        "& .MuiSvgIcon-root": {
                            color: '#fff'
                        },
                        "& .MuiTablePagination-root": {
                            color: '#fff'
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                        }}
                    >
                        <DataGrid
                            rows={clientsList}
                            columns={columns}
                            // components={{ Toolbar: GridToolbar }}
                            components={{ Toolbar: CustomToolbar }}

                            getRowId={(row) =>  row._id}

                            // rowCount={totalClients}
                            // loading={serverLoading}
                            // rowsPerPageOptions={[10]}
                            // pagination
                            // page={currentPage-1}
                            // pageSize={pageSize}
                            // paginationMode="server"
                            // onPageChange={(newPage) => {setCurrentPage(newPage+1)}}
                            // onPageSizeChange={(newPageSize) => {setPageSize(newPageSize)}}
                        />
                    </Box>
                </Box>
                

                <ConfirmDeleteDialog 
                    openDeleteDialog={openDeleteDialog}
                    setOpenDeleteDialog={(val) => setOpenDeleteDialog(val)}
                    userData={userData}
                    message="Delete Client"
                />
            </Box>
                    
            
            <ClientUpdateModal
				openClientModal={openClientModal}
				setOpenClientModal={(val) => setOpenClientModal(val)}
				clientData={userData}
                updateMessage={"Update Client"}
			/>
        </>
    );
};

export default ClientList;
