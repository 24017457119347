import React, {useState} from 'react';

import { ResidentTaxData, MotorVehicleData, LowIncomeTaxData, LowMiddleIncomeTaxData, NonResidentTaxData, WorkingHolidayData } from './RatesData';
import TaxRatesBox from './TaxRatesBox';
import EditRatesModal from '../../../../components/EditRatesModal';


const AllTaxRates = (props) => {
    const currentYear = props.clientUpdateYear;
    const [openModal, setOpenModal] = useState(false);
    const [whichTaxTable, setWhichTaxTable] = useState('');

    const editTableFunc = (tableName) => {
        setOpenModal(true);
        setWhichTaxTable(tableName);
    }

    return (
        <>
            <div style={{backgroundColor: '#fff', paddingBottom: '30px', overflow: 'auto', height: '100%'}}>
                <TaxRatesBox 
                    taxData={ResidentTaxData}
                    taxRateType="residentTaxRate"
                    editable={props.editable}
                    currentYear={currentYear}
                    editTableFunc={editTableFunc}
                />

                <hr style={{color: '#000',  margin: '20px 0 10px'}} />

                <TaxRatesBox 
                    taxData={MotorVehicleData}
                    taxRateType="motorVehicleRate"
                    editable={props.editable}
                    currentYear={currentYear}
                    editTableFunc={editTableFunc}
                />

                <hr style={{color: '#000',  margin: '20px 0 10px'}} />

                <TaxRatesBox 
                    taxData={LowIncomeTaxData}
                    taxRateType="lowIncomeTaxRate"
                    editable={props.editable}
                    currentYear={currentYear}
                    editTableFunc={editTableFunc}
                />

                <hr style={{color: '#000',  margin: '20px 0 10px'}} />

                <TaxRatesBox 
                    taxData={LowMiddleIncomeTaxData}
                    taxRateType="lowMidleIncomeTaxRate"
                    editable={props.editable}
                    currentYear={currentYear}
                    editTableFunc={editTableFunc}
                />

                <hr style={{color: '#000',  margin: '20px 0 10px'}} />

                <TaxRatesBox 
                    taxData={NonResidentTaxData}
                    taxRateType="nonResidentTaxRate"
                    editable={props.editable}
                    currentYear={currentYear}
                    editTableFunc={editTableFunc}
                />

                <hr style={{color: '#000',  margin: '20px 0 10px'}} />

                <TaxRatesBox 
                    taxData={WorkingHolidayData}
                    taxRateType="workingHolidayTaxRate"
                    editable={props.editable}
                    currentYear={currentYear}
                    editTableFunc={editTableFunc}
                />
            </div>

            <EditRatesModal 
                openModal={openModal}
                setOpenModal={setOpenModal}
                whichTaxTable={whichTaxTable}
                currentYear={currentYear}
            />
            
        </>
    )
}

export default AllTaxRates;