import { Config } from '../../util/config';

const styles = {
    borderAll: {
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
}

export const DividendsPage = (workbook, data, clientData, clientYear) => {
    let dividendSheet = workbook.getWorksheet('11');

    dividendSheet.getCell('C4').value = clientData.name;
    dividendSheet.getCell('C6').value = clientYear;
    dividendSheet.getCell('H5').value = clientData.staff;
    dividendSheet.getCell('H6').value = clientData.manager;
    dividendSheet.getCell('H7').value = clientData.partner;

    const currentDividendData = (data.dividends.length!==0)? data.dividends[0] : null;
    if(currentDividendData === undefined || currentDividendData === null){
        return;
    }
    
    dividendSheet.getCell('C7').value = currentDividendData['preparedDate'];
    dividendSheet.getCell(`I11`).value = clientData.name;

    let startingIndex = 0;
    for( ; startingIndex<currentDividendData['data'][0].length; startingIndex++) {
        if((13+startingIndex)>35){
            dividendSheet.insertRow(13+startingIndex, [], );
        }

        dividendSheet.getCell(`B${13+startingIndex}`).value = currentDividendData['data'][0][startingIndex]['company'];
        dividendSheet.getCell(`B${13+startingIndex}`).style = styles.borderAll;
        dividendSheet.getCell(`C${13+startingIndex}`).value = Number(currentDividendData['data'][0][startingIndex]['sharesHeld']);
        dividendSheet.getCell(`C${13+startingIndex}`).style = styles.borderAll;
        dividendSheet.getCell(`D${13+startingIndex}`).value = Number(currentDividendData['data'][0][startingIndex]['divPerShare']);
        dividendSheet.getCell(`D${13+startingIndex}`).style = styles.borderAll;
        dividendSheet.getCell(`E${13+startingIndex}`).value = Number(currentDividendData['data'][0][startingIndex]['Franking'])/100;
        dividendSheet.getCell(`E${13+startingIndex}`).numFmt = '0.00%';
        dividendSheet.getCell(`F${13+startingIndex}`).value = Number(currentDividendData['data'][0][startingIndex]['totalDividend']);
        dividendSheet.getCell(`F${13+startingIndex}`).style = styles.borderAll;
        dividendSheet.getCell(`G${13+startingIndex}`).value = Number(currentDividendData['data'][0][startingIndex]['totalFrankingCr']);
        dividendSheet.getCell(`G${13+startingIndex}`).style = styles.borderAll;
        dividendSheet.getCell(`H${13+startingIndex}`).value = Number(currentDividendData['data'][0][startingIndex]['ownership'])/100;
        dividendSheet.getCell(`H${13+startingIndex}`).numFmt = '0.00%';

        dividendSheet.getCell(`I${13+startingIndex}`).value = Number(currentDividendData['data'][1][startingIndex]['owner_frankedDiv']);
        dividendSheet.getCell(`I${13+startingIndex}`).style = styles.borderAll;
        dividendSheet.getCell(`J${13+startingIndex}`).value = Number(currentDividendData['data'][1][startingIndex]['owner_unFrankedDiv']);
        dividendSheet.getCell(`J${13+startingIndex}`).style = styles.borderAll;
        dividendSheet.getCell(`K${13+startingIndex}`).value = Number(currentDividendData['data'][1][startingIndex]['owner_frankingCr']);
        dividendSheet.getCell(`K${13+startingIndex}`).style = styles.borderAll;
        
        if(currentDividendData['data'][2] !== undefined){
            dividendSheet.getCell(`L11`).value = (currentDividendData['partnerName']!==undefined && currentDividendData['partnerName']!==null)? currentDividendData['partnerName'] : '';

            dividendSheet.getCell(`L${13+startingIndex}`).value = Number(currentDividendData['data'][2][startingIndex]['partner_frankedDiv']);
            dividendSheet.getCell(`L${13+startingIndex}`).style = styles.borderAll;
            dividendSheet.getCell(`M${13+startingIndex}`).value = Number(currentDividendData['data'][2][startingIndex]['partner_unFrankedDiv']);
            dividendSheet.getCell(`M${13+startingIndex}`).style = styles.borderAll;
            dividendSheet.getCell(`N${13+startingIndex}`).value = Number(currentDividendData['data'][2][startingIndex]['partner_frankingCr']);
            dividendSheet.getCell(`N${13+startingIndex}`).style = styles.borderAll;
        }
    }

    let totalIndex = (13+startingIndex)>35?  startingIndex+17 : 40;
    
    dividendSheet.getCell(`F${totalIndex}`).value = Number(currentDividendData['total']['totalDividend']);
    dividendSheet.getCell(`F${totalIndex}`).numFmt = '$#,##0.00';
    dividendSheet.getCell(`G${totalIndex}`).value = Number(currentDividendData['total']['totalFrankingCr']);
    dividendSheet.getCell(`G${totalIndex}`).numFmt = '$#,##0.00';
    dividendSheet.getCell(`I${totalIndex}`).value = Number(currentDividendData['total']['owner_frankedDiv']);
    dividendSheet.getCell(`I${totalIndex}`).numFmt = '$#,##0.00';
    dividendSheet.getCell(`J${totalIndex}`).value = Number(currentDividendData['total']['owner_unFrankedDiv']);
    dividendSheet.getCell(`J${totalIndex}`).numFmt = '$#,##0.00';
    dividendSheet.getCell(`K${totalIndex}`).value = Number(currentDividendData['total']['owner_frankingCr']);
    dividendSheet.getCell(`K${totalIndex}`).numFmt = '$#,##0.00';
    dividendSheet.getCell(`L${totalIndex}`).value = Number(currentDividendData['total']['partner_frankedDiv']);
    dividendSheet.getCell(`L${totalIndex}`).numFmt = '$#,##0.00';
    dividendSheet.getCell(`M${totalIndex}`).value = Number(currentDividendData['total']['partner_unFrankedDiv']);
    dividendSheet.getCell(`M${totalIndex}`).numFmt = '$#,##0.00';
    dividendSheet.getCell(`N${totalIndex}`).value = Number(currentDividendData['total']['partner_frankingCr']);
    dividendSheet.getCell(`N${totalIndex}`).numFmt = '$#,##0.00';



    // If There is no data related to partner..
    let emptyCellArray = (currentDividendData['data'][2] === undefined)? ['L11', 'L12', 'M12', 'N12', `L${totalIndex}`, `M${totalIndex}`, `N${totalIndex}`] : [];
    for(let j=0; j<emptyCellArray.length; j++){
        dividendSheet.getCell(emptyCellArray[j]).value = '';
        dividendSheet.getCell(emptyCellArray[j]).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'ffffffff'}, bgColor:{argb:'ffffffff'} };
        dividendSheet.getCell(emptyCellArray[j]).border = { top: {style:'thin', color: {argb:'ff808080'}}, left: {style:'thin', color: {argb:'ff808080'}}, bottom: {style:'thin', color: {argb:'ff808080'}}, right: {style:'thin', color: {argb:'ff808080'}}};
    }


    
    // Assigning Files..
    let fileIndex = totalIndex + 5;
    if(currentDividendData['attachment'].length>0){
        dividendSheet.getCell(`B${fileIndex}`).value = 'FILES:';
        dividendSheet.getCell(`B${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
    }
    fileIndex += 1;
    for(let j=0; j<currentDividendData['attachment'].length; j++){
        dividendSheet.getCell(`B${fileIndex+j}`).value = { 
            text: currentDividendData['attachment'][j]['originalname'],
            hyperlink: `${Config.ImageUrl}${currentDividendData['attachment'][j]['filename']}`, 
        };
        dividendSheet.getCell(`B${fileIndex+j}`).font = {color: { argb: '3366cc' }}
    }


    

    // Assigning Links..
    fileIndex = totalIndex + 5;
    if(currentDividendData['link'].length>0){
        dividendSheet.getCell(`L${fileIndex}`).value = 'LINKS:';
        dividendSheet.getCell(`L${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
    }
    let linkFilesLength = 0;
    fileIndex += 1;
    for(let j=0; j<currentDividendData['link'].length; j++) {
        dividendSheet.getCell(`L${fileIndex+(j+linkFilesLength)}`).value = {
            text: currentDividendData['link'][j]['link'],
            hyperlink: currentDividendData['link'][j]['link'],
        }
        dividendSheet.getCell(`L${fileIndex+(j+linkFilesLength)}`).font = {color: { argb: '3366cc' }}
        
        for(let k=0; k<currentDividendData['link'][j]['attachments'].length; k++) {
            dividendSheet.getCell(`M${fileIndex+1+(j+linkFilesLength)+k}`).value = { 
                text: currentDividendData['link'][j]['attachments'][k]['originalname'],
                hyperlink: `${Config.ImageUrl}${currentDividendData['link'][j]['attachments'][k]['filename']}`, 
            };
            dividendSheet.getCell(`M${fileIndex+1+(j+linkFilesLength)+k}`).font = {color: { argb: '3366cc' }}
        }
        linkFilesLength += currentDividendData['link'][j]['attachments'].length;
        linkFilesLength += 1;
    }

};