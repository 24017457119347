import React from 'react';
import './ClientTables';
import secureLocalStorage from "react-secure-storage";

const QueryClientTables = ({currentModal, clientData, clientUpdateYear}) => {

    return (
        <div className="table-container">
            <table className="first-table">
                <tr>
                    <td className="td-name" >Client:</td>
                    <td style={{width: '50%'}}>{clientData.name}</td>
                </tr>
                <tr>
                    <td className="td-name" >Area: </td>
                    <td style={{width: '50%'}}>{currentModal}</td>
                </tr>
                <tr>
                    <td className="td-name" >Year:</td>
                    <td style={{width: '50%'}}>{clientUpdateYear.getFullYear()}</td>
                </tr>
            </table>
        </div>
    );
}

const SimpleClientTables = ({currentModal, clientData, area, clientUpdateYear, clientTableDetails = null, setClientTableDetails = null}) => {
    const role = secureLocalStorage.getItem('role')
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setClientTableDetails(prevData => ({...prevData, [name]: value}));
    }

    return(
        <div className="table-container">
            <table className="first-table">
                <tbody>
                    <tr>
                        <td className="td-name" >Client:</td>
                        <td style={{width: '25%'}}>{clientData.name}</td>
                        <td className="td-name">Schedule Ref</td>
                        <td style={{display: 'flex', border: 'none'}} className="data-fill-field income-field">
                            <input
                                type="text"
                                placeholder=""
                                name="scheduleRef"
                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', paddingLeft: '5px'}}
                                value={clientTableDetails?.scheduleRef}
                                onChange={(e) => { 
                                    // handleInputChange(e)
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-name" >{area}</td>
                        <td >{currentModal}</td>
                        <td className="td-name">Staff Member</td>
                        <td style={{border: 'none', width: '25%'}} className="data-fill-field income-field">
                            <select style={{padding: '5px', width: '100%', border: 'none', outline: 'none'}} 
                                name='staffMember' 
                                value={clientTableDetails.staffMember}
                                onChange={(e) => { handleInputChange(e)}}
                                disabled={role!=='staff'}
                            >
                                <option disabled>Staff Member - Yes or No</option>
                                <option value={'No'}>No</option>
                                <option value={'Yes'}>Yes</option>                                            
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="td-name" >Year:</td>
                        <td >{clientUpdateYear.getFullYear()}</td>
                        <td className="td-name">Manager Review</td>
                        <td style={{border: 'none', width: '25%'}} className="data-fill-field income-field">
                            <select style={{padding: '5px', width: '100%', border: 'none', outline: 'none'}} 
                                name='managerReview' 
                                value={clientTableDetails.managerReview}
                                onChange={(e) => { handleInputChange(e)}}
                                disabled={role!=='manager'}
                            >
                                <option disabled>Manager Review - Yes or No</option>
                                <option value={'No'}>No</option>
                                <option value={'Yes'}>Yes</option>                                            
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="td-name" >Date Prepared:</td>
                        <td style={{display: 'flex', border: 'none'}} className="data-fill-field income-field">
                            <input
                                type="date"
                                placeholder=""
                                name="preparedDate"
                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', paddingLeft: '5px'}}
                                value={clientTableDetails?.preparedDate}
                                onChange={(e) => { handleInputChange(e)}}
                            />
                        </td>
                        <td className="td-name">Partner Review</td>
                        <td style={{border: 'none', width: '25%'}} className="data-fill-field income-field">
                            <select style={{padding: '5px', width: '100%', border: 'none', outline: 'none'}} 
                                name='partnerReview' 
                                value={clientTableDetails.partnerReview}
                                onChange={(e) => { handleInputChange(e)}}
                                disabled={role!=='partner'}
                            >
                                <option disabled>Partner Review - Yes or No</option>
                                <option value={'No'}>No</option>
                                <option value={'Yes'}>Yes</option>                                            
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}


const PreparedReviewedTable = ({reviewPrepare, setReviewPrepare}) => {

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setReviewPrepare(prevData => ({...prevData, [name]: value}));
    }

    return(
        <div className="table-container tb-container-lower">
            <table className="rest-table">
                <tr>
                    <td className="td-name" style={{width: '50%'}} >Prepared By:</td>
                    <td style={{display: 'flex', border: 'none'}} className="data-fill-field income-field">
                        <input
                            type="text"
                            placeholder="Prepared By..."
                            name="preparedBy"
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                            value={reviewPrepare.preparedBy}
                            onChange={(e) => {handleInputChange(e)}}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-name" style={{width: '50%'}} >Date:</td>
                    <td style={{display: 'flex', border: 'none'}} className="data-fill-field income-field">
                        <input
                            type="date"
                            placeholder=""
                            name="preparedDate"
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                            value={reviewPrepare.preparedDate}
                            onChange={(e) => { handleInputChange(e)}}
                        />
                    </td>
                </tr>
            </table>

            <table className="rest-table">
                <tr>
                    <td className="td-name" style={{width: '50%'}} >Reviewed By:</td>
                    <td style={{display: 'flex', border: 'none'}} className="data-fill-field income-field">
                        <input
                            type="text"
                            placeholder="Reviewed By..."
                            name="reviewedBy"
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                            value={reviewPrepare.reviewedBy}
                            onChange={(e) => {handleInputChange(e)}}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-name" style={{width: '50%'}} >Date:</td>
                    <td style={{display: 'flex', border: 'none'}} className="data-fill-field income-field">
                        <input
                            type="date"
                            placeholder=""
                            name="reviewedDate"
                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                            value={reviewPrepare.reviewedDate}
                            onChange={(e) => {handleInputChange(e)}}
                        />
                    </td>
                </tr>
            </table>
      </div>
    );
}



export default QueryClientTables;
export {SimpleClientTables};
export {PreparedReviewedTable};