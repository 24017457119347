import React, {useEffect} from 'react';
import {Box, useTheme} from "@mui/material";
import { useNavigate } from 'react-router-dom';

import Header from "../../components/Header";
import { tokens } from "../../theme";
import secureLocalStorage from "react-secure-storage";

import '../ClientProfile/ClientProfileComps/ClientTable.css';
import '../ClientProfile/ClientProfileComps/DataTable.css';

import AllTaxRates from '../ClientProfile/ClientProfileComps/Tax Rates/AllTaxRates';


const TaxRate = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const role = secureLocalStorage.getItem("role");
    const colors = tokens(theme.palette.mode);
    const clientUpdateYear = new Date();

    useEffect(() => {
        const TokenChecker = async() => {
            const userToken = await secureLocalStorage.getItem("token");
            const validRole = (role==="admin");

            if(userToken===null || userToken===undefined || !validRole){
                navigate("/");
            }
        }
        TokenChecker();

    }, [navigate])


    return (
        <>
            <Box p="20px" backgroundColor={colors.importantAccent[100]} style={{overflow: 'auto'}} >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Tax Rates" subtitle="All Tax Rates are Listed Here" />
                </Box>

                <Box
                    m="8px 0 0 0"
                    width="100%"
                    height="80vh"
                    sx={{}}
                >

                    <AllTaxRates 
                        clientUpdateYear={clientUpdateYear}
                        editable={false}
                    />

                </Box>
            </Box>
        

        </>
    )
}

export default TaxRate;