import React from 'react';


const QueryResponseTable = ({queryTable, handleChange}) => {
    

    return (
        <div className="table-container" style={{flexDirection: 'column'}}  >
            <table className="quest-table">
                <tr className="table-heading" >
                    <th className="th-name" >Question</th>
                    <th  className="th-name" style={{width: '50%'}}>Response</th>
                </tr>

                {queryTable.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="ques" >
                                <textarea 
                                    type="text"
                                    placeholder="" 
                                    rows="1"
                                    value={item.query}
                                    style={{width: '100%', border: 'none', outline: 'none', fontSize: '14px', padding: '5px', resize: 'vertical'}}  
                                    onChange={(e) => {
                                        handleChange(index, "query", e.target.value); 
                                    }}
                                />

                            </td>
                            <td className="ques"  style={{width: '50%', position: 'relative'}} >
                                <textarea
                                    type="text" 
                                    placeholder="" 
                                    wrap="hard"
                                    value={item.response}
                                    style={{border: 'none', outline: 'none', fontSize: '14px', padding: '5px',resize: 'none', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0}}  
                                    onChange={(e) => {
                                        handleChange(index, "response", e.target.value); 
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })}

            </table>
        
        </div>
    );
}


const TotalClientShareTable = ({dataArray, handleChange, totalAmount, clientAmount}) => {

    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset to auto height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
    };
    

    return(
        <div className="table-container" style={{flexDirection: 'column'}}> 
            <table className="data-table">
                <tr className="data-table-heading" >
                    <th className="th-name" style={{width: '50%'}} ></th>
                    <th className="th-name" style={{width: '15%'}} >Ref</th>
                    <th className="th-name"  style={{width: '15%'}}  >Total</th>
                    <th className="th-name" >Client Share</th>
                </tr>

                {dataArray.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="data-fill-field" >
                                <textarea 
                                    type="text" 
                                    placeholder="" 
                                    rows="1"
                                    wrap="hard"
                                    className="textArea textArea-complete-witdth textArea-Resize"
                                    value={item.name}
                                    onChange={(e) => { 
                                        handleChange(index, "name", e.target.value); 
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="text" 
                                    placeholder=""
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.ref}
                                    onChange={(e) => { 
                                        handleChange(index, "ref", e.target.value); 
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.total}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "total", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }} 
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.clientShare}
                                    onChange={(e) => { 
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "clientShare", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }} 
                                />
                            </td>
                        </tr>
                    );
                })}

                <tr className="data-table-heading" >
                    <th className="th-name" colSpan="2">Total</th>
                    <th className="th-name" >$ {Number(totalAmount).toFixed(2)}</th>
                    <th className="th-name" >$ {Number(clientAmount).toFixed(2)}</th>
                </tr>

            </table>
                
        </div>
    );
}

export default QueryResponseTable;
export {TotalClientShareTable};