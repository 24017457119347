import React from 'react';
import './ClientTable.css';
import './DataTable.css';
import { Drawer } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReconcilationITR from './Tax Estimates/ReconcilationITR';

const TaxEstimateModal = ({modalOpen, setModalOpen, currentModal, currentPage, clientData, clientUpdateYear}) => {
    return (
        <>
            <Drawer size="lg" placement='right' open={modalOpen} >
                <h3 className="headingTitle" >Reconciliation To ITR Check</h3>
                <IconButton 
                    aria-label="close" size="small"  style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                    onClick={() => {setModalOpen(false)}}
                >
                    <CloseIcon style={{fontSize: '30px', color: '#000'}}  />
                </IconButton>

                <div style={{backgroundColor: '#fff', paddingBottom: '30px', overflow: 'auto', height: '100%'}} >
                    <ReconcilationITR currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} />
                </div>

            </Drawer>
        </>
    );
}

export default TaxEstimateModal;