import React, { forwardRef } from 'react';
import { IconButton, useTheme, Button } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import secureLocalStorage from "react-secure-storage";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PasswordIcon from '@mui/icons-material/Password';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExportToExcel from './ExportToExcel';



const DetailBox = ({clientUpdateYear, setClientUpdateYear, clientUpdateDate, setOpenClientModal, setOpenPasswordModal, clientData, setLoading}) => {
    const theme = useTheme();
    const role = secureLocalStorage.getItem("role");
    
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button  
            style={{color: '#fff', backgroundColor: '#009ec1', padding: '5px 10px', borderRadius: '5px', fontSize: '14px'}} 
            className="example-custom-input"
            onClick={onClick} 
            ref={ref}
        >
            {value}
            <KeyboardArrowDownIcon style={{verticalAlign: 'middle'}} />
        </Button>
    ));

    return (

        <>
            <div className="face-box" style={{display: 'flex'}}>
                <div className="card" style={{flex: 1}}>
                    
                    <div className="card-body" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                        <div className="inner-card-body">
                            <div className="inner-card-text-view">
                                <h4 className="card-heading">{clientData.name}</h4>
                                <p className="card-text-special">{clientData.position}</p>
                                <p className="card-text-special" style={{marginTop: '0px', marginBottom: '1rem'}}>{clientData.email}</p>
                            </div>
                        </div>
                    </div>
                    <ExportToExcel 
                        setLoading={(val) => setLoading(val)}
                        clientUpdateYear={clientUpdateYear} 
                        clientData={clientData} 
                    />
                </div>
            </div>

            <div className="detail-box">
                <div className="card">
                    {role==="admin"&&
                        <div style={{position: 'absolute', top: '5px', right: '5px', zIndex: '10', display: 'flex'}}>
                            <IconButton onClick={() => {setOpenClientModal(true)}}>
                                <BorderColorIcon />
                            </IconButton>
                            <IconButton onClick={() => {setOpenPasswordModal(true)}}>
                                <PasswordIcon />
                            </IconButton>
                        </div>
                    }

                    <div className="card-body">
                        <ul className="list-group list-group-flush" style={{paddingTop: '20px'}}>
                            <li className="list-group-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <h6  style={{marginBottom: '0 !important', fontWeight: '500'}}>Client</h6>
                                <span className="card-text-client" >{clientData.name}</span>
                            </li>
                            <li className="list-group-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <h6  style={{marginBottom: '0 !important', fontWeight: '500'}}>Partner</h6>
                                <span className="card-text-client" >{clientData.partner}</span>
                            </li>
                            <li className="list-group-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <h6  style={{marginBottom: '0 !important', fontWeight: '500'}}>Manager</h6>
                                <span className="card-text-client" >{clientData.manager}</span>
                            </li>
                            <li className="list-group-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <h6  style={{marginBottom: '0 !important', fontWeight: '500'}}>Staff</h6>
                                <span className="card-text-client" >{clientData.staff}</span>
                            </li>
                            <li className="list-group-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <h6  style={{marginBottom: '0 !important', fontWeight: '500'}}>Date</h6>
                                <span className="card-text-client" >{clientUpdateDate} </span>
                            </li>
                            <li className="list-group-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <h6  style={{marginBottom: '0 !important', fontWeight: '500'}}>Year</h6>
                                
                                <DatePicker
                                    selected={clientUpdateYear}
                                    onChange={(date) => {setClientUpdateYear(new Date(date))}}
                                    customInput={<CustomInput />}
                                    showYearPicker
                                    dateFormat="yyyy"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailBox;