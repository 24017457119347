import React, { createContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

export const AuthContext = createContext({
  token: null,
  role: null,
  isAuthenticated: false,
  authenticate: () => {},
  roleSetter: () => {},
  clientListTrigger: null,
  updateTrigger: () => {},
  logout: () => {},
});

function AuthContextProvider({ children }) {
  const [authToken, setauthToken] = useState(null);
  const [clientListTrigger, setClientListTrigger] = useState(0);
  const [getTokenStatus, setGetTokenStatus] = useState(0);
  const [whatRole, setRole] = useState('');


  useEffect(() => {
    getTokenStatus === 0 && authToken === null && getOldToken();
  }, [getTokenStatus, authToken]);


  const getOldToken = async () => {
    setGetTokenStatus(1);
    
    let oldToken = await secureLocalStorage.getItem("token");
    if (oldToken !== "undefined" && oldToken !== null) {
      setauthToken(oldToken);
    }
    
    setGetTokenStatus(0);
  };


  async function authenticate(token) {
    await secureLocalStorage.setItem("token", token);
    setauthToken(token);
  }

  async function roleSetter(role) {
    await secureLocalStorage.setItem("role", role);
    setRole(role);
  }


  const updateTrigger = () => {
    setClientListTrigger(prevTrigger => prevTrigger+1);
  }


  async function logout() {
    await secureLocalStorage.removeItem("token");
    await secureLocalStorage.setItem("role");
    setauthToken(null);
    setRole(null);
  }


  const value = {
    token: authToken,
    role: whatRole,
    isAuthenticated: !!authToken,
    authenticate: authenticate,
    roleSetter: roleSetter,
    clientListTrigger: clientListTrigger,
    updateTrigger: updateTrigger,
    logout: logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
