import React, {useState, useContext} from "react";
// import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useTheme, Box, IconButton, InputBase, Typography, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../store/auth-context";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useProSidebar } from "react-pro-sidebar";
import ClientFormModal from "../../components/ClientFormModal";
import secureLocalStorage from "react-secure-storage";


const Topbar = (props) => {
  const authCtx = useContext(AuthContext);
  const role = secureLocalStorage.getItem("role");
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const [openClientModal, setOpenClientModal] = useState(false);


  return (
    <Box display="flex" justifyContent="space-between" p={2} backgroundColor={colors.importantAccent[100]}>
      <Box display="flex">
        {broken && !rtl && (
          <IconButton sx={{ margin: "0 6 0 2" }} onClick={() => toggleSidebar()}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
        
        {(props.name==="PartnerList" || props.name==="ManagerList" || props.name==="StaffList" || props.name==="ClientList" )&&
          <Button variant="contained" style={{backgroundColor: colors.importantAccent[300]}} onClick={() => {setOpenClientModal(true)}}>
            <Typography variant="h6" color={'#fff'} fontSize={"16px"}>{props.message}</Typography>
            <AddCircleOutlineIcon style={{color: '#fff', padding: '0 0 0 5px'}} />
          </Button>
        }

      </Box>
      

      {/* Modal Component.. */}

      <ClientFormModal
        openClientModal={openClientModal}
        setOpenClientModal={(val) => setOpenClientModal(val)}
        createMesssage={props.message}
        id={props.id}
      />
    </Box>
  );
};

export default Topbar;
