import React from 'react';


const EditableTaxRateHeader = ({editable, handleFormInput, inputName, inputLink, inputs}) => {
  return (
    <>
        {editable?
            <>
                <input
                    name={inputName}
                    placeholder='Heading'
                    style={{padding: '0 5px', border: '1px solid #000', outline: 'auto', margin: '0 5px'}}
                    value={inputs[inputName]}
                    onChange={(e) => {handleFormInput(e)}}
                />
                <input
                    name={inputLink}
                    placeholder='Link'
                    style={{padding: '0 5px', border: '1px solid #000', outline: 'auto', margin: '0 5px'}}
                    value={inputs[inputLink]}
                    onChange={(e) => {handleFormInput(e)}}
                />

                
            </>
            :
            <>
                {inputs[inputLink].length>0? 
                    <a href={inputs[inputLink]} target="_blank">{inputs[inputName]}</a>
                    :
                    <span>{inputs[inputName]}</span>
                }
            </>
        }
    </>
  )
}

export default EditableTaxRateHeader;