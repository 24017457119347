// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState, useContext, useEffect } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";
import secureLocalStorage from "react-secure-storage";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import ConfirmLogoutDialog from "../../../components/ConfirmLogoutDialog";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PercentIcon from '@mui/icons-material/Percent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import LogoutIcon from '@mui/icons-material/Logout';

import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import { color } from "@mui/system";
import { AuthContext } from "../../../store/auth-context";

const Item = ({ title, navigateTo, icon, selected, setSelected, openLogoutDialog, setOpenLogoutDialog}) => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  
  const navigateFunc = () => {
    if(navigateTo==='/'){
      setOpenLogoutDialog(true);

    }else{
      navigate(navigateTo);
    }
  };

  return (
    <MenuItem
      active={selected === title}
      onClick={() => {navigateFunc(); setSelected(title); }}
      icon={icon}
    >
      <Typography style={{fontSize: '15px'}}>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const authCtx = useContext(AuthContext);
  const role = secureLocalStorage.getItem('role')
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Client List");
  const [user, setUser] = useState('');
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

  useEffect(() => {
    if(location.pathname.includes("client-profile")){
      setSelected("Client Profile");
    }
    if(location.pathname.includes("/partner-list")){
      setSelected("Partner List");
    }
    if(location.pathname.includes("/manager-list")){
      setSelected("Manager List");
    }
    if(location.pathname.includes("/staff-list")){
      setSelected("Staff List");
    }
    if(location.pathname.includes("/client-list")){
      setSelected("Client List");
    }
    if(location.pathname === "/support"){
      setSelected("Support");
    }
    if(location.pathname==="/tax-rates"){
      setSelected("Tax Rates");
    }
    if(location.pathname==="/table-formulas"){
      setSelected("Table Formulas");
    }

    const GetUserData = async() => {
      const authToken = await secureLocalStorage.getItem("token");
      const userToken = JSON.parse(authToken);
      if(userToken!==null && userToken!==undefined) {
        setUser(userToken.doc);
      }
    }

    GetUserData();

  }, [location, openLogoutDialog])
  
  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        backgroundColor: '#fff',
        zIndex: 1000,

        "& .sidebar": {
          border: "none",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `#009ec1 !important`,
          backgroundColor: "background: rgb(0 158 193 / 12%) !important",
        },
        "& .menu-item.active": {
          color: `#009ec1 !important`,
          backgroundColor: `${colors.importantAccent[200]} !important`,
        },

      }}
    >
      <Sidebar breakPoint="md" rtl={sidebarRTL} backgroundColor={'#fff'} className="side-bar-shadow" image={sidebarImage}>
        <Menu iconshape="square">
          
          <MenuItem icon={collapsed && (<MenuOutlinedIcon onClick={() => collapseSidebar()} />)}
            style={{margin: "10px 0 20px 0", color: colors.grey[100],}}>
            
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img alt="Titan Partners" src={require('../../../assets/logo.png')} style={{width: '100px', height: 'auto', verticalAlign: 'middle'}} />
                
                <IconButton onClick={broken ? () => toggleSidebar() : () => collapseSidebar()}>
                  <KeyboardDoubleArrowLeftIcon style={{fontSize: "22px", color: colors.importantAccent[300]}} />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          

          <Box paddingLeft={collapsed ? undefined : "0"}>
            {role==="admin"&&
              <>
                <Item
                  title="Partner List"
                  navigateTo="/partner-list"
                  icon={<PersonOutlineIcon style={{fontSize: '24px'}} />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Tax Rates"
                  navigateTo="/tax-rates"
                  icon={<TrendingUpIcon style={{fontSize: '24px'}} />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Table Formulas"
                  navigateTo="/table-formulas"
                  icon={<PercentIcon style={{fontSize: '24px'}} />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            }

            {role==="partner"&&
              <Item
                title="Manager List"
                navigateTo={`/manager-list/${user._id}`}
                icon={<PersonOutlineIcon style={{fontSize: '24px'}} />}
                selected={selected}
                setSelected={setSelected}
              />
            }

            {role==="manager"&&
              <Item
                title="Staff List"
                navigateTo={`/staff-list/${user._id}`}
                icon={<PersonOutlineIcon style={{fontSize: '24px'}} />}
                selected={selected}
                setSelected={setSelected}
              />
            }

            {role==="staff"&&
              <Item
                title="Client List"
                navigateTo={`/client-list/${user._id}`}
                icon={<PersonOutlineIcon style={{fontSize: '24px'}} />}
                selected={selected}
                setSelected={setSelected}
              />
            }

            {role==="user"&&
              <Item
                title="Client Profile"
                navigateTo={`/client-profile/${user._id}`}
                icon={<AssignmentIndIcon style={{fontSize: '24px'}} />}
                selected={selected}
                setSelected={setSelected}
              />
            }

            <Item
              title="Support"
              navigateTo="/support"
              icon={<HelpOutlineOutlinedIcon style={{fontSize: '24px'}} />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Logout"
              navigateTo="/"
              icon={<LogoutIcon style={{fontSize: '24px'}} />}
              selected={selected}
              setSelected={setSelected}
              openLogoutDialog={openLogoutDialog}
              setOpenLogoutDialog={(val) => setOpenLogoutDialog(val)}
            />

            

            {/* Logout Dialog */}
            <ConfirmLogoutDialog
              openLogoutDialog={openLogoutDialog}
              setOpenLogoutDialog={(val) => setOpenLogoutDialog(val)}
            />

          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
