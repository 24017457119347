import React from 'react';
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Topbar from "./pages/global/Topbar";

// My Files..
import ClientList from "./pages/AllList/ClientList";
import PartnerList from "./pages/AllList/PartnerList";
import ManagerList from "./pages/AllList/ManagerList";
import StaffList from "./pages/AllList/StaffList";
import ClientProfile from "./pages/ClientProfile/ClientProfile";
import LoginPage from "./pages/LoginPage/LoginPage";
import TaxRate from './pages/TaxRate/TaxRate';
import TableFormulas from './pages/TableFormulas/TableFormulas';
import Support from './pages/Support/Support';

const App = () => {
  const location = useLocation();
  const [theme, colorMode] = useMode();


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MyProSidebarProvider>
          <div style={{ height: "100%", width: "100%" }}>
            <main>
              {/* {location.pathname!=='/' && (!location.pathname.includes('/client-profile') && !location.pathname.includes('/tax-rates') && !location.pathname.includes('/table-formulas')) &&
                <Topbar />
              } */}
              <Routes>
                <Route path="/" element={<LoginPage/>} />
                <Route path="/partner-list" element={<PartnerList/>} />
                <Route path="/manager-list/:partnerId" element={<ManagerList/>} />
                <Route path="/staff-list/:managerId" element={<StaffList/>} />
                <Route path="/client-list/:staffId" element={<ClientList/>} />
                <Route path="/client-profile/:userId" element={<ClientProfile/>} />
                <Route path="/support" element={<Support />} />
                <Route path="/tax-rates" element={<TaxRate />} />
                <Route path="/table-formulas" element={<TableFormulas />} />

                <Route path="*" element={<Navigate to="/" replace />} /> 

              </Routes>
            </main>
          </div>
        </MyProSidebarProvider>
        <ToastContainer toastLimit={1} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
