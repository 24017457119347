
import { Config } from '../../util/config';




// Styles..
const styles = {
    borderThin: {
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
    borderAll: { 
        font: { bold: true },
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
    borderAllDouble: { 
        font: { bold: true },
        border: {
            top: { style: 'double', color: { argb: '000000' } }, 
            left: { style: 'double', color: { argb: '000000' } }, 
            bottom: { style: 'double', color: { argb: '000000' } }, 
            right: { style: 'double', color: { argb: '000000' } } 
        }
    },
}




// Function Convertin colIndex to Perticular Cell..
const getColumnLetter = (colIndex) => {
    let letter = '';
    while (colIndex > 0) {
      const remainder = colIndex % 26;
      letter = String.fromCharCode((remainder === 0 ? 26 : remainder) + 64) + letter;
      colIndex = Math.floor((colIndex - 1) / 26);
    }
    return letter;
}



const InsuranceFileFunc = (currentInsuranceData, type, index, prop) => {
    let myFile = currentInsuranceData[type][index][prop];

    if(typeof(myFile) === 'object' && myFile['filename'] !== undefined) {
        return {
            text: `File - ${myFile['originalname']}`,
            hyperlink: `${Config.ImageUrl}${myFile['filename']}`,
        }
    }
    return '';
}



export const InsurancePage = (workbook, data, clientData, clientYear) => {
    let insuranceSheet = workbook.getWorksheet('Insurance');

    insuranceSheet.getCell('B4').value = clientData.name;
    insuranceSheet.getCell('B6').value = clientYear;
    insuranceSheet.getCell('E5').value = clientData.staff;
    insuranceSheet.getCell('E6').value = clientData.manager;
    insuranceSheet.getCell('E7').value = clientData.partner;

    const currentInsuranceData = (data.insurance.length!==0)? data.insurance[0] : null;
    if(currentInsuranceData === undefined || currentInsuranceData === null){
        return;
    }

    insuranceSheet.getCell('B7').value = currentInsuranceData['preparedDate'];

    let finalTotalIndex = 0;
    
    let colIndex = 1;
    for(let i=0; i<currentInsuranceData['data'].length; i++){
        let nameDateLetter = getColumnLetter(colIndex);
        let filAmountLetter = getColumnLetter(colIndex+1);
        let rowStartingIndex = 10;

        insuranceSheet.mergeCells(9, colIndex, 9, colIndex+1);
        insuranceSheet.getCell(`${nameDateLetter}9`).value = `Insurance ${i+1}`;
        insuranceSheet.getRow(9).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        insuranceSheet.getCell(`${nameDateLetter}${rowStartingIndex}`).value = currentInsuranceData['data'][i]['name'];
        insuranceSheet.getCell(`${nameDateLetter}${rowStartingIndex}`).style = styles.borderAll;
        insuranceSheet.getCell(`${filAmountLetter}${rowStartingIndex}`).value = InsuranceFileFunc(currentInsuranceData, 'data', i, 'file');
        insuranceSheet.getCell(`${filAmountLetter}${rowStartingIndex}`).style = styles.borderAll;
        insuranceSheet.getRow(rowStartingIndex).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        rowStartingIndex += 1;

        for(let j=0; j<currentInsuranceData['data'][i]['data'].length; j++){
            let innerObj = currentInsuranceData['data'][i]['data'][j];
            insuranceSheet.getCell(`${nameDateLetter}${rowStartingIndex}`).value = innerObj['date'];
            insuranceSheet.getCell(`${nameDateLetter}${rowStartingIndex}`).style = styles.borderThin;
            insuranceSheet.getCell(`${filAmountLetter}${rowStartingIndex}`).value = innerObj['amount']!=='-'? Number(innerObj['amount']) : 0;
            insuranceSheet.getCell(`${filAmountLetter}${rowStartingIndex}`).style = styles.borderThin;
            insuranceSheet.getCell(`${filAmountLetter}${rowStartingIndex}`).numFmt = '$#,##0.00';
            insuranceSheet.getRow(rowStartingIndex).alignment = { vertical: 'middle', horizontal: 'right', wrapText: true };

            rowStartingIndex += 1;


        }
        
        
        // TotalSetting..
        insuranceSheet.getCell(`${nameDateLetter}${rowStartingIndex}`).value = 'Total';
        insuranceSheet.getCell(`${nameDateLetter}${rowStartingIndex}`).style = styles.borderAllDouble;
        insuranceSheet.getCell(`${filAmountLetter}${rowStartingIndex}`).value = currentInsuranceData['total'][`total${i+1}`]!=='-'? Number(currentInsuranceData['total'][`total${i+1}`]) : 0;
        insuranceSheet.getCell(`${filAmountLetter}${rowStartingIndex}`).style = styles.borderAllDouble;
        insuranceSheet.getCell(`${filAmountLetter}${rowStartingIndex}`).numFmt = '$#,##0.00';
        insuranceSheet.getRow(rowStartingIndex).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        
        
        
        if(colIndex === 1){
            finalTotalIndex = finalTotalIndex>(rowStartingIndex+5)? finalTotalIndex : rowStartingIndex+5;
        
            insuranceSheet.getCell(`A${finalTotalIndex}`).value = 'All Insunace Total';
            insuranceSheet.getCell(`A${finalTotalIndex}`).style = styles.borderAllDouble;
            insuranceSheet.getCell(`B${finalTotalIndex}`).value = currentInsuranceData['finalTotal']!=='-'? Number(currentInsuranceData['finalTotal']) : 0;
            insuranceSheet.getCell(`B${finalTotalIndex}`).style = styles.borderAllDouble;
            insuranceSheet.getCell(`B${finalTotalIndex}`).numFmt = '$#,##0.00';
            insuranceSheet.getRow(finalTotalIndex).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        }



        colIndex += 2;
        insuranceSheet.getColumn(colIndex).width = 5;
        colIndex += 1;


    }
}