import React, {useState, useEffect, useContext} from 'react';
import {Box, useTheme} from "@mui/material";
import './ClientProfile.css';
import { useNavigate, useParams } from 'react-router-dom';

import Header from "../../components/Header";
import { tokens } from "../../theme";
import secureLocalStorage from "react-secure-storage";
import ReactLoading from "react-loading";
import ClientUpdateModal from '../../components/ClientUpdateModal';
import PasswordUpdateModal from '../../components/PasswordUpdateModal';

import IssueReviewModal from './ClientProfileComps/IssueReviewModal';
import DeductionModal from './ClientProfileComps/DeductionModal';
import IncomeModal from './ClientProfileComps/IncomeModal';
import TaxEstimateModal from './ClientProfileComps/TaxEstimateModal';
import OtherSmallModal from './ClientProfileComps/OtherSmallModal';
import TaxRatesModal from './ClientProfileComps/TaxRatesModal';
import InsuranceModal from './ClientProfileComps/InsuranceModal';
import ChecksModal from './ClientProfileComps/ChecksModal';


import { GetPerticulerUserAPI } from '../../util/http';
import { AuthContext } from "../../store/auth-context";
import DetailBox from "../../components/DetailBox";
import TableBox from "../../components/TableBox";


const ClientProfile = () => {
	const navigate = useNavigate();
	const {userId} = useParams();
	const role = secureLocalStorage.getItem("role");
	const theme = useTheme();
    const colors = tokens(theme.palette.mode);
	const authCtx = useContext(AuthContext);

	const [openClientModal, setOpenClientModal] = useState(false);
	const [openPasswordModal, setOpenPasswordModal] = useState(false);

	const [loading, setLoading] = useState(false);

	const [modalOpen, setModalOpen] = useState(false);
	const [currentModal, setCurrentModal] = useState("");
	const [currentPage, setCurrentPage] = useState("");

	const [clientData, setClientData] = useState({
		age: "1",
		client: "Unknown",
		createdAt: "2023-08-20T20:06:22.891Z",
		email: "unknown@gmail.com",
		manager: "Unknown",
		managerId: "",
		name: "Unknown",
		office: "Unknown",
		partner: "Unknown",
		position: "Unknown",
		salary: "Unknown",
		staff: "Unknown",
		status: "active",
		updatedAt: "2023-08-20T20:06:22.891Z"
	});

	const[clientUpdateDate, setClientUpdateDate] = useState(new Date().toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' }));
	const[clientUpdateYear, setClientUpdateYear] = useState(new Date());


    useEffect(() => {
        const TokenChecker = async() => {
			setLoading(true);
            const userToken = await secureLocalStorage.getItem("token");
			const validRole = (role==="admin" || role==="partner" || role==="manager"  || role==="staff" || role==="user");

			setLoading(false);
            if(userToken===null || userToken===undefined ||  !validRole){
                navigate("/");
            }
        }
        TokenChecker();

    }, [navigate, role])



	useEffect(() => {
		const UserPersonalDetailFunc = async() => {
			const authToken = await secureLocalStorage.getItem("token");
			if(authToken === null || authToken ===undefined){
                return;
            }
			const userToken = JSON.parse(authToken);
			setLoading(true);
			const perticularUserRes = await GetPerticulerUserAPI(userId, userToken.token);

			if(perticularUserRes.success===true){
				setClientData(perticularUserRes.doc);
				const updatedDate = perticularUserRes.doc.updatedAt;
				setClientUpdateDate(new Date(updatedDate).toLocaleDateString('en-UK', { day: '2-digit', month: 'short', year: 'numeric' }))
			}

			setLoading(false);
		}

		UserPersonalDetailFunc();

	}, [authCtx.token, userId, authCtx.clientListTrigger])

	
	const issueReviewArr = ["Review Notes", "Interview notes", "Current year Queries", "Issues to be carried forward", "Issues brought forward", "ATO Reports", "Client Source Documents"];
	const deductionsArr = ["Motor Vehicle", "Travel", "Uniforms, Laundry etc", "Self Education", "Other Work Related", "Project Pool", "Interest", "Dividend", "Gifts or Donations", "Tax Affairs", "Personal Superannuation Contributions", "Other Deductions"];
	const incomeArr = ["Salary or Wages", "Allowances, Earnings, etc", "Employer Lump Sum Payments", "ETPs", "Government Allowances", "Government Allowances", "Government Pensions", "Other Pensions / Annuities", "Superannuation Lump Sum Payments", "Attributed PSI", "Gross Interest", "Dividends", "Employee Share Schemes", "Partnerships and Trusts", "PSI", "Business Income", "Capital Gains", "Foreign Source Income", "Rent", "Other Income"];
	const otherSmallArr= ["Private Health & Medicare", "Spouse & Family Benefits", "Tax Offsets", "Adjustments"];


	const ModalOpenerFunc = (modalName, page) => {
		setCurrentModal(modalName);
		setCurrentPage(page);
		setModalOpen(true);
	}

  	
	return (
		<>
			<Box p="20px" backgroundColor={colors.importantAccent[100]} style={{overflow: 'auto'}}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Client Profile" subtitle="Personal Details of the Client" />
                </Box>

				<Box
                    m="8px 0 0 0"
                    width="100%"
                    height="80vh"
                    sx={{}}
                >
					<div className="container">
						<div className="row">
							
							{/* Detail of The Client - File in Components */}
							<DetailBox 
								clientUpdateYear={clientUpdateYear}
								setClientUpdateYear={(val) =>  setClientUpdateYear(val)}
								clientUpdateDate={clientUpdateDate}
								setOpenClientModal={(val) => setOpenClientModal(val)}
								setOpenPasswordModal={(val) => setOpenPasswordModal(val)}
								clientData={clientData}
								setLoading={(val) => setLoading(val)}
							/>

							{/* Name of All the Tables - File in Components  */}
							<TableBox ModalOpenerFunc={ModalOpenerFunc} />

						</div>
					</div>

				</Box>
			</Box>


			{/* Update Profile Modal */}
			<ClientUpdateModal
				openClientModal={openClientModal}
				setOpenClientModal={(val) => setOpenClientModal(val)}
				clientData={clientData}
				updateMessage={'Update Client'}
			/>

			<PasswordUpdateModal 
				openPasswordModal={openPasswordModal}
				setOpenPasswordModal={(val) => setOpenPasswordModal(val)}
				clientData={clientData}
				updateMessage={'Update Client Password'}
			/>
		
			

			{/*Issues & Review Modal.. */}
			{(issueReviewArr.includes(currentModal)) &&
				<IssueReviewModal 
					modalOpen={modalOpen}
					setModalOpen={() => {setModalOpen()}}
					currentModal={currentModal}
					currentPage={currentPage}
					clientData={clientData}
					clientUpdateYear={clientUpdateYear}
				/>
			}

			{/* Deduction Modal.. */}
			{(deductionsArr.includes(currentModal)) &&
				<DeductionModal
					modalOpen={modalOpen}
					setModalOpen={() => {setModalOpen()}}
					currentModal={currentModal}
					currentPage={currentPage}
					clientData={clientData}
					clientUpdateYear={clientUpdateYear}
				/>
			}

			{/* Income Modal.. */}
			{(incomeArr.includes(currentModal)) &&
				<IncomeModal 
					modalOpen={modalOpen}
					setModalOpen={() => {setModalOpen()}}
					currentModal={currentModal}
					currentPage={currentPage}
					clientData={clientData}
					clientUpdateYear={clientUpdateYear}
				/>
			}

			{/* Private Health & Medicare Modal.. */}
			{(otherSmallArr.includes(currentModal)) &&
				<OtherSmallModal 
					modalOpen={modalOpen}
					setModalOpen={() => {setModalOpen()}}
					currentModal={currentModal}
					currentPage={currentPage}
					clientData={clientData}
					clientUpdateYear={clientUpdateYear}
				/>
			}
			

			{/*Reconcilation Modal.. */}
			{(currentModal==="Reconciliation to ITR check")&&
				<TaxEstimateModal 
					modalOpen={modalOpen}  
					setModalOpen={() => {setModalOpen()}}
					currentModal={currentModal}
					currentPage={currentPage}
					clientData={clientData}
					clientUpdateYear={clientUpdateYear}
				/>
			}
			
			{(currentModal==="Insurance")&&
				<InsuranceModal
					modalOpen={modalOpen}
					setModalOpen={() => {setModalOpen()}}
					currentModal={currentModal}
					currentPage={currentPage}
					clientData={clientData}
					clientUpdateYear={clientUpdateYear}
				/>
			}

			{(currentModal==="Checks")&&
				<ChecksModal
					modalOpen={modalOpen}
					setModalOpen={() => {setModalOpen()}}
					currentModal={currentModal}
				/>
			}



			{/* Tax Rates Modal.. */}
			{(currentModal==="Tax Rates")&&
				<TaxRatesModal 
					modalOpen={modalOpen}  
					setModalOpen={() => {setModalOpen()}} 
					clientUpdateYear={clientUpdateYear}
				/>
			}




			{/* Loading Screen */}
			{(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
		</>
  	)
}

export default ClientProfile;