import { Config } from '../../util/config';

const styles = {
    borderAll: {
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
}

export const CapitalGainsPage = (workbook, data, clientData, clientYear) => {
    let cgSheet = workbook.getWorksheet('18.1');

    cgSheet.getCell('C4').value = clientData.name;
    cgSheet.getCell('C6').value = clientYear;
    cgSheet.getCell('H5').value = clientData.staff;
    cgSheet.getCell('H6').value = clientData.manager;
    cgSheet.getCell('H7').value = clientData.partner;


    const currentCgData = (data.capital.length!==0)? data.capital[0] : null;
    if(currentCgData === undefined || currentCgData === null){
        return;
    }

    cgSheet.getCell('C7').value = currentCgData['preparedDate'];

    let startingIndex = 0;
    for( ; startingIndex<currentCgData['data'][0].length; startingIndex++) {
        if((11+startingIndex)>23){
            cgSheet.insertRow(11+startingIndex, [], 'i');
        }

        cgSheet.getCell(`B${11+startingIndex}`).value = currentCgData['data'][0][startingIndex]['company'];
        cgSheet.getCell(`C${11+startingIndex}`).value = currentCgData['data'][0][startingIndex]['dop'];
        cgSheet.getCell(`D${11+startingIndex}`).value = Number(currentCgData['data'][0][startingIndex]['purchaseQty']);
        cgSheet.getCell(`E${11+startingIndex}`).value = Number(currentCgData['data'][0][startingIndex]['purchaseUnit']);
        cgSheet.getCell(`F${11+startingIndex}`).value = Number(currentCgData['data'][0][startingIndex]['purchaseBrkage']);
        cgSheet.getCell(`G${11+startingIndex}`).value = Number(currentCgData['data'][0][startingIndex]['cost']);
        
        cgSheet.getCell(`H${11+startingIndex}`).value = currentCgData['data'][1][startingIndex]['dos'];
        cgSheet.getCell(`I${11+startingIndex}`).value = Number(currentCgData['data'][1][startingIndex]['saleQty']);
        cgSheet.getCell(`J${11+startingIndex}`).value = Number(currentCgData['data'][1][startingIndex]['saleUnit']);
        cgSheet.getCell(`K${11+startingIndex}`).value = Number(currentCgData['data'][1][startingIndex]['saleBrkage']);
        cgSheet.getCell(`L${11+startingIndex}`).value = Number(currentCgData['data'][1][startingIndex]['proceeds']);
        
        cgSheet.getCell(`M${11+startingIndex}`).value = Number(currentCgData['data'][2][startingIndex]['discountCG']);
        cgSheet.getCell(`N${11+startingIndex}`).value = Number(currentCgData['data'][2][startingIndex]['otherCG']);
        cgSheet.getCell(`O${11+startingIndex}`).value = Number(currentCgData['data'][2][startingIndex]['capitalLoss']);
    }

    let totalIndex = (11+startingIndex)>23?  startingIndex+12 : 25;
    cgSheet.getCell(`G${totalIndex}`).value = Number(currentCgData['total']['cost']);
    cgSheet.getCell(`L${totalIndex}`).value = Number(currentCgData['total']['proceeds']);
    cgSheet.getCell(`M${totalIndex}`).value = Number(currentCgData['total']['discountCG']);
    cgSheet.getCell(`N${totalIndex}`).value = Number(currentCgData['total']['otherCG']);
    cgSheet.getCell(`O${totalIndex}`).value = Number(currentCgData['total']['capitalLoss']);

    cgSheet.getCell(`M${totalIndex+2}`).value = Number(currentCgData['total']['discountCG']);
    cgSheet.getCell(`M${totalIndex+3}`).value = Number(currentCgData['total']['capitalLoss']);
    
    let totalCG = Number(currentCgData['total']['discountCG']) - Number(currentCgData['total']['capitalLoss']);
    cgSheet.getCell(`M${totalIndex+4}`).value = totalCG;
    cgSheet.getCell(`M${totalIndex+5}`).value = totalCG/2;

    
    
    // Assigning Files..
    let fileIndex = totalIndex + 10;
    if(currentCgData['attachment'].length>0){
        cgSheet.getCell(`B${fileIndex}`).value = 'FILES:';
        cgSheet.getCell(`B${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
    }
    for(let j=0; j<currentCgData['attachment'].length; j++){
        cgSheet.getCell(`B${fileIndex+1+j}`).value = { 
            text: currentCgData['attachment'][j]['originalname'],
            hyperlink: `${Config.ImageUrl}${currentCgData['attachment'][j]['filename']}`, 
        };
        cgSheet.getCell(`B${fileIndex+1+j}`).font = {color: { argb: '3366cc' }}
    }


    // Assigning Links..
    fileIndex = totalIndex + 10;
    if(currentCgData['link'].length>0){
        cgSheet.getCell(`L${fileIndex}`).value = 'LINKS:';
        cgSheet.getCell(`L${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
    }
    let linkFilesLength = 0;
    for(let j=0; j<currentCgData['link'].length; j++) {
        cgSheet.getCell(`L${fileIndex+1+(j+linkFilesLength)}`).value = {
            text: currentCgData['link'][j]['link'],
            hyperlink: currentCgData['link'][j]['link'],
        }
        cgSheet.getCell(`L${fileIndex+1+(j+linkFilesLength)}`).font = {color: { argb: '3366cc' }}
        
        for(let k=0; k<currentCgData['link'][j]['attachments'].length; k++) {
            cgSheet.getCell(`M${fileIndex+2+(j+linkFilesLength)+k}`).value = { 
                text: currentCgData['link'][j]['attachments'][k]['originalname'],
                hyperlink: `${Config.ImageUrl}${currentCgData['link'][j]['attachments'][k]['filename']}`, 
            };
            cgSheet.getCell(`M${fileIndex+2+(j+linkFilesLength)+k}`).font = {color: { argb: '3366cc' }}
        }
        linkFilesLength += currentCgData['link'][j]['attachments'].length;
        linkFilesLength += 1;
    }

};