export const incomeData = [
    {
        title: 'Copy of PAYG Payment Summary - Individual',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >This should provide details of Gross Wages, Allowances, Reportable Fringe Benefits, Certain Lump Sum Payments.</span>`,
            }
        ],
    },
    {
        title: 'Eligible Termination Payments (ETP)',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >If an ETP has been received from an employer or Superannuation Fund an ETP Payment Summary should be provided.</span>`,
            }
        ],
    },
    {
        title: 'Government Pensions/Allowances',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >If Government Pensions or Allowances are received a payment summary should be provided.</span>`
            }
        ],
    },
    {
        title: 'Gross Interest',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Interest earned on bank accounts, loans to other entities, term deposits is assessable in the year it is earned.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Please provide interest details earned on all sources.</span>`
            }
        ],
    },
    {
        title: 'Dividends',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Dividends received on shares individually held are assessable in the year they are paid (received as cash or via dividend reinvestment). Franked dividends provide for a tax credit.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Please provide all dividend statements.</span>`
            },
        ],
    },
    {
        title: 'Trust & Partnership Distributions',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Distributions are assessable in the year they are received. For public unit trust distributions please provide copy of annual taxation summary.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Note distributions from discretionary trusts (eg family trusts) are assessable in year declared by trust which should be paid.</span>`
            }
        ],
    },
    {
        title: 'Business Income',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >If running a business as a sole trader please supply details of all income and expenditure.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >If earning, or foresee will earn over $50,000 in financial year you must have an ABN and be registered for GST Please supply ABN details and copies of BAS's.</span>`
            }
        ],
    },
    {
        title: 'Capital Gains',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >If an asset has been sold during the year there may be an assessable capital gain.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Please provide details of purchase date and price, item sold and amount (if selling shares), and sale proceeds. Please note that expenditure on the purchase and sale of capital assets such as stamp duty, legal fees etc do offset the gain.</span>`
            }
        ],
    },
    {
        title: 'Foreign Source Income',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >As an Australian Resident for tax purposes all income earned overseas is assessable in Australia. A tax credit is generally granted for tax paid overseas.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Please provide details of all foreign sourced income i.e, Salary & Wages, Interest, Dividends, Capital Gains etc.</span>`
            }
        ],
    },
    {
        title: 'Rent',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Rent received on properties is assessable in the year it is received. Outgoings are deductible against this income. Such outgoings include interest on loan, rates, repairs & maintenance, agent fees etc.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Please provide agent statement if available.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Please note there is a distinction between improvements which are treated as capital and are depreciated, and repairs which can be expensed.  Please identify the nature of such outgoings.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Also note capital gains tax is generally assessed on profits made on disposal of rental properties.</span>`
            },
        ],
    },

];


export const deductionData = [
    {
        title: 'Motor Vehicle Expenditure',
        isTitleAnchor: true,
        anchor: 'https://www.ato.gov.au/Individuals/Income-and-deductions/Deductions-you-can-claim/Transport-and-travel-expenses/Motor-vehicle-and-car-expenses/',
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Motor vehicle expenditure is not deductible for travel. from home to work and vice versa. It is deductible from work to other places of work.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >There are 4 methods available to claim as a deduction.</span>`
            },
            {
                data: `<div style="padding-left: 30px;">
                    <ol>
                        <li>Log book - business per centage</li>
                        <li>Set rate per kilometre</li>
                        <li>12.5% of cost value of vehicle</li>
                        <li>1/3 of actual expenditure</li>
                    </ol>
                </div>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >You may choose the best option for you. Note that the log book method requires the maintenance of a log book for 12 consecutive weeks.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Pleaes provide purchase and make & model. details of the vehicle.</span>`
            },
        ],
    },
    {
        title: 'Travel',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Work related travel is deductible.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >A travel diary should be maintained detailing. period of travel, daily log of business activities and costs.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >A per diem deduction may also be available for shorter travel periods based on ATO reasonable expenditure rates.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Airfares etc will be apportioned based on work related portion of travel.</span>`
            },
        ],
    },
    {
        title: 'Uniforms & Laundry',
        isTitleAnchor: true,
        anchor: 'https://www.ato.gov.au/Individuals/Income-and-deductions/Deductions-you-can-claim/Clothing,-laundry-and-dry-cleaning-expenses/',
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >$150 deduction available for excessive laundry.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Most work clothing not deductible except if considered protective or compulsory for specific industry.</span>`
            },
        ],
    },
    {
        title: 'Self Education',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Study may be deductible if connection between study and income is valid.</span>`
            },
        ],
    },
    {
        title: 'Interest & Dividends',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Interest on loans taken to purchase shares is deductible on the grounds that the intention of the outgoing is derive income being dividends. Interest may also be deductible on certain investments where the ATO has given permission.</span>`
            },
        ],
    },
    {
        title: 'Gifts & Donations',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Donations to registered benevolent institutions of over $2 are tax deductible.</span>`
            },
        ],
    },
    {
        title: 'Tax Agent Fees',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Payments to a registered tax agent for the preparation of income tax returns are deductible when paid.</span>`
            },
        ],
    },
    {
        title: 'Other Work Related Deductions',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >The general principle is that if the outgoing is incurred in the generation of assessable income this outgoing is allowed as a deduction against this assessable income thereby reducing the amount of income by which the tax is assessed.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Some general deductions may include;</span>`
            },
            {
                data: `<div style="padding-left: 30px;">
                    <ul style="list-style-type: disc;">
                        <li>Printing, stationery, & equipment</li>
                        <li>Computer expenditure</li>
                        <li>Home Office running costs eg electricity (usually calculated at 26c per hour of work performed at home)</li>
                        <li>Home Office where Renting (the proporition of floor space dedicated to home office multiplied by the rent and other costs)</li>
                        <li>Telephone - Mobile and portion of home</li>
                        <li>Subscriptions to professional or industry bodies</li>
                        <li>Subscriptions to industry related magazines & publications</li>
                        <li>Income Protection Insurance</li>
                        <li>Depreciation on work related equipment (eg study table, computer, bookcase etc)</li>
                        
                    </ul>
                </div>`
            },
        ],
    },
];


export const otherData = [
    {
        title: 'Private Health Insurance',
        isTitleAnchor: true,
        anchor: 'https://www.ato.gov.au/Individuals/Medicare-and-private-health-insurance/Medicare-levy-surcharge/',
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Medicare Levy Surcharge is payable at 1% for singles earning over $90,000 per year or families earning over $180,000 plus $1,500 per child after the first one per year who do not have adequate private health insurance.</span>`
            },
            {
                data: `<div>
                    <span style="font-size: 14px;" >Please supply details of private health insurance cover</span>
                    <span style="font-size: 14px; color: #009ec1;" >&nbsp;&nbsp;&nbsp;(set to increase to $100,000)</span>
                </div>`
            },
        ],
    },
    {
        title: `Children's details`,
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Please provide children's names and birth dates. Family Tax Benefits may be available, as well as other family based benefit schemes.</span>`
            },
        ],
    },
    {
        title: 'Non Employer Sponsored Superannuation Contributions',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >These may be deductible against assessable income</span>`
            },
        ],
    },
    {
        title: 'Spouse Details',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >If spouse is not working or has minimal income please provide details of name, date of birth, tax file number and income earned as rebates may be available. </span>`
            },
        ],
    },
    {
        title: 'Medical Expenditure',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >A rebate may be available for medical expenditure net of medicare/private health insurance reimbursements.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >The net amount must exceed $1,500.</span>`
            },
        ],
    },
    {
        title: 'ATO Prefilling Report',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Double-check ATO Prefilling Report for each taxpayer by logging onto Portal.</span>`
            },
            {
                data: `<span style="font-size: 14px; display: block;" >Note: Interest & Dividend Income often found here</span>`
            },
        ],
    },
    {
        title: 'Div 7A',
        isTitleAnchor: false,
        desc: [
            {
                data: `<span style="font-size: 14px; display: block;" >Check other related entities to ensure interest has been taken up if there is an inter-entity loan</span>`
            },
        ],
    },
]