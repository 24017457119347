
import { Config } from '../../util/config';

const styles = {
    borderAll: {
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
}

export const IssueReviewPage = (workbook, data, clientData, clientYear) => {
    let issueReviewList = [{sheet: 'A', type: 'Review_Notes'}, {sheet: 'C', type: 'Current_year_Queries'}, {sheet: 'B', type: 'Interview_notes'}, {sheet: 'D', type: 'Issues_to_be_carried_forward'}, {sheet: 'E', type: 'Issues_brought_forward'}, {sheet: 'Client Source Docs', type: 'Client_Source_Documents'}];
    
    for(let i=0; i<issueReviewList.length; i++) {
        let issueSheet = workbook.getWorksheet(issueReviewList[i]['sheet']);
        issueSheet.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffffffff' }};

        if(issueReviewList[i]['sheet'] === 'A' || issueReviewList[i]['sheet'] === 'C'){
            issueSheet.getCell('B4').value = clientData.name;
            issueSheet.getCell('B6').value = clientYear;
        }else{
            issueSheet.getCell('C4').value = clientData.name;
            issueSheet.getCell('C6').value = clientYear;
            issueSheet.getCell('H5').value = clientData.staff;
            issueSheet.getCell('H6').value = clientData.manager;
            issueSheet.getCell('H7').value = clientData.partner;
        }


        let currentIssueData = data.reviewNotes.find(item =>  (item.type === issueReviewList[i]['type']));
        
        if(currentIssueData === undefined || currentIssueData === null) {
            currentIssueData =  data.interViewNotes.find(item =>  (item.type === issueReviewList[i]['type']));
            if(currentIssueData === undefined || currentIssueData === null){
                continue;
            }
        }


        if(currentIssueData['type'] === 'Review_Notes' || currentIssueData['type'] === 'Current_year_Queries'){
            issueSheet.getCell('B8').value = currentIssueData['preparedBy'];
            issueSheet.getCell('B9').value = currentIssueData['preparedDate'];
            issueSheet.getCell('E8').value = currentIssueData['reviewBy'];
            issueSheet.getCell('E9').value = currentIssueData['reviewDate'];

        }else{
            issueSheet.getCell('C7').value = currentIssueData['preparedDate'];
        }


        let startingIndex = 0;
        for(; startingIndex<currentIssueData['data'].length; startingIndex++){
            if(currentIssueData['type'] === 'Review_Notes' || currentIssueData['type'] === 'Current_year_Queries'){
                if((13+startingIndex)>21){
                    issueSheet.insertRow(13+startingIndex, [], 'i');
                    issueSheet.mergeCells(startingIndex+13, 4, startingIndex+13, 5);
                }
                issueSheet.getCell(`B${13+startingIndex}`).value = currentIssueData['data'][startingIndex]['query'];
                issueSheet.getCell(`D${13+startingIndex}`).value = currentIssueData['data'][startingIndex]['response'];
                issueSheet.getRow(13+startingIndex).height = 50;
                issueSheet.getCell(`A${13+startingIndex}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc0c0c0' }};
                issueSheet.getCell(`F${13+startingIndex}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc0c0c0' }};
            }else{
                if((10+startingIndex)>35){
                    issueSheet.insertRow(10+startingIndex, [], 'i');
                }
                issueSheet.mergeCells(10+startingIndex, 1, 10+startingIndex, 5);
                issueSheet.getCell(`A${10+startingIndex}`).value = currentIssueData['data'][startingIndex]['name'];
                issueSheet.getCell(`F${10+startingIndex}`).value = currentIssueData['data'][startingIndex]['ref'];
                issueSheet.getCell(`F${10+startingIndex}`).style = styles.borderAll;
                issueSheet.getCell(`G${10+startingIndex}`).value = currentIssueData['data'][startingIndex]['total']!=='-'? Number(currentIssueData['data'][startingIndex]['total']) : 0;
                issueSheet.getCell(`G${10+startingIndex}`).style = styles.borderAll;
                issueSheet.getCell(`H${10+startingIndex}`).value = currentIssueData['data'][startingIndex]['clientShare']!=='-'? Number(currentIssueData['data'][startingIndex]['clientShare']) : 0;
                issueSheet.getCell(`H${10+startingIndex}`).style = styles.borderAll;

                // Adjusting Cell Height of Name section in Table..
                issueSheet.getRow(10+startingIndex).height = currentIssueData['data'][startingIndex]['name'].length>50?
                (currentIssueData['data'][startingIndex]['name'].length/50)*15
                : 
                15;
            }
        }

        let totalIndex = (10+startingIndex)>35?  startingIndex+14 : 40;
        if(currentIssueData['type'] !== 'Review_Notes' && currentIssueData['type'] !== 'Current_year_Queries'){
            issueSheet.mergeCells(totalIndex, 1, totalIndex, 5);
            issueSheet.getCell(`G${totalIndex}`).value = Number(currentIssueData['total']);
            issueSheet.getCell(`G${totalIndex}`).numFmt = '$#,##0.00';
            issueSheet.getCell(`H${totalIndex}`).value = Number(currentIssueData['clientShare']);
            issueSheet.getCell(`H${totalIndex}`).numFmt = '$#,##0.00';
        }


        // Assigning Files..
        if(currentIssueData['attachment'].length>0){
            issueSheet.getCell(`J9`).value = 'FILES:';
            issueSheet.getCell('J9').font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
        }
        let fileIndex = 10;
        for(let j=0; j<currentIssueData['attachment'].length; j++){
            issueSheet.getCell(`J${10+j}`).value = { 
                text: currentIssueData['attachment'][j]['originalname'],
                hyperlink: `${Config.ImageUrl}${currentIssueData['attachment'][j]['filename']}`, 
            };
            issueSheet.getCell(`J${10+j}`).font = {color: { argb: '3366cc' }}
            fileIndex += j;
        }
        fileIndex += 5;

        // Assigning Links..
        if(currentIssueData['link'].length>0){
            issueSheet.getCell(`J${fileIndex}`).value = 'LINKS:';
            issueSheet.getCell(`J${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
        }
        let linkFilesLength = 0;
        for(let j=0; j<currentIssueData['link'].length; j++) {
            issueSheet.getCell(`J${fileIndex+1+(j+linkFilesLength)}`).value = {
                text: currentIssueData['link'][j]['link'],
                hyperlink: currentIssueData['link'][j]['link'],
            }
            issueSheet.getCell(`J${fileIndex+1+(j+linkFilesLength)}`).font = {color: { argb: '3366cc' }}
            
            for(let k=0; k<currentIssueData['link'][j]['attachments'].length; k++) {
                issueSheet.getCell(`K${fileIndex+2+(j+linkFilesLength)+k}`).value = { 
                    text: currentIssueData['link'][j]['attachments'][k]['originalname'],
                    hyperlink: `${Config.ImageUrl}${currentIssueData['link'][j]['attachments'][k]['filename']}`, 
                };
                issueSheet.getCell(`K${fileIndex+2+(j+linkFilesLength)+k}`).font = {color: { argb: '3366cc' }}
            }
            linkFilesLength += currentIssueData['link'][j]['attachments'].length;
            linkFilesLength += 1;
        }

    }
    
}
