import React, {useState, useEffect, useContext} from 'react';
import { Box, Button } from "@mui/material";
import ReactLoading from "react-loading";
import { AuthContext } from "../../../../store/auth-context";
import { CreateFormulaAPI, GetFormulasAPI, UpdateFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

const CapitalGainsFormula = () => {
    const authCtx = useContext(AuthContext);
    const currentYear = new Date().getFullYear();
    const [formulas, setFormulas] = useState({
        cost: 'purchaseQty * purchaseUnit + purchaseBrkage',
        proceeds: 'saleQty * saleUnit - saleBrkage',
        capitalLoss: 'cost - proceeds',
        discountCG: 'proceeds - cost + capitalLoss',
        otherCG: 'proceeds - cost + capitalLoss',
        finalDiscountedCG: '(discountCG - capitalLoss)/2',
    });
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);

    useEffect(() => {
        const GetFormulaFunc = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);

            setLoading(true);

            const getRes = await GetFormulasAPI("capitalGains", currentYear, userToken.token);
            if(getRes.success === true){
                if(getRes.data === null || getRes.data === undefined){
                    setDataAlready(false);
                }else {
                    setDataAlready(true);
                    setFormulas(getRes.data.formulas);
                }
            }

            setLoading(false)
        }

        GetFormulaFunc();
    }, [isDataAlready])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormulas((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            year: currentYear,
            type: "capitalGains",
            formulas: formulas,
        };

        
        const submitRes = isDataAlready?
        await UpdateFormulasAPI(sendData, "capitalGains", currentYear, userToken.token)
        :
        await CreateFormulaAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
          
        setLoading(false);
    }

    return (
        <>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row',  overflowX: "auto", justifyContent: "unset", alignItems: 'baseline', margin: '10px', marginTop: 50}} >
                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading"  style={{backgroundColor: '#339966'}} >
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>company</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>dop</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>purchaseQty</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>purchaseUnit</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>purchaseBrkage</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>cost</th>
                    </tr>

                    <tr className="data-table-heading"  >
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>Google</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>13-09-2023</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>10</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>120</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>20</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="cost"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.cost}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        
                    </tr>
                </table>

                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading" style={{backgroundColor: '#ff9900'}} >
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>dos</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>saleQty</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>saleUnit</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#fff'}}>saleBrkage</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>proceeds</th>
                    </tr>

                    <tr className="data-table-heading" >
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>14-09-2023</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>5</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>150</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>30</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="proceeds"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.proceeds}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                </table>
                
                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading" style={{backgroundColor: '#0000ff'}} >
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>discountCG</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>otherCG</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#fff'}}>capitalLoss</th>
                    </tr>
                    <tr className="data-table-heading" >
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="discountCG"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.discountCG}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="otherCG"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.otherCG}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="capitalLoss"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.capitalLoss}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                </table>

            </div>


            <div style={{margin: '10px'}} >
                <table className="calculation-table" >
                    <tr style={{border: '3px solid #000'}}>
                        <td className="th-name" style={{padding: '5px'}}>Discounted CG</td>
                        <td className="calculation-table-cell  data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="finalDiscountedCG"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.finalDiscountedCG}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            
            <Box mt="20px" ml="10px">
                <Button 
                    type="submit" 
                    variant="contained" 
                    style={{color: '#fff', backgroundColor: '#009ec1', marginRight: '20px'}} 
                    onClick={() => {SubmitHandler()}}
                >
                    Submit
                </Button>
            </Box>

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>
    );
}

export default CapitalGainsFormula;