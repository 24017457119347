import React, {useState, useContext} from 'react';
import './LinkAttachFile.css';

import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { toast } from "react-toastify";
import {UploadImageAPI, DeleteImageAPI} from '../util/http';


// import { Modal  } from 'rsuite';
// import 'rsuite/dist/rsuite.min.css';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { AuthContext } from '../store/auth-context';
import { Config } from '../util/config';


const LinkAttachFile = ({links, setLinks, linkAttachable, setLinkAttachable, setDocsChange, setLoading}) => {
    const authCtx = useContext(AuthContext);
    const [linkInput, setLinkInput] = useState("");
    const [linkInputError, setLinkInputError] = useState(false);
    const [currentId, setCurrentId] = useState("")

    // const [openModal, setOpenModal] = useState(false);
    // const [openFile, setOpenFile] = useState([]);
    
    const LinkSubmit = () => {
        if(linkInput.length===0){
          setLinkInputError(true);
          return;
        }

        const newLink = {link: linkInput, attachments: []};
        setLinks(oldArray => [...oldArray, newLink]);
        setLinkAttachable(false);
        setLinkInput("");
    }


    const UploadFile = async(image, userToken) => {
        setLoading(true);
        const data = new FormData();
        data.append('file', image);  
        
        const uploadRes = await UploadImageAPI(data, userToken.token);
        if(uploadRes.success===true){
            toast.success(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });

            const newArray = links.map((item) => {
                if(item.link===currentId){
                    const previousAttach = item.attachments;
                    previousAttach.push(uploadRes.file);
                    return {...item, attachments: [...previousAttach]};
                }else{
                    return item;
                }
            })

            setLinks(newArray);
            setDocsChange(true);
        }else{
            toast.error(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }


    // File Picker Code....
    const onSelectLinkFile = (event) => {
        const files = event.target.files;
        const selectedFilesArray = Array.from(files);

        const userToken = JSON.parse(authCtx.token);
        selectedFilesArray.forEach((image) => {
            UploadFile(image, userToken);
        });
    }


    const CloseLinkInpuFunc = () => {
        setLinkAttachable(false);
    }


    const deletePerticularFile = async(fileName, itemLink) => {
        setLoading(true);
        const userToken = JSON.parse(authCtx.token)
        const deleteRes = await DeleteImageAPI(fileName, userToken.token);

        if(deleteRes.success===true){
            toast.success(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
            
            const currentList = links.filter((item) => {
                return (item.link === itemLink);
            })
            const remainingFile = currentList[0].attachments.filter((item) => {
                return(item.filename !== fileName);
            })
        
            const newArray = links.map((item) => {
                if(item.link===itemLink){
                    return {...item, attachments: remainingFile};
                }else{
                    return item;
                }
            })
            setLinks(newArray);
            setDocsChange(true);
        }else{
            toast.error(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }

    const openFileInNewTab = (file) => {
        window.open(`${Config.ImageUrl}${file.filename}`, '_blank');
    };

    return (
        <>
            {links &&
                <div>
                    {(linkAttachable)&&
                        <div className="input-button-contariner-parent">    
                            <div className="link-option-container">
                                <span>Link</span>
                                <input type="text" name="link-add" id="link-add" placeholder="Enter Link.." className='input-link'
                                    onChange={(e) => {
                                        setLinkInput(e.target.value);
                                        setLinkInputError(false)
                                    }}
                                    style={linkInputError ? {border: '1px solid #e01f3c'}:{border: '1px solid #000'}}
                                />
                            </div>
                            <IconButton size="small" className="link-submit" onClick={() => {LinkSubmit()}}>
                                <CheckIcon style={{fontSize: '30px', color: '#fff'}}  />
                            </IconButton>
                            <IconButton size="small" className="link-submit" onClick={() => {CloseLinkInpuFunc()}}>
                                <CloseIcon style={{fontSize: '30px', color: '#fff'}}  />
                            </IconButton>
                        </div>
                    }


                    {links && links.map((item, index) => {
                        return(
                            <div key={index}>
                                <div style={{display: 'flex'}} >
                                    <div  className="anchor-div" >
                                        Link: <a href={item.link} target="_blank" className='anchor' >{item.link.slice(0, 50)+ (item.link.length>50?'...':"")}</a>
                                        {"  "}<CloseIcon style={{fontSize: '16px', verticalAlign: 'middle', cursor: 'pointer' }} onClick={() => {setLinks(links.filter((e) => e !== item))}} />
                                    </div>
                                    <label 
                                        htmlFor="link-file-picker" 
                                        className='attach-link-file'
                                        onClick={() => {setCurrentId(item.link)}}
                                    >
                                        <AttachFileIcon style={{fontSize: '16px', verticalAlign: 'middle' }} /> Attach
                                    </label>                
                                </div>

                                <div className="file-picker-container" >
                        
                                    <input 
                                        type="file"
                                        name="link-file-picker"
                                        id="link-file-picker"
                                        onChange={(e) => {onSelectLinkFile(e);}} 
                                        onClick={(event)=> { 
                                            event.target.value = null
                                        }}
                                        multiple
                                        className='file-input'
                                        style={{display: 'none'}}
                                    />

                                    <div className="file-preview-container">
                                        {item.attachments && item.attachments.map((innerItem, fileIndex) => {
                                            return(
                                                <div key={fileIndex} className='file-file' onClick={() => { openFileInNewTab(innerItem)}} >
                                                {(innerItem.mimetype.startsWith('image/'))?
                                                    <img src={`${Config.ImageUrl}${innerItem.filename}`} height="200" alt="attached File" className='uploadable-img' />
                                                    :
                                                    (innerItem.mimetype==='application/pdf')?
                                                    <img src={require('../assets/icons/pdf-icon.png')} height="100%" alt="attached File" className='uploadable-file' />
                                                    :
                                                    (innerItem.mimetype==='application/vnd.ms-excel' || innerItem.mimetype==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || innerItem.mimetype==='text/csv')?
                                                    <img src={require('../assets/icons/spreadsheet-icon.png')} height="100%" alt="attached File" className='uploadable-file' />
                                                    :
                                                    <img src={require('../assets/icons/unknown-file-icon.png')} height="100%" alt="attached File" className='uploadable-file' />
                                                }
                                                    <button 
                                                        className="file-delete"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setCurrentId(item.link)
                                                            deletePerticularFile(innerItem.filename, item.link);
                                                        }}
                                                    >
                                                        <DeleteIcon style={{fontSize: '30px'}} />
                                                    </button>
                                                    <p className='file-name-size' style={{top: '5px'}} >{innerItem.filename}</p>
                                                    {(innerItem.size>=1048576)?
                                                        <p className='file-name-size' style={{bottom: '5px'}}>{(innerItem.size/1048576).toFixed(2)} MB</p>
                                                        :
                                                        <p className='file-name-size' style={{bottom: '5px'}}>{(innerItem.size/1024).toFixed(2)} KB</p>
                                                        
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                    

                                    {/* <Modal size="md" open={openModal} onClose={() => {setOpenModal(false)}}>
                                        <DocViewer
                                            pluginRenderers={DocViewerRenderers}
                                            documents={openFile}
                                            config={{
                                            header: {
                                                disableHeader: false,
                                                disableFileName: false,
                                                retainURLParams: false
                                            }
                                            }}
                                            style={{ height: 600 }}
                                        />
                                    </Modal> */}
                                </div>
                            </div>
                        );
                    })}

                </div>
            }
        </>
    );
}

export default LinkAttachFile;