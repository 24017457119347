
export const ITRPage = (workbook, data, clientData, clientYear) => {
    let itrSheet = workbook.getWorksheet('F');

    itrSheet.getCell('C4').value = clientData.name;
    itrSheet.getCell('C6').value = clientYear;
    itrSheet.getCell('H5').value = clientData.staff;
    itrSheet.getCell('H6').value = clientData.manager;
    itrSheet.getCell('H7').value = clientData.partner;
    itrSheet.getCell('H9').value = clientYear;

    const currentITRData = (data.taxEstimates.length!==0)? data.taxEstimates[0] : null;
    if(currentITRData === undefined || currentITRData === null){
        return;
    }

    itrSheet.getCell('C7').value = currentITRData['preparedDate'];

    // Income Section..
    let cellIncomeData = [
        {id: '1', cell: 'H11'}, {id: '2', cell: 'H12'}, {id: '3', cell: 'H13'}, {id: '4', cell: 'H14'}, {id: '5', cell: 'H15'}, {id: '6', cell: 'H16'},
        {id: '7', cell: 'H17'}, {id: '8', cell: 'H18'}, {id: '9', cell: 'H19'}, {id: '10', cell: 'H20'}, {id: '11', cell: 'H21'}, {id: '12', cell: 'H22'},
        {id: '13', cell: 'H23'}, {id: '14', cell: 'H24'}, {id: '15', cell: 'H25'}, {id: '18', cell: 'H26'}, {id: '20', cell: 'H27'}, {id: '21', cell: 'H28'}, {id: '24', cell: 'H29'},
    ]

    for(let i=0; i<currentITRData['income'].length; i++) {
        let currentCell = cellIncomeData.find(item => item.id === currentITRData['income'][i]['id']);
        if(currentCell === undefined || currentCell === null){
            continue;
        }

        itrSheet.getCell(`${currentCell.cell}`).value = Number(currentITRData['income'][i]['currentCost']);
    }

    
    // Deduct Section,,
    let cellDeductionData = [
        {id: 'D1', cell: 'H32'}, {id: 'D2', cell: 'H33'}, {id: 'D3', cell: 'H34'}, {id: 'D4', cell: 'H35'}, {id: 'D5', cell: 'H36'}, {id: 'D6', cell: 'H37'},
        {id: 'D7', cell: 'H38'}, {id: 'D8', cell: 'H39'}, {id: 'D9', cell: 'H40'}, {id: 'D10', cell: 'H41'}, {id: 'D12', cell: 'H42'}, {id: 'D15', cell: 'H43'},
    ]

    for(let i=0; i<currentITRData['deduction'].length; i++){
        let currentCell = cellDeductionData.find(item => item.id === currentITRData['deduction'][i]['id']);
        if(currentCell === undefined || currentCell === null){
            continue;
        }

        itrSheet.getCell(`${currentCell.cell}`).value = Number(currentITRData['deduction'][i]['currentCost']);
    }


    itrSheet.getCell('H46').value =  (currentITRData['taxableIncome']!==undefined && currentITRData['taxableIncome']!==null)? Number(currentITRData['taxableIncome']) : 0;
    itrSheet.getCell('H48').value =  (currentITRData['taxPayable']!==undefined && currentITRData['taxPayable']!==null)? Number(currentITRData['taxPayable']) : 0;
    

    // Less Offset Section..
    let cellLessOffsetData = [
        {name: 'Offsets (T1 to T15 except T2, T3, T7, T13, T14)', cell: 'H50'}, {name: 'Seniors / Pension / Beneficiary Offset', cell: 'H51'},
        {name: 'Mature Aged Workers Tax Offset', cell: 'H52'}, {name: 'Low Income Offset', cell: 'H53'}, {name: 'Low and middle Income Offset', cell: 'H54'},
        {name: 'Life Assurance Bonus', cell: 'H55'}, {name: 'Other Offsets', cell: 'H56'},
    ]
    for(let i=0; i<currentITRData['lessOffsets'].length; i++){
        let currentCell = cellLessOffsetData.find(item => item.name === currentITRData['lessOffsets'][i]['name']);
        if(currentCell === undefined || currentCell === null){
            continue;
        }

        itrSheet.getCell(`${currentCell.cell}`).value = currentITRData['lessOffsets'][i]['currentCost']!=='-'?Number(currentITRData['lessOffsets'][i]['currentCost']) : 0;
    }


    // Plus Section..
    itrSheet.getCell(`H58`).value = (currentITRData['plus'][0]!==undefined && currentITRData['plus'][0]!==null)? Number(currentITRData['plus'][0]['currentCost']) : 0;
    itrSheet.getCell(`H59`).value = (currentITRData['plus'][1]!==undefined && currentITRData['plus'][1]!==null)? Number(currentITRData['plus'][1]['currentCost']) : 0;
    itrSheet.getCell(`H60`).value = (currentITRData['plus'][2]!==undefined && currentITRData['plus'][2]!==null)? Number(currentITRData['plus'][2]['currentCost']) : 0;
    itrSheet.getCell(`E59`).value = (currentITRData['isPrivateHealth']!==undefined && currentITRData['isPrivateHealth']!==null)?  
    (currentITRData['isPrivateHealth']===true)? 'Yes' : 'No'
    : 
    'No';


    // Less Credit Section..
    let cellLessCreditData = [
        {name: 'Tax withheld - salary & wage type income', cell: 'H62'}, {name: 'Arrears tax withheld', cell: 'H63'}, {name: 'Foreign Tax Credits', cell: 'H64'},
        {name: 'TFN Amounts (credits)', cell: 'H65'}, {name: 'Franking Tax Offset (refundalble)', cell: 'H66'}, {name: 'Other Refundable Credits', cell: 'H67'}, 
        {name: 'Other Amounts withheld - ABN, Vol, Labour, Foreign', cell: 'H68'}, {name: 'Baby Bonus Offset (refundable)', cell: 'H69'}, {name: 'PAYG Income Tax Instalments', cell: 'H70'},  
    ]
    for(let i=0; i<currentITRData['lessCredits'].length; i++){
        let currentCell = cellLessCreditData.find(item => item.name === currentITRData['lessCredits'][i]['name']);
        if(currentCell === undefined || currentCell === null){
            continue;
        }

        itrSheet.getCell(`${currentCell.cell}`).value = currentITRData['lessCredits'][i]['currentCost']!=='-'? Number(currentITRData['lessCredits'][i]['currentCost']) : 0;
    }


    itrSheet.getCell('H72').value =  (currentITRData['estimatedTax']!==undefined && currentITRData['estimatedTax']!==null)? Number(currentITRData['estimatedTax']) : 0;    
    let isPositive = (currentITRData['estimatedTax']!==undefined && currentITRData['estimatedTax']!==null && Number(currentITRData['estimatedTax']) >=0)? true : false;
    if(!isPositive){
        itrSheet.getCell('I72').value =  'Refund';
        itrSheet.getCell('I72').fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'ff00ff00'}, bgColor:{argb:'ff00ff00'} };
    }else{
        itrSheet.getCell('I72').value =  'Payable';
        itrSheet.getCell('I72').fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'ffff0000'}, bgColor:{argb:'ffff0000'} };
    }

    itrSheet.getCell('H74').value =  (currentITRData['perItr']!==undefined && currentITRData['perItr']!==null)? Number(currentITRData['perItr']) : 0;    
    itrSheet.getCell('H75').value =  (currentITRData['difference']!==undefined && currentITRData['difference']!==null)? Number(currentITRData['difference']) : 0;    



}
