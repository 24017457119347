import React, {useState, useContext, useEffect, useLayoutEffect} from 'react';

import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { evaluate } from 'mathjs';
import { AuthContext } from "../../../../../store/auth-context";
import { toast } from "react-toastify";
import {UploadImageAPI, DeleteImageAPI} from '../../../../../util/http';
import { Config } from '../../../../../util/config';


const DepreciationonPropertyTable = ({depreciatePropsData, setDepreciatePropsData, clientUpdateYear, formulas, setDocsChange, setLoading, setErrorOpen}) =>  {
    const authCtx = useContext(AuthContext);
    const CURRENT_YEAR = clientUpdateYear.getFullYear();
    const [currentTable, setCurrentTable] = useState("");
    const [currentNestedIndex, seCurrentNestedIndex] = useState("");


    const EvaluateFunc = () => {
        let updatedData = depreciatePropsData;

        for(let i=0; i<updatedData.length; i++){
            for(let j=0; j<updatedData[i].length; j++){
                let scope = {
                    cost: updatedData[i][j].wdv_props[0].value,
                    effectiveLife: updatedData[i][j].wdv_props[1].value,
                    depreciationRate: updatedData[i][j].wdv_props[3].value,
                    totalDays: updatedData[i][j].wdv_props[2].value,
                    depreciation: updatedData[i][j].wdv_props[4].value,
                    wdv: updatedData[i][j].wdv_props[5].value,
                    owdv: updatedData[i][j].owdv_props!==undefined? updatedData[i][j].owdv_props[0].value : '',
                    depreciation_2: updatedData[i][j].owdv_props!==undefined? updatedData[i][j].owdv_props[2].value : '',
                    wdv_2: updatedData[i][j].owdv_props!==undefined? updatedData[i][j].owdv_props[3].value : '',
                }
                
                try {
                    let effLife =  parseFloat(updatedData[i][j].wdv_props[1].value);
    
                    updatedData[i][j].wdv_props[3].value = (effLife==0 || isNaN(effLife))? 0 : evaluate(formulas.depreciationRate, scope).toFixed(2)
                    scope.depreciationRate = updatedData[i][j].wdv_props[3].value;
    
                    updatedData[i][j].wdv_props[4].value = evaluate(formulas.depreciation, scope).toFixed(2);
                    scope.depreciation = updatedData[i][j].wdv_props[4].value;
            
                    updatedData[i][j].wdv_props[5].value = evaluate(formulas.wdv, scope).toFixed(2);
                    scope.wdv = updatedData[i][j].wdv_props[5].value;
    
                    if(updatedData[i][j].owdv_props !== undefined){
                        updatedData[i][j].owdv_props[0].value = evaluate(formulas.wdv, scope).toFixed(2);
                        scope.owdv = updatedData[i][j].owdv_props[0].value;
    
                        updatedData[i][j].owdv_props[1].value = evaluate(formulas.depreciationRate, scope).toFixed(2);
    
                        updatedData[i][j].owdv_props[2].value = evaluate(formulas.depreciation_2, scope).toFixed(2);
                        scope.depreciation_2 = updatedData[i][j].owdv_props[2].value;
    
                        updatedData[i][j].owdv_props[3].value = evaluate(formulas.wdv_2, scope).toFixed(2);
                        scope.wdv_2 = updatedData[i][j].owdv_props[3].value;
                    }
                } catch (error) {
                    setErrorOpen(true);
                }

            }
        }

        setDepreciatePropsData(updatedData);
    }


    const OWDV_AddFunc = (outerIndex, innerIndex) => {
        setDepreciatePropsData(prevData => {
            const newData = [...prevData];
            newData[outerIndex][innerIndex]['owdv_year'] = `${CURRENT_YEAR-1} - ${CURRENT_YEAR}`;
            newData[outerIndex][innerIndex]['owdv_props'] = [
                {id: "1", propName: "OWDV", value: ""},
                {id: "2", propName: "Depreciation Rate", value: ""},
                {id: "3", propName: "Depreciation", value: ""},
                {id: "4", propName: "WDV", value: ""},
            ];
            return newData;
        });
    }

    const OtherDepreciateProdFunc = (outerIndex) => {
        const newData = [...depreciatePropsData];
        newData[outerIndex].push({
            name: "",
            attachment: "",
            wdv_props: [
                {id: "1", propName: "Cost", value: ""},
                {id: "2", propName: "Effective Life", value: ""},
                {id: "3", propName: "No. of days", value: ""},
                {id: "4", propName: "Depreciation Rate", value: ""},
                {id: "5", propName: "Depreciation", value: ""},
                {id: "6", propName: "WDV", value: ""},
            ],
        })
        setDepreciatePropsData(newData);
    }

    const DeleteDepreciateProdFunc = (outerIndex) => {
        const newData = [...depreciatePropsData];
        newData[outerIndex].pop();
        setDepreciatePropsData(newData);
    }


    const UploadFile = async(image, userToken) => {
        setLoading(true);
        const data = new FormData();
        data.append('file', image);        
        
        const uploadRes = await UploadImageAPI(data, userToken.token);
        if(uploadRes.success===true){
            toast.success(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });

            setDepreciatePropsData(prevData => {
                const newData = [...prevData];
                newData[currentTable][currentNestedIndex]['attachment'] = uploadRes.file;
                return newData;
            });
            setDocsChange(true);

        }else{
            toast.error(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }


    const handleInputFileChange = (eventfile) => {
        const selectedFile = eventfile;
        const userToken = JSON.parse(authCtx.token);
        UploadFile(selectedFile[0], userToken);
    }


    const handleInputChange = (outerIndex, innerIndex, objectKey, propIndex, e, type) => {

        const newValue = e.target.value;
        if(type!=="name"){
            setDepreciatePropsData(prevData => {
                const newData = [...prevData];
                newData[outerIndex][innerIndex][objectKey][propIndex].value = newValue;
                return newData;
            });
        }
        else{
            setDepreciatePropsData(prevData => {
                const newData = [...prevData];
                newData[outerIndex][innerIndex]['name'] = newValue;
                return newData;
            });
        }
    };

    // Delete Selcted File..
    const DeleteFileChange = async(fileName, tableIndex, rowIndex) => {
        setLoading(true);
        const userToken = JSON.parse(authCtx.token)
        const deleteRes = await DeleteImageAPI(fileName, userToken.token);

        if(deleteRes.success===true){
            toast.success(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDepreciatePropsData(prevData => {
                const newData = [...prevData];
                newData[tableIndex][rowIndex]['attachment'] = "";
                return newData;
            });
            setDocsChange(true)
        }else{
            toast.error(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }

    const openFileInNewTab = (file) => {
        window.open(`${Config.ImageUrl}${file.filename}`, '_blank');
    };


    return (
        <>
            <div className="table-container" style={{justifyContent: "unset", flexDirection: 'column'}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'row',  overflowX: "auto", justifyContent: "unset", alignItems: 'baseline'}} >

                    {depreciatePropsData.map((table, tableIndex) => {
                        return(
                            <table className="data-table" style={{tableLayout: "fixed", flex: 1}} key={tableIndex}>
                                <thead>
                                    <tr className="data-table-heading">
                                        <th className="th-name" colSpan="3" >
                                            Depreciation on Property {tableIndex + 1}
                                        </th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {table.map((item, rowIndex) => {
                                        return(
                                            <React.Fragment key={rowIndex}>
                                                <tr>
                                                    <th colSpan="3">
                                                        <div style={rowIndex!==0? {borderTop: '5px solid black'} : {}} ></div>
                                                    </th>
                                                </tr>

                                                {item.wdv_props.map((propItem, propIndex) => {
                                                    return(
                                                        <tr key={propIndex}  className="data-table-heading" >
                                                            <td className="data-fill-field income-field" style={{minWidth: '320px'}}>
                                                                {(propIndex===0 || propIndex===1)?
                                                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                                                        {propIndex===0?
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Depreciatable Item"
                                                                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                                                value={item.name}
                                                                                onChange={(e) => {
                                                                                    handleInputChange(tableIndex, rowIndex, 'wdv_props' , propIndex, e, "name");
                                                                                }}
                                                                            />
                                                                            :
                                                                            <input
                                                                                type="file"
                                                                                placeholder=""
                                                                                id={(propIndex==1)&& `depreciate_file_${rowIndex}`}
                                                                                name={(propIndex==1)&& `depreciate_file_${rowIndex}`}
                                                                                style={{display: "none"}}
                                                                                onChange={(e) => {
                                                                                    handleInputFileChange(e.target.files);
                                                                                }}
                                                                                onClick={(event)=> { 
                                                                                    event.target.value = null
                                                                                }}
                                                                                className='file-input'
                                                                            />
                                                                        }
                                                                        {(propIndex==1)&&
                                                                            <>
                                                                                <label 
                                                                                    htmlFor={`depreciate_file_${rowIndex}`} 
                                                                                    className="submit-button" 
                                                                                    style={{margin: 0, borderRadius: 0}}
                                                                                    onClick={() => {setCurrentTable(tableIndex); seCurrentNestedIndex(rowIndex)}}
                                                                                >
                                                                                    <AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} />
                                                                                </label>
                                                                                {((typeof(item.attachment))==="object" && Object.keys(item.attachment).length>0)&&
                                                                                    <div style={{flex: 1, display: 'flex', cursor: 'pointer' }} onClick={() => { openFileInNewTab(item.attachment) }}>
                                                                                        <span style={{padding: '5px', border: '1px solid red', color: 'red', flex: 1}}>
                                                                                            {item.attachment.filename.slice(0,10) + (item.attachment.filename.length>10? `...${item.attachment.filename.slice(-4)}` : '')}
                                                                                            {" "}<CloseIcon style={{fontSize: '16px', verticalAlign: 'middle', cursor: 'pointer' }} onClick={(e) => {e.stopPropagation(); DeleteFileChange(item.attachment.filename, tableIndex, rowIndex) }} />
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }

                                                                    </div>
                                                                    :
                                                                    <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}></div>
                                                                }
                                                            </td>
                                                            
                                                            <td className="data-fill-field income-field" style={{minWidth: '250px'}}>
                                                                <span style={{marginLeft: '5px'}}>{propItem.propName}</span>
                                                            </td>
                                                            
                                                            <td className="data-fill-field income-field" style={{minWidth: '250px'}}>
                                                                <div style={propItem.propName==="WDV"? {display: 'flex', alignItems: 'center', fontWeight: '500', border: '1px solid black'} : {display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                                                    {(propItem.propName==="Cost" || propItem.propName==="Depreciation" || propItem.propName==="WDV")&&
                                                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                                                    }
                                                                    <input
                                                                        type="text"
                                                                        placeholder=""
                                                                        style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                                        value={propItem.value}
                                                                        onChange={(e) => {
                                                                            if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                                                handleInputChange(tableIndex, rowIndex, 'wdv_props' , propIndex, e, "field");
                                                                            }
                                                                        }}
                                                                    />
                                                                    {(propItem.propName==="Depreciation Rate")&&
                                                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}


                                                {(item.owdv_props === undefined)?
                                                    <tr  className="data-table-heading">
                                                        <th className="th-name" colSpan="3"  style={{paddingLeft: '0px'}}>
                                                            <button 
                                                                className="submit-button" 
                                                                style={{fontSize: '14px', fontWeight: 400, display: 'flex', alignItems: 'center', margin: '0px', width: '-webkit-fill-available', justifyContent: 'center', borderRadius: 0,}}
                                                                onClick={() => {OWDV_AddFunc(tableIndex, rowIndex)}}
                                                            >
                                                                <AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> OWDV
                                                            </button>
                                                        </th>
                                                    </tr>
                                                    :
                                                    <>
                                                        <tr  className="data-table-heading">
                                                            <td className="data-fill-field income-field" style={{minWidth: '320px'}}></td>
                                                            <th className="th-name" style={{minWidth: '250px', textAlign: 'center', padding: '5px'}}>{item.owdv_year}</th>
                                                            <td className="data-fill-field income-field" style={{minWidth: '250px'}}></td>
                                                        </tr>
                
                                                        {item.owdv_props.map((propItem, propIndex) => {
                                                            return(
                                                                <tr key={propIndex} className="data-table-heading">
                                                                    
                                                                    <td className="data-fill-field income-field" style={{minWidth: '320px'}}></td>
            
                                                                    <td className="data-fill-field income-field" style={{minWidth: '250px'}}>
                                                                        <span style={{marginLeft: '5px'}}>{propItem.propName}</span>
                                                                    </td>
                                                                    
                                                                    <td className="data-fill-field income-field" style={{minWidth: '250px'}}>
                                                                        <div style={propItem.propName==="WDV"? {display: 'flex', alignItems: 'center', fontWeight: '500', border: '1px solid black'} : {display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                                                            {(propItem.propName==="OWDV" || propItem.propName==="Depreciation" || propItem.propName==="WDV")&&
                                                                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                                                            }
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                                                value={propItem.value}
                                                                                onChange={(e) => {
                                                                                    if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                                                        handleInputChange(tableIndex, rowIndex, 'owdv_props' , propIndex, e, "field");
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {(propItem.propName==="Depreciation Rate")&&
                                                                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                                                                            }
                                                                        </div>
                                                                    </td>
            
                                                                </tr>
                                                            );
                                                        })}
                                                        
                                                    </>
                                                }

                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <td colSpan="3" >
                                            <button className="submit-button" style={{margin: '5px'}} onClick={() => {OtherDepreciateProdFunc(tableIndex)}}><AddIcon style={{fontSize: '14px', verticalAlign: 'middle', margin: '5px' }} /> Add Other Depreciatable Item</button>
                                            {(depreciatePropsData[tableIndex].length>1)&&
                                                <button className="submit-button" style={{margin: '5px'}} onClick={() => {DeleteDepreciateProdFunc(tableIndex)}}><AddIcon style={{fontSize: '14px', verticalAlign: 'middle', margin: '5px' }} /> Delete Depreciatable Item</button>
                                            }
                                        </td>
                                    </tr>
                                </tfoot>

                            </table>
                        )
                    })}

                </div>

                {EvaluateFunc()}
            </div>

        </>
    )
}

export default DepreciationonPropertyTable;