import React, {useContext, useState} from 'react';
import { Box, Button, TextField, IconButton, Typography } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { Modal  } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import ReactLoading from "react-loading";
import CloseIcon from '@mui/icons-material/Close';
import {UpdateUserByIdAPI, UpdateManagerByIdAPI, UpdatePartnerByIdAPI, UpdateStaffByIdAPI} from '../util/http';
import { AuthContext } from '../store/auth-context';
import secureLocalStorage from 'react-secure-storage';


const ClientUpdateModal = ({openClientModal, setOpenClientModal, clientData, updateMessage}) => {
    const authCtx = useContext(AuthContext);
    const role = secureLocalStorage.getItem("role");
    const [loading, setLoading] = useState(false);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;


    const initialValues = {
        name: clientData?.name,
        email: clientData?.email,
        position: clientData?.position,
        office: clientData?.office,
        age: clientData?.age,
        salary: clientData?.salary,
    };

    const initialManagerValues = {
        name: clientData?.name,
        email: clientData?.email,
        username: clientData?.username,
    };

    const checkoutSchema = yup.object().shape({
        name:yup.string().required("Required"),
        email:yup.string().email("Invalid email!").required("Required"),
        position:yup.string().required("Required"),
        office:yup.string().required("Required"),
        age:yup.string().required("Required"),
        salary:yup.string().required("Required"),
        username:yup.string().required("Required"),
    })


    const handleFormSubmit = (values) => {
        let conditionalArr = Object.keys(values).map((item) => {
            return values[item].length === 0;
        })
        const validEmail = (emailRegExp.test(values.email));

        if((conditionalArr.includes(true)) || (values.password !==values.confirmPassword) || (!validEmail)){
            return;
        }
        
        // Checking if there is any change or not (if not, dont proceed further)..
        let isChange = true;
        for(const key in values){
            if(clientData[key] !== values[key]){
                isChange = false;
            }
        }
        if(isChange){
            toast.error(`You have to make changes before update`, {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

        setLoading(true);

        const updateUserFunc = async() => {
            const userToken = JSON.parse(authCtx.token);

            const updateUserRes = (updateMessage === "Update Partner")?
            await UpdatePartnerByIdAPI(values, clientData._id, userToken.token)
            :
            (updateMessage === "Update Manager")?
            await UpdateManagerByIdAPI(values, clientData._id, userToken.token)
            :
            (updateMessage === "Update Staff")?
            await UpdateStaffByIdAPI(values, clientData._id, userToken.token)
            :
            await UpdateUserByIdAPI(values, clientData._id, userToken.token)

            if(updateUserRes.success===true){
                toast.success(`${updateUserRes?.message}.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }else{
                toast.error(`${updateUserRes?.message}.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

            setLoading(false);
            authCtx.updateTrigger();
            setOpenClientModal(false);
        }

        updateUserFunc();
    };


    return (
        <Modal size="lg" open={openClientModal} >
            <Box  mb="10px">
                <Typography variant="h1" color={'#009ec1'} textAlign="center" >{(updateMessage==="Update Partner")? 'Edit Partner Profile' :(updateMessage==="Update Manager")? 'Edit Manager Profile' :(updateMessage==="Update Staff")? 'Edit Staff Profile' : 'Edit Client Profile'}</Typography>
            </Box>
            
            <IconButton 
                aria-label="close" 
                size="small" 
                style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                onClick={() => {setOpenClientModal(false)}}
            >
                <CloseIcon fontSize="inherit" />
            </IconButton>

            <Formik onSubmit={handleFormSubmit} initialValues={(updateMessage==="Update Partner" || updateMessage==="Update Manager" || updateMessage==="Update Staff")? initialManagerValues : initialValues} validationSchema={checkoutSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        {(updateMessage==="Update Partner" || updateMessage==="Update Manager" || updateMessage==="Update Staff")?
                            <>
                                <Box
                                    display="grid"
                                    gap="30px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}
                                >
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </Box>
                                
                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button type="submit" variant="contained" style={{color: '#fff', backgroundColor: '#009ec1'}} onClick={() => {handleFormSubmit(values)}}>Edit Profile</Button>
                                </Box>
                            </>
                            :
                            <>
                                <Box
                                    display="grid"
                                    gap="30px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}
                                >
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Position"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.position}
                                        name="position"
                                        error={!!touched.position && !!errors.position}
                                        helperText={touched.position && errors.position}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Office"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.office}
                                        name="office"
                                        error={!!touched.office && !!errors.office}
                                        helperText={touched.office && errors.office}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="age"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            if(/^-?\d*\.?\d*$/.test(e.target.value)){
                                                handleChange(e)
                                            }
                                        }}
                                        value={values.age}
                                        name="age"
                                        error={!!touched.age && !!errors.age}
                                        helperText={touched.age && errors.age}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Salary"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                handleChange(e);
                                            }
                                        }}
                                        value={values.salary}
                                        name="salary"
                                        error={!!touched.salary && !!errors.salary}
                                        helperText={touched.salary && errors.salary}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </Box>
                                
                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button type="submit" variant="contained" style={{color: '#fff', backgroundColor: '#009ec1'}} onClick={() => {handleFormSubmit(values)}}>Edit Profile</Button>
                                </Box>

                            </>
                        }
                    </form>
                )}
            </Formik>



            {/* Loading Screen */}
			{(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </Modal>
    )
}

export default ClientUpdateModal;