import React from 'react';
import { Drawer } from 'rsuite';
import { IconButton } from '@mui/material';
import 'rsuite/dist/rsuite.min.css';
import CloseIcon from '@mui/icons-material/Close';
import './ClientTable.css';
import './DataTable.css';

import AllTaxRates from './Tax Rates/AllTaxRates';



const TaxRatesModal = ({modalOpen, setModalOpen, clientUpdateYear}) => {
    
    return (
        <>
            <Drawer size="md" placement='right' open={modalOpen} >
                <h3 className="headingTitle" >Tax Rates</h3>
                <IconButton 
                    aria-label="close" size="small"  style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                    onClick={() => {setModalOpen(false)}}
                >
                    <CloseIcon style={{fontSize: '30px', color: '#000'}}  />
                </IconButton>


                <AllTaxRates 
                    clientUpdateYear={clientUpdateYear}
                    editable={false}
                />

            </Drawer>

        </>
    );
}

export default TaxRatesModal;