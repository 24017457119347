import React from 'react';
import { Drawer } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Checks from './Checks/Checks';

const ChecksModal = ({modalOpen, setModalOpen, currentModal}) => {
    return (
        <Drawer size="full" placement='right' open={modalOpen}  style={{overflow: 'auto'}}>
            <h3 className="headingTitle" >{currentModal}</h3>

            <IconButton 
                aria-label="close" size="small"  style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                onClick={() => {setModalOpen(false)}}
            >
                <CloseIcon style={{fontSize: '30px', color: '#000'}}  />
            </IconButton>

            <Checks />

        </Drawer>
    )
}

export default ChecksModal;