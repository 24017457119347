import React from 'react';

const RefTotalTable = ({dataArray, handleChange, totalAmount}) => {
    
    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset to auto height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
    };

    return (
        <div className="table-container" style={{flexDirection: 'column'}}>
            <table className="data-table">
                <tr className="data-table-heading" >
                    <th className="th-name" style={{width: '50%'}} ></th>
                    <th className="th-name" style={{width: '20%'}}>Ref</th>
                    <th className="th-name" >Total</th>
                </tr>

                {dataArray.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="data-fill-field" >
                                <textarea 
                                    type="text" 
                                    placeholder="" 
                                    rows="1"
                                    wrap="hard"
                                    style={{resize: 'vertical'}}
                                    className="textArea textArea-complete-witdth"
                                    value={item.name}
                                    onChange={(e) => { 
                                        handleChange(index, "name", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="text" 
                                    placeholder=""
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.ref}
                                    onChange={(e) => { 
                                        handleChange(index, "ref", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.total}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "total", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }} 
                                />
                            </td>
                        </tr>
                    );
                })}

                <tr className="data-table-heading" >
                    <th className="th-name" colSpan="2" >Total</th>
                    <th className="th-name" >$ {Number(totalAmount).toFixed(2)}</th>
                </tr>

            </table>
        
        </div>
    );
}



const OtherWorkRelatedTable = ({dataArray, handleChange, totalFixedRate, totalShortcut}) => {

    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset to auto height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
    };

    return (
        <div className="table-container" style={{flexDirection: 'column'}}>
            <table className="data-table">
                <tr className="data-table-heading" >
                    <th className="th-name" style={{width: '50%'}} ></th>
                    <th className="th-name" style={{width: '15%'}} >Ref</th>
                    <th className="th-name" >Fixed Rate Method</th>
                    <th className="th-name" >Shortcut Method</th>
                </tr>

                {dataArray.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="data-fill-field" >
                                <textarea 
                                    type="text" 
                                    placeholder="" 
                                    rows="1"
                                    wrap="hard"
                                    style={{resize: 'vertical'}}
                                    className="textArea textArea-complete-witdth"
                                    value={item.name}
                                    onChange={(e) => { 
                                        handleChange(index, "name", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="text" 
                                    placeholder=""
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.ref}
                                    onChange={(e) => { 
                                        handleChange(index, "ref", e.target.value);
                                        adjustTextareaHeight(e.target); 
                                    }}
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.fixedRateMethod}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "fixedRateMethod", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }}  
                                />
                            </td>
                            <td  className="data-fill-field" style={{position: 'relative'}}>
                                <textarea 
                                    type="number" 
                                    placeholder="$"
                                    wrap="hard"
                                    className="textArea textArea-dependend-width"
                                    value={item.shortcutMethod}
                                    onChange={(e) => {
                                        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                            handleChange(index, "shortcutMethod", e.target.value);
                                            adjustTextareaHeight(e.target); 
                                        }
                                    }} 
                                />
                            </td>
                        </tr>
                    );
                })}

                <tr className="data-table-heading" >
                    <th className="th-name" colSpan="2" >Total</th>
                    <th className="th-name" >$ {Number(totalFixedRate).toFixed(2)}</th>
                    <th className="th-name" >$ {Number(totalShortcut).toFixed(2)}</th>
                </tr>

            </table>

        </div>
    );
}

export default RefTotalTable;
export {OtherWorkRelatedTable};