import React, {useEffect} from 'react';
import {Box, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useLocation } from 'react-router-dom';


const Support = () => {
    const navigate = useNavigate();
    const location = useLocation();
	const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box p="20px" backgroundColor={colors.importantAccent[100]} >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Support" subtitle="You can get All Support Here!" />
            </Box>
        </Box>
    )
}

export default Support