import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, useTheme, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import secureLocalStorage from "react-secure-storage";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";

import { GetStaffByIdAPI } from "../../util/http";
import { AuthContext } from "../../store/auth-context";
import ClientUpdateModal from "../../components/ClientUpdateModal";
import PasswordUpdateModal from '../../components/PasswordUpdateModal';
import LaunchIcon from '@mui/icons-material/Launch';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PasswordIcon from '@mui/icons-material/Password';
import Topbar from '../global/Topbar';

const StaffList = () => {
    const {managerId} = useParams();
    const navigate = useNavigate();
    const role = secureLocalStorage.getItem("role");
    const authCtx = useContext(AuthContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openClientModal, setOpenClientModal] = useState(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [userData, setUserData] = useState({});

    const [staffList, setStaffList] = useState([]);

    useEffect(() => {
        const TokenChecker = async() => {
            const userToken = await secureLocalStorage.getItem("token");
            const validRole = (role==="admin" || role==="partner" || role==="manager");

            if(userToken===null || userToken===undefined || !validRole){
                navigate("/");
            }
        }
        TokenChecker();

    }, [navigate])

    useEffect(() => {
        const ListUpdater = async() => {
            const authToken = await secureLocalStorage.getItem("token")
            if(authToken === null || authToken === undefined){
                return;
            }
            const userToken = JSON.parse(authToken);
            const getRes = await GetStaffByIdAPI(managerId, userToken.token);
            if(getRes.success===true){
                setStaffList(getRes.doc);
            }
        }

        ListUpdater();
    }, [authCtx.token, authCtx.clientListTrigger, role])


    const UpdateManagerFunc = (data) => {
        setUserData(data);
        setOpenClientModal(true);
    }

    const DeletePerticularClient = (data) => {
        setUserData(data);
        setOpenDeleteDialog(true);
    }
    
    const UpdatePasswordFunc = (data) => {
        setUserData(data);
        setOpenPasswordModal(true);
    }


    const columns = [
        { field: "name", headerName: "Name", flex: 1, headerAlign: 'center', },
        { field: "username", headerName: "Username", flex: 1, headerAlign: 'center', },
        { field: "email", headerName: "Email", flex: 1, headerAlign: 'center', },
        { 
            field: "action", 
            headerName: "Action", 
            flex: 1, 
            headerAlign: 'center', 
            renderCell: ({ row }) => {
                return (
                    <div style={{margin: '0 auto'}}>
                        <Button
                            style={{backgroundColor: colors.importantAccent[300], color: '#fff', padding: '5px 10px', marginRight: '5px'}}
                            onClick={() => { navigate(`/client-list/${row._id}`) }}
                        >
                            <RemoveRedEyeIcon />
                            {(role!=="admin")&&<Typography color={'#fff'} sx={{ ml: "5px" }}>Open</Typography>}
                        </Button>

                        {(role==="admin")&&
                            <>
                                <Button
                                    style={{backgroundColor: colors.importantAccent[300], color: '#fff', padding: '5px 10px', marginRight: '5px'}}
                                    onClick={() => { UpdateManagerFunc(row) }}
                                    >
                                    <BorderColorIcon />
                                </Button>

                                <Button
                                    style={{backgroundColor: colors.importantAccent[300], color: '#fff', padding: '5px 10px', marginRight: '5px'}}
                                    onClick={() => { UpdatePasswordFunc(row) }}
                                    >
                                    <PasswordIcon />
                                </Button>
                            </>
                        }
                        
                        {/* <Button
                            style={{backgroundColor: '#f75e7f', color: '#fff', padding: '5px 10px'}}
                            onClick={() => { DeletePerticularClient(row) }}
                        >
                            <DeleteForeverIcon />
                        </Button> */}

                    </div>
                );
            },
        },
    ]

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{
                "& .MuiSvgIcon-root": {
                    color: '#000'
                },
                "& .MuiTablePagination-root": {
                    color: '#000'
                },
            }}>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    return (
        <>
            {(role==="admin")&&
                <Box>
                    <Topbar 
                        name="StaffList" 
                        message="Create Staff"
                        id={managerId}
                        />
                </Box>
            }

            <Box>
                <Box p="20px" backgroundColor={colors.importantAccent[100]} >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Header title="Staff List" subtitle="All Staff are Listed Here" />
                    </Box>

                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        height="80vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .align-column--cell": {
                                textAlign: "start",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.importantAccent[300],
                                fontSize: '14px',
                                borderBottom: "none",
                                color: '#fff'
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                                fontSize: '14px',
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.importantAccent[300],
                                fontSize: '14px',
                                color: '#fff'
                            },
                            "& .MuiSvgIcon-root": {
                                color: '#fff'
                            },
                            "& .MuiTablePagination-root": {
                                color: '#fff'
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <DataGrid
                            rows={staffList}
                            columns={columns}
                            components={{ Toolbar: CustomToolbar }}
                            getRowId={(row) =>  row._id}
                        />
                    </Box>
                </Box>

                <ConfirmDeleteDialog 
                    openDeleteDialog={openDeleteDialog}
                    setOpenDeleteDialog={(val) => setOpenDeleteDialog(val)}
                    userData={userData}
                    message="Delete Staff"
                />
            </Box>
            
            <ClientUpdateModal
				openClientModal={openClientModal}
				setOpenClientModal={(val) => setOpenClientModal(val)}
				clientData={userData}
                updateMessage={"Update Staff"}
			/>

            <PasswordUpdateModal 
				openPasswordModal={openPasswordModal}
				setOpenPasswordModal={(val) => setOpenPasswordModal(val)}
				clientData={userData}
				updateMessage={'Update Staff Password'}
			/>
        </>
    );
}

export default StaffList;