import React from 'react';
import EditableTaxRateHeader from '../../../../../components/EditableTaxRateHeader';

const RestTable = ({editable, inputs, handleFormInput, dataArray, handleInputChange}) => {
    return (
        <table className="data-table" style={{marginTop: '5px', textAlign: 'center',  tableLayout: 'fixed', width: '100%'}}>
                    
            <tr className="data-table-heading" >
                <th className="th-name" style={{overflow: 'hidden'}}>
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading1'}
                        inputLink={'heading1Link'}
                        inputs={inputs}
                    />
                </th>
                <th className="th-name" style={{overflow: 'hidden'}}>
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading2'}
                        inputLink={'heading2Link'}
                        inputs={inputs}
                    />
                </th>
                <th className="th-name" style={{overflow: 'hidden'}}>
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading3'}
                        inputLink={'heading3Link'}
                        inputs={inputs}
                    />
                </th>
                <th className="th-name" style={{overflow: 'hidden'}}>
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading4'}
                        inputLink={'heading4Link'}
                        inputs={inputs}
                    />
                </th>
                <th className="th-name" style={{overflow: 'hidden'}}>
                    <EditableTaxRateHeader 
                        editable={editable}
                        handleFormInput={handleFormInput}
                        inputName={'tableHeading5'}
                        inputLink={'heading5Link'}
                        inputs={inputs}
                    />
                </th>
            </tr>

            {dataArray.map((item, index) =>{
                return(

                    <tr key={index}>
                        <th >
                            <p style={{ flex: 1,fontSize: '14px', padding: '5px'}}>{item.taxableIncomeName}</p>
                        </th>
                        <td className="data-fill-field" >
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                <input 
                                    type="text" 
                                    placeholder="" 
                                    value={item.taxableIncome_1}
                                    style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px', overflow: 'hidden', whiteSpace: 'no-wrap'}}  
                                    onChange={(e) => { 
                                        if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                            handleInputChange(e, index, 'taxableIncome_1');
                                        }
                                    }} 
                                />
                            </div>
                            
                        </td>

                        <td className="data-fill-field" >
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                {(index!==0 && index!==dataArray.length-1)&&
                                    <>
                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                        <input 
                                            type="text" 
                                            placeholder="" 
                                            value={item.taxableIncome_2}
                                            style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px', overflow: 'hidden', whiteSpace: 'no-wrap'}}  
                                            onChange={(e) => {
                                                if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                                    handleInputChange(e, index, 'taxableIncome_2');
                                                }
                                            }} 
                                        />
                                    </>
                                }
                            </div>
                        </td>

                        <td className="data-fill-field" >
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>
                                <input 
                                    type="text" 
                                    placeholder="" 
                                    value={item.baseTax}
                                    style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px', overflow: 'hidden', whiteSpace: 'no-wrap'}}  
                                    onChange={(e) => { 
                                        if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                            handleInputChange(e, index, 'baseTax');
                                        }
                                    }} 
                                />

                            </div>
                            
                        </td>

                        <td  className="data-fill-field" style={{position: 'relative'}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input 
                                    type="text" 
                                    placeholder=""
                                    value={item.marginalRate}
                                    style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px', overflow: 'hidden', whiteSpace: 'no-wrap'}}  
                                    onChange={(e) => { 
                                        if(e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value) && editable){
                                            handleInputChange(e, index, 'marginalRate');
                                        }}}
                                />
                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                            </div>
                        </td>
                        
                    </tr>
                );
            })}

        </table>
    )
}

export default RestTable;