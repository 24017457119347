import React, {useState, useEffect, useContext} from 'react';
import { Box, Button } from "@mui/material";
import ReactLoading from "react-loading";
import { AuthContext } from "../../../../store/auth-context";
import { CreateFormulaAPI, GetFormulasAPI, UpdateFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

const RentFormula = () => {
    const authCtx = useContext(AuthContext);
    const currentYear = new Date().getFullYear();
    const [formulas, setFormulas] = useState({
        netRent: '(grossRent - totalExpenses) * percentOwnership/100',
        depreciationRate: '200/effectiveLife',
        depreciation: 'cost*(depreciationRate/100)*totalDays/365',
        wdv: 'cost - depreciation',
        depreciation_2: 'owdv*(depreciationRate/100)',
        wdv_2: 'owdv - depreciation_2'
    })
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);

    useEffect(() => {
        const GetFormulaFunc = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);

            setLoading(true);

            const getRes = await GetFormulasAPI("rent", currentYear, userToken.token);

            if(getRes.success === true){
                if(getRes.data === null || getRes.data === undefined){
                    setDataAlready(false);
                }else {
                    setDataAlready(true);
                    setFormulas(getRes.data.formulas);
                }
            }

            setLoading(false)
        }

        GetFormulaFunc();
    }, [isDataAlready])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormulas((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            year: currentYear,
            type: "rent",
            formulas: formulas,
        };

        const submitRes = isDataAlready?
        await UpdateFormulasAPI(sendData, "rent", currentYear, userToken.token)
        :
        await CreateFormulaAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
          
        setLoading(false);
    }


    return (
        <>
            <div className="table-container" style={{flexDirection: 'column',  overflowX: "auto"}}>
                <table className="data-table" style={{tableLayout: "fixed"}}>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <th className="th-name" style={{minWidth: "250px"}}>Property</th>
                        <th className="th-name" style={{minWidth: "250px"}}>Reference</th>
                    </tr>

                    <tr className="data-table-heading" >
                        <th className="th-name" style={{textAlign: 'initial'}}>percentOwnership</th>
                        <th className="th-name" style={{textAlign: 'initial'}}>100 %</th>
                        <th className="th-name"></th>
                    </tr>

                    <tr className="data-table-heading" >
                        <th className="th-name" style={{textAlign: 'initial'}}>grossRent</th>
                        <th className="th-name" style={{textAlign: 'initial'}}>$ 22083.50</th>
                        <th className="th-name"></th>
                    </tr>
                    
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{textAlign: 'initial'}}>totalExpenses</th>
                        <th className="th-name" style={{textAlign: 'initial'}}>$ 33209.39</th>
                        <th className="th-name"></th>
                    </tr>

                    <tr className="data-table-heading" >
                        <th className="th-name" style={{textAlign: 'initial'}}>netRent</th>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="netRent"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.netRent}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <th className="th-name"></th>
                    </tr>

                </table>

                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading">
                        <th className="th-name" colSpan="3" >Depreciation on Property</th>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>cost</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>$ 528</span>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>effectiveLife</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>12</span>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>totalDays</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>63</span>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>depreciationRate</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="depreciationRate"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.depreciationRate}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>depreciation</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="depreciation"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.depreciation}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>wdv</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="wdv"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.wdv}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <th className="th-name" style={{minWidth: "250px"}}>{`${currentYear-1} - ${currentYear}` }</th>
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>owdv</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="wdv"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.wdv}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>depreciationRate</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="depreciationRate"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.depreciationRate}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>depreciation_2</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="depreciation_2"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.depreciation_2}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr className="data-table-heading" >
                        <th className="th-name" style={{minWidth: "250px"}}>{""}</th>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <span style={{marginLeft: '5px'}}>wdv_2</span>
                        </td>
                        <td className="data-fill-field income-field" style={{minWidth: "250px"}}>
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="wdv_2"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.wdv_2}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                    </tr>
                </table>

            </div>

            <Box mt="20px" ml="10px">
                <Button 
                    type="submit" 
                    variant="contained" 
                    style={{color: '#fff', backgroundColor: '#009ec1', marginRight: '20px'}} 
                    onClick={() => {SubmitHandler()}}
                >
                    Submit
                </Button>
            </Box>

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>
    );
}

export default RentFormula;