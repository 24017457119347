import React, {useState, useContext, useEffect} from 'react'

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SendIcon from '@mui/icons-material/Send';

import {SimpleClientTables} from '../../ClientTables/ClientTables';
import InsuranceTable from './InsuranceTable';

import { CreateInsuranceAPI, GetInsuranceByIdAPI, UpdateInsuranceByIdAPI, UploadImageAPI, DeleteImageAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../../../../store/auth-context";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";


const Insurance = ({currentModal, currentPage, clientData, clientUpdateYear, setDocsChange}) => {
    const authCtx = useContext(AuthContext);
    const [insuranceArray, setInsuranceArray] = useState([
        {
            name: '',
            file: '',
            data: [{date: '', amount: ''}],
        },
    ])
    const [totals, setTotals] = useState({ total1: 0 })
    const [finalTotal, setFinalTotal] = useState(0);

    const [clientTableDetails, setClientTableDetails] = useState({
        preparedDate: '',
        scheduleRef: currentPage,
        staffMember: 'No',
        managerReview: 'No',
        partnerReview: 'No',
    })
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);

    const [currentTable, setCurrentTable] = useState("");
    const clientYear = clientUpdateYear.getFullYear();



    useEffect(() => {
        const GetINsuranceDataFunc = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);
            setLoading(true);

            const getRes = await GetInsuranceByIdAPI(clientData._id, clientYear, userToken.token);
            if(getRes.success===true){
                if(getRes.data === null || getRes.data === undefined){
                    setDataAlready(false);
                }else{
                    setDataAlready(true);
                    setInsuranceArray(getRes.data.data);
                    setTotals(getRes.data.total);
                    setFinalTotal(getRes.data.finalTotal);
                    setClientTableDetails({preparedDate: getRes.data.preparedDate, scheduleRef: getRes.data.scheduleRef, staffMember: getRes.data.staffMember, managerReview: getRes.data.managerReview, partnerReview: getRes.data.partnerReview});
                }
            }

            setLoading(false);
        }

        GetINsuranceDataFunc();

    }, [isDataAlready])


    useEffect(() => {
        let totalVal = 0;
        for (const key in totals) {
            let value = parseFloat(totals[key]) 
            if (!isNaN(value)) {
                totalVal += value;
            }
        }
        setFinalTotal(totalVal);
    }, [totals])
    
    useEffect(() => {
        let amountTotals = {...totals};
        for(let i=0; i<insuranceArray.length; i++){
            let totalAmount = 0;
            for(let j=0; j<insuranceArray[i]['data'].length; j++){
                let amount = parseFloat(insuranceArray[i]['data'][j]['amount']);
                if(!isNaN(amount)){
                    totalAmount += amount;
                }
            }
            amountTotals[`total${i+1}`] = totalAmount;
        }
        setTotals(amountTotals);
    }, [insuranceArray])




    const UploadFile = async(image, userToken) => {
        const data = new FormData();
        data.append('file', image);
        setLoading(true);
        
        const uploadRes = await UploadImageAPI(data, userToken.token);
        if(uploadRes.success===true){
            toast.success(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });

            setInsuranceArray(prevData => {
                const newData = [...prevData];
                newData[currentTable]['file'] = uploadRes.file;
                return newData;
            });
            setDocsChange(true);

        }else{
            toast.error(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }



    const handleInputFileChange = (eventfile) => {
        const selectedFile = eventfile;
        const userToken = JSON.parse(authCtx.token);
        UploadFile(selectedFile[0], userToken);
    }


    // Delete Selcted File..
    const DeleteFileChange = async(fileName, index) => {
        setLoading(true);
        const userToken = JSON.parse(authCtx.token)
        const deleteRes = await DeleteImageAPI(fileName, userToken.token);

        if(deleteRes.success===true){
            toast.success(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setInsuranceArray(prevData => {
                const newData = [...prevData];
                newData[index]['file'] = '';
                return newData;
            });
            setDocsChange(true)
        }else{
            toast.error(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }

        
    const handleInputChange = (outerIndex, innerIndex, objectKey, e, type) => {
        let newValue = e.target.value;
        
        if(type === 'data'){
            setInsuranceArray(prevData => {
                const newData = [...prevData];
                newData[outerIndex]['data'][innerIndex][objectKey] = newValue;
                return newData;
            });
        }else if(type === 'name'){
            setInsuranceArray(prevData => {
                const newData = [...prevData];
                newData[outerIndex]['name'] = newValue;
                return newData;
            });
        }
    }


    const addcellFunc = (index) => {
        let updatedData = [...insuranceArray];
        updatedData[index]['data'].push({date: '', amount: ''});
        setInsuranceArray(updatedData);
    }

    const removeCellFunc = (index) => {
        let updatedData = [...insuranceArray];
        updatedData[index]['data'].pop();
        setInsuranceArray(updatedData);
    }


    const addNewInsuranceTable = () => {
        let appendableData = {
            name: '',
            file: '',
            data: [{date: '', amount: ''}],
        };

        const updatedItems = [...insuranceArray, appendableData];
        setInsuranceArray(updatedItems);
    }

    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            clientId: clientData._id,
            year: clientYear,
            type: currentModal,
            leadSchedule : currentModal,
            preparedDate: clientTableDetails?.preparedDate,
            scheduleRef: clientTableDetails?.scheduleRef,
            staffMember: clientTableDetails?.staffMember,
            managerReview: clientTableDetails?.managerReview,
            partnerReview: clientTableDetails?.partnerReview,
            data: insuranceArray,
            total: totals,
            finalTotal: finalTotal,
        };

        const submitRes = isDataAlready?
        await UpdateInsuranceByIdAPI(sendData, clientData._id, clientYear, userToken.token)
        :
        await CreateInsuranceAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
            setDocsChange(false);

        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        setLoading(false);
    }



    return (
        <>
            <SimpleClientTables 
                currentModal={currentModal} 
                clientData={clientData} 
                area={'Lead Schedule:'} 
                clientUpdateYear={clientUpdateYear} 
                clientTableDetails={clientTableDetails}
                setClientTableDetails={setClientTableDetails}
            />



            <InsuranceTable 
                insuranceArray={insuranceArray}
                handleInputChange={handleInputChange}
                handleInputFileChange={handleInputFileChange}
                setCurrentTable={setCurrentTable}
                DeleteFileChange={DeleteFileChange}
                addcellFunc={addcellFunc}
                removeCellFunc={removeCellFunc}
                totals={totals}
                finalTotal={finalTotal}
            />



            <div style={{display: 'flex', marginLeft: '5px', paddingBottom: '80px'}} >
                <button className="submit-button" onClick={() => {addNewInsuranceTable()}} ><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add New Insurance</button>
                <button className="submit-button" onClick={() => {SubmitHandler()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button> 
            </div>


            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>   
    )
}

export default Insurance