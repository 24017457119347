import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import SendIcon from '@mui/icons-material/Send';

import { evaluate } from 'mathjs';

import {SimpleClientTables} from '../../ClientTables/ClientTables';
import {CapitalGainsTable} from './DataTables/IncomeDataTables';

import { CreateCapitalGainAPI, GetCapitalGainByIdAPI, UpdateCapitalGainByIdAPI, GetFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../../../../store/auth-context";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

import LinkAttachFile from '../../../../components/LinkAttachFile';
import AttachFile from '../../../../components/AttachFile';
import FormulaErrorSnackbar from '../../../../components/FormulaErrorSnackbar';


const CapitalGains = ({currentModal, currentPage, clientData, clientUpdateYear, setDocsChange}) => {
    const authCtx = useContext(AuthContext);
    const [capitalGainsData, setCapitalGainsData] = useState([
        [
            {company: '',dop: '', purchaseQty: '', purchaseUnit: '', purchaseBrkage: '', cost: ''},
        ],
        [
            {dos: '', saleQty: '', saleUnit: '', saleBrkage: '', proceeds: ''},
        ],
        [
            {discountCG: '', otherCG: '', capitalLoss: ''},
        ]
    ]);

    const [totals, setTotals] = useState(
        {cost: 0, proceeds: 0, discountCG: 0, otherCG: 0, capitalLoss: 0}
    );

    const [clientTableDetails, setClientTableDetails] = useState({
        preparedDate: '',
        scheduleRef: currentPage,
        staffMember: 'No',
        managerReview: 'No',
        partnerReview: 'No',
    })
    
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);

    const [linkAttachable, setLinkAttachable] = useState(false);
    const [links, setLinks] = useState([]);
    const [errorOpen, setErrorOpen] = useState(false);
    
    // Using at AttachFile.js
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [discountedCG, setDiscountedCG] = useState(0);
    const clientYear = clientUpdateYear.getFullYear();
    const [formulas, setFormulas] = useState({
        cost: 'purchaseQty * purchaseUnit + purchaseBrkage',
        proceeds: 'saleQty * saleUnit - saleBrkage',
        capitalLoss: 'cost - proceeds',
        discountCG: 'proceeds - cost + capitalLoss',
        otherCG: 'proceeds - cost + capitalLoss',
        finalDiscountedCG: '(discountCG - capitalLoss)/2',
    });


    useEffect(() => {
        const GetCapitalGainsData = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);
            setLoading(true);

            const getDataRes = await GetCapitalGainByIdAPI(clientData._id, clientYear, userToken.token);
            if(getDataRes.success===true){
                if(getDataRes.data === null || getDataRes.data === undefined){
                    setDataAlready(false);
                }else{
                    setDataAlready(true);
                    setCapitalGainsData(getDataRes.data.data);
                    setTotals(getDataRes.data.total);
                    setClientTableDetails({preparedDate: getDataRes.data.preparedDate, scheduleRef: getDataRes.data.scheduleRef, staffMember: getDataRes.data.staffMember, managerReview: getDataRes.data.managerReview, partnerReview: getDataRes.data.partnerReview});
                    setSelectedFiles(getDataRes.data.attachment);
                    setLinks(getDataRes.data.link)
                }
            }

            const getFormulas = await GetFormulasAPI('capitalGains', clientYear, userToken.token);
            if(getFormulas.success === true){
                if(getFormulas.data !== null && getFormulas.data !== undefined){
                    setFormulas(getFormulas.data.formulas)
                }
            }

            setLoading(false);
        }

        GetCapitalGainsData()
    }, [isDataAlready])


    useEffect(() => {
        let total__cost = 0;
        let total__proceeds = 0;
        let total__diconuntCG = 0;
        let total__otherCG = 0;
        let total__capitalLoss = 0;

        for(let i=0; i<capitalGainsData[0].length; i++){
            
            let cost = parseFloat(capitalGainsData[0][i]['cost']);
            let proceeds = parseFloat(capitalGainsData[1][i]['proceeds']);
            let discountCG = parseFloat(capitalGainsData[2][i]['discountCG']);
            let otherCG = parseFloat(capitalGainsData[2][i]['otherCG']);
            let capitalLoss = parseFloat(capitalGainsData[2][i]['capitalLoss']);

            total__cost = !isNaN(cost)? total__cost + cost : total__cost;
            total__proceeds = !isNaN(proceeds)? total__proceeds + proceeds : total__proceeds;
            total__diconuntCG = !isNaN(discountCG)? total__diconuntCG + discountCG : total__diconuntCG;
            total__otherCG = !isNaN(otherCG)? total__otherCG + otherCG : total__otherCG;
            total__capitalLoss = !isNaN(capitalLoss)? total__capitalLoss + capitalLoss : total__capitalLoss;

            let scope = {
                cost: total__cost,
                proceeds: total__proceeds,
                discountCG: total__diconuntCG,
                otherCG: total__otherCG,
                capitalLoss: total__capitalLoss,
            }

            try {
                setDiscountedCG(evaluate(formulas.finalDiscountedCG, scope).toFixed(2));
            } catch (error) {
                setErrorOpen(true);
            }
            
        }

        setTotals(prevObj => ({
            ...prevObj, cost: total__cost, proceeds: total__proceeds, discountCG: total__diconuntCG, otherCG: total__otherCG, capitalLoss: total__capitalLoss,
        }))


    }, [capitalGainsData])


    useLayoutEffect(() => {
        const updatedData = capitalGainsData;

        for(let i=0; i<updatedData[0].length; i++){
            let scope = {
                purchaseQty: updatedData[0][i]['purchaseQty'],
                purchaseUnit: updatedData[0][i]['purchaseUnit'],
                purchaseBrkage: updatedData[0][i]['purchaseBrkage'],
                cost: updatedData[0][i]['cost'],
                saleQty: updatedData[1][i]['saleQty'],
                saleUnit: updatedData[1][i]['saleUnit'],
                saleBrkage: updatedData[1][i]['saleBrkage'],
                proceeds: updatedData[1][i]['proceeds'],
                capitalLoss: updatedData[2][i]['capitalLoss'],
                discountCG: updatedData[2][i]['discountCG'],
                otherCG: updatedData[2][i]['otherCG']
            }


            try {
                updatedData[0][i]['cost'] = evaluate(formulas.cost, scope).toFixed(2);
                scope.cost = updatedData[0][i]['cost'];
    
                updatedData[1][i]['proceeds'] = evaluate(formulas.proceeds, scope).toFixed(2);
                scope.proceeds = updatedData[1][i]['proceeds'];
    
                const dopDate = updatedData[0][i]['dop'].length !== 0? new Date(updatedData[0][i]['dop']) : 0;
                const dosDate = updatedData[1][i]['dos'].length !== 0? new Date(updatedData[1][i]['dos']) : 0;
                const millisecondsPerDay = 24 * 60 * 60 * 1000;
                const dateDifference = Math.floor((dosDate - dopDate)/millisecondsPerDay);
    
                updatedData[2][i]['capitalLoss'] = (updatedData[1][i]['proceeds'] - updatedData[0][i]['cost'] < 0)? evaluate(formulas.capitalLoss, scope).toFixed(2) : Number('0').toFixed(2);
                scope.capitalLoss = updatedData[2][i]['capitalLoss'];
    
                updatedData[2][i]['discountCG'] = (dateDifference > 365)? evaluate(formulas.discountCG, scope).toFixed(2) : Number('0').toFixed(2);
                scope.discountCG = updatedData[2][i]['discountCG'];
                
                updatedData[2][i]['otherCG'] = (dateDifference < 365 && updatedData[1][i]['dos'].length !== 0)? evaluate(formulas.otherCG, scope).toFixed(2) : Number('0').toFixed(2);
                scope.otherCG = updatedData[2][i]['otherCG'];

            } catch (error){
                setErrorOpen(true);
            }

            
        }

        setCapitalGainsData(updatedData);

    }, [capitalGainsData])






    
    const handleInputChange = (outerIndex, innerIndex, objectKey, e) => {
        let newValue = e.target.value;
        
        setCapitalGainsData(prevData => {
            const newData = [...prevData];
            newData[outerIndex][innerIndex][objectKey] = newValue;
            return newData;
        });
    }


    const AddCellFunc = () => {
        const array_1_append = {company: '',dop: '', purchaseQty: '', purchaseUnit: '', purchaseBrkage: '', cost: ''};
        const array_2_append = {dos: '', saleQty: '', saleUnit: '', saleBrkage: '', proceeds: ''};
        const array_3_append = {discountCG: '', otherCG: '', capitalLoss: ''};
        let newData = [...capitalGainsData];
        newData[0].push(array_1_append);
        newData[1].push(array_2_append);
        newData[2].push(array_3_append);

        setCapitalGainsData(newData);
    };

    const RemoveCellFunc = () => {
        let newData = [...capitalGainsData];
        newData[0].pop();
        newData[1].pop();
        newData[2].pop();

        setCapitalGainsData(newData);
    }




    const SubmitFunc = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            clientId: clientData._id,
            year: clientYear,
            leadSchedule : currentModal,
            preparedDate: clientTableDetails?.preparedDate,
            scheduleRef: clientTableDetails?.scheduleRef,
            staffMember: clientTableDetails?.staffMember,
            managerReview: clientTableDetails?.managerReview,
            partnerReview: clientTableDetails?.partnerReview,
            data: capitalGainsData,
            total: totals,
            attachment: selectedFiles,
            link: links,
        };

        const submitRes = isDataAlready?
        await UpdateCapitalGainByIdAPI(sendData, clientData._id, clientYear, userToken.token)
        :
        await CreateCapitalGainAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
            setDocsChange(false);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        setLoading(false);
    }



    return (
        <>
            <SimpleClientTables 
                currentModal={currentModal} 
                clientData={clientData} 
                area={'Lead Schedule: '} 
                clientUpdateYear={clientUpdateYear} 
                clientTableDetails={clientTableDetails}
                setClientTableDetails={setClientTableDetails}
            />

            <CapitalGainsTable 
                capitalGainsData={capitalGainsData}
                handleInputChange={handleInputChange}
                totals={totals}
                discountedCG={discountedCG}
            />
            
            
            <AttachFile 
                selectedFiles={selectedFiles} 
                setSelectedFiles={setSelectedFiles}
                setDocsChange={setDocsChange} 
                setLoading={setLoading}
            />

            <LinkAttachFile 
                links={links} 
                setLinks={setLinks} 
                linkAttachable={linkAttachable} 
                setLinkAttachable={setLinkAttachable} 
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            /> 

            <div style={{display: 'flex',  marginLeft: '5px', paddingBottom: '80px'}} >
                <button className="submit-button" onClick={() => {AddCellFunc()}} ><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Cell</button>
                {(capitalGainsData[0].length>1)&&
                    <button className="submit-button" onClick={() => {RemoveCellFunc()}} ><DeleteForeverIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Remove Cell</button>
                }
                <label htmlFor="file-picker" className="submit-button" onClick={() => {}} ><AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Attach</label>
                <button  className="submit-button" onClick={() =>{setLinkAttachable(true)}} ><InsertLinkIcon  style={{fontSize: '14px', verticalAlign: 'middle' }} /> Link</button>
                <button className="submit-button" onClick={() => {SubmitFunc()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
            </div>

            <FormulaErrorSnackbar
                open={errorOpen}
                setOpen={(val) => setErrorOpen(val)}
                currentTable="Capital Gains Formulas"
            />
            
            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>
    )
}

export default CapitalGains