import React, {useState, useEffect, useContext} from 'react';
import { Box, Button } from "@mui/material";
import ReactLoading from "react-loading";
import { AuthContext } from "../../../../store/auth-context";
import { CreateFormulaAPI, GetFormulasAPI, UpdateFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";


const DividendsFormula = () => {
    const authCtx = useContext(AuthContext);
    const currentYear = new Date().getFullYear();
    const [formulas, setFormulas] = useState({
        totalDividend: 'sharesHeld*divPerShare', 
        totalFrankingCr: 'sharesHeld*divPerShare*(franking/100)*0.3/0.7', 
        owner_frankedDiv: 'totalDividend*(franking/100)*(ownership/100)', 
        owner_unFrankedDiv: 'totalDividend*(ownership/100)*(1-franking/100)', 
        owner_frankingCr: 'owner_frankedDiv*(0.3/0.7)', 
        partner_frankedDiv: 'totalDividend*(franking/100)*(1-ownership/100)', 
        partner_unFrankedDiv: 'totalDividend*(1-franking/100)*(1-ownership/100)', 
        partner_frankingCr: 'partner_frankedDiv*(0.3/0.7)'
    });
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);


    useEffect(() => {
        const GetFormulaFunc = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);

            setLoading(true);

            const getRes = await GetFormulasAPI("dividends", currentYear, userToken.token);

            if(getRes.success === true){
                if(getRes.data === null || getRes.data === undefined){
                    setDataAlready(false);
                }else {
                    setDataAlready(true);
                    setFormulas(getRes.data.formulas);
                }
            }

            setLoading(false)
        }

        GetFormulaFunc();
    }, [isDataAlready])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormulas((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            year: currentYear,
            type: "dividends",
            formulas: formulas,
        };

        const submitRes = isDataAlready?
        await UpdateFormulasAPI(sendData, "dividends", currentYear, userToken.token)
        :
        await CreateFormulaAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
          
        setLoading(false);
    };

    return (
        <>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row',  overflowX: "auto", justifyContent: "unset", alignItems: 'baseline', margin: '10px', marginTop: 50}} >
                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading">
                        <th className="th-name" colSpan="7" ></th>
                    </tr>
                    <tr className="data-table-heading" style={{backgroundColor: '#ffff99'}} >
                        <th className="th-name" style={{minWidth: "140px", color: '#000'}}>company</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#000'}}>sharesHeld</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#000'}}>divPerShare</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#000'}}>franking</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>totalDividend</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>totalFrankingCr</th>
                        <th className="th-name" style={{minWidth: "140px", color: '#000'}}>ownership</th>
                    </tr>

                    <tr  className="data-table-heading" >
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>AMAZON</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>1</div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>$</span>120
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>
                                100<span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="totalDividend"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.totalDividend}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="totalFrankingCr"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.totalFrankingCr}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500', paddingLeft: 5}}>
                                100<span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                            </div>
                        </td>
                    </tr>
                </table>
                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading" style={{backgroundColor: '#99ccff'}} >
                        <th className="th-name" style={{color: '#000'}} colSpan="3" >OWNER</th>
                    </tr>

                    <tr className="data-table-heading" style={{backgroundColor: '#99ccff'}} >
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>owner_frankedDiv</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>owner_unFrankedDiv</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>owner_frankingCr</th>
                    </tr>

                    <tr  className="data-table-heading" >
                                
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="owner_frankedDiv"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.owner_frankedDiv}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="owner_unFrankedDiv"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.owner_unFrankedDiv}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="owner_frankingCr"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.owner_frankingCr}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        
                    </tr>
                </table>

                <table className="data-table" style={{tableLayout: "fixed", flex: 1}} >
                    <tr className="data-table-heading" style={{backgroundColor: '#ff99cc'}}>
                        <th className="th-name" style={{color: '#000'}} colSpan="3" >PARTNER</th>
                    </tr>

                    <tr className="data-table-heading" style={{backgroundColor: '#ff99cc'}}>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>partner_frankedDiv</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>partner_unFrankedDiv</th>
                        <th className="th-name" style={{minWidth: "250px", color: '#000'}}>partner_frankingCr</th>
                    </tr>

                    <tr  className="data-table-heading" >
                                    
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="partner_frankedDiv"
                                    style={{flex: 1, border: '2px solid #009ec1', fontSize: '14px', padding: '5px'}}
                                    value={formulas.partner_frankedDiv}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="partner_unFrankedDiv"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.partner_unFrankedDiv}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        <td className="data-fill-field income-field">
                            <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="partner_frankingCr"
                                    style={{flex: 1, border: '2px solid #009ec1', outline: 'none', fontSize: '14px', padding: '5px'}}
                                    value={formulas.partner_frankingCr}
                                    onChange={(e) => {handleInputChange(e)}}
                                />
                            </div>
                        </td>
                        
                    </tr>
                </table>
            </div>

            
            <Box mt="20px" ml="10px">
                <Button 
                    type="submit" 
                    variant="contained" 
                    style={{color: '#fff', backgroundColor: '#009ec1', marginRight: '20px'}} 
                    onClick={() => {SubmitHandler()}}
                >
                    Submit
                </Button>
            </Box>

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}

        </>
    )
}

export default DividendsFormula;