// Resident Tax Rate Data....
export const ResidentTaxData = {
    dataArray: [
        {taxableIncome: '0.00', baseTax: '0.00',marginalRate: '0.0'},
        {taxableIncome: '18200.00', baseTax: '0.00',marginalRate: '19.0'},
        {taxableIncome: '45000.00', baseTax: '5092.00',marginalRate: '32.5'},
        {taxableIncome: '120000.00', baseTax: '29467.00',marginalRate: '37.0'},
        {taxableIncome: '180000.00', baseTax: '51667.00',marginalRate: '45.0'}
    ],
    inputs: {
        title: 'Resident Tax Rates for the year ending 30 June 2023',
        tableHeading1: 'Taxable Income',
        heading1Link: '',
        tableHeading2: 'Base Tax',
        heading2Link: '',
        tableHeading3: 'Marginal Rate',
        heading3Link: '',
        working: 'Working',
        fixedName: 'Resident Tax Rates',
    },
    links: [
        {link: '', linkTitle: ''},
    ],
    notes: [
        {note: 'Note - These do not include the Medicare levy of 2%', noteIndex: 0},
    ]   
};



// Motor Vehicle Tax Rate Data...
export const MotorVehicleData = {
    dataArray: [
        {name: 'Luxury Car Limit', year: '2022/2023', price: '71849'},
        {name: 'Luxury Car Limit for fuel efficient vehicles', year: "2022/2023", price: "84916"},
        {name: 'Cents Per KM', year: "2022/2023", price: "0.78"}
    ],
    inputs: {
        title: 'Motor Vehicle rates for the year ending 30 June 2023',
        tableHeading1: 'Luxury Car Limit',
        heading1Link: '',
        tableHeading2: 'Luxury Car Limit for fuel efficient vehicles',
        heading2Link: '',
        tableHeading3: 'Cents Per KM',
        heading3Link: '',
        working: 'Working',
        fixedName: 'Motor Vehicle Rates',
    },
    links: [
        {link: 'https://www.ato.gov.au/Business/Luxury-car-tax/In-detail/Definitions---Luxury-car-tax/', linkTitle: 'LCT'},
        {link: 'https://www.ato.gov.au/Rates/Luxury-car-tax-rate-and-thresholds/', linkTitle: '33%'},
    ],
    notes: [
        {note: 'Indexation factor for 2022/2023 financial year:', noteIndex: 0},
        {note: 'fuel efficient vehicles is 1.066', noteIndex: 1},
        {note: 'other vehicles is 1.039', noteIndex: 2},
    ]   
}



// Low Income Tax Rate Data...
export const LowIncomeTaxData = {
    dataArray: [
        {taxableIncomeName: 'Income less than', taxableIncome_1: '37000.00', taxableIncome_2: '', marginalRate: '$ 445.00'},
        {taxableIncomeName: 'Income between', taxableIncome_1: '37001.00', taxableIncome_2: '66666.00', marginalRate: '1.5 %'},
        {taxableIncomeName: 'Income Over', taxableIncome_1: '', taxableIncome_2: '', marginalRate: ''},
    ],
    inputs: {
        title: '** DEFUNCT 2020 ** Low Income Tax Offset',
        tableHeading1: 'Taxable Income',
        heading1Link: 'https://www.ato.gov.au/Individuals/Income-and-deductions/Offsets-and-rebates/Low-income-earners/',
        tableHeading2: '',
        heading2Link: '',
        tableHeading3: '',
        heading3Link: '',
        tableHeading4: '',
        heading4Link: '',
        working: 'Defunct',
        fixedName: 'Low Income Rates',
    },
    links: [
        {link: '', linkTitle: ''},
    ],
    notes: [
        {note: 'Note - No more LITO for minors from 1 July 2011', noteIndex: 0},
    ] 
}



// Low & Middle Income Tax Rate Data...
export const LowMiddleIncomeTaxData = {
    dataArray: [
        {taxableIncomeName: 'Income less than', taxableIncome_1: '37000.00', taxableIncome_2: '', marginalRate: 'Defunct'},
        {taxableIncomeName: 'Income between', taxableIncome_1: '37000.00', taxableIncome_2: '66666.00', marginalRate: 'Defunct'},
        {taxableIncomeName: 'Income between', taxableIncome_1: '48001.00', taxableIncome_2: '90000.00', marginalRate: 'Defunct'},
        {taxableIncomeName: 'Income between', taxableIncome_1: '90001.00', taxableIncome_2: '126000.00', marginalRate: 'Defunct'},
        {taxableIncomeName: 'Income Over', taxableIncome_1: '126001.00', taxableIncome_2: '', marginalRate: 'Defunct'},
    ],
    inputs: {
        title: '** DEFUNCT 2020 **  Low and Middle Income Tax Offset',
        tableHeading1: 'Taxable Income',
        heading1Link: 'https://www.ato.gov.au/Individuals/Income-and-deductions/Offsets-and-rebates/Low-and-middle-income-earners/',
        tableHeading2: '',
        heading2Link: '',
        tableHeading3: '',
        heading3Link: '',
        tableHeading4: '',
        heading4Link: '',
        working: 'Defunct',
        fixedName: 'Low & Middle Income Rates',
    },
    links: [
        {link: '', linkTitle: ''},
    ],
    notes: [
        {note: '', noteIndex: 0},
    ] 
}



// Non Resident Tax Data....
export const NonResidentTaxData = {
    dataArray: [
        {taxableIncomeName: 'Income less than', taxableIncome_1: '120000.00', taxableIncome_2: '', baseTax: '0.00', marginalRate: '32.5'},
        {taxableIncomeName: 'Income between', taxableIncome_1: '120001.00', taxableIncome_2: '180000.00', baseTax: '39000.00', marginalRate: '37.0'},
        {taxableIncomeName: 'Income Over', taxableIncome_1: '180001.00', taxableIncome_2: '', baseTax: '61200.00', marginalRate: '45.0'},
    ],
    inputs: {
        title: `Non-Resident & Foreign Resident Tax Rates for the year ending 30 June 2023`,
        tableHeading1: 'Taxable Income',
        heading1Link: 'https://www.ato.gov.au/Rates/Individual-income-tax-rates/',
        tableHeading2: '',
        heading2Link: '',
        tableHeading3: '',
        heading3Link: '',
        tableHeading4: 'Base Tax',
        heading4Link: '',
        tableHeading5: 'Marginal Rate',
        heading5Link: '',
        working: 'Working',
        fixedName: 'Non-Resident Rates',
    },
    links: [
        {link: '', linkTitle: ''},
    ],
    notes: [
        {note: '', noteIndex: 0},
    ] 
}



// Working Holiday Tax Data..
export const WorkingHolidayData = {
    dataArray: [
        {taxableIncomeName: 'Income less than', taxableIncome_1: '45000.00', taxableIncome_2: '', baseTax: '0.00', marginalRate: '15.0'},
        {taxableIncomeName: 'Income between', taxableIncome_1: '45001.00', taxableIncome_2: '120000.00', baseTax: '6750.00', marginalRate: '32.5'},
        {taxableIncomeName: 'Income between', taxableIncome_1: '120001.00', taxableIncome_2: '180000.00', baseTax: '31125', marginalRate: '37.0'},
        {taxableIncomeName: 'Income Over', taxableIncome_1: '180001.00', taxableIncome_2: '', baseTax: '53325.00', marginalRate: '45.0'},
    ],
    inputs: {
        title: `Working holiday maker Tax Rates for the year ending 30 June 2023`,
        tableHeading1: 'Taxable Income',
        heading1Link: 'https://www.ato.gov.au/Rates/Individual-income-tax-rates/',
        tableHeading2: '',
        heading2Link: '',
        tableHeading3: '',
        heading3Link: '',
        tableHeading4: 'Base Tax',
        heading4Link: '',
        tableHeading5: 'Marginal Rate',
        heading5Link: '',
        working: 'Working',
        fixedName: 'Working Holiday Rates',
    },
    links: [
        {link: '', linkTitle: ''},
    ],
    notes: [
        {note: '', noteIndex: 0},
    ] 
}