import React, { useState, useEffect } from 'react';
import {Box, useTheme, Button} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import FormulaDrawer from './TaxFormulaComps/FormulaDrawer';
import secureLocalStorage from "react-secure-storage";



const TableFormulas = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const role = secureLocalStorage.getItem("role");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentDrawer, setCurrentDrawer] = useState('');


    useEffect(() => {
        const TokenChecker = async() => {
            const userToken = await secureLocalStorage.getItem("token");
            const validRole = (role==="admin");

            if(userToken===null || userToken===undefined || !validRole){
                navigate("/");
            }
        }
        TokenChecker();
    }, [navigate])


    const DrawerFunc = (name) => {
        setDrawerOpen(true);
        setCurrentDrawer(name)
    }

    
    return (
        <>
            <Box p="20px" backgroundColor={colors.importantAccent[100]} >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Table Formulas" subtitle="Formulas can be changed from here" />
                </Box>

                <Box
                    m="8px 0 0 0"
                    width="100%"
                    height="80vh"
                    sx={{
                        "& .file-preview-container": {
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: '0 5px',
                        },
                        "& .fil-file": {
                            width: '240px',
                            height: '120px',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            margin: '5px',
                            position: 'relative',
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)',
                        },
                        "& .name-file-size": {
                            fontSize: '24px',
                            fontWeight: 600,
                            color: '#000',
                        }
                    }}
                >
                    <div className="file-preview-container">
                        
                        <Button className='fil-file' onClick={() => {DrawerFunc('Dividends')}}>
                            <p className='name-file-size' style={{top: 0, bottom: 0, left: 0, right: 0}} >Dividends</p>
                        </Button>
                        <Button className='fil-file' onClick={() => {DrawerFunc('Capital Gains')}}>
                            <p className='name-file-size' style={{top: 0, bottom: 0, left: 0, right: 0}} >Capital Gains</p>
                        </Button>
                        <Button className='fil-file' onClick={() => {DrawerFunc('Partnerships and Trusts')}}>
                            <p className='name-file-size' style={{top: 0, bottom: 0, left: 0, right: 0}} >Partnerships and Trusts</p>
                        </Button>
                        <Button className='fil-file' onClick={() => {DrawerFunc('Rent')}}>
                            <p className='name-file-size' style={{top: 0, bottom: 0, left: 0, right: 0}} >Rent</p>
                        </Button>
                        <Button className='fil-file' onClick={() => {DrawerFunc('Tax Estimate')}}>
                            <p className='name-file-size' style={{top: 0, bottom: 0, left: 0, right: 0}} >Tax Estimate</p>
                        </Button>
                            
                    </div>
                </Box>

            </Box>

            <FormulaDrawer 
                drawerOpen={drawerOpen}
                setDrawerOpen={(val) => {setDrawerOpen(val)}}
                currentDrawer={currentDrawer}
            />
        </>
    );
}

export default TableFormulas;