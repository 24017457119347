import React, {useState, useEffect, useContext} from 'react';

import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useLocation } from "react-router-dom";


import secureLocalStorage from 'react-secure-storage';
import EditRates from '../../../../components/EditRates';
import { GetTaxRateByTypeAPI, CreateTaxRateAPI, UpdateTaxRateAPI } from '../../../../util/http.js';
import TaxRateTable from './TaxRateTable';
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { AuthContext } from '../../../../store/auth-context';


const TaxRatesBox = (props) => {
    const role = secureLocalStorage.getItem("role");
    const location = useLocation();
    const authCtx = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [dataArray, setDataArray] = useState(props.taxData.dataArray);
    const [isDataAlready, setDataAlready] = useState(false);
    const [id, setId] = useState(null);
    const [inputs, setInputs] = useState(props.taxData.inputs);
    const [links, setLinks] = useState(props.taxData.links);
    const [notes, setNotes] = useState(props.taxData.notes);

    useEffect(() => {
        const GetTaxRateByTypeFunc = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            if(authToken === null || authToken ===undefined){
                return;
            }
            const userToken = JSON.parse(authToken);
            setLoading(true);

            const getRes = await GetTaxRateByTypeAPI(props.currentYear.getFullYear(), props.taxRateType, userToken.token);
            if(getRes.success===true){
                if(getRes.doc === null || getRes.doc === undefined){
                    setDataAlready(false);
                }else{
                    setDataAlready(true);
                    setNotes(getRes.doc.notes);
                    setLinks(getRes.doc.link);
                    setInputs(getRes.doc.inputs);
                    setDataArray(getRes.doc.data);
                    setId(getRes.doc._id);
                }
            }
            setLoading(false);
        }

        
        GetTaxRateByTypeFunc();

    }, [authCtx.clientListTrigger, props.currentYear, props.taxRateType])


    const addLinkorNote = (type) => {
        if(type==='link'){
            setLinks([...links, {link: '', linkTitle: ''}]);
        }else{
            setNotes([...notes, {note: '', noteIndex: notes.length}]);
        }
    }
    
    const removeLinkorNote = (type) => {
        if(type==="link"){
            const newArray = links.slice(0, links.length - 1);
            setLinks(newArray)
        }else{
            const newArray = notes.slice(0, notes.length - 1);
            setNotes(newArray)
        }
    }


    const handleInputChange = (e, rowIndex, name) => {
        const { value } = e.target;
        const updatedData = [...dataArray];
        updatedData[rowIndex][name] = value;
        setDataArray(updatedData);
    };
    

    const handleFormInput = (e) => {
        const {name, value} = e.target;

        setInputs((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleArrayInputs = (rowIndex, cellKey, type, e) => {
        if(type==="link"){
            const updatedTableData = links.map((row, index) => {
                if (index === rowIndex) {
                    return { ...row, [cellKey]: e.target.value };
                }
                return row;
            });
            setLinks(updatedTableData);
        }else{
            const updatedTableData = notes.map((row, index) => {
                if (index === rowIndex) {
                    return { ...row, [cellKey]: e.target.value };
                }
                return row;
            });
            setNotes(updatedTableData);
        }
    }


    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            type: props.taxRateType,
            year: props.currentYear.getFullYear(),
            inputs: inputs,
            link: links,
            notes: notes,
            data: dataArray,
        }

        const submitRes = isDataAlready?
        await UpdateTaxRateAPI(sendData, id, userToken.token)
        :
        await CreateTaxRateAPI(sendData, userToken.token);

        if(submitRes.success === true){
            setDataAlready(true);
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });

            props.setOpenModal(false);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        authCtx.updateTrigger();
        setLoading(false);
    }


    return (
        <>
            <div className="table-container" style={{flexDirection: 'column', paddingTop: '10px'}}>
                <p style={{color: '#000', fontSize: '20px'}}>{inputs.title} {inputs.working==='Defunct'&& '(Defunct)'}</p>


                <TaxRateTable 
                    taxRateType={props.taxRateType}
                    editable={props.editable}
                    inputs={inputs}
                    handleFormInput={handleFormInput}
                    dataArray={dataArray}
                    handleInputChange={handleInputChange}
                />

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {(role==="admin" && !location.pathname.includes("client-profile"))&&
                        <>
                            {props.editable?
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <div>
                                        <button className="submit-button" onClick={() => {addLinkorNote('link')}} ><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Links</button>
                                        {links.length>1&&
                                            <button className="submit-button" onClick={() => {removeLinkorNote('link')}} ><DeleteForeverIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Remove Links</button>
                                        }
                                        <button className="submit-button" onClick={() => {addLinkorNote('note')}} ><AddIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Add Notes</button>
                                        {notes.length>1&&
                                            <button className="submit-button" onClick={() => {removeLinkorNote('note')}} ><DeleteForeverIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Remove Notes</button>
                                        }
                                        <button className="submit-button" onClick={() => {SubmitHandler()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
                                    </div>

                                    <select
                                        className="select-option-button" 
                                        name='working' 
                                        value={inputs.working}
                                        onChange={(e) => { handleFormInput(e)}}
                                    >
                                        <option disabled>Make Tax Rate Working / Defunct</option>
                                        <option value={'Working'}>Working</option>                                            
                                        <option value={'Defunct'}>Defunct</option>
                                    </select>
                                </div>
                                :
                                <button className="submit-button" onClick={() => {props.editTableFunc(props.taxRateType)}} ><EditIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Edit Data</button>
                            
                            }
                        </>
                    }
                    
                    <EditRates
                        notes={notes}
                        links={links}
                        editable={props.editable}
                        inputs={inputs}
                        handleFormInput={handleFormInput}
                        handleArrayInputs={handleArrayInputs}
                    />

                </div>
            </div>

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.1)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </>
    );
}

export default TaxRatesBox;