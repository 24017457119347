import React, {useState} from 'react';
import { Drawer } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { toast } from "react-toastify";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ClientTable.css';
import './DataTable.css';

import GrossInterest from './Income/GrossInterest';
import Dividends from './Income/Dividends';
import BusinessIncome from './Income/BusinessIncome';
import PartnershipTrusts from './Income/PartnershipTrusts';
import CapitalGains from './Income/CapitalGains';
import SimpleCapitalGains from './Income/SimpleCapitalGains';
import Rent from './Income/Rent';
import SalaryWages from './Income/SalaryWages';


const IncomeModal = ({modalOpen, setModalOpen, currentModal, currentPage, clientData, clientUpdateYear}) => {
    const [isdocsChange, setDocsChange] = useState(false);

    const CloseModalFunc = () => {
        if(isdocsChange){
            toast.warn(`Submit data before closing to avoid confict.`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }else{
            setModalOpen(false);
        }
    }

    return (
        <>
            <Drawer 
                size={(currentModal==="Dividends" || (currentModal==="Capital Gains" && currentPage==="18.1") || currentModal==="Rent")? "full" : "md"}
                placement='right'  open={modalOpen}
            >
                <h3 className="headingTitle" >{currentModal}</h3>
                <IconButton 
                    aria-label="close" size="small"  style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                    onClick={() => {CloseModalFunc()}}
                >
                    <CloseIcon style={{fontSize: '30px', color: '#000'}}  />
                </IconButton>


                <div style={{backgroundColor: '#fff', paddingBottom: '30px', overflow: 'auto', height: '100%'}}>
                    {(currentModal==="Gross Interest")?
                        <GrossInterest currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                        :
                        (currentModal==="Dividends")?
                        <Dividends currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                        :
                        (currentModal==="Business Income")?
                        <BusinessIncome currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                        :
                        (currentModal==="Partnerships and Trusts")?
                        <PartnershipTrusts currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                        :
                        (currentModal==="Capital Gains" && currentPage==="18")?
                        <SimpleCapitalGains currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                        :
                        (currentModal==="Capital Gains" && currentPage==="18.1")?
                        <CapitalGains currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                        :
                        (currentModal==="Rent")?
                        <Rent currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                        :
                        <SalaryWages currentModal={currentModal} currentPage={currentPage} clientData={clientData} clientUpdateYear={clientUpdateYear} setDocsChange={(val) => setDocsChange(val)} />
                    }
                </div>
            
            </Drawer>

        </>
    );
}

export default IncomeModal;