import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Modal  } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'

import TaxRatesBox from '../pages/ClientProfile/ClientProfileComps/Tax Rates/TaxRatesBox';
import { ResidentTaxData, MotorVehicleData, LowIncomeTaxData, LowMiddleIncomeTaxData, NonResidentTaxData, WorkingHolidayData } from '../pages/ClientProfile/ClientProfileComps/Tax Rates/RatesData';


const EditRatesModal = ({openModal, setOpenModal, whichTaxTable, currentYear}) => {

    return (
        <Modal size="lg" open={openModal}>
            <Box  mb="10px">
                <Typography variant="h1" color={'#009ec1'} textAlign="center" >Update Tax Rate Table</Typography>
            </Box>

            <IconButton 
                aria-label="close" 
                size="small" 
                style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                onClick={() => {setOpenModal(false)}}
            >
                <CloseIcon fontSize="inherit" />
            </IconButton>



            {(whichTaxTable==="residentTaxRate")?
                <TaxRatesBox 
                    taxData={ResidentTaxData}
                    taxRateType="residentTaxRate"
                    editable={true}
                    currentYear={currentYear}
                    setOpenModal={(val) => setOpenModal(val)}
                />
                :
                (whichTaxTable==="motorVehicleRate")?
                <TaxRatesBox 
                    taxData={MotorVehicleData}
                    taxRateType="motorVehicleRate"
                    editable={true}
                    currentYear={currentYear}
                    setOpenModal={(val) => setOpenModal(val)}
                />
                :
                (whichTaxTable==="lowIncomeTaxRate")?
                <TaxRatesBox 
                    taxData={LowIncomeTaxData}
                    taxRateType="lowIncomeTaxRate"
                    editable={true}
                    currentYear={currentYear}
                    setOpenModal={(val) => setOpenModal(val)}
                />
                :
                (whichTaxTable==="lowMidleIncomeTaxRate")?
                <TaxRatesBox 
                    taxData={LowMiddleIncomeTaxData}
                    taxRateType="lowMidleIncomeTaxRate"
                    editable={true}
                    currentYear={currentYear}
                    setOpenModal={(val) => setOpenModal(val)}
                />
                :
                (whichTaxTable==="nonResidentTaxRate")?
                <TaxRatesBox 
                    taxData={NonResidentTaxData}
                    taxRateType="nonResidentTaxRate"
                    editable={true}
                    currentYear={currentYear}
                    setOpenModal={(val) => setOpenModal(val)}
                />
                :
                <TaxRatesBox 
                    taxData={WorkingHolidayData}
                    taxRateType="workingHolidayTaxRate"
                    editable={true}
                    currentYear={currentYear}
                    setOpenModal={(val) => setOpenModal(val)}
                />
            }


        </Modal>
    )
}

export default EditRatesModal;