import React, {useState, useContext} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AuthContext } from "../../../../../store/auth-context";
import { toast } from "react-toastify";
import {UploadImageAPI, DeleteImageAPI} from '../../../../../util/http';
import { Config } from '../../../../../util/config';


const PropertyTable = ({propertyData, setPropertyData, setDocsChange, setLoading}) => {
    const authCtx = useContext(AuthContext);
    const [currentRow, seCurrentRow] = useState("");
    const [currenCell, setCurrentCell] = useState("");
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    const handleTextChange = (rowIndex, cellKey, value) => {
        const updatedTableData = propertyData.map((row, index) => {
            if (index === rowIndex) {
                return { ...row, [cellKey]: value };
            }
            return row;
        });
        setPropertyData(updatedTableData);
    };


    const UploadFile = async(image, userToken) => {
        setLoading(true);
        const data = new FormData();
        data.append('file', image);        
        
        const uploadRes = await UploadImageAPI(data, userToken.token);
        if(uploadRes.success===true){
            toast.success(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_RIGHT,
            });

            const updatedTableData = propertyData.map((row, index) => {
                if (index === currentRow) {
                    return { ...row, [currenCell]: uploadRes.file };
                }
                return row;
            });
            setPropertyData(updatedTableData);
            setDocsChange(true);
        }else{
            toast.error(`${uploadRes?.message}.`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        setLoading(false);
    }



    // File Picker Code..
    const handleFileChange = (eventfile) => {
        const selectedFile = eventfile;
        const userToken = JSON.parse(authCtx.token);
        UploadFile(selectedFile[0], userToken);
    }

    // Delete Selcted File..
    const DeleteFileChange = async(rowIndex, cellKey, fileName) => {
        setLoading(true);
        const userToken = JSON.parse(authCtx.token)
        const deleteRes = await DeleteImageAPI(fileName, userToken.token);

        if(deleteRes.success===true){
            toast.success(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            const updatedTableData = propertyData.map((row, index) => {
                if (index === rowIndex) {
                    return { ...row, [cellKey]: "" };
                }
                return row;
            });
            setPropertyData(updatedTableData);
            setDocsChange(true);
        }else{
            toast.error(`${deleteRes?.message}.`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        setLoading(false);
    }


    const openFileInNewTab = (file) => {
        window.open(`${Config.ImageUrl}${file.filename}`, '_blank');
    };

    
    return (
        <div className="table-container" style={{flexDirection: 'column',  overflowX: "auto"}}>
                <table className="data-table" style={{tableLayout: "fixed"}}>
                    <thead>
                        <tr className="data-table-heading" >
                            {Object.keys(propertyData[0]).map((key, colIndex) => {
                                return(
                                    <React.Fragment key={colIndex}>
                                        {(key!=="id")&&
                                            <th className="th-name" style={key==="name"?{minWidth: "320px"} : {minWidth: "250px"}} key={key}>
                                                {key==="name"? "" : key.split("_").join(" ").toUpperCase()}
                                            </th>
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {propertyData.map((item, rowIndex) => {
                            return(
                                <tr key={rowIndex} className="data-table-heading" >
                                    {Object.keys(item).map((key, colIndex) => {
                                        return(
                                            (key!=="id")&&
                                            <td key={colIndex} className="data-fill-field income-field" >
                                                <div style={{display: 'flex', alignItems: 'center', fontWeight: '500'}}>

                                                    {(key==="name")?
                                                        <span style={{marginLeft: '5px', flex: 1}}>{item[key]}</span>
                                                        :
                                                        <>
                                                            {(key.includes("property") || key.includes("comment"))?
                                                                <>
                                                                    <input
                                                                        type={(rowIndex===1 && key.includes("property"))?  "date" : "text"}
                                                                        placeholder=""
                                                                        style={{flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                                        value={item[key]}
                                                                        onChange={(e) => {
                                                                            if((rowIndex===3 || rowIndex===2) && key.includes("property")){
                                                                                if (e.target.value!=='-' && /^-?\d*\.?\d*$/.test(e.target.value)){
                                                                                    handleTextChange(rowIndex, key, e.target.value);
                                                                                }
                                                                            }else{
                                                                                handleTextChange(rowIndex, key, e.target.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                    {(rowIndex===3 && key.includes("property"))&&
                                                                        <span style={{fontSize: "14px", padding: "5px", color: '#000'}}>%</span>
                                                                    }
                                                                </>
                                                                :
                                                                <div style={{flex: 1,display: "flex", alignItems: 'center'}}>
                                                                    {(typeof(item[key])==="string") ?
                                                                        <input 
                                                                            type="text"
                                                                            placeholder={""}
                                                                            style={urlRegex.test(item[key])? {flex: 1, border: 'none', color: '#009ec1', outline: 'none', fontSize: '14px', padding: '5px'} : {flex: 1, border: 'none', outline: 'none', fontSize: '14px', padding: '5px'}}
                                                                            value={item[key]}
                                                                            onChange={(e) => { handleTextChange(rowIndex, key, e.target.value); }}
                                                                        />
                                                                        :
                                                                        <div style={{flex: 1, display: 'flex', cursor: 'pointer' }} onClick={() => { openFileInNewTab(item[key])}}>
                                                                            <span style={{padding: '5px', border: '1px solid red', color: 'red', flex: 1}}>
                                                                                {item[key].filename.slice(0,10) + (item[key].filename.length>10? `...${item[key].filename.slice(-4)}` : '')}
                                                                                {" "}<CloseIcon style={{fontSize: '16px', verticalAlign: 'middle', cursor: 'pointer' }} onClick={(e) => {e.stopPropagation();  DeleteFileChange(rowIndex, key, item[key].filename)}} />
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    <div style={{display: 'flex'}}>
                                                                        <label 
                                                                            htmlFor="prop-file-picker" 
                                                                            className="submit-button" 
                                                                            style={{margin: 0, borderRadius: 0}}
                                                                            onClick={() => {seCurrentRow(rowIndex); setCurrentCell(key)}}
                                                                        >
                                                                            <AttachFileIcon style={{fontSize: '14px', verticalAlign: 'middle' }} />
                                                                        </label>
                                                                        <input 
                                                                            type="file"
                                                                            name="prop-file-picker"
                                                                            id="prop-file-picker"
                                                                            onChange={(e) => {
                                                                                handleFileChange(e.target.files);
                                                                            }}
                                                                            onClick={(event)=> { 
                                                                                event.target.value = null
                                                                            }}
                                                                            className='file-input'
                                                                            style={{display: 'none'}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}                 
                    </tbody>

                </table>
            </div>
    );
}

export default PropertyTable