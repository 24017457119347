import { Config } from '../../util/config';


const styles = {
    borderAll: {
        border: {
            top: { style: 'thin', color: { argb: '000000' } }, 
            left: { style: 'thin', color: { argb: '000000' } }, 
            bottom: { style: 'thin', color: { argb: '000000' } }, 
            right: { style: 'thin', color: { argb: '000000' } } 
        }
    },
}

export const IncomePages = (workbook, data, clientData, clientYear) => {
    let incomeList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '12', '14', '15', '18', '20', '24'];

    for(let i=0; i<incomeList.length; i++){
        let incomeSheet = workbook.getWorksheet(incomeList[i]);

        incomeSheet.getCell('C4').value = clientData.name;
        incomeSheet.getCell('C6').value = clientYear;
        incomeSheet.getCell('H5').value = clientData.staff;
        incomeSheet.getCell('H6').value = clientData.manager;
        incomeSheet.getCell('H7').value = clientData.partner;

        const currentIncomeData = data.income.find(item =>  (item.scheduleRef === incomeList[i]))
        if(currentIncomeData === undefined || currentIncomeData === null){
            continue;
        }

        incomeSheet.getCell('C7').value = currentIncomeData['preparedDate'];

        let startingIndex = 0;
        for( ; startingIndex<currentIncomeData['data'].length; startingIndex++){
            if((10+startingIndex)>35){
                incomeSheet.insertRow(10+startingIndex, [], 'i');
            }

            incomeSheet.mergeCells(10+startingIndex, 1, 10+startingIndex, 5);
            incomeSheet.getCell(`A${10+startingIndex}`).value = currentIncomeData['data'][startingIndex]['name'];
            incomeSheet.getCell(`F${10+startingIndex}`).value = currentIncomeData['data'][startingIndex]['ref'];
        
            if(currentIncomeData['scheduleRef']==='10'){
                incomeSheet.getCell(`G${10+startingIndex}`).value = currentIncomeData['data'][startingIndex]['total']!=='-'? Number(currentIncomeData['data'][startingIndex]['total']) : 0;
                incomeSheet.getCell(`H${10+startingIndex}`).value = currentIncomeData['data'][startingIndex]['clientShare']!=='-'? Number(currentIncomeData['data'][startingIndex]['clientShare']) : 0;
            }else if(currentIncomeData['scheduleRef']==='15'){
                incomeSheet.getCell(`H${10+startingIndex}`).value = currentIncomeData['data'][startingIndex]['total']!=='-'? Number(currentIncomeData['data'][startingIndex]['total']) : 0;
            }else{
                incomeSheet.getCell(`G${10+startingIndex}`).value = currentIncomeData['data'][startingIndex]['taxWithheld']!=='-'? Number(currentIncomeData['data'][startingIndex]['taxWithheld']) : 0;
                incomeSheet.getCell(`H${10+startingIndex}`).value = currentIncomeData['data'][startingIndex]['gross']!=='-'? Number(currentIncomeData['data'][startingIndex]['gross']) : 0; 
            }
            incomeSheet.getCell(`F${10+startingIndex}`).style = styles.borderAll;
            incomeSheet.getCell(`G${10+startingIndex}`).style = styles.borderAll;
            incomeSheet.getCell(`G${10+startingIndex}`).numFmt = '$#,##0.00';
            incomeSheet.getCell(`H${10+startingIndex}`).style = styles.borderAll;
            incomeSheet.getCell(`H${10+startingIndex}`).numFmt = '$#,##0.00';

            
            // Adjusting Cell Height of Name section in Table..
            incomeSheet.getRow(10+startingIndex).height = currentIncomeData['data'][startingIndex]['name'].length>50?
            (currentIncomeData['data'][startingIndex]['name'].length/50)*15
            : 
            15;
        }

        if((10+startingIndex)>35) {
            incomeSheet.mergeCells(startingIndex+14, 1, startingIndex+14, 5);
            if(currentIncomeData['scheduleRef']==='15'){
                incomeSheet.getCell(`H${startingIndex+14}`).value = Number(currentIncomeData['total']);
                incomeSheet.getCell(`H${startingIndex+14}`).numFmt = '$#,##0.00';
            }else {
                incomeSheet.getCell(`G${startingIndex+14}`).value = Number(currentIncomeData['total']);
                incomeSheet.getCell(`G${startingIndex+14}`).numFmt = '$#,##0.00';
                incomeSheet.getCell(`H${startingIndex+14}`).value = Number(currentIncomeData['clientShare']);
                incomeSheet.getCell(`H${startingIndex+14}`).numFmt = '$#,##0.00';
            }
        }else{
            incomeSheet.mergeCells(40, 1, 40, 5);
            if(currentIncomeData['scheduleRef']==='15'){
                incomeSheet.getCell(`H40`).value = Number(currentIncomeData['total']);
                incomeSheet.getCell(`H40`).numFmt = '$#,##0.00';
            }else {
                incomeSheet.getCell(`G40`).value = Number(currentIncomeData['total']);
                incomeSheet.getCell(`G40`).numFmt = '$#,##0.00';
                incomeSheet.getCell(`H40`).value = Number(currentIncomeData['clientShare']);
                incomeSheet.getCell(`H40`).numFmt = '$#,##0.00';
            }
        }


        // Assigning Files..
        if(currentIncomeData['attachment'].length>0){
            incomeSheet.getCell(`J9`).value = 'FILES:';
            incomeSheet.getCell('J9').font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
        }
        let fileIndex = 10;
        for(let j=0; j<currentIncomeData['attachment'].length; j++){
            incomeSheet.getCell(`J${10+j}`).value = { 
                text: currentIncomeData['attachment'][j]['originalname'],
                hyperlink: `${Config.ImageUrl}${currentIncomeData['attachment'][j]['filename']}`, 
            };
            incomeSheet.getCell(`J${10+j}`).font = {color: { argb: '3366cc' }}
            fileIndex += j;
        }
        fileIndex += 5;

        // Assigning Links..
        if(currentIncomeData['link'].length>0){
            incomeSheet.getCell(`J${fileIndex}`).value = 'LINKS:';
            incomeSheet.getCell(`J${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
        }
        let linkFilesLength = 0;
        for(let j=0; j<currentIncomeData['link'].length; j++) {
            incomeSheet.getCell(`J${fileIndex+1+(j+linkFilesLength)}`).value = {
                text: currentIncomeData['link'][j]['link'],
                hyperlink: currentIncomeData['link'][j]['link'],
            }
            incomeSheet.getCell(`J${fileIndex+1+(j+linkFilesLength)}`).font = {color: { argb: '3366cc' }}
            
            for(let k=0; k<currentIncomeData['link'][j]['attachments'].length; k++) {
                incomeSheet.getCell(`K${fileIndex+2+(j+linkFilesLength)+k}`).value = { 
                    text: currentIncomeData['link'][j]['attachments'][k]['originalname'],
                    hyperlink: `${Config.ImageUrl}${currentIncomeData['link'][j]['attachments'][k]['filename']}`, 
                };
                incomeSheet.getCell(`K${fileIndex+2+(j+linkFilesLength)+k}`).font = {color: { argb: '3366cc' }}
            }
            linkFilesLength += currentIncomeData['link'][j]['attachments'].length;
            linkFilesLength += 1;
        }
    }
}



export const IncomePartnershipPage = (workbook, data, clientData, clientYear) => {
    let partnershipSheet = workbook.getWorksheet('13');

    partnershipSheet.getCell('C4').value = clientData.name;
    partnershipSheet.getCell('C6').value = clientYear;
    partnershipSheet.getCell('H5').value = clientData.staff;
    partnershipSheet.getCell('H6').value = clientData.manager;
    partnershipSheet.getCell('H7').value = clientData.partner;

    const currentPartnershipData = (data.partnership.length!==0)? data.partnership[0] : null;
    if(currentPartnershipData === undefined || currentPartnershipData === null){
        return;
    }

    partnershipSheet.getCell('C7').value = currentPartnershipData['preparedDate'];

    let startingIndex = 0;
    for( ; startingIndex<currentPartnershipData['data'].length; startingIndex++){
        if((10+startingIndex)>35){
            partnershipSheet.insertRow(10+startingIndex, [], 'i');
        }

        partnershipSheet.mergeCells(10+startingIndex, 1, 10+startingIndex, 5);
        partnershipSheet.getCell(`A${10+startingIndex}`).value = currentPartnershipData['data'][startingIndex]['name'];
        partnershipSheet.getCell(`F${10+startingIndex}`).value = currentPartnershipData['data'][startingIndex]['ref'];
        partnershipSheet.getCell(`F${10+startingIndex}`).style = styles.borderAll;
        partnershipSheet.getCell(`G${10+startingIndex}`).value = currentPartnershipData['data'][startingIndex]['taxWithheld']!=='-'? Number(currentPartnershipData['data'][startingIndex]['taxWithheld']) : 0;
        partnershipSheet.getCell(`G${10+startingIndex}`).style = styles.borderAll;
        partnershipSheet.getCell(`G${10+startingIndex}`).numFmt = '$#,##0.00';
        partnershipSheet.getCell(`H${10+startingIndex}`).value = currentPartnershipData['data'][startingIndex]['gross']!=='-'? Number(currentPartnershipData['data'][startingIndex]['gross']) : 0;
        partnershipSheet.getCell(`H${10+startingIndex}`).style = styles.borderAll;
        partnershipSheet.getCell(`H${startingIndex+14}`).numFmt = '$#,##0.00';

        // Adjusting Cell Height of Name section in Table..
        partnershipSheet.getRow(10+startingIndex).height = currentPartnershipData['data'][startingIndex]['name'].length>50?
        (currentPartnershipData['data'][startingIndex]['name'].length/50)*15
        : 
        15;
    }

    if((10+startingIndex)>35) {
        partnershipSheet.getCell(`G${startingIndex+14}`).value = (currentPartnershipData['totalTaxWithheld']!==undefined && currentPartnershipData['totalTaxWithheld']!==null)? Number(currentPartnershipData['totalTaxWithheld']) : 0;
        partnershipSheet.getCell(`G${startingIndex+14}`).numFmt = '$#,##0.00';
        partnershipSheet.getCell(`H${startingIndex+14}`).value = Number(currentPartnershipData['total']);
        partnershipSheet.getCell(`H${startingIndex+14}`).numFmt = '$#,##0.00';
    }else{
        partnershipSheet.getCell(`G40`).value = (currentPartnershipData['totalTaxWithheld']!==undefined && currentPartnershipData['totalTaxWithheld']!==null)? Number(currentPartnershipData['totalTaxWithheld']) : 0;
        partnershipSheet.getCell(`G40`).numFmt = '$#,##0.00';
        partnershipSheet.getCell(`H40`).value = Number(currentPartnershipData['total']);
        partnershipSheet.getCell(`H40`).numFmt = '$#,##0.00';
    }

    partnershipSheet.getCell(`K21`).value =  (currentPartnershipData['secondLastYearCost']!==undefined && currentPartnershipData['secondLastYearCost']!==null)? Number(currentPartnershipData['secondLastYearCost']) : 0;
    partnershipSheet.getCell(`K22`).value =  (currentPartnershipData['lastYearCost']!==undefined && currentPartnershipData['lastYearCost']!==null)? Number(currentPartnershipData['lastYearCost']) : 0;
    partnershipSheet.getCell(`K23`).value =  Number(currentPartnershipData['total']);
    partnershipSheet.getCell(`K24`).value =  (currentPartnershipData['taxDeferredTotal']!==undefined && currentPartnershipData['taxDeferredTotal']!==null)? Number(currentPartnershipData['taxDeferredTotal']) : 0;
    
    partnershipSheet.getCell(`K27`).value = Number(currentPartnershipData['costObject']['cost']);
    partnershipSheet.getCell(`K28`).value = (currentPartnershipData['taxDeferredTotal']!==undefined && currentPartnershipData['taxDeferredTotal']!==null)? Number(currentPartnershipData['taxDeferredTotal']) : 0;
    partnershipSheet.getCell(`K29`).value = (currentPartnershipData['adjustedCost']!==undefined && currentPartnershipData['adjustedCost']!==null)? Number(currentPartnershipData['adjustedCost']) : 0;





    // Assigning Files..
    if(currentPartnershipData['attachment'].length>0){
        partnershipSheet.getCell(`M9`).value = 'FILES:';
        partnershipSheet.getCell('M9').font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
    }
    let fileIndex = 10;
    for(let j=0; j<currentPartnershipData['attachment'].length; j++){
        partnershipSheet.getCell(`M${10+j}`).value = { 
            text: currentPartnershipData['attachment'][j]['originalname'],
            hyperlink: `${Config.ImageUrl}${currentPartnershipData['attachment'][j]['filename']}`, 
        };
        partnershipSheet.getCell(`M${10+j}`).font = {color: { argb: '3366cc' }}
        fileIndex += j;
    }
    fileIndex += 5;


    // Assigning Links..
    if(currentPartnershipData['link'].length>0){
        partnershipSheet.getCell(`M${fileIndex}`).value = 'LINKS:';
        partnershipSheet.getCell(`M${fileIndex}`).font = { name: 'Arial Black', color: { argb: '00f75472' }, family: 2 };
    }
    let linkFilesLength = 0;
    for(let j=0; j<currentPartnershipData['link'].length; j++) {
        partnershipSheet.getCell(`M${fileIndex+1+(j+linkFilesLength)}`).value = {
            text: currentPartnershipData['link'][j]['link'],
            hyperlink: currentPartnershipData['link'][j]['link'],
        }
        partnershipSheet.getCell(`M${fileIndex+1+(j+linkFilesLength)}`).font = {color: { argb: '3366cc' }}
        
        for(let k=0; k<currentPartnershipData['link'][j]['attachments'].length; k++) {
            partnershipSheet.getCell(`N${fileIndex+2+(j+linkFilesLength)+k}`).value = { 
                text: currentPartnershipData['link'][j]['attachments'][k]['originalname'],
                hyperlink: `${Config.ImageUrl}${currentPartnershipData['link'][j]['attachments'][k]['filename']}`, 
            };
            partnershipSheet.getCell(`N${fileIndex+2+(j+linkFilesLength)+k}`).font = {color: { argb: '3366cc' }}
        }
        linkFilesLength += currentPartnershipData['link'][j]['attachments'].length;
        linkFilesLength += 1;
    }

}