import React, {useContext, useState} from 'react';
import { Box, Button, TextField, IconButton, Typography } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { Modal  } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import ReactLoading from "react-loading";
import secureLocalStorage from 'react-secure-storage';
import CloseIcon from '@mui/icons-material/Close';
import {CreateUserAPI, CreateManagerAPI, CreatePartnerAPI, CreateStaffAPI} from '../util/http';
import { AuthContext } from '../store/auth-context';


const ClientFormModal = ({openClientModal, setOpenClientModal, createMesssage, id}) => {
    const authCtx = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const role = secureLocalStorage.getItem('role');
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    const initialValues = {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        position: "",
        office: "",
        age: "",
        salary: "",
        tfn: "",
        abn: "",
    };

    
    const initialManagerValues= {
        name: '',
        username: '',
        password: '',
        confirmPassword: '',
        email: '',
    };

    const checkoutSchema = yup.object().shape({
        name:yup.string().required("Required"),
        password:yup.string().min(5, 'Password must be at least 5 characters long').required("Required"),
        confirmPassword:yup.string().oneOf([yup.ref('password'), null], 'Must match with Password').required("Required"),
        email:yup.string().email("Invalid email!").required("Required"),
        position:yup.string().required("Required"),
        office:yup.string().required("Required"),
        age:yup.string().required("Required"),
        salary:yup.string().required("Required"),
        tfn:yup.string().min(9, 'Must be exactly 9 digits').max(9, 'Must be exactly 9 digits').required("Required"),
        abn:yup.string().min(11, 'Must be exactly 11 digits').max(11, 'Must be exactly 11 digits').required("Required"),
        username: yup.string().required("Required").test('is-valid-username','Invalid username',(value) => !/\s/.test(value) || yup.string().email().isValidSync(value)),
    });


    
    const handleFormSubmit = (values) => {
        values.username = values.email;

        let conditionalArr = Object.keys(values).map((item) => {
            return values[item].length === 0;
        })
        const validEmail = values.email===undefined? true : (emailRegExp.test(values.email));

        if((conditionalArr.includes(true)) || (values.password.length<5) || (values.password !==values.confirmPassword) || (!validEmail)){
            return;
        }

        values.parentId = id;

        setLoading(true);

        const CreateUserFunc = async() => {
            const userToken = JSON.parse(authCtx.token)

            const createUserRes = (createMesssage==="Create Partner")?
            await CreatePartnerAPI(values, userToken.token)
            :
            (createMesssage==="Create Manager")?
            await CreateManagerAPI(values, userToken.token)
            :
            (createMesssage==="Create Staff")?
            await CreateStaffAPI(values, userToken.token)
            :
            await CreateUserAPI(values, userToken.token)
            
            if(createUserRes.success===true){
                toast.success(`${createUserRes?.message}.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }else{
                toast.error(`${createUserRes?.message}.`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

            setLoading(false);
            authCtx.updateTrigger();
            setOpenClientModal(false);

        }

        CreateUserFunc();

    };

    return (
        <Modal size="lg" open={openClientModal} >
            <Box  mb="10px">
                <Typography variant="h1" color={'#009ec1'} textAlign="center" >{createMesssage}</Typography>
            </Box>
            
            <IconButton 
                aria-label="close" 
                size="small" 
                style={{position: 'absolute', right: '10px', top: '10px', fontSize: '30px'}} 
                onClick={() => {setOpenClientModal(false)}}
            >
                <CloseIcon fontSize="inherit" />
            </IconButton>

            
                <Formik onSubmit={handleFormSubmit} initialValues={(createMesssage==="Create Partner" || createMesssage==="Create Manager" || createMesssage==="Create Staff")? initialManagerValues : initialValues} validationSchema={checkoutSchema}>
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            {(createMesssage==="Create Partner" || createMesssage==="Create Manager" || createMesssage==="Create Staff")?
                                <>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            name="name"
                                            error={!!touched.name && !!errors.name}
                                            helperText={touched.name && errors.name}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            error={!!touched.email && !!errors.email}
                                            helperText={touched.email && errors.email}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="password"
                                            label="Password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            name="password"
                                            error={!!touched.password && !!errors.password}
                                            helperText={touched.password && errors.password}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Confirm Password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                            name="confirmPassword"
                                            error={!!touched.confirmPassword && !!errors.confirmPassword }
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        {/* <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            error={!!touched.email && !!errors.email}
                                            helperText={touched.email && errors.email}
                                            sx={{ gridColumn: "span 4" }}
                                        /> */}
                                    </Box>
                                    
                                    <Box display="flex" justifyContent="end" mt="20px">
                                        <Button type="submit" variant="contained" style={{color: '#fff', backgroundColor: '#009ec1'}} onClick={() => {handleFormSubmit(values)}}>{createMesssage}</Button>
                                    </Box>
                                </>
                                :
                                <>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            name="name"
                                            error={!!touched.name && !!errors.name}
                                            helperText={touched.name && errors.name}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            error={!!touched.email && !!errors.email}
                                            helperText={touched.email && errors.email}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="password"
                                            label="Password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            name="password"
                                            error={!!touched.password && !!errors.password}
                                            helperText={touched.password && errors.password}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Confirm Password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                            name="confirmPassword"
                                            error={!!touched.confirmPassword && !!errors.confirmPassword }
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Position"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.position}
                                            name="position"
                                            error={!!touched.position && !!errors.position}
                                            helperText={touched.position && errors.position}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Office"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.office}
                                            name="office"
                                            error={!!touched.office && !!errors.office}
                                            helperText={touched.office && errors.office}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="age"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                if(/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleChange(e)
                                                }
                                            }}
                                            value={values.age}
                                            name="age"
                                            error={!!touched.age && !!errors.age}
                                            helperText={touched.age && errors.age}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Salary"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleChange(e);
                                                }
                                            }}
                                            value={values.salary}
                                            name="salary"
                                            error={!!touched.salary && !!errors.salary}
                                            helperText={touched.salary && errors.salary}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="TFN"
                                            inputProps={{ maxLength: 9 }}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleChange(e);
                                                }
                                            }}
                                            value={values.tfn}
                                            name="tfn"
                                            error={!!touched.tfn && !!errors.tfn}
                                            helperText={touched.tfn && errors.tfn}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="ABN"
                                            inputProps={{ maxLength: 11 }}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                if (/^-?\d*\.?\d*$/.test(e.target.value)){
                                                    handleChange(e);
                                                }
                                            }}
                                            value={values.abn}
                                            name="abn"
                                            error={!!touched.abn && !!errors.abn}
                                            helperText={touched.abn && errors.abn}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                    </Box>
                                    
                                    <Box display="flex" justifyContent="end" mt="20px">
                                        <Button type="submit" variant="contained" style={{color: '#fff', backgroundColor: '#009ec1'}} onClick={(e) => {e.preventDefault(); handleFormSubmit(values)}}>{createMesssage}</Button>
                                    </Box>

                                </>   
                            }
                        </form>
                    )}
                </Formik>

            

            {/* Loading Screen */}
			{(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
        </Modal>
    );
}

export default ClientFormModal;