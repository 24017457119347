import React, {useState, useEffect, useContext} from 'react';
import SendIcon from '@mui/icons-material/Send';

import {SimpleClientTables} from '../../ClientTables/ClientTables';
import { CreateRentAPI, GetRentByIdAPI, updateRentByIdAPI, GetFormulasAPI } from '../../../../util/http';
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../../../../store/auth-context";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

import { RentPropertyData, RentIncomeData, RentExpensesData, RentReconciliationData, RentRepairMaintenanceData, RentDepreciationPropsData, RentNRIPData } from './RentData/RentData';
import PropertyTable from './RentTables/PropertyTable';
import RentIncomeTable from './RentTables/RentIncomeTable';
import ExpensesTable from './RentTables/ExpensesTable';
import ReconciliationRentTable from './RentTables/ReconciliationRentTable';
import NRIPStatementTable from './RentTables/NRIPStatementTable';
import RepairMaintenanceTable from './RentTables/RepairMaintenanceTable';
import DepreciationonPropertyTable from './RentTables/DepreciationonPropertyTable';
import FormulaErrorSnackbar from '../../../../components/FormulaErrorSnackbar';



const Rent = ({currentModal, currentPage, clientData, clientUpdateYear, setDocsChange}) => {
    const authCtx = useContext(AuthContext);
    const clientYear = clientUpdateYear.getFullYear();
    
    const [propertyData, setPropertyData] = useState(RentPropertyData);
    const [incomeDataArray, setDataIncome] = useState(RentIncomeData);
    const [grossRentData, setGrossRentData] = useState({ property_1: 0 });
    const [expensesData, setExpensesData] = useState(RentExpensesData);
    const [totalExpenseData, setTotalExpenseData] = useState({ property_1: 0 })
    const [netRentData, setNetRentData] = useState({ property_1: 0 });
    const [totalRent, setTotalRent] = useState('');
    const [reconciliationRentData, setReconciliationRentData] = useState(RentReconciliationData);
    const [totalReconciliationRent, setTotalReconciliationRent] = useState({ property_1: 0 });
    const [NRIPStatementData, setNRIPStatementData] = useState(RentNRIPData)
    const [differenceObj, setDifferenceObj] = useState({ property_1: 0 })
    const [repairMaintenanceData, setRepairMaintenanceData] = useState(RentRepairMaintenanceData);
    const [totalRepairMaintain, setTotalRepairMaintain] = useState({ property_1: 0 })
    const [depreciatePropsData, setDepreciatePropsData] = useState(RentDepreciationPropsData);

    const [clientTableDetails, setClientTableDetails] = useState({
        preparedDate: '',
        scheduleRef: currentPage,
        staffMember: 'No',
        managerReview: 'No',
        partnerReview: 'No',
    })
    
    const [loading, setLoading] = useState(false);
    const [isDataAlready, setDataAlready] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);

    const [formulas, setFormulas] = useState({
        netRent: '(grossRent - totalExpenses) * percentOwnership/100',
        depreciationRate: '200/effectiveLife',
        depreciation: 'cost*(depreciationRate/100)*totalDays/365',
        wdv: 'cost - depreciation',
        depreciation_2: 'owdv*(depreciationRate/100)',
        wdv_2: 'owdv - depreciation_2'
    });


    useEffect(() => {
        const GetRentDataFunc = async() => {
            const authToken = await secureLocalStorage.getItem("token");
            const userToken = JSON.parse(authToken);
            setLoading(true);

            const getDataRes = await GetRentByIdAPI(clientData._id, clientYear, userToken.token);
            if(getDataRes.success===true){
                if(getDataRes.data === null || getDataRes.data === undefined){
                    setDataAlready(false);
                }else{
                    setDataAlready(true);
                    setPropertyData(getDataRes.data.propertyData);
                    setDataIncome(getDataRes.data.incomeData);
                    setGrossRentData(getDataRes.data.grossRentData);
                    setExpensesData(getDataRes.data.expensesData);
                    setTotalExpenseData(getDataRes.data.totalExpensesData);
                    setNetRentData(getDataRes.data.netRentData);
                    setTotalRent(getDataRes.data.total);
                    setReconciliationRentData(getDataRes.data.reconciliationRentData);
                    setTotalReconciliationRent(getDataRes.data.totalReconciliationRent);
                    setRepairMaintenanceData(getDataRes.data.repairMaintenanceData);
                    setTotalRepairMaintain(getDataRes.data.totalRepairMaintanace);
                    setDepreciatePropsData(getDataRes.data.depreciatePropsData);
                    setNRIPStatementData(getDataRes.data.NRIPerStatement)
                    setDifferenceObj(getDataRes.data.difference);

                    setClientTableDetails({preparedDate: getDataRes.data.preparedDate, scheduleRef: getDataRes.data.scheduleRef, staffMember: getDataRes.data.staffMember, managerReview: getDataRes.data.managerReview, partnerReview: getDataRes.data.partnerReview});
                }
            }

            const getFormulas = await GetFormulasAPI('rent', clientYear, userToken.token);
            if(getFormulas.success === true){
                if(getFormulas.data !== null && getFormulas.data !== undefined){
                    setFormulas(getFormulas.data.formulas)
                }
            }

            setLoading(false);
        }

        GetRentDataFunc()
    }, [isDataAlready])


    const addPropertyColumn = () => {
        const objSize = Object.keys(propertyData[0]).length;
        
        // For property Table..
        const updateData_1 = propertyData.map((item) => {
            let propertyKey = `property_${Math.ceil((objSize)/3)}`;
            let refrenceKey = `refrence_${Math.ceil((objSize)/3)}`;
            let commentKey = `comment_${Math.ceil((objSize)/3)}`;
            return{...item, [propertyKey]: "", [refrenceKey]: "", [commentKey]: ""};
        })
        setPropertyData(updateData_1);

        // For Exspenses Table..
        const updateData_2 = expensesData.map((item) => {
            let propertyKey = `property_${Math.ceil((objSize)/3)}`;
            let refrenceKey = `refrence_${Math.ceil((objSize)/3)}`;
            let commentKey = `comment_${Math.ceil((objSize)/3)}`;
            return{...item, [propertyKey]: "", [refrenceKey]: "", [commentKey]: ""};
        })
        setExpensesData(updateData_2);

        // For IncomeData Table..
        const updateData_3 = incomeDataArray.map((item) => {
            let propertyKey = `property_${Math.ceil((objSize)/3)}`;
            let refrenceKey = `refrence_${Math.ceil((objSize)/3)}`;
            let commentKey = `comment_${Math.ceil((objSize)/3)}`;
            return{...item, [propertyKey]: "", [refrenceKey]: "", [commentKey]: ""};
        })
        setDataIncome(updateData_3);

        // For Rental Statement Table..
        const updateData_4 = reconciliationRentData.map((item) => {
            let propertyKey = `property_${Math.ceil((objSize)/3)}`;
            let refrenceKey = `refrence_${Math.ceil((objSize)/3)}`;
            let commentKey = `comment_${Math.ceil((objSize)/3)}`;
            return{...item, [propertyKey]: "", [refrenceKey]: "", [commentKey]: ""};
        })
        setReconciliationRentData(updateData_4);

        const updateData_7 = NRIPStatementData.map((item) => {
            let propertyKey = `property_${Math.ceil((objSize)/3)}`;
            let refrenceKey = `refrence_${Math.ceil((objSize)/3)}`;
            let commentKey = `comment_${Math.ceil((objSize)/3)}`;
            return{...item, [propertyKey]: "", [refrenceKey]: "", [commentKey]: ""};
        })
        setNRIPStatementData(updateData_7);
        

        //For Repair & Maintenance Table..
        const updateData_5 = repairMaintenanceData.map((item) => {
            let propertyKey = `property_${Math.ceil((objSize)/3)}`;
            let refrenceKey = `refrence_${Math.ceil((objSize)/3)}`;
            let commentKey = `comment_${Math.ceil((objSize)/3)}`;
            return{...item, [propertyKey]: "", [refrenceKey]: "", [commentKey]: ""};
        })
        setRepairMaintenanceData(updateData_5);



        // Depreciatable Item Table..
        const updateData_6 = [...depreciatePropsData]
        updateData_6.push([
            {
                name: "",
                attachment: "",
                wdv_props: [
                    {id: "1", propName: "Cost", value: ""},
                    {id: "2", propName: "Effective Life", value: ""},
                    {id: "3", propName: "No. of days", value: ""},
                    {id: "4", propName: "Depreciation Rate", value: ""},
                    {id: "5", propName: "Depreciation", value: ""},
                    {id: "6", propName: "WDV", value: ""},
                ],
            }
        ])
        setDepreciatePropsData(updateData_6);




        // For Gross Rent..
        const grossRentUpdate = {...grossRentData};
        grossRentUpdate[`property_${Math.ceil((objSize)/3)}`] = 0;
        setGrossRentData(grossRentUpdate);

        // For Total ExpenseData..
        const expenseDataUpdate = {...totalExpenseData};
        expenseDataUpdate[`property_${Math.ceil((objSize)/3)}`] = 0;
        setTotalExpenseData(expenseDataUpdate);

        // For Net RentData..
        const netRentUpdate = {...netRentData};
        netRentUpdate[`property_${Math.ceil((objSize)/3)}`] = 0;
        setNetRentData(netRentUpdate);

        // for totalReconciliationRent..
        const totalReconRentUpdate = {...totalReconciliationRent};
        totalReconRentUpdate[`property_${Math.ceil((objSize)/3)}`] = 0;
        setTotalReconciliationRent(totalReconRentUpdate);

        // for Difference..
        const differenceUpdate = {...differenceObj};
        differenceUpdate[`property_${Math.ceil((objSize)/3)}`] = 0;
        setDifferenceObj(differenceUpdate);

        // for totalRepairMaintain..
        const totalRepMaintain = {...totalRepairMaintain};
        totalRepMaintain[`property_${Math.ceil((objSize)/3)}`] = 0;
        setTotalRepairMaintain(totalRepMaintain);
        
    };

    const deletePropertyColumn = () => {
        const objSize = Object.keys(propertyData[0]).length;

        // For property Table..
        const updateData_1 = propertyData.map((item) => {
            const newItem = {...item};
            delete newItem[`property_${Math.floor((objSize-1)/3)}`];
            delete newItem[`refrence_${Math.floor((objSize-1)/3)}`];
            delete newItem[`comment_${Math.floor((objSize-1)/3)}`];
            return newItem;
        })
        setPropertyData(updateData_1);

        // For Exspenses Table..
        const updateData_2 = expensesData.map((item) => {
            const newItem = {...item};
            delete newItem[`property_${Math.floor((objSize-1)/3)}`];
            delete newItem[`refrence_${Math.floor((objSize-1)/3)}`];
            delete newItem[`comment_${Math.floor((objSize-1)/3)}`];
            return newItem;
        })
        setExpensesData(updateData_2);

        // For IncomeData Table..
        const updateData_3 = incomeDataArray.map((item) => {
            const newItem = {...item};
            delete newItem[`property_${Math.floor((objSize-1)/3)}`];
            delete newItem[`refrence_${Math.floor((objSize-1)/3)}`];
            delete newItem[`comment_${Math.floor((objSize-1)/3)}`];
            return newItem;
        })
        setDataIncome(updateData_3);

        // For Rental Statement Table..
        const updateData_4 = reconciliationRentData.map((item) => {
            const newItem = {...item};
            delete newItem[`property_${Math.floor((objSize-1)/3)}`];
            delete newItem[`refrence_${Math.floor((objSize-1)/3)}`];
            delete newItem[`comment_${Math.floor((objSize-1)/3)}`];
            return newItem;
        })
        setReconciliationRentData(updateData_4);

        //For Repair & Maintenance Table..
        const updateData_5 = repairMaintenanceData.map((item) => {
            const newItem = {...item};
            delete newItem[`property_${Math.floor((objSize-1)/3)}`];
            delete newItem[`refrence_${Math.floor((objSize-1)/3)}`];
            delete newItem[`comment_${Math.floor((objSize-1)/3)}`];
            return newItem;
        })
        setRepairMaintenanceData(updateData_5);


        // Depreciatable Item Table..
        const updateData_6 = [...depreciatePropsData]
        updateData_6.pop();
        setDepreciatePropsData(updateData_6);

        const updateData_7 = NRIPStatementData.map((item) => {
            const newItem = {...item};
            delete newItem[`property_${Math.floor((objSize-1)/3)}`];
            delete newItem[`refrence_${Math.floor((objSize-1)/3)}`];
            delete newItem[`comment_${Math.floor((objSize-1)/3)}`];
            return newItem;
        })
        setNRIPStatementData(updateData_7);



        // For Gross Rent..
        const grossRentUpdate = {...grossRentData};
        delete grossRentUpdate[`property_${Math.floor((objSize-1)/3)}`];
        setGrossRentData(grossRentUpdate);

        // For Total ExpenseData..
        const expenseDataUpdate = {...totalExpenseData};
        delete expenseDataUpdate[`property_${Math.floor((objSize-1)/3)}`];
        setTotalExpenseData(expenseDataUpdate);

        // For Net RentData..
        const netRentUpdate = {...netRentData};
        delete netRentUpdate[`property_${Math.floor((objSize-1)/3)}`];
        setNetRentData(netRentUpdate);

        // for totalReconciliationRent..
        const totalReconRentUpdate = {...totalReconciliationRent};
        delete totalReconRentUpdate[`property_${Math.floor((objSize-1)/3)}`];
        setTotalReconciliationRent(totalReconRentUpdate);

        // for totalRepairMaintain..
        const totalRepMaintain = {...totalRepairMaintain};
        delete totalRepMaintain[`property_${Math.floor((objSize-1)/3)}`];
        setTotalRepairMaintain(totalRepMaintain);

        // for Difference..
        const differenceUpdate = {...differenceObj};
        delete differenceUpdate[`property_${Math.floor((objSize)/3)}`];
        setDifferenceObj(differenceUpdate);
    }


    const SubmitHandler = async() => {
        const userToken = JSON.parse(authCtx.token);
        setLoading(true);

        let sendData = {
            clientId: clientData._id,
            year: clientYear,
            leadSchedule : currentModal,
            preparedDate: clientTableDetails?.preparedDate,
            scheduleRef: clientTableDetails?.scheduleRef,
            staffMember: clientTableDetails?.staffMember,
            managerReview: clientTableDetails?.managerReview,
            partnerReview: clientTableDetails?.partnerReview,

            propertyData: propertyData,
            incomeData: incomeDataArray,
            grossRentData: grossRentData,
            expensesData: expensesData,
            totalExpensesData: totalExpenseData,
            netRentData: netRentData,
            total: totalRent,
            reconciliationRentData: reconciliationRentData,
            totalReconciliationRent: totalReconciliationRent,
            repairMaintenanceData: repairMaintenanceData,
            totalRepairMaintanace: totalRepairMaintain,
            depreciatePropsData: depreciatePropsData,
            NRIPerStatement: NRIPStatementData,
            difference: differenceObj,
        };

        const submitRes = isDataAlready?
        await updateRentByIdAPI(sendData, clientData._id, clientYear, userToken.token)
        :
        await CreateRentAPI(sendData, userToken.token);

        if(submitRes.success === true){
            toast.success(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
            setDataAlready(true);
            setDocsChange(false);
        }else{
            toast.error(`${submitRes.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        setLoading(false);
    }


    return (
        <>
            <SimpleClientTables 
                currentModal={currentModal} 
                clientData={clientData} 
                area={'Lead Schedule: '} 
                clientUpdateYear={clientUpdateYear} 
                clientTableDetails={clientTableDetails}
                setClientTableDetails={setClientTableDetails}
            />


            <PropertyTable 
                propertyData={propertyData} 
                setPropertyData={(val) => setPropertyData(val)}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />

            <RentIncomeTable 
                incomeDataArray={incomeDataArray}
                setDataIncome={(val) => setDataIncome(val)}
                grossRentData={grossRentData}
                setGrossRentData={(val) => setGrossRentData(val)}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />

            <ExpensesTable 
                expensesData={expensesData} 
                setExpensesData={(val)=>setExpensesData(val)}
                totalExpenseData={totalExpenseData}
                setTotalExpenseData={(val)=>setTotalExpenseData(val)}
                netRentData={netRentData}
                setNetRentData={(val)=>setNetRentData(val)}
                grossRentData={grossRentData}
                propertyData={propertyData}
                totalRent={totalRent}
                setTotalRent={(val)=>setTotalRent(val)}
                addPropertyColumn={()=>addPropertyColumn()}
                deletePropertyColumn={()=>deletePropertyColumn()}
                SubmitHandler={()=>SubmitHandler()}
                formulas={formulas}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
                setErrorOpen={(val)=>setErrorOpen(val)}
            />

            <ReconciliationRentTable
                reconciliationRentData={reconciliationRentData} 
                setReconciliationRentData={(val)=>setReconciliationRentData(val)}
                totalReconciliationRent={totalReconciliationRent}
                setTotalReconciliationRent={(val) => setTotalReconciliationRent(val)}
                netRentData={netRentData}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />


            <NRIPStatementTable 
                NRIPStatementData={NRIPStatementData}
                setNRIPStatementData={(val) => setNRIPStatementData(val)}
                differenceObj={differenceObj}
                setDifferenceObj={(val) => setDifferenceObj(val)}
                totalReconciliationRent={totalReconciliationRent}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />


            <RepairMaintenanceTable
                repairMaintenanceData={repairMaintenanceData}
                setRepairMaintenanceData={(val)=>setRepairMaintenanceData(val)}
                totalRepairMaintain={totalRepairMaintain}
                setTotalRepairMaintain={(val)=>setTotalRepairMaintain(val)}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
            />

            <DepreciationonPropertyTable 
                depreciatePropsData={depreciatePropsData}
                setDepreciatePropsData={(val) => setDepreciatePropsData(val)}
                clientUpdateYear={clientUpdateYear}
                formulas={formulas}
                setDocsChange={setDocsChange}
                setLoading={setLoading}
                setErrorOpen={(val)=>setErrorOpen(val)}
            />


            <div className="table-container" style={{justifyContent: 'left', paddingBottom: 80}}>
                <div style={{display: 'flex'}} >
                    <button className="submit-button" onClick={() => {SubmitHandler()}} ><SendIcon style={{fontSize: '14px', verticalAlign: 'middle' }} /> Submit</button>
                </div>
            </div>
            
            <FormulaErrorSnackbar
                open={errorOpen}
                setOpen={(val) => setErrorOpen(val)}
                currentTable="Rent Formulas"
            />

            {(loading)&&
				<div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255, 255, 255, 0.5)'}}>
					<ReactLoading type="spinningBubbles" color="#b224ef"  width={80} />
				</div>
			}
            
        </>
    );
}

export default Rent;