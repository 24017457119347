import React, {useState, useRef } from 'react'
import { Drawer } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Box, useTheme, Button, Typography, IconButton  } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DividendsFormula from './FormulaTables/DividendsFormula';
import CapitalGainsFormula from './FormulaTables/CapitalGainsFormula';
import PartnershipTrustFormula from './FormulaTables/PartnershipTrustFormula';
import RentFormula from './FormulaTables/RentFormula';
import TaxEstimateFormula from "./FormulaTables/TaxEstimateFormula";

const FormulaDrawer = ({drawerOpen, setDrawerOpen, currentDrawer}) => {
    
    return (
        <Drawer 
            size='full'
            placement='right' 
            open={drawerOpen} 
            style={{overflow: 'auto'}} 
        >
            
            <Typography className="headingTitle" style={{fontSize: '20px', fontWeight: '500', color: '#009ec1', margin: '10px 0', textAlign: 'center',}}>{currentDrawer}</Typography>
            
            <IconButton 
                color="primary" 
                aria-label="close drawer" 
                size="large"
                style={{position: 'absolute', top: 10, right: 10,}}
                onClick={() => setDrawerOpen(false)}
            >
                <CloseIcon />
            </IconButton>

            
            <div style={{backgroundColor: '#fff'}}>
                {(currentDrawer ==='Dividends')&&
                    <DividendsFormula />
                }
                {(currentDrawer ==='Capital Gains')&&
                    <CapitalGainsFormula />
                }
                {(currentDrawer ==='Partnerships and Trusts')&&
                    <PartnershipTrustFormula />
                }
                {(currentDrawer === 'Rent')&&
                    <RentFormula />
                }
                {(currentDrawer === 'Tax Estimate')&&
                    <TaxEstimateFormula />
                }
            </div>


        </Drawer>
    )
}

export default FormulaDrawer;